import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from 'react';
import { MaintenanceAreaExtended } from "../models/MaintenanceAreaExtended";

interface CustomUpdateSectionsCellProperties {
    handleUpdateAreaSectionsClicked: (dataItem: MaintenanceAreaExtended) => void,
    handleCancelUpdateAreaSectionsClicked: (dataItem: MaintenanceAreaExtended) => void,
    handleValidateUpdateAreaSectionsClicked: (dataItem: MaintenanceAreaExtended) => void
}

export const CustomUpdateSectionsCell = (props: GridCellProps & CustomUpdateSectionsCellProperties): JSX.Element => {

    if (props.dataItem.isEditingSections) {
        return (
            <td className="confirm-cancel-update-area-sections">
                <Button className="btn-cancel" onClick={() => props.handleCancelUpdateAreaSectionsClicked(props.dataItem)} >
                    <ArrowBackIcon className="btn-icon" />
                </Button>
                <Button className="btn-validate" onClick={() => props.handleValidateUpdateAreaSectionsClicked(props.dataItem)} >
                    <CheckIcon className="btn-icon" />
                </Button>
            </td>
        );
    }
    else {
        return (
            <td className="update-area-sections">
                <Button className={`btn-area-sections ${props.dataItem.sections.length > 0 ? 'sections' : ''}`} onClick={() => props.handleUpdateAreaSectionsClicked(props.dataItem)} />
            </td>
        );
    }
}