import { faFileExcel, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, Input, InputAdornment } from '@mui/material';
import { orderBy as sortOrderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridItemChangeEvent, GridPageChangeEvent, GridRowProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { debounce } from 'lodash';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import iconAdd from 'src/assets/icons/icon-add.svg';
import iconConeHeaderDrawerClosed from 'src/assets/icons/icon-cone-header-drawer-closed.svg';
import iconConeHeader from 'src/assets/icons/icon-cone-header.svg';
import iconDelete from 'src/assets/icons/icon-delete.svg';
import iconDrawerClosed from 'src/assets/icons/icon-drawer-closed.svg';
import iconDrawerOpened from 'src/assets/icons/icon-drawer-opened.svg';
import Translate, { Localization } from '../../../localization/Localization';
import { downloadFileFromBlob, getFileName } from '../../../utils/DownloadFile';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { AppModule, LocalStorage } from '../../../utils/Storage';
import { AreaSectionsAndSummaryModel } from '../models/AreaSectionsAndSummaryModel';
import { MaintenanceAreaExtended } from '../models/MaintenanceAreaExtended';
import { MaintenanceScenarioExtended } from '../models/MaintenanceScenarioExtended';
import { MergedProjectVersion } from '../models/MergedProjectVersion';
import { TypeOfWorkSelectModel } from '../models/TypeOfWorkSelectModel';
import { MaintenanceArea } from '../services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceArea';
import { MaintenanceAreaSection } from '../services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceAreaSection';
import { MaintenanceAreaStatus } from '../services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceAreaStatus';
import { MaintenanceScenariosApiClient } from '../services/MaintenanceScenarios/MaintenanceScenariosApiClient';
import { SizingUtilities } from '../SizingUtilities';
import { ConfirmDeleteDialog, Mode } from './ConfirmDeleteDialog';
import { ConfirmUpdateAreaSectionsDialog } from './ConfirmUpdateAreaSectionsDialog';
import { CustomAreaStatusCell } from './CustomAreaStatusCell';
import { CustomColorPickerCell } from './CustomColorPickerCell';
import { CustomDatePickerCell } from './CustomDatePickerCell';
import { CustomDisplayAreaSections } from './CustomDisplayAreaSections';
import { CustomScoreColorIndicatorCell } from './CustomScoreColorIndicatorCell';
import { CustomSelectCell } from './CustomSelectCell';
import { CustomUpdateSectionsCell } from './CustomUpdateSectionsCell';
import { MaintenanceAreaNumericCell } from './MaintenanceAreaNumericCell';
import { CellRender, RowRender } from './Renderers';
import './ScenarioManagementStyles.scss';

interface ScenarioManagementComponentProps {
    isOpen: boolean,
    selectedScenario: MaintenanceScenarioExtended,
    goodScoreThreshold: number,
    poorScoreThreshold: number,
    roadsConditionViewData: MergedProjectVersion,
    typesOfWorks: string[],
    selectedSectionsMap: Map<number, number>,
    displayedAreasIds: Map<number, number>,
    areasPaletteColors: string[],
    highlightedAreaId: number,
    handleDrawerOpened: () => void,
    handleDrawerClosed: () => void,
    handleDisplayOrHideSelectedAreaSection: (maintenanceAreaId: number, sections: MaintenanceAreaSection[], isDisplayAreaSection: boolean) => void,
    handleAreaSectionsEditStarted: (maintenanceArea: MaintenanceAreaExtended) => void,
    handleAreaSectionsEditCanceled: (maintenanceArea: MaintenanceAreaExtended) => void,
    handleAddOrUpdateArea: (maintenanceArea: MaintenanceAreaExtended, maintenanceScenarioId: number, isAdd: boolean, isStatusChanged: boolean) => Promise<void>,
    handleDeleteArea: (maintenanceScenarioId: number, maintenanceAreaId: number) => Promise<void>,
    handleAddAreaCanceled: (maintenanceArea: MaintenanceAreaExtended) => void
}

//TODO HGA CMA voir si c'est encore nécessaire après mise à jour de package
function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const EDIT_FIELD = "inEditField";
const ScenarioAreasGridName = 'scenarioAreas';
const ModuleKey = AppModule.MaintenanceScenarios;

export const ScenarioManagementComponent = (props: ScenarioManagementComponentProps): JSX.Element => {

    const forceUpdate = useForceUpdate();

    const measurementSystemType = MeasurementSystem.getCurrentType();

    const { isOpen, selectedScenario, displayedAreasIds, areasPaletteColors, handleDrawerOpened, handleDrawerClosed } = props;

    let initialSort: SortDescriptor[] = [{ field: 'label', dir: 'desc' }];

    const [areas, setAreas] = useState<MaintenanceAreaExtended[]>(selectedScenario ? selectedScenario.areas as MaintenanceAreaExtended[] : []);
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [skip, setSkip] = useState<number>(0);
    const [isConfirmDeleteDialogOpened, setIsConfirmDeleteDialogOpened] = useState<boolean>(false);
    const [isConfirmUpdateAreaSectionsDialogOpened, setIsConfirmUpdateAreaSectionsDialogOpened] = useState<boolean>(false);
    const [dataItemToDelete, setDataItemToDelete] = useState<MaintenanceAreaExtended>(null);
    const [dataItemToUpdateSections, setDataItemToUpdateSections] = useState<MaintenanceAreaExtended>(null);
    const [typesOfWorksUsedInUserProject, setTypesOfWorksUsedInUserProject] = useState<TypeOfWorkSelectModel[]>([]);
    const [typesOfWorksUsedInScenario, setTypesOfWorksUsedInScenario] = useState<TypeOfWorkSelectModel[]>([]);
    const [typeOfWorkFilterValue, setTypeOfWorkFilterValue] = useState<TypeOfWorkSelectModel>({ label: '', value: '' });

    const inputSearchScenarioAreasRef: MutableRefObject<HTMLInputElement> = useRef(null);

    useEffect(() => {
        if (selectedScenario) {
            let areas: MaintenanceAreaExtended[] = [];
            let isEnlightened: boolean = false;
            selectedScenario.areas.forEach((area: MaintenanceArea) => {
                isEnlightened = displayedAreasIds.has(area.maintenanceAreaId);
                let areaExtended: MaintenanceAreaExtended = {
                    ...area,
                    defaultLabel: area.label,
                    inEditField: undefined,
                    isVisible: true,
                    isHighlighted: area.maintenanceAreaId === props.highlightedAreaId ? true : false,
                    labelLowerWithoutDiacritics: area.label ? area.label.toLowerCase().removeDiacritics() : null,
                    remarksLowerWithoutDiacritics: area.remarks ? area.remarks.toLowerCase().removeDiacritics() : null,
                    isEditingSections: false,
                    isEnlightened: isEnlightened,
                    hiddenStatusForSort: getHiddenStatusForSortValue(area.status)
                }

                areas.push(areaExtended);
            });

            setAreas(areas);
        }
        else {
            setAreas([]);
        }
        setTypeOfWorkFilterValue({ label: '', value: '' });
        updateTypesOfWorksUsedInScenario(selectedScenario);
    }, [selectedScenario]);

    useEffect(() => {
        var typesOfWorks: TypeOfWorkSelectModel[] = [];
        props.typesOfWorks.forEach((typeOfWork: string) => {
            if (typeOfWork) {
                typesOfWorks.push({ label: typeOfWork, value: typeOfWork });
            }
        });
        setTypesOfWorksUsedInUserProject(typesOfWorks);
        setTypeOfWorkFilterValue({ label: '', value: '' });
    }, [props.typesOfWorks]);

    let selectedScenarioLabel = selectedScenario ? selectedScenario.label : '';

    const updateTypesOfWorksUsedInScenario = (selectedScenario: MaintenanceScenarioExtended): void => {
        let typesOfWorks: TypeOfWorkSelectModel[] = [];
        if (selectedScenario) {
            selectedScenario.areas.forEach((area) => {
                if (area.typeOfWork && !typesOfWorks.some(x => x.label === area.typeOfWork)) {
                    typesOfWorks.push({ label: area.typeOfWork, value: area.typeOfWork })
                }
            });
        }

        setTypesOfWorksUsedInScenario(typesOfWorks);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        let sort = e.sort[0];
        if (sort && sort.field === "status") {
            setSort([{ field: 'hiddenStatusForSort', dir: sort.dir }, sort]);
            return;
        }
        setSort(e.sort);
    }

    const getHiddenStatusForSortValue = (status: MaintenanceAreaStatus): number => {
        switch (status) {
            case MaintenanceAreaStatus.draft:
                return 0;
            case MaintenanceAreaStatus.workToDo:
                return 1;
            case MaintenanceAreaStatus.workDone:
                return 2;
            default:
                return -1;
        }
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const addNewScenarioArea = async (): Promise<void> => {
        const newArea = {} as MaintenanceAreaExtended;
        newArea.typeOfWork = null;
        newArea.budgetAmount = null;
        newArea.unitPrice = null;
        newArea.startDate = null;
        newArea.remarks = null;
        newArea.hexColor = "#858889"; //couleur par défaut à la création

        var areaSectionsAndSummary: AreaSectionsAndSummaryModel = getAreaSectionsAndSummaryFromSelectedSections();
        newArea.sections = areaSectionsAndSummary.sections;
        newArea.label = getDefaultLabelOfNewArea(areaSectionsAndSummary);
        newArea.defaultLabel = newArea.label;
        newArea.lengthInLinearMeters = areaSectionsAndSummary.lengthInLinearMeters;
        newArea.lengthInLinearMetersIsOverridden = false;
        newArea.areaInSquareMeters = areaSectionsAndSummary.areaInSquareMeters;
        newArea.areaInSquareMetersIsOverridden = false;
        newArea.averageScore = areaSectionsAndSummary.averageScore ?? null;
        newArea.status = MaintenanceAreaStatus.draft;
        newArea.hiddenStatusForSort = getHiddenStatusForSortValue(MaintenanceAreaStatus.draft);

        var isAdd = true;
        var isStatusChanged = false;
        await props.handleAddOrUpdateArea(newArea, selectedScenario.maintenanceScenarioId, isAdd, isStatusChanged);
    }

    const getDefaultLabelOfNewArea = (areaSectionsAndSummary: AreaSectionsAndSummaryModel): string => {
        let label: string = "";
        let sections = areaSectionsAndSummary.sections;
        if (sections.length > 0) {
            var roadLabels = areaSectionsAndSummary.roadLabels;
            label = roadLabels.length > 1 ? `${roadLabels[0]} - ${roadLabels[roadLabels.length - 1]}` : roadLabels[0];
        }
        else {
            label = Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_NewArea;
        }

        return label;
    }

    const handleCanceledAreaDeleteDialog = (): void => {
        setIsConfirmDeleteDialogOpened(false);
        setDataItemToDelete(null);
    }

    const handleDeleteAreaClicked = (dataItem: MaintenanceAreaExtended): void => {
        setIsConfirmDeleteDialogOpened(true);
        setDataItemToDelete(dataItem);
    }

    const handleConfirmedAreaDeleteDialog = async (): Promise<void> => {
        await props.handleDeleteArea(selectedScenario.maintenanceScenarioId, dataItemToDelete.maintenanceAreaId);
        setIsConfirmDeleteDialogOpened(false);
        setDataItemToDelete(null);
    }

    const handleCanceledUpdateAreaSectionsDialog = (): void => {
        setIsConfirmUpdateAreaSectionsDialogOpened(false);
        setDataItemToUpdateSections(null);
    }

    const handleConfirmedUpdateAreaSectionsDialog = (): void => {
        notifyAreaSectionEditStarted(dataItemToUpdateSections);
        setIsConfirmUpdateAreaSectionsDialogOpened(false);
        setDataItemToUpdateSections(null);
    }

    const handleUpdateAreaSectionClicked = (dataItem: MaintenanceAreaExtended): void => {
        if (props.selectedSectionsMap.size > 0) {
            setIsConfirmUpdateAreaSectionsDialogOpened(true);
            setDataItemToUpdateSections(dataItem);
            return;
        }

        notifyAreaSectionEditStarted(dataItem);
        setDataItemToUpdateSections(null);
    }

    const resetAreasEditStatus = (dataItem: MaintenanceAreaExtended, inEditField?: string, editingSections?: boolean): MaintenanceAreaExtended[] => {
        return areas.map((item) => ({
            ...item,
            inEditField: inEditField && item.maintenanceAreaId === dataItem.maintenanceAreaId ? inEditField : undefined,
            isHighlighted: item.maintenanceAreaId === dataItem.maintenanceAreaId ? true : false,
            isEditingSections: editingSections ? (item.maintenanceAreaId === dataItem.maintenanceAreaId ? true : false) : item.isEditingSections
        }));
    }

    const notifyAreaSectionEditStarted = (dataItem: MaintenanceAreaExtended): void => {
        const newAreas = resetAreasEditStatus(dataItem, null, true);
        setAreas(newAreas);

        props.handleAreaSectionsEditStarted(dataItem);
    }

    const handleStartDateChanged = (dataItem: MaintenanceAreaExtended): void => {
        var oldItem = selectedScenario.areas.find(x => x.maintenanceAreaId === dataItem.maintenanceAreaId);
        if (!Date.equals(oldItem.startDate, dataItem.startDate)) {
            updateArea(dataItem);
        }
        else {
            const newAreas = resetAreasEditStatus(dataItem);
            setAreas(newAreas);
        }
    }

    const handleColorChanged = (dataItem: MaintenanceAreaExtended): void => {
        var oldItem = selectedScenario.areas.find(x => x.maintenanceAreaId === dataItem.maintenanceAreaId);
        if (dataItem.hexColor !== oldItem.hexColor) {
            updateArea(dataItem);
        }
        else {
            const newAreas = resetAreasEditStatus(dataItem);
            setAreas(newAreas);
        }
    }

    const enterEdit = (dataItem: MaintenanceAreaExtended, field: string): void => {
        const newAreas = resetAreasEditStatus(dataItem, field);
        setAreas(newAreas);
    }

    const exitEdit = (): void => {
        var item = areas.find(x => x.inEditField !== undefined);
        if (item) {
            var shouldUpdate = true;
            var oldItem = selectedScenario.areas.find(x => x.maintenanceAreaId === item.maintenanceAreaId);
            switch (item.inEditField) {
                case "label":
                    if (!item.label.trim()) {
                        item.label = item.defaultLabel;
                        const newAreas = resetAreasEditStatus(item);
                        setAreas(newAreas);
                        return;
                    }
                    shouldUpdate = oldItem.label.trim() !== item.label.trim();
                    item.defaultLabel = item.label;
                    break;

                case "budgetAmount":
                    shouldUpdate = oldItem.budgetAmount !== item.budgetAmount;
                    if (shouldUpdate) {
                        if (item.areaInSquareMeters) {
                            item.unitPrice = item.budgetAmount / item.areaInSquareMeters;
                        }

                        if (!item.budgetAmount) {
                            item.unitPrice = null;
                        }
                    }
                    break;

                case "unitPrice":
                    shouldUpdate = oldItem.unitPrice !== item.unitPrice;
                    if (shouldUpdate) {
                        if (item.areaInSquareMeters) {
                            item.budgetAmount = item.unitPrice * item.areaInSquareMeters;
                        }

                        if (!item.unitPrice) {
                            item.budgetAmount = null;
                        }
                    }
                    break;

                case "lengthInLinearMeters":
                    shouldUpdate = oldItem.lengthInLinearMeters !== item.lengthInLinearMeters;
                    if (shouldUpdate) {
                        item.lengthInLinearMetersIsOverridden = true;
                    }
                    break;

                case "areaInSquareMeters":
                    shouldUpdate = oldItem.areaInSquareMeters !== item.areaInSquareMeters;
                    if (shouldUpdate) {
                        if (!item.areaInSquareMeters) {
                            item.areaInSquareMetersIsOverridden = false;
                            item.budgetAmount = null;
                            item.unitPrice = null;
                        }
                        else {
                            item.areaInSquareMetersIsOverridden = true;
                            if (item.unitPrice) {
                                item.budgetAmount = item.unitPrice * item.areaInSquareMeters;
                            }
                        }
                    }
                    break;

                case "typeOfWork":
                    shouldUpdate = oldItem.typeOfWork !== item.typeOfWork;
                    break;

                case "remarks":
                    shouldUpdate = oldItem.remarks !== item.remarks;
                    break;

                default:
                    break;
            }

            if (shouldUpdate) {
                updateArea(item);
            }
            else {
                const newAreas = resetAreasEditStatus(item);
                setAreas(newAreas);
            }
        }
    }

    const handleUpdateAreaStatus = (dataItem: MaintenanceAreaExtended, status: MaintenanceAreaStatus): void => {
        dataItem.status = status;
        dataItem.hiddenStatusForSort = getHiddenStatusForSortValue(status);
        let isStatusChanged = true;
        updateArea(dataItem, isStatusChanged);
    }

    const updateArea = async (item: MaintenanceAreaExtended, isStatusChanged?: boolean): Promise<void> => {
        var isAdd = false;
        await props.handleAddOrUpdateArea(item, selectedScenario.maintenanceScenarioId, isAdd, isStatusChanged ?? false);
    }

    const itemChange = (event: GridItemChangeEvent): void => {
        let field = event.field || "";
        event.dataItem[field] = event.value;
        let newAreas = areas.map((item) => {
            if (item.maintenanceAreaId === event.dataItem.maintenanceAreaId) {
                item[field] = event.value;
            }
            return item;
        });
        setAreas(newAreas);
    }

    const customCellRender = (
        td: React.ReactElement<HTMLTableCellElement>,
        props: GridCellProps
    ) => (
        <CellRender
            originalProps={props}
            td={td}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
        />
    );

    const customRowRender = (
        tr: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => (
        <RowRender
            originalProps={props}
            tr={tr}
            exitEdit={exitEdit}
            editField={EDIT_FIELD}
        />
    );

    const handleCancelUpdateAreaSectionsClicked = (dataItem: MaintenanceAreaExtended): void => {
        dataItem.isEditingSections = false;
        props.handleAreaSectionsEditCanceled(dataItem);
        setDataItemToUpdateSections(null);
    }

    const handleValidateUpdateAreaSectionsClicked = (dataItem: MaintenanceAreaExtended): void => {
        dataItem.isEditingSections = false;

        var areaSectionsAndSummary: AreaSectionsAndSummaryModel = getAreaSectionsAndSummaryFromSelectedSections();
        dataItem.sections = areaSectionsAndSummary.sections;
        dataItem.lengthInLinearMeters = areaSectionsAndSummary.lengthInLinearMeters;
        dataItem.lengthInLinearMetersIsOverridden = false;
        dataItem.areaInSquareMeters = areaSectionsAndSummary.areaInSquareMeters;
        dataItem.areaInSquareMetersIsOverridden = false;
        dataItem.averageScore = areaSectionsAndSummary.averageScore;

        if (dataItem.unitPrice) {
            dataItem.budgetAmount = dataItem.unitPrice * dataItem.areaInSquareMeters;
        }
        else if (dataItem.budgetAmount) {
            dataItem.unitPrice = dataItem.budgetAmount / dataItem.areaInSquareMeters
        }

        updateArea(dataItem);
    }

    const getAreaSectionsAndSummaryFromSelectedSections = (): AreaSectionsAndSummaryModel => {
        var sections: MaintenanceAreaSection[] = [];
        var weightedScoreSum: number = null;
        var lengthInMeters: number = null;
        var lengthInMetersScored: number = null;
        var surface: number = null;
        var averageScore: number = null;
        var roadLabels: string[] = [];

        props.selectedSectionsMap.forEach((value, key) => {
            var roadSectionId = key;
            var roadSection = props.roadsConditionViewData.roadsSections.get(roadSectionId);
            var roadSectionScoreId = roadSection.roadSectionScoreId;

            if (!roadLabels.includes(roadSection.roadLabel)) {
                roadLabels.push(roadSection.roadLabel);
            }

            sections.push({
                roadSectionId: roadSectionId,
                roadSectionScoreId: roadSectionScoreId,
                pathGeometry: null
            });

            weightedScoreSum += roadSection.lengthInMeters * roadSection.score;
            lengthInMeters += roadSection.lengthInMeters;
            lengthInMetersScored += roadSection.score !== null ? roadSection.lengthInMeters : 0;
            surface += roadSection.lengthInMeters * roadSection.roadExtended.widthInMeters;
        });

        if (weightedScoreSum && lengthInMetersScored) {
            averageScore = weightedScoreSum / lengthInMetersScored;
        }

        return {
            sections: sections,
            averageScore: averageScore,
            lengthInLinearMeters: Math.round(lengthInMeters),
            areaInSquareMeters: Math.round(surface),
            roadLabels: roadLabels
        };
    }

    const handleHideAreaSections = (dataItem: MaintenanceAreaExtended): void => {
        var isEnlightened = false;
        displayOrHideSelectedAreaSection(dataItem, isEnlightened);
    }

    const handleDisplayAreaSections = (dataItem: MaintenanceAreaExtended): void => {
        var isEnlightened = true;
        displayOrHideSelectedAreaSection(dataItem, isEnlightened);
    }

    const displayOrHideSelectedAreaSection = (dataItem: MaintenanceAreaExtended, isEnlightened: boolean): void => {
        dataItem.isEnlightened = isEnlightened;

        const newAreas = resetAreasEditStatus(dataItem);
        setAreas(newAreas);

        props.handleDisplayOrHideSelectedAreaSection(dataItem.maintenanceAreaId, dataItem.sections, isEnlightened);
    }

    const handleTypeOfWorkFilterChanges = (e: TypeOfWorkSelectModel): void => {
        setTypeOfWorkFilterValue(e ? e : { label: '', value: '' });
        var areasArray = [...areas];
        updateAreasVisibility(areasArray, e ? e.value : null, inputSearchScenarioAreasRef.current.value);
    }

    const handleClearScenarioAreasSearchText = (): void => {
        inputSearchScenarioAreasRef.current.value = "";
        var areasArray = [...areas];
        updateAreasVisibility(areasArray, typeOfWorkFilterValue.value, "");
        setAreas(areasArray);
    }

    const handleChangeScenarioAreasSearchText = debounce((value: string): void => {
        let inputValue = value;
        var areasArray = [...areas];
        if (inputValue.length > 2) {
            updateAreasVisibility(areasArray, typeOfWorkFilterValue.value, inputValue);
        }
        else if (inputValue.length === 0) {
            updateAreasVisibility(areasArray, typeOfWorkFilterValue.value, "");
        }

        setAreas(areasArray);
    }, 500);

    const updateAreasVisibility = (areas: MaintenanceAreaExtended[], typeOfWork: string, inputSearchText: string): void => {
        let searchText = inputSearchText.trim().toLowerCase().removeDiacritics();

        areas.forEach((area) => {
            if (((typeOfWork && area.typeOfWork === typeOfWork) || !typeOfWork) &&
                (area.labelLowerWithoutDiacritics?.includes(searchText) || (area.remarksLowerWithoutDiacritics?.includes(searchText)))) {
                area.isVisible = true;
            }
            else {
                area.isVisible = false;
            }
        });
    }

    const handleExportScenario = (): void => {
        MaintenanceScenariosApiClient.ExportExcel(selectedScenario.maintenanceScenarioId, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const onColumnResizeHandler = (event: GridColumnResizeEvent): void => {
        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            ScenarioAreasGridName,
            event.columns.find(c => c.orderIndex === event.index).field,
            event.newWidth);
    }

    const getScenarioAreasGridWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, ScenarioAreasGridName, fieldName, columnWidth);
    }

    const onColumnRorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, ScenarioAreasGridName, event.columns);
        forceUpdate();
    }

    const getScenarioAreasGridOrderIndexColumn = (propName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, ScenarioAreasGridName, propName, defaultIndex);
    }

    var isAreaUpdateSectionsActive: boolean = areas.some(x => x.isEditingSections);

    var gridOffsetFromWindowTop: number = SizingUtilities.scenarioAreasGridOffsetFromWindowTop();
    var gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    var rowHeight: number = SizingUtilities.rowHeight;
    var gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    var gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
        forceUpdate();
    }
    window.onresize = resize;

    var filteredAreas = areas.filter(x => x.isVisible === true);
    var dataGrid = sortOrderBy(filteredAreas, sort).slice(skip, skip + gridPageSize);

    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();
    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();

    return (
        <>
            {!isOpen &&
                <Box className="closed-drawer-content" display="flex" flexDirection="column" alignItems="center" onClick={() => handleDrawerOpened()}>
                    <img className="open-drawer-icon" src={iconDrawerClosed} alt="icon drawer closed" />
                    <Box className="closed-drawer-label">
                        <img src={iconConeHeaderDrawerClosed} alt="icon cone header drawer closed" />
                        {selectedScenarioLabel}
                    </Box>
                </Box>
            }
            {isOpen &&
                <Box display="flex" flexDirection="column" className="opened-scenario-management-drawer-content">
                    <Box className="header" display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" width="85%" alignItems="center">
                            <img src={iconConeHeader} alt="icon cone header" />
                            <div className="title">{selectedScenarioLabel}</div>
                        </Box>
                        <IconButton onClick={() => handleDrawerClosed()}>
                            <img src={iconDrawerOpened} alt="icon drawer opened" />
                        </IconButton>
                    </Box>
                    <Box display="flex" flexDirection="row" className="filters">
                        <Box className="search-input">
                            <Input disableUnderline className="input-search-scenario-areas" inputRef={inputSearchScenarioAreasRef}
                                endAdornment={<>
                                    <InputAdornment position="end" classes={{ root: 'input-icon-close-root' }}>
                                        <FontAwesomeIcon icon={faTimes} onClick={handleClearScenarioAreasSearchText} />
                                    </InputAdornment>
                                    <InputAdornment position="end" classes={{ root: 'input-icon-search-root' }}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputAdornment>
                                </>}
                                id="input-search-scenario-areas-text"
                                placeholder={Translate.Resources.UI_Home_AuscultationSummaryDrawer_Search_Placeholder}
                                onChange={(event) => handleChangeScenarioAreasSearchText(event.target.value)}
                            />
                        </Box>
                        <Select
                            className="type-of-work"
                            classNamePrefix="select"
                            value={typeOfWorkFilterValue}
                            isSearchable
                            menuPosition="fixed"
                            options={typesOfWorksUsedInScenario}
                            onChange={handleTypeOfWorkFilterChanges}
                            isClearable
                        />
                    </Box>
                    <Box className="scenario-areas-list">
                        <Box display="flex" flexDirection="row" justifyContent="start" className="scenario-areas-grid-content">
                            <LocalizationProvider language={Localization.locale}>
                                <IntlProvider locale={Localization.locale}>
                                    <Grid
                                        className="scenario-areas-grid"
                                        data={dataGrid}
                                        sortable
                                        resizable={true}
                                        reorderable={true}
                                        editField={EDIT_FIELD}
                                        selectedField="isHighlighted"
                                        sort={sort}
                                        scrollable="virtual"
                                        skip={skip}
                                        total={filteredAreas.length}
                                        rowHeight={rowHeight}
                                        pageSize={gridPageSize}
                                        dataItemKey="maintenanceAreaId"
                                        style={gridStyle}
                                        onPageChange={pageChange}
                                        onSortChange={handleSortChange}
                                        onItemChange={itemChange}
                                        cellRender={customCellRender}
                                        rowRender={customRowRender}
                                        onColumnResize={onColumnResizeHandler}
                                        onColumnReorder={onColumnRorderHandler}
                                    >
                                        <GridColumn field="areaScoreColor" orderIndex={getScenarioAreasGridOrderIndexColumn("areaScoreColor", 0)} width={getScenarioAreasGridWidth("areaScoreColor", 8)} sortable={false} editable={false} headerClassName="no-sortable"
                                            cell={(cellProps: GridCellProps) => <CustomScoreColorIndicatorCell {...cellProps} poorScoreThreshold={props.poorScoreThreshold} goodScoreThreshold={props.goodScoreThreshold} />}
                                        />
                                        <GridColumn field="displayAreaSections" orderIndex={getScenarioAreasGridOrderIndexColumn("displayAreaSections", 1)} width={getScenarioAreasGridWidth("displayAreaSections", 70)} sortable={false} editable={false} headerClassName="no-sortable"
                                            cell={(cellProps: GridCellProps) => <CustomDisplayAreaSections {...cellProps}
                                                handleHideAreaSections={handleHideAreaSections}
                                                handleDisplayAreaSections={handleDisplayAreaSections}
                                            />}
                                        />
                                        <GridColumn field="updateSections" orderIndex={getScenarioAreasGridOrderIndexColumn("updateSections", 2)} width={getScenarioAreasGridWidth("updateSections", 70)} sortable={false} editable={false} headerClassName="no-sortable"
                                            cell={(cellProps: GridCellProps) => <CustomUpdateSectionsCell {...cellProps}
                                                handleUpdateAreaSectionsClicked={handleUpdateAreaSectionClicked}
                                                handleCancelUpdateAreaSectionsClicked={handleCancelUpdateAreaSectionsClicked}
                                                handleValidateUpdateAreaSectionsClicked={handleValidateUpdateAreaSectionsClicked}
                                            />}
                                        />
                                        <GridColumn field="label" orderIndex={getScenarioAreasGridOrderIndexColumn("label", 3)} width={getScenarioAreasGridWidth("label", 200)} title={Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Grid_Zone} />
                                        <GridColumn field="status" editable={false} orderIndex={getScenarioAreasGridOrderIndexColumn("status", 4)} width={getScenarioAreasGridWidth("status", 200)} title={Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Grid_Status}
                                            cell={(cellProps: GridCellProps) => <CustomAreaStatusCell {...cellProps} handleUpdateAreaStatus={handleUpdateAreaStatus} />}
                                        />
                                        <GridColumn field="typeOfWork" editable={false} orderIndex={getScenarioAreasGridOrderIndexColumn("typeOfWork", 5)} width={getScenarioAreasGridWidth("typeOfWork", 150)} title={Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Grid_TypeOfWork}
                                            cell={(cellProps: GridCellProps) => <CustomSelectCell {...cellProps} typeOfWorkOptions={typesOfWorksUsedInUserProject} />}
                                        />
                                        <GridColumn field="hexColor" title="" editable={false} sortable={false} headerClassName="no-sortable" orderIndex={getScenarioAreasGridOrderIndexColumn("hexColor", 6)} width={getScenarioAreasGridWidth("hexColor", 100)}
                                            cell={(cellProps: GridCellProps) => <CustomColorPickerCell {...cellProps} paletteColors={areasPaletteColors} handleColorChanged={handleColorChanged} />}
                                        />
                                        <GridColumn field="averageScore" orderIndex={getScenarioAreasGridOrderIndexColumn("averageScore", 7)} width={getScenarioAreasGridWidth("averageScore", 90)} editable={false} title={Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Grid_Score}
                                            cell={(cellProps: GridCellProps) => <td>{cellProps.dataItem.averageScore?.toFixedLocalized(2)}</td>}
                                        />
                                        <GridColumn field="budgetAmount" orderIndex={getScenarioAreasGridOrderIndexColumn("budgetAmount", 8)} width={getScenarioAreasGridWidth("budgetAmount", 120)} title={Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Scenarios_grid_Budget}
                                            cell={(cellProps: GridCellProps) => <MaintenanceAreaNumericCell {...cellProps} format="n2" />}
                                        />
                                        <GridColumn field="unitPrice" orderIndex={getScenarioAreasGridOrderIndexColumn("unitPrice", 9)} width={getScenarioAreasGridWidth("unitPrice", 120)} editable={true} title={Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Grid_UP_Surf}
                                            cell={(cellProps: GridCellProps) => <MaintenanceAreaNumericCell {...cellProps} format="n2" />}
                                        />
                                        <GridColumn field="lengthInLinearMeters" orderIndex={getScenarioAreasGridOrderIndexColumn("lengthInLinearMeters", 10)} width={getScenarioAreasGridWidth("lengthInLinearMeters", 120)} title={`${Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Grid_Linear} (${symbolOfLengthUnit})`}
                                            cell={(cellProps: GridCellProps) => <MaintenanceAreaNumericCell {...cellProps} format="n0" />}
                                        />
                                        <GridColumn field="areaInSquareMeters" orderIndex={getScenarioAreasGridOrderIndexColumn("areaInSquareMeters", 11)} width={getScenarioAreasGridWidth("areaInSquareMeters", 120)} title={`${Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Grid_Surface} (${symbolOfSurfaceUnit})`}
                                            cell={(cellProps: GridCellProps) => <MaintenanceAreaNumericCell {...cellProps} format="n0" />}
                                        />
                                        <GridColumn field="startDate" orderIndex={getScenarioAreasGridOrderIndexColumn("startDate", 12)} width={getScenarioAreasGridWidth("startDate", 150)} title={Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Grid_Period}
                                            cell={(cellProps: GridCellProps) => <CustomDatePickerCell {...cellProps} handleStartDateChanged={handleStartDateChanged} />}
                                        />
                                        <GridColumn field="remarks" orderIndex={getScenarioAreasGridOrderIndexColumn("remarks", 13)} width={getScenarioAreasGridWidth("remarks", 200)} title={Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Grid_Comments} />
                                        <GridColumn field="delete" orderIndex={getScenarioAreasGridOrderIndexColumn("delete", 14)} sortable={false} width={getScenarioAreasGridWidth("delete", 70)} editable={false} headerClassName="no-sortable"
                                            cell={(cellProps: GridCellProps) => <td><div className="delete-cell"><Button onClick={() => handleDeleteAreaClicked(cellProps.dataItem)}><img src={iconDelete} alt="icon delete" /></Button></div></td>}
                                        />
                                    </Grid>
                                </IntlProvider>
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" className="add-and-export-scenario-area">
                        <Button className={`btn-add-scenario-area ${!selectedScenario || isAreaUpdateSectionsActive ? 'disabled' : ''}`} onClick={() => addNewScenarioArea()} disabled={!selectedScenario || isAreaUpdateSectionsActive}>
                            <img src={iconAdd} alt="icon add" />
                            {Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Button_AddAnArea}
                        </Button>
                        <Button className={`btn-export ${!selectedScenario ? 'disabled' : ''} `} onClick={handleExportScenario} disabled={!selectedScenario}>
                            <FontAwesomeIcon icon={faFileExcel} />
                            {Translate.Resources.UI_Home_AuscultationSummaryDrawer_Checkbox_Export}
                        </Button>
                        <Box display="flex" flexDirection="column" ml="5px">
                            <div className="budget-scenario-title">{Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_label_ScenarioBudget}</div>
                            <div className="budget-scenario-value">{`${selectedScenario?.budgetAmount?.toLocaleString() ?? ''}`}</div>
                        </Box>
                    </Box>
                </Box>
            }
            {isConfirmDeleteDialogOpened &&
                <ConfirmDeleteDialog
                    mode={Mode.DeleteArea}
                    isOpen={isConfirmDeleteDialogOpened}
                    handleCanceled={handleCanceledAreaDeleteDialog}
                    handleConfirmed={handleConfirmedAreaDeleteDialog}
                />
            }
            {isConfirmUpdateAreaSectionsDialogOpened &&
                <ConfirmUpdateAreaSectionsDialog
                    isOpen={isConfirmUpdateAreaSectionsDialogOpened}
                    handleCanceled={handleCanceledUpdateAreaSectionsDialog}
                    handleConfirmed={handleConfirmedUpdateAreaSectionsDialog}
                />
            }
        </>
    );
}