import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import React from 'react';
import { ImageExtended } from '../models/ImageExtended';

interface ImageComponentProps {
    image: ImageExtended,
    selectedImage: ImageExtended,
    scoringValue: number[],
    handleChangeLabeledImage: (imageId: number) => void
}

export const ImageComponent = (props: ImageComponentProps): JSX.Element => {
    const { selectedImage, image, scoringValue, handleChangeLabeledImage } = props;

    return (
        <Box display="flex" flexDirection="column" className="image-item-card" onClick={() => handleChangeLabeledImage(image?.imageId)}>
            <Box className="image-item-content">
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className={selectedImage.imageId === image?.imageId ? "image-title selected" : "image-title"}>{image?.referenceImageId}</Box>
                <Box className="image-content" display="flex" flexDirection="row" alignItems="center">
                    <img src={`/api/RoadsCondition/GetImageContent?publicImageId=${image?.publicImageId}`} alt="content" className="img" />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" className="image-score-item-content">
                {/* à partir de la valeur 8 le texte avec la valeur du score s'affichera avant la flèche. ceci afin que la valeur de score ne déborde pas.*/}
                {image?.score !== null && image?.score <= 8 &&
                    <Box display="flex" flexDirection="row" alignItems="center" className="image-score-indication" ml={`calc(${((image?.score > 10 ? 10 : image?.score) * 10)}% - 5px)`}>
                        <FontAwesomeIcon className="arrow-icon" icon={faArrowDown} size="1x" />
                        {image?.score.toFixedLocalized(2)}
                    </Box>
                }
                {image?.score !== null && image?.score > 8 &&
                    <Box display="flex" flexDirection="row" alignItems="center" className="image-score-indication" ml={`calc(${((image?.score > 10 ? 10 : image?.score) * 10)}% - 40px)`}>
                        {image?.score.toFixedLocalized(2)}
                        <FontAwesomeIcon className="arrow-icon" icon={faArrowDown} size="1x" />
                    </Box>
                }
                {image?.score === null &&
                    <Box className="image-score-indication"></Box>
                }
                <Box display="flex" flexDirection="row" className="score-line">
                    <Box width={`${(scoringValue[0] * 100) / 10}%`} className="good"></Box>
                    <Box width={`${(((scoringValue[1] * 100) / 10) - ((scoringValue[0] * 100) / 10))}%`} className="to-monitor"></Box>
                    <Box width={`${((10 - scoringValue[1]) * 100) / 10}%`} className="bad"></Box>
                </Box>
                <Box display="flex" flexDirection="row" width="100%">
                    <Box className="score-indication" ml={`calc(${(scoringValue[0] * 10)}% - 10px)`}>{scoringValue[0]}</Box>
                    <Box className="score-indication" ml={`calc(${(scoringValue[1] * 10)}% - 10px - ${(scoringValue[0] * 10)}% - 10px)`}>{scoringValue[1]}</Box>
                </Box>
            </Box>
        </Box>
    )
}