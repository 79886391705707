import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MailIcon from '@mui/icons-material/Mail';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import scenarioIcon from 'src/assets/icons/icon-scenario.svg';
import workDoneAreaConeIcon from 'src/assets/icons/icon-work-done-area-cone.svg';
import workToDoAreaConeIcon from 'src/assets/icons/icon-work-to-do-area-cone.svg';
import Translate from '../../../localization/Localization';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { MaintenanceAreaStatus } from '../../RoadsConditionAndScenarios/services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceAreaStatus';
import { AreaShapeProperties } from '../models/AreaShapeProperties';
import './RoadWorksInformationDrawerStyles.scss';
import { SendRoadWorksAreaMessageDialog } from './SendRoadWorksAreaMessageDialog';

interface RoadWorksInformationDrawerProps {
    selectedMaintenanceAreas: Map<number, AreaShapeProperties>,
    handleClose: () => void,
    handleAreaEnlightenmentChange: (maintenanceAreaId: number) => void
}

const AccordionContentElement = (props: { title: string, value: string, handleMailIconClick?: () => void }): JSX.Element => {
    return (
        <Box display="flex" flexDirection="row" alignItems="center" className="content" mb="10px">
            <Box width="40%" className="title">{props.title}</Box>
            <Box display="flex" flexDirection="row" alignItems="center" width="60%" className="value">
                {props.value}
                {props.handleMailIconClick &&
                    <MailIcon className="mail-icon" onClick={props.handleMailIconClick} />
                }
            </Box>
        </Box>
    );
}

const AccordionContentStatusElement = (props: { title: string, value: string, status: MaintenanceAreaStatus }): JSX.Element => {
    let valueClassName = "value";

    let status = props.status;
    if (status) {
        valueClassName += ` ${status}`;
    }

    return (
        <Box display="flex" flexDirection="row" className="content" alignItems="center" mb="10px">
            <Box width="40%" className="title">{props.title}</Box>
            <Box display="flex" flexDirection="row" className={valueClassName}>
                <img src={scenarioIcon} alt="cone" />
                <div>{props.value}</div>
            </Box>
        </Box>
    );
}

export const RoadWorksInformationDrawer = (props: RoadWorksInformationDrawerProps): JSX.Element => {

    const [isSendRoadWorksAreaMessageDialogOpened, setIsSendRoadWorksAreaMessageDialogOpened] = useState<boolean>(false);
    const [selectedAreaForSendingMessage, setSelectedAreaForSendingMessage] = useState<AreaShapeProperties>(null);

    const handleDisplayOrHideAreaSections = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, maintenanceAreaId: number): void => {
        e.stopPropagation();
        props.handleAreaEnlightenmentChange(maintenanceAreaId);
    }

    const getAreaStatusLabel = (status: MaintenanceAreaStatus): string => {
        switch (status) {
            case MaintenanceAreaStatus.workToDo:
                return Translate.Resources.UI_RoadWorksInformationDrawer_WorkToDo;
            case MaintenanceAreaStatus.workDone:
                return Translate.Resources.UI_RoadWorksInformationDrawer_WorkDone;
            default:
                return "";
        }
    }

    const handleMailIconClick = (area: AreaShapeProperties): void => {
        setIsSendRoadWorksAreaMessageDialogOpened(true);
        setSelectedAreaForSendingMessage(area);
    }

    const handleAreaMessageDialogClosed = (): void => {
        setIsSendRoadWorksAreaMessageDialogOpened(false);
        setSelectedAreaForSendingMessage(null);
    }

    let selectedMaintenanceAreas = Array.from(props.selectedMaintenanceAreas);

    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();
    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();

    return (
        <Box className="road-works-information" display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="header">
                <div className="drawer-title">{Translate.Resources.UI_RoadWorksInformationDrawer_Title_WorkArea}</div>
                <IconButton onClick={props.handleClose} aria-label="close" className="btn-close">
                    <div>{Translate.Resources.UI_RoadWorksInformationDrawer_Close}</div>
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                </IconButton>
            </Box>
            <Box className="maintenance-areas">
                {selectedMaintenanceAreas.map(([key, maintenanceArea]) => {
                    let length = maintenanceArea.lengthInLinearMeters ? MeasurementSystem.getLengthInMetersOrYards(maintenanceArea.lengthInLinearMeters).toFixedLocalized(0) : '';
                    let area = maintenanceArea.areaInSquareMeters ? MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(maintenanceArea.areaInSquareMeters).toFixedLocalized(0) : '';
                    return (
                        <Accordion key={`area-${key}`} defaultExpanded={true}>
                            <AccordionSummary className="summary"
                                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                            >
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    {(maintenanceArea.status === MaintenanceAreaStatus.workDone ? <img src={workDoneAreaConeIcon} alt="work done area cone icon" /> :
                                        (maintenanceArea.status === MaintenanceAreaStatus.workToDo ? <img src={workToDoAreaConeIcon} alt="work to do area cone icon" /> : ''))
                                    }
                                    <div className="summary-title">{maintenanceArea.label}</div>
                                    <Button className={maintenanceArea.isEnlightened ? "btn-hide-area-sections" : "btn-display-area-sections"} onClick={(e) => handleDisplayOrHideAreaSections(e, maintenanceArea.maintenanceAreaId)} />
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails className="details">
                                <Box display="flex" flexDirection="column" width="100%">
                                    <AccordionContentStatusElement title={Translate.Resources.UI_RoadWorksInformationDrawer_Status} value={getAreaStatusLabel(maintenanceArea.status)} status={maintenanceArea.status} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_ScenarioName} value={maintenanceArea.scenarioLabel} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_Period} value={maintenanceArea.startDate ? `${(maintenanceArea.startDate.getMonth() + 1)}/${maintenanceArea.startDate.getFullYear()}` : ''} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_TypeOfWork} value={maintenanceArea.typeOfWork} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_Budget} value={maintenanceArea.budgetAmount?.toLocaleString()} />
                                    <AccordionContentElement title={`${Translate.Resources.UI_RoadWorksInformationDrawer_Linear} (${symbolOfLengthUnit})`} value={`${length} ${symbolOfLengthUnit}`} />
                                    <AccordionContentElement title={`${Translate.Resources.UI_RoadWorksInformationDrawer_SurfaceArea} (${symbolOfSurfaceUnit})`} value={`${area} ${symbolOfSurfaceUnit}`} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_PreparedBy} value={maintenanceArea.ownerUserFullName} handleMailIconClick={() => handleMailIconClick(maintenanceArea)} />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
            {isSendRoadWorksAreaMessageDialogOpened &&
                <SendRoadWorksAreaMessageDialog
                    isSendRoadWorksAreaMessageDialogOpened={isSendRoadWorksAreaMessageDialogOpened}
                    area={selectedAreaForSendingMessage}
                    handleSendRoadWorksAreaMessageDialogClosed={() => handleAreaMessageDialogClosed()}
                />
            }
        </Box>
    )
}