import { Box, Button, Tooltip } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { Component } from 'react';
import { ScaleLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';
import Translate, { Localization } from "../../localization/Localization";
import ToastService from '../../ToastService';
import '../../utils/Date';
import Utilities from '../../utils/Utilities';
import { RouteComponentProps, withRouter } from '../../withRouter';
import { AddOrUpdateUserDialog } from './components/AddOrUpdateUserDialog';
import { UserDialogMode } from './models/UserDialogMode';
import { UserGridModel } from './models/UserGridModel';
import { UserSignupInvitationRequestArgs } from './services/dataContracts/controller/UserSignupInvitationRequestArgs';
import { UserModel } from './services/dataContracts/queryStack/UserModel';
import { UsersAdminApiClient } from './services/UsersAdminApiClient';
import './UsersAdminStyles.scss';

interface UsersAdminViewState {
    rows: UserGridModel[],
    loading: boolean,
    selectedUser: UserGridModel,
    sort: SortDescriptor[],
    isAddOrUpdateUserDialogOpened: boolean,
    userDialogMode: UserDialogMode
}

export class UsersAdminView extends Component<RouteComponentProps, UsersAdminViewState> {
    _isMounted: boolean;

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            loading: false,
            selectedUser: null,
            sort: [{ field: 'lastName', dir: 'asc' }],
            isAddOrUpdateUserDialogOpened: false,
            userDialogMode: null
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getUsers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getUsers = (): void => {
        this.setState({
            loading: true
        });

        UsersAdminApiClient.GetUsers()
            .then((res) => {
                if (this._isMounted && res.data) {
                    var listUsers: UserGridModel[] = [];
                    res.data.forEach((user: UserModel) => {
                        listUsers.push({
                            ...user,
                            civilityText: user.civility === "Mr" ? Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_Civility_Value_Mr : (user.civility === "Mrs" ? Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_Civility_Value_Mrs : ""),
                            formatedPhoneNumber: Utilities.formatPhoneNumber(user.phoneNumber),
                            lastSignupInvitationSentDateText: user.lastSignupInvitationSentDate ? (user.lastSignupInvitationSentDate.toLocaleDateString() + " " + user.lastSignupInvitationSentDate.toLocaleTimeString()) : '',
                            selected: false
                        });
                    });

                    this.setState({
                        rows: listUsers,
                        loading: false,
                        selectedUser: null
                    });
                }
            });
    }

    onRowClick = (e: GridRowClickEvent): void => {
        var data: UserGridModel = e.dataItem;
        var rowsArray = [...this.state.rows];

        if (data.userId === this.state.selectedUser?.userId && (e.syntheticEvent as any).ctrlKey) {
            data.selected = false;

            this.setState({
                selectedUser: null
            });
        } else {
            var index = rowsArray.findIndex(x => x.selected);
            if (index !== -1) {
                rowsArray[index].selected = false;
            }
            data.selected = true;
            this.setState({
                selectedUser: data,
                rows: rowsArray
            });
        }
    }

    handleSortChange = (e: GridSortChangeEvent): void => {
        this.setState({
            sort: e.sort
        });
    }

    sendSignupInvitation = (): void => {
        const { selectedUser } = this.state;

        if (selectedUser) {
            var requestArgs: UserSignupInvitationRequestArgs = {
                userId: selectedUser.userId,
                lastName: selectedUser.lastName,
                fullName: selectedUser.fullName,
                civility: selectedUser.civility,
                email: selectedUser.email,
                language: Localization.locale
            };

            UsersAdminApiClient.SendSignupInvitation(requestArgs)
                .then((res) => {
                    if (res.status === 200 && res.data) {
                        ToastService.showSuccessToast(Translate.Resources.UI_UsersAdmin_SnackBar_Success_Message);
                        this.getUsers();
                    } else {
                        ToastService.showErrorToast(Translate.Resources.UI_UsersAdmin_SnackBar_Error_Message);
                    }
                })
                .catch(() => {
                    ToastService.showErrorToast(Translate.Resources.UI_UsersAdmin_SnackBar_Error_Message);
                });
        }
    }

    canBeInvitedOrUpdated = (user: UserGridModel): boolean => {
        if (user?.userRoleId === "CLI"
            || user?.userRoleId === "CLIPLUS"
            || user?.userRoleId === "PSP")
            return true;

        return false;
    }

    onClickHomeLogo = (): void => {
        let urlRedirect = "/";
        this.props.navigate(urlRedirect);
    }

    handleAddUserClicked = (): void => {
        this.setState({
            isAddOrUpdateUserDialogOpened: true,
            userDialogMode: UserDialogMode.Add
        });
    }

    handleUpdateUserClicked = (): void => {
        this.setState({
            isAddOrUpdateUserDialogOpened: true,
            userDialogMode: UserDialogMode.Update
        });
    }

    handleAddOrUpdateUserDialogClosed = (): void => {
        this.setState({
            isAddOrUpdateUserDialogOpened: false,
            userDialogMode: null
        });
    }

    handleAfterUserAddedOrUpdated = (): void => {
        this.getUsers();
        this.handleAddOrUpdateUserDialogClosed();
    }

    render() {
        const { rows, loading, sort, selectedUser, isAddOrUpdateUserDialogOpened, userDialogMode } = this.state;

        return (
            <Box className="users-admin" display="flex" flexDirection="column">
                <Box className="users-grid-label">{`${Translate.Resources.UI_UsersAdmin_UsersGrid_GridTitle_UsersList} : `}</Box>
                {loading &&
                    <Box display="flex" flexDirection="column" alignItems="center" className="loader">
                        <ScaleLoader
                            width={5}
                            height={20}
                            radius={50}
                            color="#000000"
                            loading={loading}
                        />
                    </Box>
                }
                <Box display="flex" flexDirection="row">
                    <Box className="btn-action">
                        <Tooltip title={selectedUser ? (this.canBeInvitedOrUpdated(selectedUser) ? Translate.Resources.UI_UsersAdmin_SendButton_Tooltip_SendInvitation : Translate.Resources.UI_UsersAdmin_SendButton_Tooltip_OnlyUsersWithACLIProfileCanReceiveAnInvitation) : Translate.Resources.UI_UsersAdmin_SendButton_Tooltip_PleaseSelectAUser} placement="top">
                            <span>
                                <Button variant="contained" color="primary" disabled={!this.canBeInvitedOrUpdated(selectedUser)} onClick={this.sendSignupInvitation}>
                                    {Translate.Resources.UI_UsersAdmin_SendButton_SendInvitation}
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                    <Box className="btn-action">
                        <Button variant="contained" color="primary" onClick={this.handleAddUserClicked}>
                            {Translate.Resources.UI_UsersAdmin_AddButton_Add}
                        </Button>
                    </Box>
                    <Box className="btn-action">
                        <Button variant="contained" color="primary" disabled={!this.canBeInvitedOrUpdated(selectedUser)} onClick={this.handleUpdateUserClicked}>
                            {Translate.Resources.UI_UsersAdmin_UpdateButton_Update}
                        </Button>
                    </Box>
                </Box>
                <Box className="users-grid">
                    <LocalizationProvider language={Localization.locale}>
                        <IntlProvider locale={Localization.locale}>
                            <Grid
                                className="grid"
                                data={orderBy(rows, sort)}
                                selectedField="selected"
                                scrollable="scrollable"
                                sortable={true}
                                sort={sort}
                                onRowClick={(e) => this.onRowClick(e)}
                                onSortChange={(e) => this.handleSortChange(e)}
                            >
                                <GridColumn field="lastName" orderIndex={0} width={150} title={Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_LastName}
                                    cell={(properties: GridCellProps) =>
                                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                                            {properties.dataItem.lastName}
                                        </td>
                                    }
                                />
                                <GridColumn field="firstName" orderIndex={1} width={150} title={Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_FirstName}
                                    cell={(properties: GridCellProps) =>
                                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                                            {properties.dataItem.firstName}
                                        </td>
                                    }
                                />
                                <GridColumn field="civilityText" orderIndex={2} width={150} title={Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_Civility}
                                    cell={(properties: GridCellProps) =>
                                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                                            {properties.dataItem.civilityText}
                                        </td>
                                    }
                                />
                                <GridColumn field="formatedPhoneNumber" orderIndex={3} width={200} title={Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_PhoneNumber}
                                    cell={(properties: GridCellProps) =>
                                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                                            {properties.dataItem.formatedPhoneNumber}
                                        </td>
                                    }
                                />
                                <GridColumn field="email" orderIndex={4} width={300} title={Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_Email}
                                    cell={(properties: GridCellProps) =>
                                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                                            {properties.dataItem.email}
                                        </td>
                                    }
                                />
                                <GridColumn field="userRoleId" orderIndex={5} width={150} title={Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_Profile}
                                    cell={(properties: GridCellProps) =>
                                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                                            {properties.dataItem.userRoleId}
                                        </td>
                                    }
                                />
                                <GridColumn field="isEnabled" orderIndex={6} width={100} title={Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_Status}
                                    cell={(properties: GridCellProps) => {
                                        let isEnabled = properties.dataItem.isEnabled;
                                        return (
                                            <td className={!isEnabled ? "cell-disabled" : ""}>
                                                {isEnabled ? Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_value_Active : Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_value_Inactive}
                                            </td>
                                        )
                                    }}
                                />
                                <GridColumn field="customerId" orderIndex={7} width={100} title={Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_CustomerId}
                                    cell={(properties: GridCellProps) =>
                                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                                            {properties.dataItem.customerId}
                                        </td>
                                    }
                                />
                                <GridColumn field="customerName" orderIndex={8} width={200} title={Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_CustomerLabel}
                                    cell={(properties: GridCellProps) =>
                                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                                            {properties.dataItem.customerName}
                                        </td>
                                    }
                                />
                                <GridColumn field="lastSignupInvitationSentDateText" orderIndex={9} width={250} title={Translate.Resources.UI_UsersAdmin_UsersGrid_Columns_InvitationSendingDate}
                                    cell={(properties: GridCellProps) =>
                                        <td className={!properties.dataItem.isEnabled ? "cell-disabled" : ""}>
                                            {properties.dataItem.lastSignupInvitationSentDateText}
                                        </td>
                                    }
                                />
                            </Grid>
                        </IntlProvider>
                    </LocalizationProvider>
                </Box>
                {isAddOrUpdateUserDialogOpened &&
                    <AddOrUpdateUserDialog
                        isAddOrUpdateUserDialogOpened={isAddOrUpdateUserDialogOpened}
                        title={userDialogMode && (userDialogMode === UserDialogMode.Add ? "Ajouter un utilisateur externe" : (userDialogMode === UserDialogMode.Update ? "Modifier un utilisateur externe" : ""))}
                        mode={userDialogMode}
                        selectedUser={selectedUser}
                        handleAddOrUpdateUserDialogClosed={this.handleAddOrUpdateUserDialogClosed}
                        handleAfterUserAddedOrUpdated={this.handleAfterUserAddedOrUpdated}
                    />
                }
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(UsersAdminView));