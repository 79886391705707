import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React from 'react';
import Translate from 'src/localization/Localization';
import './AlgorithmsModelVersionInfoDialog.scss';

interface AlgorithmsModelVersionInfoDialogProps {
  auscultations: {
    videoDateTime?: Date
    anonymizerVersion?: string
    damageDetectorVersion?: string
  } []
  projectLabel: string
  versionNumber: number
  isOpen: boolean
  toggleIsDialogOpen: () => void
}

export const AlgorithmsModelVersionInfoDialog = (props: AlgorithmsModelVersionInfoDialogProps): JSX.Element => {
  const { 
    auscultations,
    projectLabel,
    versionNumber,
    isOpen,
    toggleIsDialogOpen
  } = props;

  if (!projectLabel) {
    return null;
  }
  
  const data = auscultations.map((auscultation) => ({
    statementDate: auscultation.videoDateTime?.toLocaleDateString() ?? 'n/a',
    anonymizerVersion: auscultation.anonymizerVersion ?? 'n/a',
    damageDetectorVersion: auscultation.damageDetectorVersion ?? 'n/a',
  })).reverse();

  return (
    <Box className="algorithms-model-version-info-dialog">
      <Dialog
        open={isOpen}
        onClose={toggleIsDialogOpen}
        className="algorithms-model-version-info-dialog__dialog"
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box mr="2rem" className="font-bold">{Translate.Resources.UI_AlgorithmsModelVersionInfoDialog_VersioningOfAlgos}</Box>
            <IconButton onClick={toggleIsDialogOpen} aria-label="close">
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </IconButton>
          </Box>      
        </DialogTitle>

        <DialogContent>
          <Grid data={data}>
            <GridColumn 
              headerClassName="grid-header--center font-primary" 
              className="grid-cell--center"
              field="statementDate" 
              title={Translate.Resources.UI_AlgorithmsModelVersionInfoDialog_StatementDate} 
            />
            <GridColumn 
              headerClassName="grid-header--center font-primary" 
              className="grid-cell--center"
              field="anonymizerVersion" 
              title={Translate.Resources.UI_AlgorithmsModelVersionInfoDialog_AnonymizerVersion} 
            />
            <GridColumn 
              headerClassName="grid-header--center font-primary"
              className="grid-cell--center" 
              field="damageDetectorVersion" 
              title={Translate.Resources.UI_AlgorithmsModelVersionInfoDialog_DamageDetectorVersion} 
            />
          </Grid>
          <Box marginY="1rem" display="flex" justifyContent="center">
            <span className="font-primary font-bold">{Translate.Resources.UI_AlgorithmsModelVersionInfoDialog_ProjectVersion} : &nbsp;</span>
            <span className="font-bold">{`${projectLabel} v${versionNumber}`}</span>
          </Box>
        </DialogContent>
      </Dialog> 
    </Box>
  )
}