import { GridCellProps } from "@progress/kendo-react-grid";
import React, { useState } from 'react';
import Select, { InputActionMeta } from 'react-select';
import { TypeOfWorkSelectModel } from "../models/TypeOfWorkSelectModel";

interface CustomSelectCellProperties {
    typeOfWorkOptions: TypeOfWorkSelectModel[]
}

export const CustomSelectCell = (props: GridCellProps & CustomSelectCellProperties): JSX.Element => {

    const { dataItem, field, render } = props;
    const isInEdit = field === dataItem.inEditField;

    const [typeOfWork, setTypeOfWork] = useState<TypeOfWorkSelectModel>(dataItem.typeOfWork ?
        { label: dataItem.typeOfWork, value: dataItem.typeOfWork } :
        { label: "", value: "" });
    const [typeOfWorkInputValue, setTypeOfWorkInputValue] = useState<string>(dataItem.typeOfWork ?? "");

    const handleChange = (e: TypeOfWorkSelectModel): void => {
        setTypeOfWork(e);
        setTypeOfWorkInputValue(e.value);
    }

    const handleInputChange = (value, inputActionMeta: InputActionMeta): void => {
        if (inputActionMeta.action === "input-change") {
            setTypeOfWorkInputValue(value);
        }
    }

    const handleBlur = (): void => {
        dataItem.typeOfWork = typeOfWorkInputValue !== '' ? typeOfWorkInputValue : null;
    }

    const defaultRendering = (
        <td>
            {isInEdit ? (
                <Select
                    className="type-of-work"
                    classNamePrefix="select"
                    value={typeOfWork}
                    inputValue={typeOfWorkInputValue}
                    isSearchable
                    name="type-of-work"
                    menuPosition="fixed"
                    options={props.typeOfWorkOptions}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onBlur={handleBlur}
                />
            ) : <>{dataItem.typeOfWork}</>}
        </td >
    );

    return render?.call(undefined, defaultRendering, props);
}