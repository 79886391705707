import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@mui/material';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import iconNextImageWhite from 'src/assets/icons/icon-next-image-white.svg';
import iconNextSection from 'src/assets/icons/icon-next-section.svg';
import iconPreviousImageWhite from 'src/assets/icons/icon-previous-image-white.svg';
import iconPreviousSection from 'src/assets/icons/icon-previous-section.svg';
import Translate from '../../../localization/Localization';
import { AppModule, LocalStorage } from '../../../utils/Storage';
import { ScoringParameter } from '../../Home/services/dataContracts/queryStack/ScoringParameter';
import { ImageAnomalyGridModel } from '../models/ImageAnomalyGridModel';
import { ImageExtended } from '../models/ImageExtended';
import { RoadSectionViewData } from '../models/RoadSectionViewData';
import { ScoreColors } from '../models/ScoreColors';
import { ImageAnomaly } from '../services/RoadsCondition/dataContracts/queryStack/ImageAnomaly';
import { ImageComponent } from './ImageComponent';
import { LabeledImagesDialog } from './LabeledImagesDialog';

const showOnlyPathologiesEnteringIntoScoreCalculationKey = 'imageAnomalies.showOnlyPathologiesEnteringIntoScoreCalculation';
const ModuleKey = AppModule.Home;

interface LabeledImagesComponentProps {
    isScenarioView: boolean,
    selectedRoadSectionLabel: string,
    selectedRoadSection: RoadSectionViewData,
    openedAuscultationsSelectorWithRoadsDrawer: boolean,
    openedScenarioManagementDrawer: boolean,
    openedScenariosSelectorDrawer: boolean,
    selectedImage: ImageExtended,
    hasScoreAnalysisAccess: boolean,
    role: string,
    thresholdOfGoodScore: number,
    thresholdOfPoorScore: number,
    projectLabel: string,
    handleChangeImage: (imageId: number) => void,
    handleSectionChanged: (selectedImage: ImageExtended, searchByNext: boolean) => void,
    handleCloseLabeledImageComponent: () => void
}

export const LabeledImagesComponent = (props: LabeledImagesComponentProps): JSX.Element => {

    const { selectedImage, selectedRoadSection, selectedRoadSectionLabel, openedAuscultationsSelectorWithRoadsDrawer, openedScenarioManagementDrawer, openedScenariosSelectorDrawer,
        handleCloseLabeledImageComponent } = props;

    let scoringValue: number[] = [props.thresholdOfGoodScore, props.thresholdOfPoorScore];
    var scoringParameters = selectedRoadSection.roadExtended?.scoringParameters;
    var showOnlyPathologiesEnteringIntoScoreCalculationLocalStorage = LocalStorage.GetItem(ModuleKey, showOnlyPathologiesEnteringIntoScoreCalculationKey);
    var showOnlyPathologiesEnteringIntoScoreCalculationChecked = showOnlyPathologiesEnteringIntoScoreCalculationLocalStorage === null ? false : (showOnlyPathologiesEnteringIntoScoreCalculationLocalStorage === "false" ? false : true);

    const [labeledImages, setLabeledImages] = useState<ImageExtended[]>([]);
    const [imageAnomalies, setImageAnomalies] = useState<ImageAnomalyGridModel[]>([]);
    const [filteredImageAnomalies, setFilteredImageAnomalies] = useState<ImageAnomalyGridModel[]>([]);
    const [showOnlyPathologiesEnteringIntoScoreCalculation, setShowOnlyPathologiesEnteringIntoScoreCalculation] = useState<boolean>(showOnlyPathologiesEnteringIntoScoreCalculationChecked);
    const [isImageDetailsDialogOpened, setIsImageDetailsDialogOpened] = useState<boolean>(false);


    useEffect(() => {
        var labeledImagesArray = selectedRoadSection.images;
        labeledImagesArray = orderBy(labeledImagesArray, [(i: ImageExtended) => { return i.referenceImageId.toLowerCase() }], ['asc']);
        var index: number = labeledImagesArray.findIndex(x => x.imageId === selectedImage.imageId);
        labeledImagesArray = labeledImagesArray.length < 5 ? labeledImagesArray : (index >= 3 ? labeledImagesArray.slice(index - 3, index + 1) : labeledImagesArray.slice(0, 4));
        setLabeledImages(labeledImagesArray);

        if (isImageDetailsDialogOpened && props.hasScoreAnalysisAccess) {
            getImageAnomalies(selectedImage);
        }
    }, [selectedImage]);

    const handleChangeLabeledImage = (imageId: number): void => {
        setIsImageDetailsDialogOpened(true);
        if (imageId !== selectedImage.imageId) {
            props.handleChangeImage(imageId);
            return;
        }

        if (props.hasScoreAnalysisAccess) {
            getImageAnomalies(selectedImage);
        }
    }

    const getImageAnomalies = (selectedImage: ImageExtended): void => {
        var anomalies: ImageAnomalyGridModel[] = [];
        var scoringParameter: ScoringParameter = null;

        selectedImage.imageAnomalies.forEach((item: ImageAnomaly) => {
            scoringParameter = scoringParameters.get(item.anomalyType);
            anomalies.push({
                anomalyType: Translate.GetAnomalyTypeLabel(item.anomalyType),
                confidence: item.confidence,
                confidencePercentage: `${(item.confidence * 100).toFixedLocalized(1)} %`,
                confidenceThreshold: scoringParameter.confidenceThreshold,
                confidenceThresholdPercentage: `${(scoringParameter.confidenceThreshold * 100).toFixedLocalized(1)} %`,
                coefficient: scoringParameter.weight,
                area: `${(item.area * 100).toFixedLocalized(1)} %`
            });
        });

        setImageAnomalies(anomalies);

        var filteredAnomalies = getFilteredImageAnomalies(anomalies, showOnlyPathologiesEnteringIntoScoreCalculation);
        setFilteredImageAnomalies(filteredAnomalies);
    }

    const getFilteredImageAnomalies = (anomalies: ImageAnomalyGridModel[], showOnlyPathologiesEnteringIntoScoreCalculation: boolean): ImageAnomalyGridModel[] => {
        var anomaliesArray: ImageAnomalyGridModel[] = anomalies;
        if (showOnlyPathologiesEnteringIntoScoreCalculation) {
            anomaliesArray = anomaliesArray.filter(x => x.coefficient > 0 && x.confidence >= x.confidenceThreshold);
        }

        return anomaliesArray;
    }

    const handleChangeShowOnlyPathologiesEnteringIntoScoreCalculation = (): void => {
        setShowOnlyPathologiesEnteringIntoScoreCalculation(!showOnlyPathologiesEnteringIntoScoreCalculation);
        LocalStorage.SetItem(ModuleKey, showOnlyPathologiesEnteringIntoScoreCalculationKey, '' + !showOnlyPathologiesEnteringIntoScoreCalculation);

        var filteredAnomalies = getFilteredImageAnomalies(imageAnomalies, !showOnlyPathologiesEnteringIntoScoreCalculation);
        setFilteredImageAnomalies(filteredAnomalies);
    }

    let labeledImagesClassName = 'labeled-images';
    let imagesContentClassName = 'images-content';

    if (props.isScenarioView) {
        labeledImagesClassName += ' labeled-images-scenario-view';

        if (!openedAuscultationsSelectorWithRoadsDrawer && !openedScenarioManagementDrawer && openedScenariosSelectorDrawer) {
            labeledImagesClassName += ' labeled-images-scenario-view-width-with-only-opened-scenarios-selector-drawer';
        }
        else if (!openedAuscultationsSelectorWithRoadsDrawer && openedScenarioManagementDrawer && !openedScenariosSelectorDrawer) {
            labeledImagesClassName += ' labeled-images-scenario-view-width-with-only-opened-scenario-management-drawer';
        }
        else if (!openedAuscultationsSelectorWithRoadsDrawer && openedScenarioManagementDrawer && openedScenariosSelectorDrawer) {
            labeledImagesClassName += ' labeled-images-scenario-view-width-with-opened-scenarios-selector-and-opened-scenario-management-dawers';
        }
        else if (openedAuscultationsSelectorWithRoadsDrawer && !openedScenarioManagementDrawer && !openedScenariosSelectorDrawer) {
            labeledImagesClassName += ' labeled-images-scenario-view-width-with-only-opened-auscultations-selector-with-roads-drawer';
        }
        else if (openedAuscultationsSelectorWithRoadsDrawer && !openedScenarioManagementDrawer && openedScenariosSelectorDrawer) {
            labeledImagesClassName += ' labeled-images-scenario-view-width-with-opened-scenarios-selector-and-opened-auscultations-selector-with-roads-drawers';
        }
        else if (openedAuscultationsSelectorWithRoadsDrawer && openedScenarioManagementDrawer && !openedScenariosSelectorDrawer) {
            labeledImagesClassName += ' labeled-images-scenario-view-width-with-opened-scenario-management-and-opened-auscultations-selector-with-roads-drawers';
        }
        else if (openedAuscultationsSelectorWithRoadsDrawer && openedScenarioManagementDrawer && openedScenariosSelectorDrawer) {
            labeledImagesClassName += ' labeled-images-scenario-view-width-with-opened-drawers';
        }
    }
    else {
        labeledImagesClassName += ' labeled-images-releve-view';
        labeledImagesClassName += props.openedAuscultationsSelectorWithRoadsDrawer ? ' opened-auscultations-selector-with-roads-drawer' : '';
        imagesContentClassName += props.openedAuscultationsSelectorWithRoadsDrawer ? ' opened-auscultations-selector-with-roads-drawer' : ' closed-auscultations-selector-with-roads-drawer';
    }

    let sectionAnomalies = selectedRoadSection.trustedAnomaliesCounters ? Array.from(selectedRoadSection.trustedAnomaliesCounters) : [];

    return (
        <Box className={labeledImagesClassName} display="flex" flexDirection="column">
            <Box className="road" display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box className={`road-score ${selectedRoadSection.scoreColor === ScoreColors.poor ? 'bad' : (selectedRoadSection.scoreColor === ScoreColors.toMonitor ? 'to-monitor' : (selectedRoadSection.scoreColor === ScoreColors.good ? 'good' : 'undetermined'))}`}>{selectedRoadSection.score !== null ? selectedRoadSection.score.toFixedLocalized(2) : "n/a"}</Box>
                    <Box className="road-label">{selectedRoadSectionLabel ?? ''}</Box>
                    {props.hasScoreAnalysisAccess &&
                        <Box display="flex" flexDirection="row" marginLeft="5px">
                            {sectionAnomalies.map(([key, anomaly]) => {
                                if (anomaly.counter > 0 && anomaly.scoringWeight > 0)
                                    return (
                                        <Box className="anomaly-item entering-into-score" key={`anomalie-${key}`} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                            <Box>{Translate.GetAnomalyTypeLabel(key)}</Box>
                                            <Box>{anomaly.counter}</Box>
                                        </Box>
                                    );
                                return (<React.Fragment key={`anomalie-${key}`} />);
                            })}
                            {sectionAnomalies.map(([key, anomaly]) => {
                                if (anomaly.counter > 0 && (anomaly.scoringWeight === null || anomaly.scoringWeight === 0))
                                    return (
                                        <Box className="anomaly-item" key={`anomalie-${key}`} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                            <Box>{Translate.GetAnomalyTypeLabel(key)}</Box>
                                            <Box>{anomaly.counter}</Box>
                                        </Box>
                                    );
                                return (<React.Fragment key={`anomalie-${key}`} />);
                            })}
                        </Box>
                    }
                </Box>
                <Box>
                    <IconButton size="small" onClick={() => handleCloseLabeledImageComponent()}>
                        <FontAwesomeIcon size="1x" icon={faTimes} color="#1DB3DD" />
                    </IconButton>
                </Box>
            </Box>
            <hr />
            <Box display="flex" flexDirection="row" className="images">
                <Box display="flex" flexDirection="column" justifyContent="center" className="previous-section">
                    <IconButton onClick={() => props.handleSectionChanged(selectedImage, false)}>
                        <img src={iconPreviousSection} alt="icon previous section" />
                    </IconButton>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" className="previous-image">
                    <IconButton onClick={() => props.handleChangeImage(selectedImage.previousImageId)}>
                        <img src={iconPreviousImageWhite} alt="icon previous white" />
                    </IconButton>
                </Box>
                <Box className={imagesContentClassName} display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="displayed-images">
                        {labeledImages[0]?.imageId && <ImageComponent
                            image={labeledImages[0]}
                            selectedImage={selectedImage}
                            scoringValue={scoringValue}
                            handleChangeLabeledImage={() => handleChangeLabeledImage(labeledImages[0].imageId)}
                        />}
                        {labeledImages[1]?.imageId && <ImageComponent
                            image={labeledImages[1]}
                            selectedImage={selectedImage}
                            scoringValue={scoringValue}
                            handleChangeLabeledImage={() => handleChangeLabeledImage(labeledImages[1].imageId)}
                        />}
                        {labeledImages[2]?.imageId && <ImageComponent
                            image={labeledImages[2]}
                            selectedImage={selectedImage}
                            scoringValue={scoringValue}
                            handleChangeLabeledImage={() => handleChangeLabeledImage(labeledImages[2].imageId)}
                        />}
                        {labeledImages[3]?.imageId && <ImageComponent
                            image={labeledImages[3]}
                            selectedImage={selectedImage}
                            scoringValue={scoringValue}
                            handleChangeLabeledImage={() => handleChangeLabeledImage(labeledImages[3].imageId)}
                        />}
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" className="next-image">
                    <IconButton onClick={() => props.handleChangeImage(selectedImage.nextImageId)}>
                        <img src={iconNextImageWhite} alt="icon next white" />
                    </IconButton>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" className="next-section">
                    <IconButton onClick={() => props.handleSectionChanged(selectedImage, true)}>
                        <img src={iconNextSection} alt="icon next section" />
                    </IconButton>
                </Box>
            </Box>
            {isImageDetailsDialogOpened &&
                <LabeledImagesDialog
                    selectedRoadSectionLabel={selectedRoadSectionLabel}
                    selectedRoadSection={selectedRoadSection}
                    isImageDetailsDialogOpened={isImageDetailsDialogOpened}
                    setIsImageDetailsDialogOpened={setIsImageDetailsDialogOpened}
                    selectedImage={selectedImage}
                    hasScoreAnalysisAccess={props.hasScoreAnalysisAccess}
                    role={props.role}
                    scoringValue={scoringValue}
                    filteredImageAnomalies={filteredImageAnomalies}
                    showOnlyPathologiesEnteringIntoScoreCalculation={showOnlyPathologiesEnteringIntoScoreCalculation}
                    projectLabel={props.projectLabel}
                    handleSectionChanged={props.handleSectionChanged}
                    handleChangeLabeledImage={handleChangeLabeledImage}
                    handleChangeShowOnlyPathologiesEnteringIntoScoreCalculation={handleChangeShowOnlyPathologiesEnteringIntoScoreCalculation}
                />
            }
        </Box>
    );
}
