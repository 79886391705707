import { InteractionStatus } from "@azure/msal-browser";
import { WithMsalProps } from "@azure/msal-react";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
import CryptoJS from "crypto-js";
import React, { Component } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MsalConfig } from './MsalConfig';
import { SettingsProvider } from './SettingsProvider';
import { MeasurementSystemType } from "./shared/models/MeasurementSystemType";
import { UserModel } from "./shared/User/services/dataContracts/queryStack/UserModel";
import { UserApiClient } from './shared/User/services/UserApiClient';
import { MeasurementSystem } from "./utils/MeasurementSystem";
import HomeView from "./views/Home/HomeView";
import { ProjectVersion } from "./views/Home/services/dataContracts/queryStack/ProjectVersion";
import { HomeApiClient } from "./views/Home/services/HomeApiClient";
import { Layout } from './views/Layout';
import { NoAuscultationComponent } from "./views/NoAuscultation/NoAuscultationComponent";
import RoadsConditionAndScenariosView, { RoadsConditionAndScenariosView as RoadsConditionAndScenariosViewClass } from "./views/RoadsConditionAndScenarios/RoadsConditionAndScenariosView";
import RoadWorksView, { RoadWorksView as RoadWorksViewClass } from "./views/RoadWorks/RoadWorksView";
import UsersAdminView from "./views/UsersAdmin/UsersAdminView";

//TODO HGA CMA faire le ménage sur les resources (nettoyage + renommage)

interface AppState {
    hasAuscultation?: boolean,
    user: UserModel,
    allProjectsVersions: ProjectVersion[]
}

export class App extends Component<WithMsalProps, AppState> {
    _isMounted: boolean = false;
    _googleAnalyticsTrackingCode: string = null;
    currentView: React.RefObject<RoadsConditionAndScenariosViewClass | RoadWorksViewClass>;

    constructor(props: WithMsalProps) {
        super(props);

        MeasurementSystem.Init();

        this.currentView = React.createRef();

        this.state = {
            hasAuscultation: null,
            user: null,
            allProjectsVersions: []
        };
    }

    componentDidMount() {
        this._isMounted = true;

        this.ensureUserAuthentication();

        if (SettingsProvider.IsReady()) {
            this.getUserProfileAndAuscultations();
        }
    }

    componentDidUpdate() {
        this.ensureUserAuthentication();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    ensureUserAuthentication() {
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        const msalInstance = this.props.msalContext.instance;

        // If a user is not logged in and authentication is not already in progress, invoke login
        if (!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
            msalInstance.loginRedirect({ scopes: MsalConfig.GetLoginRequestScopes() });
        }
    }

    getMatomoInstance = (matomoUserId: string) => {
        if (SettingsProvider.IsReady()) {
            const settings = SettingsProvider.Get();
            if (settings.matomo && settings.matomo.urlBase && settings.matomo.siteId !== 0) {
                return createInstance({
                    urlBase: settings.matomo?.urlBase,
                    siteId: settings.matomo?.siteId,
                    userId: matomoUserId
                });
            }
            return null;
        }
        return null;
    }

    getUserProfileAndAuscultations = (): void => {
        Promise.all(
            [
                UserApiClient.GetUserProfile(),
                HomeApiClient.GetAllProjectsVersions()
            ])
            .then((res) => {
                if (this._isMounted) {
                    var userProfileData = res[0].data;
                    if (userProfileData) {
                        var user = userProfileData.isEnabled ? userProfileData : null
                        this.setState({
                            hasAuscultation: true,
                            user: user,
                            allProjectsVersions: user?.role === "ADM" ? res[1].data : []
                        });
                    }
                    else {
                        this.setState({ hasAuscultation: false });
                    }
                }
            });
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        MeasurementSystem.Set(measurementSystemType);

        if (!this.currentView.current)
            return;

        let changeHandler = this.currentView.current.handleMeasurementSystemTypeChanged;
        if (changeHandler) {
            changeHandler(measurementSystemType);
        }
    }

    render() {
        const { hasAuscultation, user, allProjectsVersions } = this.state;
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        const settingsReady = SettingsProvider.IsReady();
        const userRole = user?.role;
        const userFullName = user?.fullName;
        if (isAuthenticated && settingsReady && user)
            return (
                <MatomoProvider value={this.getMatomoInstance(user.matomoUserId)}>
                    <ToastContainer className="generic-toast" />
                    <Layout user={user} projects={allProjectsVersions} handleMeasurementSystemTypeChanged={this.handleMeasurementSystemTypeChanged} >
                        <Routes>
                            <Route path='/' element={hasAuscultation === true ? <HomeView /> :
                                hasAuscultation === false ? <NoAuscultationComponent /> : <></>}
                            />
                            <Route path='/RoadsCondition' Component={() => <RoadsConditionAndScenariosView ref={this.currentView} role={userRole} isScenarioView={false} />} />
                            <Route path='/MaintenanceScenarios' Component={() => <RoadsConditionAndScenariosView ref={this.currentView} role={userRole} userFullName={userFullName} isScenarioView={true} />} />
                            <Route path='/RoadWorks' Component={() => <RoadWorksView ref={this.currentView} />} />
                            <Route path='/UsersAdmin' Component={() => userRole === "ADM" ? <UsersAdminView /> :
                                userRole ? <Navigate to="/" /> : <></>}
                            />
                            <Route path='/' element={<Navigate to="/" />} />
                        </Routes>
                    </Layout>
                </MatomoProvider>
            );

        return (
            <></>
        );
    }
}
