import TuneIcon from '@mui/icons-material/Tune';
import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import iconDrawerClosed from 'src/assets/icons/icon-drawer-closed.svg';
import iconMapClosed from 'src/assets/icons/icon-map-closed.svg';
import iconMapHeaderDrawerClosed from 'src/assets/icons/icon-map-header-drawer-closed.svg';
import { CustomMapChoice } from 'src/shared/models/CustomMapChoice';
import Translate from '../../../localization/Localization';
import { MapFiltersComponent } from '../../../shared/components/MapFilters/MapFiltersComponent';
import { MapStyleSelector } from '../../../shared/components/MapStyleSelector/MapStyleSelector';
import { ScoringParameter } from '../../Home/services/dataContracts/queryStack/ScoringParameter';
import { ImageExtended } from '../models/ImageExtended';
import { MergedProjectVersion } from '../models/MergedProjectVersion';
import { RoadSectionViewData } from '../models/RoadSectionViewData';
import { LabeledImagesComponent } from './LabeledImagesComponent';

interface MapWithImagesComponentProps {
    openedMapDrawer: boolean,
    openedAuscultationsSelectorWithRoadsDrawer: boolean,
    openedScenarioManagementDrawer: boolean,
    openedScenariosSelectorDrawer: boolean,
    isScenarioView: boolean,
    loading: boolean,
    isLabeledImagesContentOpened: boolean,
    selectedRoadSectionLabel: string,
    selectedRoadSection: RoadSectionViewData,
    selectedImage: ImageExtended,
    thresholdOfGoodScore: number,
    thresholdOfPoorScore: number,
    hasScoreAnalysisAccess: boolean,
    scoringParameters: ScoringParameter[],
    role: string,
    mergedProject: MergedProjectVersion,
    activeRoadsConditions: Map<string, boolean>,
    activeAnomalies: Set<string>,
    isGroupView: boolean,
    handleDrawerOpened: () => void,
    handleDrawerClosed: () => void,
    handleChangeImage: (imageId: number) => void
    handleSectionChanged: (selectedImage: ImageExtended, searchByNext: boolean) => void
    handleCloseLabeledImageComponent: () => void,
    handleDisplaySectionsFromFilters: (activeRoadsConditions: Map<string, boolean>, activeAnomalies: Set<string>, isGroupView: boolean) => void
    handleMapStyleChange: (style: CustomMapChoice) => void
}

export const MapWithImagesComponent = (props: MapWithImagesComponentProps): JSX.Element => {

    const {
        openedMapDrawer,
        openedAuscultationsSelectorWithRoadsDrawer,
        openedScenarioManagementDrawer,
        openedScenariosSelectorDrawer,
        isScenarioView,
        loading,
        isLabeledImagesContentOpened,
        selectedRoadSectionLabel,
        selectedRoadSection,
        selectedImage,
        thresholdOfGoodScore,
        thresholdOfPoorScore,
        hasScoreAnalysisAccess,
        role,
        handleDrawerOpened,
        handleDrawerClosed,
        handleChangeImage,
        handleSectionChanged,
        handleCloseLabeledImageComponent,
        handleMapStyleChange
    } = props;

    const [isMapFiltersDialogDisplayed, setIsMapFiltersDialogDisplayed] = useState<boolean>(false);

    const handleCloseMapFiltersDialog = (): void => {
        setIsMapFiltersDialogDisplayed(false);
    }

    const handleDisplaySectionsFromFilters = (activeRoadsConditions: Map<string, boolean>, activeAnomalies: Set<string>, isGroupView: boolean): void => {
        props.handleDisplaySectionsFromFilters(activeRoadsConditions, activeAnomalies, isGroupView);
        setIsMapFiltersDialogDisplayed(false);
    }

    const isDefaultMapFiltersActive = (activeRoadsConditions: Map<string, boolean>, activeAnomalies: Set<string>): boolean => {
        let allRoadsConditionsAreActive = true;
        for (let isActive of activeRoadsConditions.values()) {
            if (!isActive) {
                allRoadsConditionsAreActive = false;
                break;
            }
        }

        return allRoadsConditionsAreActive && activeAnomalies.size === 0;
    }

    const className = `${isScenarioView && !openedMapDrawer ? 'hidden' : ''}`;
    const mapFilterButtonClassName = `map-filter-button ${isMapFiltersDialogDisplayed || !isDefaultMapFiltersActive(props.activeRoadsConditions, props.activeAnomalies) ? 'selected' : ''} ${className}`;

    const map: JSX.Element = (
        <>
            <div id="map" className={className}></div>
            <div id="selection-draw" className={className}></div>
            <div id="deselection-draw" className={className}></div>
            {!loading && (
                <>
                    <IconButton
                        className={mapFilterButtonClassName}
                        onClick={() => setIsMapFiltersDialogDisplayed(true)}
                    >
                        <TuneIcon />
                    </IconButton>

                    {isMapFiltersDialogDisplayed && (
                        <MapFiltersComponent
                            activeRoadsConditions={props.activeRoadsConditions}
                            activeAnomalies={props.activeAnomalies}
                            isGroupView={props.isGroupView}
                            trustedAnomaliesEnteringScore={props.mergedProject?.trustedAnomaliesEnteringScore}
                            trustedAnomaliesOther={props.mergedProject?.trustedAnomaliesOther}
                            handleCloseMapFiltersDialog={handleCloseMapFiltersDialog}
                            handleDisplaySectionsFromFilters={(activeRoadsConditions: Map<string, boolean>, activeAnomalies: Set<string>, isGroupView: boolean) => handleDisplaySectionsFromFilters(activeRoadsConditions, activeAnomalies, isGroupView)}
                        />
                    )}

                    {(openedMapDrawer || !isScenarioView) &&
                        <MapStyleSelector
                            handleMapStyleChange={handleMapStyleChange}
                        />
                    }

                    {openedMapDrawer && (
                        <IconButton className="map-drawer-close-icon" onClick={() => handleDrawerClosed()}>
                            <img src={iconMapClosed} alt="map closed icon" />
                        </IconButton>
                    )}
                </>
            )}
        </>
    );

    const labeledImagesComponent: JSX.Element = (
        <LabeledImagesComponent
            isScenarioView={isScenarioView}
            selectedRoadSectionLabel={selectedRoadSectionLabel}
            selectedRoadSection={selectedRoadSection}
            openedAuscultationsSelectorWithRoadsDrawer={openedAuscultationsSelectorWithRoadsDrawer}
            openedScenarioManagementDrawer={isScenarioView ? openedScenarioManagementDrawer : false}
            openedScenariosSelectorDrawer={isScenarioView ? openedScenariosSelectorDrawer : false}
            selectedImage={selectedImage}
            hasScoreAnalysisAccess={hasScoreAnalysisAccess}
            role={role}
            thresholdOfGoodScore={thresholdOfGoodScore}
            thresholdOfPoorScore={thresholdOfPoorScore}
            projectLabel={props.mergedProject?.projectLabel}
            handleChangeImage={handleChangeImage}
            handleSectionChanged={handleSectionChanged}
            handleCloseLabeledImageComponent={handleCloseLabeledImageComponent}
        />
    );

    const scaleLoader: JSX.Element = (
        <Box display="flex" flexDirection="column" alignItems="center" className="loader">
            <ScaleLoader width={5} height={20} radius={50} color="#000000" loading={loading} />
        </Box>
    );

    if (isScenarioView) {
        return (
            <Box display="flex"
                flexDirection="column"
                className={openedMapDrawer ? "content" : "map-with-images-closed-drawer"}
                alignItems={openedMapDrawer ? "start" : "center"}
                onClick={openedMapDrawer ? null : () => handleDrawerOpened()}>
                {!openedMapDrawer &&
                    <>
                        <img className="open-drawer-icon" src={iconDrawerClosed} alt="drawer cloded icon" />
                        <Box className="closed-drawer-label">
                            <img src={iconMapHeaderDrawerClosed} alt="map header drawer closed icon" />
                            {Translate.Resources.UI_MaintenanceSenarios_MapDrawer_Title_Map}
                        </Box>
                    </>
                }
                {openedMapDrawer && loading ? scaleLoader : ''}
                {map}
                {openedMapDrawer && isLabeledImagesContentOpened ? labeledImagesComponent : ''}
            </Box>
        );
    }
    else {
        return (
            <Box display="flex" flexDirection="column" className="content">
                {loading ? scaleLoader : ''}
                {map}
                {isLabeledImagesContentOpened ? labeledImagesComponent : ''}
            </Box>
        );
    }
}