import ReplyIcon from '@mui/icons-material/Reply';
import { Box, Button, Checkbox, Dialog, DialogContent, FormControlLabel, TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, { MutableRefObject, useRef, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import Translate, { Localization } from '../../../localization/Localization';
import ToastService from '../../../ToastService';
import BusinessMessages from '../../../utils/BusinessMessages';
import Utilities from '../../../utils/Utilities';
import { ImageExtended } from '../models/ImageExtended';
import { SendImageRequestArgs } from '../services/RoadsCondition/dataContracts/controller/SendImageRequestArgs';
import { RoadsConditionApiClient } from '../services/RoadsCondition/RoadsConditionApiClient';
import './SendImageDialogStyles.scss';

interface SendImageDialogProps {
    isSendImageDialogOpened: boolean,
    selectedImage: ImageExtended,
    roadLabel: string,
    projectLabel: string,
    handleCloseDialog: () => void
}

export const SendImageDialog = (props: SendImageDialogProps): JSX.Element => {

    const [emails, setEmails] = useState<string>("");
    const [isValidEmails, setIsValidEmails] = useState<boolean>(false);
    const [shouldSendCopyToSender, setShouldSendCopyToSender] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    let inputTextMessageRef: MutableRefObject<HTMLInputElement> = useRef(null);

    const handleEmailsChanged = (value: string): void => {
        setEmails(value);
        if (value.trim()) {
            setIsValidEmails(checkEmailsValidity(value));
        }
        else {
            setIsValidEmails(false);
        }
    }

    const checkEmailsValidity = (value: string): boolean => {
        var regSeparator = new RegExp('[,;]+');
        let emails = value.split(regSeparator);
        var isMatch: boolean = true;
        emails.forEach((e) => {
            let email = e.trim();
            if (email && !Utilities.validateEmailAddress(email)) {
                isMatch = false;
            }
        });

        return isMatch;
    }

    const handleSendImageClicked = debounce((): void => {
        setLoading(true);

        let recipientsEmails = emails;
        if (recipientsEmails.includes(';')) {
            recipientsEmails = recipientsEmails.replace(';', ',');
        }

        let requestArgs: SendImageRequestArgs = {
            referenceImageId: props.selectedImage.referenceImageId,
            publicImageId: props.selectedImage.publicImageId,
            roadLabel: props.roadLabel,
            projectLabel: props.projectLabel,
            recipientsEmails: recipientsEmails,
            message: inputTextMessageRef.current.value,
            shouldSendCopyToSender: shouldSendCopyToSender,
            language: Localization.locale
        };

        RoadsConditionApiClient.SendImage(requestArgs)
            .then((res) => {
                setLoading(false);

                let data = res?.data;

                let warnings = BusinessMessages.GetWarnings(data);
                if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                }

                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                ToastService.showSuccessToast("", Translate.Resources.UI_SendImageDialog_SendingSuccessMessage);
                props.handleCloseDialog();
            });
    }, 500);

    return (
        <Dialog id="send-image-dialog" open={props.isSendImageDialogOpened}>
            <DialogContent>
                <Box className="dialog-content" display="flex" flexDirection="column">
                    <Box className="title" display="flex" flexDirection="row">
                        {Translate.Resources.UI_SendImageDialog_Title_SharingImages}
                        {loading &&
                            <div className="loader">
                                <ScaleLoader
                                    color="#000000"
                                    width={5}
                                    height={20}
                                    radius={50}
                                    loading={loading}
                                />
                            </div>
                        }
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <div className={`input-text-label ${!isValidEmails ? 'invalid' : ''}`}>{Translate.Resources.UI_SendImageDialog_Textbox_Email}</div>
                        <TextField
                            className={`input-text ${!isValidEmails ? 'invalid' : ''}`}
                            hiddenLabel
                            size="small"
                            placeholder={Translate.Resources.UI_SendImageDialog_Textbox_Email_Placeholder}
                            value={emails}
                            onChange={(e) => handleEmailsChanged(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            className="checkbox-label"
                            control={
                                <Checkbox
                                    checked={shouldSendCopyToSender}
                                    onChange={() => setShouldSendCopyToSender(!shouldSendCopyToSender)}
                                    color="primary"
                                />
                            }
                            label={Translate.Resources.UI_SendImageDialog_Checkbox_ReceiveCopy}
                        />
                    </Box>
                    <Box>
                        <TextField
                            label={Translate.Resources.UI_SendImageDialog_Message}
                            margin="normal"
                            multiline
                            variant="outlined"
                            rows="9"
                            className="input-text-multiline"
                            inputRef={inputTextMessageRef}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button className="btn-close" onClick={props.handleCloseDialog}>{Translate.Resources.UI_SendImageDialog_Btn_Close}</Button>
                        <Button className={`btn-send ${!isValidEmails || loading ? 'disabled' : ''}`} disabled={!isValidEmails} onClick={handleSendImageClicked}>
                            <ReplyIcon />
                            {Translate.Resources.UI_SendImageDialog_Btn_Send}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
