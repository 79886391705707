import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import blueConeIcon from 'src/assets/icons/icon-cone-blue.svg';
import orangeConeIcon from 'src/assets/icons/icon-cone-orange.svg';
import whiteConeIcon from 'src/assets/icons/icon-cone-white.svg';
import Translate from '../../../localization/Localization';
import { MaintenanceArea } from '../../RoadsConditionAndScenarios/services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceArea';
import { MaintenanceAreaStatus } from '../../RoadsConditionAndScenarios/services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceAreaStatus';
import { MaintenanceScenario } from '../../RoadsConditionAndScenarios/services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceScenario';
import { SelectOptionModel } from '../models/SelectOptionModel';
import './WorkZonesFilterSectionStyles.scss';

interface WorkZonesFilterSectionComponentProps {
    projectScenarios: MaintenanceScenario[],
    yearValues: SelectOptionModel[]
    scenarioNameValues: SelectOptionModel[],
    activeStatusOfWorkZones: Set<string>,
    handleBtnStatusOfWorkClicked: (statusOfWork: string, isActive: boolean) => void,
    handleYearSelected: (e: SelectOptionModel[]) => void,
    handleScenarioNameSelected: (e: SelectOptionModel[]) => void
}

export const WorkZonesFilterSectionComponent = (props: WorkZonesFilterSectionComponentProps): JSX.Element => {

    const [yearOptions, setYearOptions] = useState<SelectOptionModel[]>([]);
    const [scenarioNameOptions, setScenarioNameOptions] = useState<SelectOptionModel[]>([]);

    useEffect(() => {
        let yearOptions: SelectOptionModel[] = [];
        let scenarioNameOptions: SelectOptionModel[] = [];
        let nullYearOption: SelectOptionModel = { label: 'n/a', value: null };

        props.projectScenarios.forEach((scenario: MaintenanceScenario) => {
            let label = scenario.label;
            if (!scenarioNameOptions.some(x => x.value === label)) {
                scenarioNameOptions.push({ label: label, value: label });
            }

            scenario.areas.forEach((area: MaintenanceArea) => {
                let year = area.startDate ? area.startDate.getFullYear() : null;
                let option = year !== null ? { label: `${year}`, value: year } : nullYearOption;
                if (!yearOptions.some(x => x.value === option.value)) {
                    option.value === null ? yearOptions.unshift(option) : yearOptions.push(option);
                }
            });
        });

        setYearOptions(yearOptions.sort((a, b) => { return (a.label !== 'n/a' && b.label !== 'n/a') ? a.label.localeCompare(b.label) : 0 }));
        setScenarioNameOptions(scenarioNameOptions.sort((a, b) => { return a.label.localeCompare(b.label) }));
    }, [props.projectScenarios])

    return (
        <Box className="status-of-work-zones">
            {props.projectScenarios.length > 0 ? (
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column" mb="10px">
                        <div className="label">{Translate.Resources.UI_WorkZonesFilterSection_Status}</div>
                        <Box display="flex" flexDirection="row">
                            <Button className={`btn-work-to-do ${props.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workToDo) ? 'active' : ''}`} onClick={() => props.handleBtnStatusOfWorkClicked(MaintenanceAreaStatus.workToDo, props.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workToDo))}>
                                {(
                                    props.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workToDo) ? <img src={whiteConeIcon} alt="white cone" /> : <img src={orangeConeIcon} alt="orange cone" />
                                )}
                                {Translate.Resources.UI_WorkZonesFilterSection_WorkToDo}
                            </Button>
                            <Button className={`btn-work-done ${props.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workDone) ? 'active' : ''}`} onClick={() => props.handleBtnStatusOfWorkClicked(MaintenanceAreaStatus.workDone, props.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workDone))}>
                                {(
                                    props.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workDone) ? <img src={whiteConeIcon} alt="white cone" /> : <img src={blueConeIcon} alt="blue cone" />
                                )}
                                {Translate.Resources.UI_WorkZonesFilterSection_WorkDone}
                            </Button>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" mb="10px">
                        <div className="label">{Translate.Resources.UI_WorkZonesFilterSection_Year}</div>
                        <Select
                            className="select"
                            menuPosition="fixed"
                            menuShouldBlockScroll={true}
                            options={yearOptions}
                            value={props.yearValues}
                            placeholder=""
                            onChange={(e) => props.handleYearSelected(e as SelectOptionModel[])}
                            isMulti
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" mb="10px">
                        <div className="label">{Translate.Resources.UI_WorkZonesFilterSection_ScenarioName}</div>
                        <Select
                            className="select"
                            menuPosition="fixed"
                            menuShouldBlockScroll={true}
                            options={scenarioNameOptions}
                            value={props.scenarioNameValues}
                            placeholder=""
                            onChange={(e) => props.handleScenarioNameSelected(e as SelectOptionModel[])}
                            isMulti
                        />
                    </Box>
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center">
                    <WarningIcon />
                    <Box className="no-work-area-text">
                        {Translate.Resources.UI_WorkZonesFilterSection_NoWorkAreaIsDefined}
                    </Box>
                </Box>
            )}
        </Box>
    )
}