import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, ClickAwayListener, FormControlLabel, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import Translate from '../../../localization/Localization';
import { ScoreTypes } from '../../models/ScoreTypes';
import './MapFiltersStyles.scss';

interface MapFiltersAdditionalSection {
    title: string,
    component: JSX.Element
}

interface MapFiltersComponentProps {
    activeRoadsConditions: Map<string, boolean>,
    activeAnomalies: Set<string>,
    isGroupView: boolean,
    trustedAnomaliesEnteringScore: Set<string>,
    trustedAnomaliesOther: Set<string>,
    additionalSection?: MapFiltersAdditionalSection,
    handleCloseMapFiltersDialog: () => void,
    handleDisplaySectionsFromFilters: (activeRoadsConditions: Map<string, boolean>, activeAnomalies: Set<string>, isGroupView: boolean) => void
}

export const MapFiltersComponent = (props: MapFiltersComponentProps): JSX.Element => {

    const [activeRoadsConditions, setActiveRoadsConditions] = useState<Map<string, boolean>>(new Map<string, boolean>(props.activeRoadsConditions));
    const [activeAnomalies, setActiveAnomalies] = useState<Set<string>>(new Set<string>(props.activeAnomalies));
    const [isGroupView, setIsGroupView] = useState<boolean>(props.isGroupView);
    const [sortedAnomaliesEnteringScore] = useState(Array.from(props.trustedAnomaliesEnteringScore).sort());
    const [sortedAnomaliesOther] = useState(Array.from(props.trustedAnomaliesOther).sort());

    const handleRoadsConditionClicked = (roadsCondition: string, isActive: boolean): void => {
        let activeRoadsConditionsSet = new Map(activeRoadsConditions);
        activeRoadsConditionsSet.set(roadsCondition, !isActive);
        setActiveRoadsConditions(activeRoadsConditionsSet);
    }

    const handleAnomlyButtonClicked = (anomalyType: string, isActive: boolean): void => {
        let activeAnomaliesSet = new Set(activeAnomalies);
        if (isActive) {
            activeAnomaliesSet.delete(anomalyType);
        }
        else {
            activeAnomaliesSet.add(anomalyType);
        }
        setActiveAnomalies(activeAnomaliesSet);
    }

    const handleDisplay = (): void => {
        props.handleDisplaySectionsFromFilters(activeRoadsConditions, activeAnomalies, isGroupView);
    }

    const handleActiveClusterChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setIsGroupView(e.target.checked);
    }

    return (
        <ClickAwayListener onClickAway={props.handleCloseMapFiltersDialog}>
            <Box className="map-filters-content" display="flex" flexDirection="column" justifyContent="space-between">
                <Box>
                    <Accordion className="filters" defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="sections-condition-content"
                            id="sections-condition-header"
                        >
                            <Typography className="title">{Translate.Resources.UI_MapFilters_Accordion_Title_SectionStatus}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display="flex" flexDirection="row">
                                <Button className={`btn-bad-status ${activeRoadsConditions.get(ScoreTypes.poor) === true ? 'active' : ''}`} onClick={() => handleRoadsConditionClicked(ScoreTypes.poor, activeRoadsConditions.get(ScoreTypes.poor))}>
                                    {Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_BadStatus}
                                </Button>
                                <Button className={`btn-to-monitor ${activeRoadsConditions.get(ScoreTypes.toMonitor) === true ? 'active' : ''}`} onClick={() => handleRoadsConditionClicked(ScoreTypes.toMonitor, activeRoadsConditions.get(ScoreTypes.toMonitor))}>
                                    {Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_ToMonitor}
                                </Button>
                                <Button className={`btn-good-status ${activeRoadsConditions.get(ScoreTypes.good) === true ? 'active' : ''}`} onClick={() => handleRoadsConditionClicked(ScoreTypes.good, activeRoadsConditions.get(ScoreTypes.good))}>
                                    {Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_GoodStatus}
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="filters" defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="anomalies-content"
                            id="anomalies-header"
                        >
                            <Typography className="title">{Translate.Resources.UI_MapFilters_Accordion_Title_TypeOfDetections}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display="flex" flexDirection="column">
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    {sortedAnomaliesEnteringScore.map((anomaly, index) => {
                                        let isActive = activeAnomalies.has(anomaly);
                                        return (
                                            <Button key={`anomaly-type-button-${index}`} className={`anomaly-detection-button ${anomaly} ${isActive ? 'active' : ''}`} onClick={() => handleAnomlyButtonClicked(anomaly, isActive)}>
                                                {Translate.GetAnomalyTypeLabel(anomaly)}
                                            </Button>
                                        );
                                    })}
                                    {sortedAnomaliesOther.map((anomaly, index) => {
                                        let isActive = activeAnomalies.has(anomaly);
                                        return (
                                            <Button key={`anomaly-type-button-${index}`} className={`anomaly-detection-button ${anomaly} ${isActive ? 'active' : ''}`} onClick={() => handleAnomlyButtonClicked(anomaly, isActive)}>
                                                {Translate.GetAnomalyTypeLabel(anomaly)}
                                            </Button>
                                        );
                                    })}
                                </Box>
                                <FormControlLabel
                                    className="group-view-checkbox"
                                    control={<Checkbox checked={isGroupView} onChange={(e) => handleActiveClusterChange(e)} />}
                                    label={Translate.Resources.UI_MapFilters_Checkbox_GroupView}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    {props.additionalSection &&
                        <Accordion className="filters" defaultExpanded={true}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className="title">{props.additionalSection.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {props.additionalSection.component}
                            </AccordionDetails>
                        </Accordion>
                    }
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button className="btn-cancel" onClick={props.handleCloseMapFiltersDialog}>
                        {Translate.Resources.UI_MapFilters_Button_Cancel}
                    </Button>
                    <Button className="btn-display" onClick={handleDisplay}>
                        {Translate.Resources.UI_MapFilters_Button_Display}
                    </Button>
                </Box>
            </Box>
        </ClickAwayListener>
    );
}
