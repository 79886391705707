import { GridCellProps } from "@progress/kendo-react-grid";
import { NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from 'react';
import { MeasurementSystemType } from "../../../shared/models/MeasurementSystemType";
import { MeasurementSystem } from "../../../utils/MeasurementSystem";
import { MaintenanceAreaExtended } from "../models/MaintenanceAreaExtended";

interface CustomNumericCellProperties {
    format: string
}

export const MaintenanceAreaNumericCell = (props: GridCellProps & CustomNumericCellProperties): JSX.Element => {

    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

    const typeDataItem = dataItem as MaintenanceAreaExtended;

    const [isValid, setIsValid] = useState<boolean>(true);

    const isInEdit = field === typeDataItem.inEditField;

    let value: number = field && dataItem[field] ? dataItem[field] : null;

    const measurementSystemType = MeasurementSystem.getCurrentType();
    if (measurementSystemType !== MeasurementSystemType.Metric) {
        if (value && field === "lengthInLinearMeters") {
            value = MeasurementSystem.getLengthInMetersOrYards(value);
        }
        if (value && field === "areaInSquareMeters") {
            value = MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(value);
        }
        if (value && field === "unitPrice") {
            value = typeDataItem.unitPrice / MeasurementSystem.SquaredMetersToSquaredYardsConversionRatio;
        }
    }

    let displayedValue = value?.toFixedLocalized(2);
    if (value && field === "lengthInLinearMeters") {
        displayedValue = value?.toFixedLocalized(0);
    }
    if (value && field === "areaInSquareMeters") {
        displayedValue = value?.toFixedLocalized(0);
    }

    const handleOnChange = (e: NumericTextBoxChangeEvent): void => {
        let changedValue = e.target.value;
        if (changedValue < 0) {
            setIsValid(false);
            return;
        }

        let measurementSystemType = MeasurementSystem.getCurrentType();
        let convertedValue = changedValue;
        if (measurementSystemType !== MeasurementSystemType.Metric) {
            if (changedValue && field === "lengthInLinearMeters") {
                convertedValue = MeasurementSystem.convertToLengthInMeters(changedValue, measurementSystemType);
            }
            if (changedValue && field === "areaInSquareMeters") {
                convertedValue = MeasurementSystem.convertToSurfaceInSquaredMeters(changedValue, measurementSystemType);
            }
            if (changedValue && field === "unitPrice") {
                convertedValue = changedValue * MeasurementSystem.SquaredMetersToSquaredYardsConversionRatio;
            }
        }

        dataItem[field] = convertedValue;
    }

    const defaultRendering = (
        <td
            style={{ textAlign: "end" }}
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            {isInEdit ? (
                <NumericTextBox
                    value={value}
                    min={0}
                    format={props.format}
                    valid={isValid}
                    onChange={handleOnChange}
                    disabled={(field === "areaInSquareMeters" || field === "lengthInLinearMeters") && typeDataItem.sections.length === 0}
                />
            ) : <span className={((field === "areaInSquareMeters" && typeDataItem.areaInSquareMetersIsOverridden) || (field === "lengthInLinearMeters" && typeDataItem.lengthInLinearMetersIsOverridden)) ? "overriden" : ""}>{displayedValue}</span>}
        </td>
    );

    return render?.call(undefined, defaultRendering, props);
}