import { Box, IconButton } from "@mui/material";
import { Calendar, CalendarChangeEvent, DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { GridCellProps } from "@progress/kendo-react-grid";
import React, { useState } from 'react';
import iconCalendar from 'src/assets/icons/icon-calendar.svg';
import { MaintenanceAreaExtended } from "../models/MaintenanceAreaExtended";

interface CustomDatePickerCellProps {
    handleStartDateChanged: (dataItem: MaintenanceAreaExtended) => void
}

export const CustomDatePickerCell = (props: GridCellProps & CustomDatePickerCellProps): JSX.Element => {

    const { dataItem } = props;

    const [show, setShow] = useState<boolean>(null);
    const [value, setValue] = useState<Date>(dataItem.startDate ?? null);

    const onCalendarChanged = (e: CalendarChangeEvent): void => {
        dataItem.startDate = e.target.value;
        setShow(false);
        setValue(e.target.value);
        props.handleStartDateChanged(dataItem);
    }

    const onDatePickerChanged = (e: DatePickerChangeEvent): void => {
        if (e.target.validity.valid) {
            dataItem.startDate = e.value;
            setValue(e.value);
        }
    }

    const onDatePickerBlur = () => {
        props.handleStartDateChanged(dataItem);
    }

    const defaultRendering = (
        <td>
            <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
                <DatePicker
                    toggleButton={() => <></>}
                    show={show}
                    className="date-picker"
                    format={{ year: "numeric", month: "numeric" }}
                    calendar={() => <Calendar
                        bottomView="year"
                        topView="year"
                        value={value}
                        onChange={(e) => onCalendarChanged(e)}
                    />}
                    value={value}
                    onChange={(e) => onDatePickerChanged(e)}
                    onBlur={() => onDatePickerBlur()}
                />
                <IconButton onClick={() => setShow(true)}>
                    <img src={iconCalendar} alt="icon calendar" />
                </IconButton>
            </Box>
        </td>
    );

    return defaultRendering;
}