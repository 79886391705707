import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplyIcon from '@mui/icons-material/Reply';
import { Box, Button, ClickAwayListener } from '@mui/material';
import { GridCellProps } from "@progress/kendo-react-grid";
import { Offset, Popup } from "@progress/kendo-react-popup";
import React, { useRef, useState } from 'react';
import duplicateIcon from 'src/assets/icons/icon-duplicate.svg';
import Translate from '../../../localization/Localization';
import { MaintenanceScenarioExtended } from '../models/MaintenanceScenarioExtended';
import { RecipientForScenarioSending } from '../services/MaintenanceScenarios/dataContracts/queryStack/RecipientForScenarioSending';
import { AddOrUpdateScenarioDialog, Mode as AddOrUpdateScenarioDialogMode } from './AddOrUpdateScenarioDialog';
import { ConfirmDeleteDialog, Mode as ConfirmDeleteDialogMode } from './ConfirmDeleteDialog';
import { RecipientsForScenarioSendingDialog } from './RecipientsForScenarioSendingDialog';

interface CustomScenarioActionsCellProps {
    recipientsForScenarioSending: RecipientForScenarioSending[],
    userFullName: string,
    handleUpdateScenarioLabel: (maintenanceScenarioId: number, scenarioLabel: string) => Promise<boolean>,
    handleDeleteScenario: (maintenanceScenarioId: number) => void,
    handleDuplicateScenario: (maintenanceScenarioId: number, duplicateScenarioLabel: string,) => Promise<boolean>,
    handleExportScenario: (maintenanceScenarioId: number) => void
}

export const CustomScenarioActionsCell = (props: GridCellProps & CustomScenarioActionsCellProps): JSX.Element => {

    let dataItem = props.dataItem as MaintenanceScenarioExtended;

    const [isActionsContentOpen, setIsActionsContentOpen] = useState<boolean>(false);
    const [isRecipientsDialogOpen, setIsRecipientsDialogOpen] = useState<boolean>(false);
    const [isUpdateScenarioDialogOpen, setIsUpdateScenarioDialogOpen] = useState<boolean>(false);
    const [isDuplicateScenarioDialogOpen, setIsDuplicateScenarioDialogOpen] = useState<boolean>(false);
    const [isConfirmDeleteDialogOpened, setIsConfirmDeleteDialogOpened] = useState<boolean>(false);
    const [currentScenarioLabel, setCurrentScenarioLabel] = useState<string>(null);

    const offSet = useRef<Offset>({ left: 0, top: 0 });

    const handleOpenMenuActions = (e): void => {
        e.preventDefault();
        offSet.current = { left: e.clientX, top: e.clientY };
        setIsActionsContentOpen(true);
    }

    const handleOpenRecipientsDialog = (): void => {
        setIsRecipientsDialogOpen(true);
        setIsActionsContentOpen(false);
    }

    const handleOpenUpdateScenarioDialog = (): void => {
        setCurrentScenarioLabel(dataItem.label);
        setIsUpdateScenarioDialogOpen(true);
        setIsActionsContentOpen(false);
    }

    const handleUpdateScenarioDialogClosed = (): void => {
        setIsUpdateScenarioDialogOpen(false);
    }

    const handleValidateScenarioLabelUpdated = async (scenarioLabel: string): Promise<void> => {
        var hasError = await props.handleUpdateScenarioLabel(dataItem.maintenanceScenarioId, scenarioLabel);

        if (!hasError)
            setIsUpdateScenarioDialogOpen(false);
    }

    const handleOpenDuplicateScenarioDialog = (): void => {
        setCurrentScenarioLabel(dataItem.label);
        setIsDuplicateScenarioDialogOpen(true);
        setIsActionsContentOpen(false);
    }

    const handleDuplicateScenarioDialogClosed = (): void => {
        setIsDuplicateScenarioDialogOpen(false);
    }

    const handleValidateScenarioDuplicated = async (duplicateScenarioLabel: string): Promise<void> => {
        var hasError = await props.handleDuplicateScenario(dataItem.maintenanceScenarioId, duplicateScenarioLabel);

        if (!hasError)
            setIsDuplicateScenarioDialogOpen(false);
    }

    const handleCloseRecipientDialog = () => {
        setIsRecipientsDialogOpen(false);
    }

    const handleCanceledScenarioDeleteDialog = (): void => {
        setIsConfirmDeleteDialogOpened(false);
    }

    const handleConfirmedScenarioDeleteDialog = (): void => {
        props.handleDeleteScenario(dataItem.maintenanceScenarioId);
        setIsConfirmDeleteDialogOpened(false);
    }

    const handleOpenDeleteScenarioDialog = (): void => {
        setIsConfirmDeleteDialogOpened(true);
    }

    const handleExportScenario = (): void => {
        props.handleExportScenario(dataItem.maintenanceScenarioId)
    }

    return (
        <td>
            <MoreVertIcon className="actions-icon" onClick={(e) => handleOpenMenuActions(e)} />
            {isActionsContentOpen &&
                <ClickAwayListener onClickAway={() => setIsActionsContentOpen(false)}>
                    <Popup show={isActionsContentOpen} offset={offSet.current} animate={false}>
                        <Box display="flex" flexDirection="column" className="actions-content">
                            <Button className="btn-action" onClick={handleOpenUpdateScenarioDialog}>
                                <EditIcon className="icon" />
                                {Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Scenarios_grid_Button_Rename}
                            </Button>
                            <hr />
                            <Button className="btn-action" onClick={handleOpenDuplicateScenarioDialog}>
                                <img src={duplicateIcon} alt="duplicate icon" className="duplicate" />
                                {Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Scenarios_grid_Button_Duplicate}
                            </Button>
                            <hr />
                            <Button className="btn-action" onClick={handleOpenRecipientsDialog}>
                                <ReplyIcon className="icon send" />
                                {Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Scenarios_grid_Button_Send}
                            </Button>
                            <hr />
                            <Button className="btn-action" onClick={handleExportScenario}>
                                <FontAwesomeIcon icon={faFileExcel} className="export" />
                                {Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Scenarios_grid_Button_Export}
                            </Button>
                            <hr />
                            <Button className="btn-action" onClick={handleOpenDeleteScenarioDialog}>
                                <DeleteIcon className="icon" />
                                {Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Scenarios_grid_Button_Delete}
                            </Button>
                        </Box>
                    </Popup>
                </ClickAwayListener>
            }
            {isRecipientsDialogOpen &&
                <RecipientsForScenarioSendingDialog
                    isOpen={isRecipientsDialogOpen}
                    scenarioLabel={dataItem.label}
                    userFullName={props.userFullName}
                    recipientsForScenarioSending={props.recipientsForScenarioSending}
                    maintenanceScenarioId={dataItem.maintenanceScenarioId}
                    onClose={handleCloseRecipientDialog}
                />
            }
            {isUpdateScenarioDialogOpen &&
                <AddOrUpdateScenarioDialog
                    mode={AddOrUpdateScenarioDialogMode.Editing}
                    isOpen={isUpdateScenarioDialogOpen}
                    currentScenarioLabel={currentScenarioLabel}
                    onCancel={handleUpdateScenarioDialogClosed}
                    handleValidate={handleValidateScenarioLabelUpdated}
                />
            }
            {isDuplicateScenarioDialogOpen &&
                <AddOrUpdateScenarioDialog
                    mode={AddOrUpdateScenarioDialogMode.duplicating}
                    isOpen={isDuplicateScenarioDialogOpen}
                    currentScenarioLabel={currentScenarioLabel}
                    onCancel={handleDuplicateScenarioDialogClosed}
                    handleValidate={handleValidateScenarioDuplicated}
                />
            }
            {isConfirmDeleteDialogOpened &&
                <ConfirmDeleteDialog
                    mode={ConfirmDeleteDialogMode.DeleteScenario}
                    isOpen={isConfirmDeleteDialogOpened}
                    handleCanceled={handleCanceledScenarioDeleteDialog}
                    handleConfirmed={handleConfirmedScenarioDeleteDialog}
                />
            }
        </td>
    );
}
