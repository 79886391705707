import { Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import scenarioIcon from 'src/assets/icons/icon-scenario.svg';
import statementIcon from 'src/assets/icons/icon-statement.svg';
import worksMapIcon from 'src/assets/icons/icon-worksMap.svg';
import infracareLogo from 'src/assets/logos/Infracare-logo.svg';
import Translate from '../../../localization/Localization';
import { RouteLocationStateModel } from '../../../views/RoadsConditionAndScenarios/models/RouteLocationStateModel';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import './NavMenuStyles.scss';

interface NavMenuProps {
    role: string
}

const NavMenu = (props: NavMenuProps & RouteComponentProps): JSX.Element => {

    const location = useLocation();
    let pathname = location.pathname.trim().toLowerCase()
    if (pathname !== "/" && !pathname.endsWith("/")) {
        pathname = pathname + "/";
    }

    let shouldDisplayNavMenu = pathname !== "/";

    let locationState = location.state as RouteLocationStateModel;
    let isCurrentVersionProject = locationState?.isCurrentVersion;

    const getHasScenarioAccess = (role: string, isCurrentVersionProject: boolean): boolean => {
        return (role === "ADM" || role === "ATXPLUS" || role === "CLIPLUS") && isCurrentVersionProject;
    }

    const [hasScenarioAccess, setHasScenarioAccess] = useState<boolean>(shouldDisplayNavMenu ? getHasScenarioAccess(props.role, isCurrentVersionProject) : false);

    const onScenariosButtonClick = (): void => {
        let urlRedirect = "/MaintenanceScenarios";
        let locationState = location.state as RouteLocationStateModel;

        props.navigate(urlRedirect, { state: locationState });
    }

    const onRoadsConditionButtonClick = () => {
        let urlRedirect = "/RoadsCondition";
        let locationState = location.state as RouteLocationStateModel;

        props.navigate(urlRedirect, { state: locationState });
    }

    const onRoadWorksButtonClick = () => {
        let urlRedirect = "/RoadWorks";
        let locationState = location.state as RouteLocationStateModel;

        props.navigate(urlRedirect, { state: locationState });
    }

    useEffect(() => {
        setHasScenarioAccess(getHasScenarioAccess(props.role, isCurrentVersionProject));
    }, [isCurrentVersionProject, props.role])

    let ret: JSX.Element = <></>;

    if (shouldDisplayNavMenu) {
        ret = <Box className="nav-menu" display="flex" flexDirection="column">
            <NavLink className="logo" to="/">
                <img src={infracareLogo} alt="logo" />
            </NavLink>
            {pathname !== "/usersadmin/" &&
                <>
                    <Box className={`item ${pathname === "/roadscondition/" ? 'active' : ''}`} display="flex" alignItems="center" justifyContent="center">
                        <IconButton className="item-link" onClick={onRoadsConditionButtonClick}>
                            <img src={statementIcon} alt="roads condition" />
                            <span className="link-label">{Translate.Resources.UI_NavMenu_Statements}</span>
                        </IconButton>
                    </Box>
                    {hasScenarioAccess &&
                        <Box className={`item ${pathname === "/maintenancescenarios/" ? 'active' : ''}`} display="flex" alignItems="center" justifyContent="center" >
                            <IconButton className="item-link" onClick={onScenariosButtonClick}>
                                <img src={scenarioIcon} alt="scenario" />
                                <span className="link-label">{Translate.Resources.UI_NavMenu_Scenarios}</span>
                            </IconButton>
                        </Box>
                    }
                    {isCurrentVersionProject &&
                        <Box className={`item ${pathname === "/roadworks/" ? 'active' : ''}`} display="flex" alignItems="center" justifyContent="center">
                            <IconButton className="item-link" onClick={onRoadWorksButtonClick}>
                                <img src={worksMapIcon} alt="works Map" />
                                <span className="link-label">{Translate.Resources.UI_NavMenu_WorksMap}</span>
                            </IconButton>
                        </Box>
                    }
                </>
            }
        </Box>
    }

    return ret;
}

export default React.forwardRef(withRouter(NavMenu));
