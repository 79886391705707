import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, NativeSelect, Paper, PaperProps, Slider } from '@mui/material';
import { orderBy } from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import Draggable from 'react-draggable';
import allProjectsVersionsIcon from 'src/assets/icons/icon-all-projects-versions.svg';
import { makeStyles } from 'tss-react/mui';
import Translate from '../../../localization/Localization';
import { ProjectVersion } from '../../../views/Home/services/dataContracts/queryStack/ProjectVersion';
import { RouteLocationStateModel } from '../../../views/RoadsConditionAndScenarios/models/RouteLocationStateModel';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import './AllProjectsVersionsDialogStyles.scss';

interface AllProjectsVersionsDialogProps {
    projects: ProjectVersion[],
    isOpen: boolean
    onCancel: () => void,
    onValidate: () => void
}

const AllProjectsVersionsDialog = (props: AllProjectsVersionsDialogProps & RouteComponentProps): JSX.Element => {
    const currentScoringValue = [0.5, 2];

    const [selectedProject, setSelectedProject] = useState<ProjectVersion>(null);
    const [scoringValue, setScoringValue] = useState<number[]>([currentScoringValue[0] * 100, currentScoringValue[1] * 100]);
    const [shouldShowUnscoredSections, setShouldShowUnscoredSections] = useState<boolean>(false);

    const PaperComponent = (props: PaperProps) => {
        return (
            <Draggable handle="#all-projects-versions-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="parent">
                <Paper {...props} />
            </Draggable>
        );
    }

    const handleAuscultationChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        var project = props.projects.find(x => x.projectVersionId === Number(event.target.value));
        if (project) {
            setSelectedProject(project);
        } else {
            setSelectedProject(null);
        }
    }

    const getValueLabelFormat = (value: number, index: number): number => {
        return value / 100;
    }

    const onChange = (event: Event, value: number | number[], activeThumb: number): void => {
        const [min, max] = value as number[];

        if (max > min && max !== min)
            setScoringValue(value as number[]);
    }

    const useStyles = makeStyles<{ scoringValue: number[] }>()(
        (_theme, { scoringValue }) => ({
            root: {
                width: 300
            },
            margin: {
                height: _theme.spacing(3)
            },
            thumb: {
                background: "green",
                "&~&": {
                    background: "red"
                },
                width: "12px",
                height: "12px"
            },
            mark: {
                background: "black"
            },
            rail: {
                background: `linear-gradient(to right, green, green ${(scoringValue[0]) / 10}%, red ${(scoringValue[1]) / 10}%, red);`,
                height: "2px"
            },
            track: {
                background: "orange",
                height: "2px",
                border: "none"
            },
            valueLabel: {
                background: "white",
                "&>*": {
                    background: "black"
                }
            }
        })
    );

    const { classes } = useStyles({ "scoringValue": scoringValue });

    const handleValidate = (): void => {
        props.onValidate();
        let urlRedirect = "/RoadsCondition";
        let routeLocationState: RouteLocationStateModel = {
            projectId: selectedProject.projectId,
            projectVersionId: selectedProject.projectVersionId,
            locationGeometry: selectedProject.locationGeometry,
            isCurrentVersion: selectedProject.isCurrentVersion,
            label: selectedProject.label,
            scoringValue: [scoringValue[0] / 100, scoringValue[1] / 100],
            shouldShowUnscoredSections: shouldShowUnscoredSections
        };
        props.navigate(urlRedirect, { state: routeLocationState });
    }

    return (
        <Dialog
            disableEscapeKeyDown
            aria-modal="false"
            open={props.isOpen}
            onClose={props.onCancel}
            scroll="paper"
            className="all-projects-versions-dialog"
            PaperComponent={PaperComponent}
            aria-labelledby="all-projects-versions-dialog-title"
        >
            <DialogTitle id="all-projects-versions-dialog-title" >
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box className="dialog-title">
                        <img className="icon" src={allProjectsVersionsIcon} alt="all projects versions icon" />
                        {Translate.Resources.UI_NavMenu_ClickedZone_ExaminationsChoice}
                    </Box>
                    <Box>
                        <IconButton onClick={props.onCancel}>
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" className="clicked-zone-content">
                    <Box mb="15px">
                        <NativeSelect
                            value={selectedProject ? selectedProject.projectVersionId : ""}
                            onChange={(e) => handleAuscultationChange(e)}
                            name="auscultationChoice"
                            inputProps={{ 'aria-label': 'auscultationChoice' }}
                        >
                            <option value=""></option>
                            {orderBy(props.projects, [(p: ProjectVersion) => { return p.projectId.toLowerCase() }], ['asc']).map((project: ProjectVersion, index: number) => {
                                return (
                                    <option key={`auscultation-option-${index}`} value={project.projectVersionId}>
                                        {`${project.projectId} v${project.versionNumber} ${project.isCurrentVersion ? '*' : ''}`}
                                    </option>
                                )
                            })}
                        </NativeSelect>
                    </Box>
                    <Box className="text">{Translate.Resources.UI_NavMenu_ClickedZone_ValuesForScoring}</Box>
                    <Box mt="45px" className="slider">
                        <Slider
                            classes={{
                                thumb: classes.thumb,
                                rail: classes.rail,
                                track: classes.track,
                                valueLabel: classes.valueLabel,
                                mark: classes.mark
                            }}
                            valueLabelDisplay="on"
                            valueLabelFormat={getValueLabelFormat}
                            value={scoringValue}
                            min={0}
                            max={1000}
                            step={25}
                            onChange={onChange}
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            className="checkbox-label"
                            control={
                                <Checkbox
                                    checked={shouldShowUnscoredSections}
                                    onChange={() => setShouldShowUnscoredSections(!shouldShowUnscoredSections)}
                                    color="primary"
                                />
                            }
                            label={Translate.Resources.UI_NavMenu_ClickedZone_ViewUnexaminedDomain}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" alignSelf="flex-end">
                        <Button variant="contained" color="primary" disabled={selectedProject === null} onClick={handleValidate}>{Translate.Resources.UI_NavMenu_ClickedZone_Button_Validate}</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default React.forwardRef(withRouter(AllProjectsVersionsDialog));