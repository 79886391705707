//SYNC HGA with HomeController.cs _roadTypesWidthInMeters
export const roadTypesWidthInMeters: Map<string, number> = new Map<string, number>([
    ['unclassified', 5],
    ['primary', 8],
    ['secondary', 7],
    ['secondary_link', 3.5],
    ['residential', 5.5],
    ['road', 5.5],
    ['track', 3],
    ['primary_link', 3],
    ['tertiary', 5.5],
    ['tertiary_link', 3],
    ['trunk', 8],
    ['trunk_link', 3.5],
    ['motorway_link', 6],
    ['motorway', 12],
    ['service', 5],
    ['living_street', 5.5],
    ['autres', 5.5]
]);