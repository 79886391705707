import { faChevronCircleLeft, faChevronCircleRight, faRoute, faSearch, faThumbsUp, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import React, { useState } from 'react';
import auscultationImage from 'src/assets/help/auscultationImage.png';
import auscultationSectionDefinition from 'src/assets/help/auscultationSectionDefinition.png';
import auscultationSectionsMap from 'src/assets/help/auscultationSectionsMap.png';
import selectedSections from 'src/assets/help/selectedSections.png';
import Translate from '../../../localization/Localization';

export const HelpDialogContentComponent = (): JSX.Element => {

    const [expanded, setExpanded] = useState(1);

    return (
        <>
            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 1} onChange={() => setExpanded(1)}>
                <AccordionSummary>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="summmary">
                        {Translate.Resources.UI_HelpDialog_AccordionSummary_WantToKnowTheDefinitionOfASection}
                        {expanded === 1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Box>
                </AccordionSummary>
                <AccordionDetails className="images-items">
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box display="flex" flexDirection="row" mb="10px" className="text">
                            {Translate.Resources.UI_HelpDialog_Accordion_ASectionIsAPieceOfRoad}
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="center" mb="10px">
                            <img src={auscultationSectionDefinition} width="300px" alt="auscultation section definition" />
                        </Box>
                        <Box display="flex" flexDirection="row" mb="10px" className="text">
                            {Translate.Resources.UI_HelpDialog_Accordion_RoadSectionDividedIntoTenSections}
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 2} onChange={() => setExpanded(2)}>
                <AccordionSummary>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="summmary">
                        {Translate.Resources.UI_HelpDialog_AccordionSummary_WantToVisualizeYourRoad}
                        {expanded === 2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Box>
                </AccordionSummary>
                <AccordionDetails className="images-items">
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box display="flex" flexDirection="row" mb="10px" className="text">{Translate.Resources.UI_HelpDialog_Accordion_JustClickToTheDesiredPlace}</Box>
                        <Box display="flex" flexDirection="row" justifyContent="center" mb="10px">
                            <img src={auscultationSectionsMap} alt="auscultation sections map" />
                        </Box>
                        <Box display="flex" flexDirection="row" mb="10px" className="text">{Translate.Resources.UI_HelpDialog_Accordion_DriveRoadWithArrowsOnTheLeftAndRightOfThePicture}</Box>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mb="10px">
                            <FontAwesomeIcon className="navigation-icon" size="2x" icon={faChevronCircleLeft} />
                            <img src={auscultationImage} alt="auscultation" />
                            <FontAwesomeIcon className="navigation-icon" size="2x" icon={faChevronCircleRight} />
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 3} onChange={() => setExpanded(3)}>
                <AccordionSummary>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="summmary">
                        {Translate.Resources.UI_HelpDialog_AccordionSummary_WantToSelectSectionsOfYourRoad}
                        {expanded === 3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Box>
                </AccordionSummary>
                <AccordionDetails className="images-items">
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box display="flex" flexDirection="column" className="text">
                            <Box mb="10px"><b>CTRL CLIC</b> : {Translate.Resources.UI_HelpDialog_Accordion_CtrlClick}</Box>
                            <Box display="flex" flexDirection="row" justifyContent="center" mb="10px">
                                <img src={selectedSections} alt="selected sections" className="selected-sections-img" />
                            </Box>
                            <Box mb="10px"><b>ALT CLIC</b> : {Translate.Resources.UI_HelpDialog_Accordion_AltClick}</Box>
                            <Box mb="10px"><b>SHIFT CLIC</b> : {Translate.Resources.UI_HelpDialog_Accordion_ShiftClick}</Box>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 4} onChange={() => setExpanded(4)}>
                <AccordionSummary>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="summmary">
                        {Translate.Resources.UI_HelpDialog_AccordionSummary_WantToKnowHowWeCalculateTheRoadSurface}
                        {expanded === 3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Box>
                </AccordionSummary>
                <AccordionDetails className="images-items">
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box display="flex" flexDirection="column" className="text">
                            <Box mb="10px">{Translate.Resources.UI_HelpDialog_Accordion_RoadsSurfaceCalculated}</Box>
                            <Box mb="10px">{Translate.Resources.UI_HelpDialog_Accordion_RoadWidthDefined}</Box>
                            <Box mb="10px">
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="total-covered">
                                    <FontAwesomeIcon size="2x" icon={faRoute} />
                                    <Box ml="10px"><b>{`${1.683.toLocaleString()} KM`}</b> {Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_CoveredRoad}</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Box display="flex" flexDirection="column" alignItems="center" width="33%">
                                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="road-condition poor">
                                            <FontAwesomeIcon size="1x" icon={faWrench} />
                                            <Box ml="10px">{Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_BadStatus}</Box>
                                        </Box>
                                        <Box className="percent-text">{`${7.64.toLocaleString()} %`}</Box>
                                        <Box className="km-text"><b>{`${0.129.toLocaleString()} KM`}</b></Box>
                                        <Box className="km-text"><b>{`≈ ${707} m²`}</b></Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column" alignItems="center" width="33%">
                                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="road-condition to-monitor">
                                            <FontAwesomeIcon size="1x" icon={faSearch} />
                                            <Box ml="10px">{Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_ToMonitor}</Box>
                                        </Box>
                                        <Box className="percent-text">{`${7.94.toLocaleString()} %`}</Box>
                                        <Box className="km-text"><b>{`${0.134.toLocaleString()} KM`}</b></Box>
                                        < Box className="km-text"><b>{`≈ ${735} m²`}</b></Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column" alignItems="center" width="33%">
                                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="road-condition good">
                                            <FontAwesomeIcon size="1x" icon={faThumbsUp} />
                                            <Box ml="10px">{Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_GoodStatus}</Box>
                                        </Box>
                                        <Box className="percent-text">{`${84.42.toLocaleString()} %`}</Box>
                                        <Box className="km-text"><b>{`${1.421.toLocaleString()} KM`}</b></Box>
                                        <Box className="km-text"><b>{`≈ ${7815} m²`}</b></Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" mb="10px">
                                <Box display="flex" flexDirection="row" className="road-type-row header">
                                    <Box width="50%">{Translate.Resources.UI_Global_RoadType_Title_RoadType}</Box>
                                    <Box width="50%">{Translate.Resources.UI_Global_RoadType_Title_RoadWidthInMeter}</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("unclassified")}</Box>
                                    <Box width="50%">5</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("primary")}</Box>
                                    <Box width="50%">8</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("secondary")}</Box>
                                    <Box width="50%">7</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("secondary_link")}</Box>
                                    <Box width="50%">{3.5.toLocaleString()}</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("residential")}</Box>
                                    <Box width="50%">{5.5.toLocaleString()}</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("road")}</Box>
                                    <Box width="50%">{5.5.toLocaleString()}</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("track")}</Box>
                                    <Box width="50%">3</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("primary_link")}</Box>
                                    <Box width="50%">3</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("tertiary")}</Box>
                                    <Box width="50%">{5.5.toLocaleString()}</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("tertiary_link")}</Box>
                                    <Box width="50%">3</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("trunk")}</Box>
                                    <Box width="50%">8</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("trunk_link")}</Box>
                                    <Box width="50%">{3.5.toLocaleString()}</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("motorway_link")}</Box>
                                    <Box width="50%">6</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("motorway")}</Box>
                                    <Box width="50%">12</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("service")}</Box>
                                    <Box width="50%">5</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("living_street")}</Box>
                                    <Box width="50%">{5.5.toLocaleString()}</Box>
                                </Box>
                                <Box display="flex" flexDirection="row" className="road-type-row">
                                    <Box width="50%">{Translate.GetRoadTypeLabel("others")}</Box>
                                    <Box width="50%">{5.5.toLocaleString()}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
}