import { AuthenticationType, data, layer, Map as AzureMap, MapMouseEvent, Shape, source } from 'azure-maps-control';
import { SettingsProvider } from '../../SettingsProvider';
import { CustomMapChoice } from '../../shared/models/CustomMapChoice';
import { MapCursorMode } from '../../shared/models/MapCursorMode';
import { ScoreTypes } from '../../shared/models/ScoreTypes';
import '../../utils/Map';
import MathExtensions from '../../utils/MathExtensions';
import { AppModule, LocalStorage } from '../../utils/Storage';
import '../../utils/String';
import Utilities from '../../utils/Utilities';
import styles from "../../_variables.scss";
import { ProjectThumbnailModel } from '../Home/models/ProjectThumbnailModel';
import { Auscultation } from '../Home/services/dataContracts/queryStack/Auscultation';
import { ProjectVersion } from '../Home/services/dataContracts/queryStack/ProjectVersion';
import { ScoringParameter } from '../Home/services/dataContracts/queryStack/ScoringParameter';
import { HomeApiClient } from '../Home/services/HomeApiClient';
import { AuscultationExtended } from './models/AuscultationExtended';
import { ImageExtended } from './models/ImageExtended';
import { MergedProjectVersion } from './models/MergedProjectVersion';
import { ProjectVersionExtended } from './models/ProjectVersionExtended';
import { RoadExtended } from './models/RoadExtended';
import { RoadSectionExtended } from './models/RoadSectionExtended';
import { RoadSectionScoreExtended } from './models/RoadSectionScoreExtended';
import { RoadSectionViewData } from './models/RoadSectionViewData';
import { RoadViewData } from './models/RoadViewData';
import { ScoreColors } from './models/ScoreColors';
import { ScoreTypesColors } from './models/ScoreTypesColors';
import { ShapeEntityType } from './models/ShapeEntityType';
import { roadTypesWidthInMeters } from './RoadTypesWidths';
import { Image } from './services/RoadsCondition/dataContracts/queryStack/Image';
import { MultiAuscultationMergedScores } from './services/RoadsCondition/dataContracts/queryStack/MultiAuscultationMergedScores';
import { Point } from './services/RoadsCondition/dataContracts/queryStack/Point';
import { Road } from './services/RoadsCondition/dataContracts/queryStack/Road';
import { RoadSectionScore } from './services/RoadsCondition/dataContracts/queryStack/RoadSectionScore';
import { RoadsConditionApiClient } from './services/RoadsCondition/RoadsConditionApiClient';

export const transparentColor = 'transparent';

export const sectionWidth = 6;
export const enlightenedAreaWidth = 14;
export const areaWidth = 6;

export const mainDatasourceId = "main";
export const anomaliesDatasourceId = "anomalies";

export const roadLayerId = "roadLayerId";
export const anomaliesLayerId = "anomaliesLayerId";
export const clusterBubbleLayerId = "clusterBubbleLayerId";
export const clusterCountLayerId = "clusterCountLayerId";

export const cameraAnimationType = "fly";
export const cameraAnimationDuration = 500;

export const anomaliesColors = new Map<string, string>([
    ['arrachement', styles.arrachementAnomalyColor],
    ['faiençage', styles.faiençageAnomalyColor],
    ['feu_de_circulation', styles.feuDeCirculationAnomalyColor],
    ['fissure_longitudinale', styles.fissureLongitudinaleAnomalyColor],
    ['fissure_transversale', styles.fissureTransversaleAnomalyColor],
    ['joint_longitudinal', styles.jointLongitudinalAnomalyColor],
    ['nid_de_poule', styles.nidDePouleAnomalyColor],
    ['passage_pieton', styles.passagePietonAnomalyColor],
    ['pontage', styles.pontageAnomalyColor],
    ['reparation', styles.reparationAnomalyColor],
    ['tampon', styles.tamponAnomalyColor],
    ['tranchee_transversale', styles.trancheeTransversaleAnomalyColor],
    ['affaissement_de_rive', styles.affaissementDeRiveAnomalyColor],
    ['bordure_trottoir', styles.bordureTrottoirAnomalyColor],
    ['emergence_sur_chaussee', styles.emergenceSurChausseeAnomalyColor],
    ['faiençage_ponte', styles.faiençagePonteAnomalyColor],
    ['fiss_long_ponte', styles.fissLongPonteAnomalyColor],
    ['fiss_trans_ponte', styles.fissTransPonteAnomalyColor],
    ['peignage', styles.peignageAnomalyColor],
    ['ressuage', styles.ressuageAnomalyColor],
    ['tranchee', styles.trancheeAanomalyColor]
]);
export class RoadsConditionAndScenariosShared {

    public static createMap = (id: string, zoom: number, center: Point, mapChoice: CustomMapChoice): AzureMap => {
        let map = new AzureMap(id, {
            zoom: zoom,
            center: center ? [center.coordinates[0], center.coordinates[1]] : null,
            view: 'Auto',
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: SettingsProvider.Get().azureMapsApiKey
            }
        });

        map.events.add('ready', () => {
            Utilities.ensureCustomMapChoice(map, mapChoice);
        });

        return map;
    }

    public static getMergedProject = async (projectVersionId: number, shouldShowUnscoredSections: boolean, thresholdOfGoodScore: number, thresholdOfPoorScore: number, mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>, projectVersionsCache: Map<number, ProjectVersion>): Promise<MergedProjectVersion> => {
        let result = mergedProjectAuscultationsCache.get(projectVersionId);
        if (result) {
            return Promise.resolve(result);
        }

        let project = await RoadsConditionAndScenariosShared.getVersionOfProject(projectVersionId, projectVersionsCache);
        let projectAuscultationsArray = project.auscultations;

        let auscultationsIdsArray: number[] = projectAuscultationsArray.map(x => x.auscultationId);
        let auscultationsIdsString: string = Utilities.GetCommaSeparatedString(auscultationsIdsArray);
        let multiAuscultationMergedScores = await RoadsConditionAndScenariosShared.getMergedScoresRawData(project.projectVersionId, auscultationsIdsString, thresholdOfPoorScore);

        let projectVersionData = await RoadsConditionAndScenariosShared.getProjectVersionRawData(project.projectVersionId);
        let projectVersion: ProjectVersionExtended = RoadsConditionAndScenariosShared.buildExtendedProjectVersion(project, projectVersionData.roads, projectVersionData.imagesData, projectVersionData.roadSectionsScoresData, thresholdOfGoodScore, thresholdOfPoorScore);
        let mergedProject: MergedProjectVersion = RoadsConditionAndScenariosShared.buildMergedProject(projectVersion, multiAuscultationMergedScores, auscultationsIdsString, shouldShowUnscoredSections);

        result = mergedProject;

        mergedProjectAuscultationsCache.set(projectVersionId, result);

        return result;
    }

    public static getVersionOfProject = (projectVersionId: number, projectVersionsCache: Map<number, ProjectVersion>): Promise<ProjectVersion> => {
        let projectVersion = projectVersionsCache.get(projectVersionId);
        if (projectVersion) {
            return Promise.resolve(projectVersion);
        }

        return HomeApiClient.GetVersionOfProject(projectVersionId)
            .then((res) => {
                if (res) {
                    projectVersion = res.data[0]
                    projectVersionsCache.set(projectVersionId, projectVersion);
                    return projectVersion;
                }

                return null;
            });
    }

    public static getMergedScoresRawData = (projectVersionId: number, auscultationsIdsString: string, thresholdOfPoorScore: number): Promise<MultiAuscultationMergedScores> => {
        return RoadsConditionApiClient.GetMergedScores(projectVersionId, auscultationsIdsString, thresholdOfPoorScore)
            .then((res) => {
                if (res) {
                    return res.data;
                }

                return null;
            });
    }

    public static getProjectVersionRawData = async (projectVersionId: number): Promise<{ roads: Road[], imagesData: Map<number, Image[]>, roadSectionsScoresData: Map<number, RoadSectionScore[]> }> => {
        return await Promise.all([
            RoadsConditionApiClient.GetRoads(projectVersionId),
            RoadsConditionApiClient.GetPerAuscultationImages(projectVersionId),
            RoadsConditionApiClient.GetPerAuscultationScores(projectVersionId)
        ])
            .then((res) => {
                if (res) {
                    let roads = res[0].data;
                    let imagesData = res[1].data;
                    let roadSectionsScoresData = res[2].data;

                    return {
                        roads: roads,
                        imagesData: imagesData,
                        roadSectionsScoresData: roadSectionsScoresData
                    }
                }

                return null;
            });
    }

    public static buildExtendedProjectVersion = (projectVersion: ProjectThumbnailModel, roads: Road[], imagesData: Map<number, Image[]>, roadSectionsScoresData: Map<number, RoadSectionScore[]>, thresholdOfGoodScore: number, thresholdOfPoorScore: number): ProjectVersionExtended => {
        let imagesMap = new Map<number, Image[]>();
        imagesMap = imagesMap.fromObject(imagesData, true);

        let perAuscultationScoresMap = new Map<number, RoadSectionScore[]>();
        perAuscultationScoresMap = perAuscultationScoresMap.fromObject(roadSectionsScoresData, true);

        let roadSectionsScoresMap = new Map<number, RoadSectionScoreExtended>();
        perAuscultationScoresMap.forEach((roadSectionScores: RoadSectionScore[], auscultationId: number) => {
            roadSectionScores.forEach((roadSectionScore: RoadSectionScore) => {
                let roadSectionScoreExtended = roadSectionScore as RoadSectionScoreExtended;
                roadSectionScoreExtended.auscultationId = auscultationId;
                roadSectionsScoresMap.set(roadSectionScore.roadSectionScoreId, roadSectionScoreExtended);
            });
        });

        let roadsArray = roads as RoadExtended[];
        let scoringParametersDico = new Map<string, ScoringParameter>();
        projectVersion.scoringParameters.forEach((scoringParameter: ScoringParameter) => {
            scoringParametersDico.set(scoringParameter.anomalyType, scoringParameter);
        });

        let extendedRoads: Map<number, RoadExtended> = new Map<number, RoadExtended>();
        let roadsSections = new Map<number, RoadSectionExtended>();

        roadsArray.forEach((road: RoadExtended) => {
            road.extendedSections = new Map<number, RoadSectionExtended>();
            road.widthInMeters = roadTypesWidthInMeters.get(road.roadType) ?? roadTypesWidthInMeters.get('autres');

            let sections = road.sections as RoadSectionExtended[];
            sections.forEach((section: RoadSectionExtended) => {
                let extendedSection: RoadSectionExtended = section;
                extendedSection.roadLabel = road.label ?? road.referenceRoadId;
                extendedSection.roadExtended = road;

                road.extendedSections.set(extendedSection.roadSectionId, extendedSection);
                roadsSections.set(extendedSection.roadSectionId, extendedSection);
            });

            extendedRoads.set(road.roadId, road);
        });

        let roadSectionsScoresExtendedMap: Map<number, RoadSectionScoreExtended> = new Map<number, RoadSectionScoreExtended>();
        let auscultations = new Map<number, AuscultationExtended>();
        projectVersion.auscultations.forEach((auscultation) => {
            let imagesData = imagesMap.get(auscultation.auscultationId) as ImageExtended[];
            let images = RoadsConditionAndScenariosShared.buildExtendedImagesDictionary(imagesData, auscultation, thresholdOfGoodScore, thresholdOfPoorScore);
            let roadSectionsScores = perAuscultationScoresMap.get(auscultation.auscultationId);
            let bySectionIdMap = new Map<number, RoadSectionScoreExtended>();
            roadSectionsScores.forEach((element) => {
                let roadSectionsScore = element as RoadSectionScoreExtended;
                let score = roadSectionsScore?.score;
                let scoreColor: string = ScoreColors.black;
                if (roadSectionsScore?.roadSectionScoreId !== null) {
                    scoreColor = RoadsConditionAndScenariosShared.getScoreColor(score, thresholdOfGoodScore, thresholdOfPoorScore);
                }

                roadSectionsScore.color = scoreColor;
                bySectionIdMap.set(roadSectionsScore.roadSectionId, roadSectionsScore);

                let roadSectionScoreExtended = roadSectionsScoresMap.get(roadSectionsScore.roadSectionScoreId) as RoadSectionScoreExtended;
                roadSectionScoreExtended.color = scoreColor;
                roadSectionsScoresExtendedMap.set(roadSectionsScore.roadSectionScoreId, roadSectionScoreExtended);
            });

            let perSectionImages: Map<number, ImageExtended[]> = new Map<number, ImageExtended[]>();
            imagesData.forEach((image: ImageExtended) => {
                let array: ImageExtended[] = perSectionImages.get(image.roadSectionId);
                if (array != null) {
                    array.push(image);
                }
                else {
                    array = [image];
                }
                perSectionImages.set(image.roadSectionId, array);
            });

            let auscultationExtended = auscultation as AuscultationExtended;

            auscultationExtended.imagesDico = images;
            auscultationExtended.roadSectionsScores = bySectionIdMap;
            auscultationExtended.perSectionImages = perSectionImages;
            auscultations.set(auscultationExtended.auscultationId, auscultationExtended);
        });

        let result: ProjectVersionExtended = {
            auscultations: projectVersion.auscultations,
            projectId: projectVersion.projectId,
            projectVersionId: projectVersion.projectVersionId,
            versionNumber: projectVersion.versionNumber,
            isCurrentVersion: projectVersion.isCurrentVersion,
            label: projectVersion.label,
            imageBlobName: projectVersion.imageBlobName,
            locationGeometry: projectVersion.locationGeometry,
            scoringParameters: projectVersion.scoringParameters,
            images: imagesMap,
            roads: roads,
            perAuscultationScores: perAuscultationScoresMap,
            roadSectionsScores: roadSectionsScoresExtendedMap,
            scoringParametersDico: scoringParametersDico,
            extendedAuscultations: auscultations,
            roadsSections: roadsSections,
            extendedRoads: extendedRoads
        };

        return result;
    }

    public static buildExtendedImagesDictionary = (images: ImageExtended[], auscultation: Auscultation, thresholdOfGoodScore: number, thresholdOfPoorScore: number): Map<number, ImageExtended> => {
        let imagesDico: Map<number, ImageExtended> = new Map<number, ImageExtended>();
        images.forEach((image: ImageExtended) => {
            if (image.score !== null) {
                image.scoreColor = RoadsConditionAndScenariosShared.getScoreColor(image.score, thresholdOfGoodScore, thresholdOfPoorScore);
            }
            image.auscultation = auscultation;

            imagesDico.set(image.imageId, image);
        });

        return imagesDico;
    }

    public static buildMergedProject = (project: ProjectVersionExtended, multiAuscultationMergedScores: MultiAuscultationMergedScores, auscultationsIdsString: string, shouldShowUnscoredSections: boolean): MergedProjectVersion => {
        let boundingBox = RoadsConditionAndScenariosShared.computeBoundingBox(project.auscultations);
        let southWesternBoundingLocationGeometry = boundingBox.southWesternBoundingLocationGeometry;
        let northEasternBoundingLocationGeometry = boundingBox.northEasternBoundingLocationGeometry;
        let extendedRoads = new Map<number, RoadViewData>();
        let extendedSections = new Map<number, RoadSectionViewData>();

        let mergedRoadSectionScoresIds = multiAuscultationMergedScores.roadSectionScoresIds;
        mergedRoadSectionScoresIds.forEach((roadSectionScoresId) => {
            let sectionScore = project.roadSectionsScores.get(roadSectionScoresId);
            let auscultationId = sectionScore.auscultationId;
            let auscultation = project.extendedAuscultations.get(auscultationId);
            let roadSection = project.roadsSections.get(sectionScore.roadSectionId);
            let sectionViewData: RoadSectionViewData = {
                roadSectionId: roadSection.roadSectionId,
                label: roadSection.label,
                lengthInMeters: roadSection.lengthInMeters,
                pathGeometry: roadSection.pathGeometry,
                roadLabel: roadSection.roadLabel,
                images: auscultation.perSectionImages.get(roadSection.roadSectionId),
                scoreColor: sectionScore.color,
                scoreType: sectionScore.color === ScoreColors.poor ? ScoreTypes.poor :
                    (sectionScore.color === ScoreColors.toMonitor ? ScoreTypes.toMonitor :
                        (sectionScore.color === ScoreColors.good ? ScoreTypes.good : null)),
                score: sectionScore.score,
                roadSectionScoreId: sectionScore.roadSectionScoreId,
                trustedAnomaliesCounters: null,
                roadExtended: null
            };

            extendedSections.set(sectionViewData.roadSectionId, sectionViewData);

            let road = roadSection.roadExtended;
            let roadViewData = extendedRoads.get(road.roadId);
            if (!roadViewData) {
                roadViewData = {
                    roadId: road.roadId,
                    extendedSections: new Map<number, RoadSectionViewData>(),
                    scoringParameters: project.scoringParametersDico,
                    widthInMeters: road.widthInMeters
                };

                extendedRoads.set(roadViewData.roadId, roadViewData);
            }

            sectionViewData.roadExtended = roadViewData;
            roadViewData.extendedSections.set(sectionViewData.roadSectionId, sectionViewData);
        });

        if (shouldShowUnscoredSections) {
            project.extendedRoads.forEach((r: RoadExtended) => {
                let roadViewData = extendedRoads.get(r.roadId);
                if (!roadViewData) {
                    roadViewData = {
                        roadId: r.roadId,
                        extendedSections: new Map<number, RoadSectionViewData>(),
                        scoringParameters: project.scoringParametersDico,
                        widthInMeters: r.widthInMeters
                    };

                    extendedRoads.set(roadViewData.roadId, roadViewData);
                }

                r.extendedSections.forEach((roadSection: RoadSectionExtended) => {
                    if (!extendedSections.has(roadSection.roadSectionId)) {
                        let sectionViewData: RoadSectionViewData = {
                            roadSectionId: roadSection.roadSectionId,
                            label: roadSection.label,
                            lengthInMeters: roadSection.lengthInMeters,
                            pathGeometry: roadSection.pathGeometry,
                            roadLabel: roadSection.roadLabel,
                            images: null,
                            scoreColor: ScoreColors.black,
                            scoreType: null,
                            score: null,
                            roadSectionScoreId: null,
                            trustedAnomaliesCounters: null,
                            roadExtended: null
                        };

                        extendedSections.set(sectionViewData.roadSectionId, sectionViewData);
                        sectionViewData.roadExtended = roadViewData;
                        roadViewData.extendedSections.set(sectionViewData.roadSectionId, sectionViewData);
                    }
                });
            });
        }

        let imagesDico = new Map<number, ImageExtended>();
        let trustedAnomaliesEnteringScore = new Set<string>();
        let trustedAnomaliesOther = new Set<string>();
        extendedSections.forEach((s) => {
            if (s.images) {
                s.images.forEach((i) => {
                    imagesDico.set(i.imageId, i);
                    if (i.imageAnomalies && i.imageAnomalies.length > 0) {
                        i.imageAnomalies.forEach((anomaly) => {
                            let scoringParameter = project.scoringParametersDico.get(anomaly.anomalyType);
                            if (scoringParameter && anomaly.confidence >= scoringParameter.confidenceThreshold) {
                                if (scoringParameter.weight > 0 && !trustedAnomaliesEnteringScore.has(anomaly.anomalyType)) {
                                    trustedAnomaliesEnteringScore.add(anomaly.anomalyType);
                                }
                                else if ((scoringParameter.weight == null || scoringParameter.weight === 0) && !trustedAnomaliesOther.has(anomaly.anomalyType)) {
                                    trustedAnomaliesOther.add(anomaly.anomalyType);
                                }
                            }
                        });
                    }
                });
            }
        });

        let result: MergedProjectVersion = {
            ...multiAuscultationMergedScores,
            projectLabel: project.label,
            projectLocationGeometry: project.locationGeometry,
            projectVersion: project,
            extendedRoads: extendedRoads,
            imagesDico: imagesDico,
            roadsSections: extendedSections,
            southWesternBoundingLocationGeometry: southWesternBoundingLocationGeometry,
            northEasternBoundingLocationGeometry: northEasternBoundingLocationGeometry,
            auscultationsIdsString: auscultationsIdsString,
            trustedAnomaliesEnteringScore: trustedAnomaliesEnteringScore,
            trustedAnomaliesOther: trustedAnomaliesOther
        };

        return result;
    }

    public static computeBoundingBox = (auscultations: Auscultation[]): { southWesternBoundingLocationGeometry: Point, northEasternBoundingLocationGeometry: Point } => {
        let minLongitude = null;
        let minLatitude = null;
        let maxLongitude = null;
        let maxLatitude = null;

        auscultations.forEach((item) => {
            let minLong = item.southWesternBoundingLocationGeometry.coordinates[0];
            let minLat = item.southWesternBoundingLocationGeometry.coordinates[1];
            minLongitude = minLongitude ? Math.min(minLongitude, minLong) : minLong;
            minLatitude = minLatitude ? Math.min(minLatitude, minLat) : minLat;

            let maxLong = item.northEasternBoundingLocationGeometry.coordinates[0];
            let maxLat = item.northEasternBoundingLocationGeometry.coordinates[1];
            maxLongitude = maxLongitude ? Math.max(maxLongitude, maxLong) : maxLong;
            maxLatitude = maxLatitude ? Math.max(maxLatitude, maxLat) : maxLat;
        });

        let southWesternBoundingLocationGeometryCoordinates = new data.Position(minLongitude, minLatitude);
        let southWesternBoundingLocationGeometry: Point = {
            coordinates: southWesternBoundingLocationGeometryCoordinates,
            type: "Point"
        };

        let northEasternBoundingLocationGeometryCoordinates = new data.Position(maxLongitude, maxLatitude);
        let northEasternBoundingLocationGeometry: Point = {
            coordinates: northEasternBoundingLocationGeometryCoordinates,
            type: "Point"
        };

        return {
            southWesternBoundingLocationGeometry: southWesternBoundingLocationGeometry,
            northEasternBoundingLocationGeometry: northEasternBoundingLocationGeometry
        };
    }

    public static setMapCursor = (map: AzureMap, cursor: MapCursorMode): void => {
        let classList = map.getCanvasContainer().classList;

        const cursorPointerClassName = "cursor-pointer";
        const cursorAutoClassName = "cursor-auto";
        const cursorCrosshairClassName = "cursor-crosshair";

        let cursorClassName = null;

        switch (cursor) {
            case MapCursorMode.Pointer:
                cursorClassName = cursorPointerClassName;
                break;
            case MapCursorMode.Crosshair:
                cursorClassName = cursorCrosshairClassName;
                break;
            case MapCursorMode.Auto:
            default:
                cursorClassName = cursorAutoClassName;
                break;
        }

        if (cursorClassName !== cursorAutoClassName && classList.contains(cursorAutoClassName)) {
            classList.remove(cursorAutoClassName);
        }
        if (cursorClassName !== cursorCrosshairClassName && classList.contains(cursorCrosshairClassName)) {
            classList.remove(cursorCrosshairClassName);
        }
        if (cursorClassName !== cursorPointerClassName && classList.contains(cursorPointerClassName)) {
            classList.remove(cursorPointerClassName);
        }

        if (!classList.contains(cursorClassName)) {
            classList.add(cursorClassName);
        }
    }

    public static getScoreColor = (score: number, thresholdOfGoodScore: number, thresholdOfPoorScore: number): string => {
        if (score === null)
            return ScoreColors.undetermined;
        else if (score < thresholdOfGoodScore)
            return ScoreColors.good;
        else if (score >= thresholdOfGoodScore && score <= thresholdOfPoorScore)
            return ScoreColors.toMonitor;
        else if (score > thresholdOfPoorScore)
            return ScoreColors.poor;
        else
            return null;
    }

    public static createShape = (coordinates: data.Position[], id: number | string, strokeColor: string, strokeWidth: number, entityType: string, roadSectionId: number, sectionScoreType?: string, areaProps?: any): Shape => {
        return new Shape(new data.LineString(coordinates), id, { strokeColor, strokeWidth, EntityType: entityType, RoadSectionId: roadSectionId, sectionScoreType: sectionScoreType ?? null, areaProps: areaProps ?? null });
    }

    public static createAnomaliesShape = (section: RoadSectionViewData, anomalies: Set<string>): Shape => {
        let color = styles.mixedAnomaliesColor;
        if (anomalies.size === 1) {
            const [anomaly] = anomalies;
            color = anomaliesColors.get(anomaly);
        }
        let sectionId = section.roadSectionId;
        let anomalyShapeId = RoadsConditionAndScenariosShared.getAnomalyShapeId(sectionId);
        let coordinates = section.pathGeometry.coordinates;
        let midPoint = MathExtensions.interpolateMidPoint(coordinates);

        return new Shape(new data.Point(midPoint), anomalyShapeId, { EntityType: ShapeEntityType.anomaly, color: color, anomalyBubble: true, radius: 6, anomalies: anomalies, sectionId: sectionId });
    }

    public static getSectionShapeId = (roadSectionId: number): string => {
        return `${roadSectionId}`;
    }

    public static getAnomalyShapeId = (roadSectionId: number): string => {
        return `${roadSectionId}/anomaly`;
    }

    public static createLineLayer = (datasource: source.DataSource, layerId: string): layer.LineLayer => {
        return new layer.LineLayer(datasource, layerId, {
            strokeColor: ['case', ['has', 'strokeColor'], ['get', 'strokeColor'], transparentColor],
            strokeWidth: ['case', ['has', 'strokeWidth'], ['get', 'strokeWidth'], 0],
            lineJoin: 'round', lineCap: 'round', visible: true
        });
    }

    public static createAnomaliesLayer = (datasource: source.DataSource, layerId: string): layer.BubbleLayer => {
        return new layer.BubbleLayer(datasource, layerId, {
            color: ['get', 'color'],
            radius: ['get', 'radius'],
            filter: ['!', ['has', 'point_count']]
        });
    }

    public static recreateAnomaliesDatasource = (isGroupView: boolean, map: AzureMap, mergedProject: MergedProjectVersion, hasAnomaliesLayerMapEvent: boolean, anomalyLayerMouseover?: () => void, anomalyLayerMouseout?: () => void, anomalyPointClickHandler?: (e: void | MapMouseEvent | layer.Layer) => void, handleAnomalyPointClicked?: (e: void | MapMouseEvent | layer.Layer, mergedProject: MergedProjectVersion) => void): source.DataSource => {
        let anomaliesDatasource = map.sources.getById(anomaliesDatasourceId) as source.DataSource;
        if (anomaliesDatasource) {
            RoadsConditionAndScenariosShared.removeAnomaliesDatasource(map, hasAnomaliesLayerMapEvent, anomalyLayerMouseover, anomalyLayerMouseout, anomalyPointClickHandler);
        }

        anomaliesDatasource = RoadsConditionAndScenariosShared.createAnomaliesDatasource(map, isGroupView, mergedProject, hasAnomaliesLayerMapEvent, anomalyLayerMouseover, anomalyLayerMouseout, anomalyPointClickHandler, handleAnomalyPointClicked);
        return anomaliesDatasource;
    }

    public static removeAnomaliesDatasource = (map: AzureMap, hasAnomaliesLayerMapEvent: boolean, anomalyLayerMouseover?: () => void, anomalyLayerMouseout?: () => void, anomalyPointClickHandler?: (e: void | MapMouseEvent | layer.Layer) => void): void => {
        let existingAnomaliesLayer = map.layers.getLayerById(anomaliesLayerId) as layer.BubbleLayer;
        if (existingAnomaliesLayer) {

            if (hasAnomaliesLayerMapEvent) {
                map.events.remove('mouseover', existingAnomaliesLayer, anomalyLayerMouseover);
                map.events.remove('mouseout', existingAnomaliesLayer, anomalyLayerMouseout);
                map.events.remove('mousedown', existingAnomaliesLayer, anomalyPointClickHandler);
            }

            map.layers.remove(anomaliesLayerId);
        }

        let existingClusterBubbleLayer = map.layers.getLayerById(clusterBubbleLayerId);
        if (existingClusterBubbleLayer) {
            map.layers.remove(clusterBubbleLayerId);
        }

        let existingClusterCountLayer = map.layers.getLayerById(clusterCountLayerId);
        if (existingClusterCountLayer) {
            map.layers.remove(clusterCountLayerId);
        }

        let anomaliesDatasource = map.sources.getById(anomaliesDatasourceId) as source.DataSource;
        if (anomaliesDatasource) {
            anomaliesDatasource.clear();
            map.sources.remove(anomaliesDatasource);
        }
    }

    public static createAnomaliesDatasource = (map: AzureMap, isGroupView: boolean, mergedProject: MergedProjectVersion, hasAnomaliesLayerMapEvent: boolean, anomalyLayerMouseover?: () => void, anomalyLayerMouseout?: () => void, anomalyPointClickHandler?: (e: void | MapMouseEvent | layer.Layer) => void, handleAnomalyPointClicked?: (e: void | MapMouseEvent | layer.Layer, mergedProject: MergedProjectVersion) => void): source.DataSource => {
        let anomaliesDatasource = new source.DataSource(anomaliesDatasourceId, { cluster: isGroupView });
        map.sources.add(anomaliesDatasource);

        let clusterBubbleLayer = new layer.BubbleLayer(anomaliesDatasource, clusterBubbleLayerId, {
            color: styles.mixedAnomaliesColor,
            radius: 10,
            filter: ['has', 'point_count']
        });

        let clusterCountLayer = new layer.SymbolLayer(anomaliesDatasource, clusterCountLayerId, {
            iconOptions: {
                image: 'none'
            },
            textOptions: {
                textField: ['get', 'point_count_abbreviated'],
                allowOverlap: true,
                size: 16,
                offset: [0, -0.7],
            }
        });

        let anomaliesLayer = RoadsConditionAndScenariosShared.createAnomaliesLayer(anomaliesDatasource, anomaliesLayerId);
        map.layers.add([anomaliesLayer, clusterBubbleLayer, clusterCountLayer]);

        if (hasAnomaliesLayerMapEvent) {
            map.events.add('mouseover', anomaliesLayer, anomalyLayerMouseover);
            map.events.add('mouseout', anomaliesLayer, anomalyLayerMouseout);

            anomalyPointClickHandler = (e: void | MapMouseEvent | layer.Layer) => handleAnomalyPointClicked(e, mergedProject);
            map.events.add('mousedown', anomaliesLayer, anomalyPointClickHandler);
        }

        return anomaliesDatasource;
    }

    public static isShapeVisible = (shape: Shape): boolean => {
        let properties = shape.getProperties();
        let result = properties.strokeColor !== transparentColor && properties.strokeWidth !== 0;
        return result;
    }

    public static hideShape = (shape: Shape): void => {
        let props = shape.getProperties();
        props.strokeColor = transparentColor;
        props.strokeWidth = 0;
        shape.setProperties(props);
    }

    public static showAreaEnlightenedShape = (shapeId: string, datasource: source.DataSource): void => {
        let shape: Shape = datasource.getShapeById(shapeId);
        if (shape) {
            let props = shape.getProperties();
            props.strokeColor = styles.enlightenedAreaColor;
            props.strokeWidth = enlightenedAreaWidth;
            shape.setProperties(props);
        }
    }

    public static hideAreaEnlightenedShape = (shapeId: string, datasource: source.DataSource): void => {
        let shape: Shape = datasource.getShapeById(shapeId);
        if (shape) {
            RoadsConditionAndScenariosShared.hideShape(shape);
        }
    }

    public static updateSectionShapesColor = (datasource: source.DataSource, activeRoadsConditions: Map<string, boolean>): void => {
        datasource.getShapes().forEach((section: Shape) => {
            let properties = section.getProperties();
            let entityType = properties.EntityType;

            if (entityType === ShapeEntityType.section) {
                let sectionScoreType = properties.sectionScoreType;
                let strokeColor = styles.unfilteredSectionColor;

                if (activeRoadsConditions.get(sectionScoreType) === true) {
                    strokeColor = ScoreTypesColors.get(sectionScoreType);
                }

                if (strokeColor !== properties.strokeColor) {
                    properties.strokeColor = strokeColor;
                    section.setProperties(properties);
                }
            }
        });
    }

    public static getSectionVisibleAnomalies = (section: RoadSectionViewData, scoringParameters: Map<string, ScoringParameter>, activeAnomalies: Set<string>): Set<string> => {
        let anomalies = new Set<string>();

        section.images.forEach((image) => {
            if (image.imageAnomalies) {
                image.imageAnomalies.forEach((anomaly) => {
                    if (!anomalies.has(anomaly.anomalyType) && activeAnomalies.has(anomaly.anomalyType)) {
                        let scoringParameter = scoringParameters.get(anomaly.anomalyType);
                        if (anomaly.confidence >= scoringParameter.confidenceThreshold) {
                            anomalies.add(anomaly.anomalyType);
                        }
                    }
                });
            }
        });

        return anomalies;
    }

    public static getMapChoiceValue = (): CustomMapChoice => {
        const ModuleKey = AppModule.MapStyleSelector;
        const MapChoiceKey = 'mapChoice';

        const defaultMapChoice = CustomMapChoice.OpenStreetMap;

        let mapChoice: CustomMapChoice | any = LocalStorage.GetItem(ModuleKey, MapChoiceKey) || defaultMapChoice;
        let isMapChoice: boolean = mapChoice && Object.values(CustomMapChoice).includes(mapChoice);
        mapChoice = isMapChoice ? mapChoice : defaultMapChoice;

        return mapChoice;
    }

}