import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Box, Button, Dialog, DialogContent } from '@mui/material';
import React from 'react';
import iconBtnCancel from 'src/assets/icons/icon-btn-cancel.svg';
import Translate from '../../../localization/Localization';
import './ConfirmDeleteDialogStyles.scss';

export enum Mode {
    DeleteScenario = 0,
    DeleteArea = 1
}

interface ConfirmDeleteAreaDialogProps {
    isOpen: boolean,
    mode: Mode,
    handleCanceled: () => void,
    handleConfirmed: () => void
}

export const ConfirmDeleteDialog = (props: ConfirmDeleteAreaDialogProps): JSX.Element => {
    return (
        <Dialog
            disableEscapeKeyDown
            aria-modal="false"
            open={props.isOpen}
            onClose={props.handleCanceled}
            className="confirm-delete-dialog"
        >
            <DialogContent id="confirm-delete-dialog-content">
                <Box display="flex" flexDirection="column" justifyContent="space-around" height="100%">
                    <Box className="text"><ReportProblemIcon /></Box>
                    <Box className="text">{props.mode === Mode.DeleteArea ? Translate.Resources.UI_DeleteAreaDialog_Content : Translate.Resources.UI_DeleteScenarioDialog_Content}</Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Button className="btn-cancel" onClick={props.handleCanceled}>
                            <img src={iconBtnCancel} alt="icon btn cancel" />
                            {Translate.Resources.UI_DeleteAreaDialog_Button_Cancel}
                        </Button>
                        <Button className="btn-delete" onClick={props.handleConfirmed}>{Translate.Resources.UI_DeleteAreaDialog_Button_Delete}</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
