import { GridColumnProps } from "@progress/kendo-react-grid";

export enum AppModule {
    Global = "Global",
    Home = "Home",
    MaintenanceScenarios = "MaintenanceScenarios",
    MapStyleSelector = "MapStyleSelector",
    UserDialog = "UserDialog"
}

export class StorageKeyBuilder {

    public static BuildItemKey(module: AppModule, localKey: string): string {
        let globalKeyName: string = `${module}/item_${localKey}`;
        return globalKeyName;
    }

    public static BuildLocalKey(...keys: string[]): string {
        const localKey: string = keys.join('/');
        return localKey;
    }

    public static BuildGridColumnWidthKey(module: AppModule, gridName: string, columnName: string): string {
        let globalKeyName: string = `${module}/grid_${gridName}/columnWidth_${columnName}`;
        return globalKeyName;
    }

    public static BuildGridColumnsOrderIndexesKey(module: AppModule, gridName: string): string {
        let globalKeyName: string = `${module}/grid_${gridName}/columnsOrderIndexes`;
        return globalKeyName;
    }
}

export class LocalStorage {

    private static _gridColumnsOrderIndexesCache: Map<string, Map<string, number>> = new Map<string, Map<string, number>>();

    public static GetItem(module: AppModule, localKey: string): string {
        let keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        return localStorage.getItem(keyName);
    }

    public static SetItem(module: AppModule, localKey: string, value: string): void {
        let keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        localStorage.setItem(keyName, value);
    }

    public static RemoveItem(module: AppModule, localKey: string): void {
        let keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        localStorage.removeItem(keyName);
    }

    public static GetNumber(module: AppModule, localKey: string, defaultValue: number = null): number {
        let keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        const value = localStorage.getItem(keyName);
        return value ? Number(value) : defaultValue;
    }

    public static SetNumber(module: AppModule, localKey: string, value: number): void {
        let keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        localStorage.setItem(keyName, value?.toString() || null);
    }

    public static GetBool(module: AppModule, localKey: string, defaultValue: boolean = null): boolean {
        let keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        const value = localStorage.getItem(keyName);
        return value ? value === 'true' : defaultValue;
    }

    public static SetBool(module: AppModule, localKey: string, value: boolean): void {
        let keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        localStorage.setItem(keyName, value?.toString());
    }

    public static GetGridColumnWidth(module: AppModule, gridName: string, fieldName: string, defaultWidth: number): number {
        let key: string = StorageKeyBuilder.BuildGridColumnWidthKey(module, gridName, fieldName);
        var value = localStorage.getItem(key);

        if (value != null) {
            try {
                return Number(value);
            }
            catch {
            }
        }

        return defaultWidth;
    }

    public static SetGridColumnWidth(module: AppModule, gridName: string, fieldName: string, width: number): void {
        let key: string = StorageKeyBuilder.BuildGridColumnWidthKey(module, gridName, fieldName);
        localStorage.setItem(key, width.toString());
    }

    public static GetGridColumnOrderIndex(module: AppModule, gridName: string, fieldName: string, defaultIndex: number): number {

        let key: string = StorageKeyBuilder.BuildGridColumnsOrderIndexesKey(module, gridName);

        let indexesMap: Map<string, number> = LocalStorage._gridColumnsOrderIndexesCache[key];

        //already cached 
        if (indexesMap !== null && indexesMap !== undefined && indexesMap.size > 0) {

            let fieldIndex: number = indexesMap[fieldName];
            if (fieldIndex !== null && fieldIndex !== undefined)
                return fieldIndex;

            return defaultIndex;
        }

        //get from storage and cache
        let json = localStorage.getItem(key);
        if (json != null) {
            try {
                indexesMap = JSON.parse(json);
                LocalStorage._gridColumnsOrderIndexesCache[key] = indexesMap;

                let fieldIndex: number = indexesMap[fieldName];
                if (fieldIndex !== null && fieldIndex !== undefined)
                    return fieldIndex;
            }
            catch {
            }
        }

        return defaultIndex;
    }

    public static SetGridColumnsOrderIndexes(module: AppModule, gridName: string, columns: GridColumnProps[]) {

        let key: string = StorageKeyBuilder.BuildGridColumnsOrderIndexesKey(module, gridName);

        let indexesMap: Map<string, number> = new Map<string, number>();
        columns.forEach((column) => indexesMap[column.field] = column.orderIndex);

        LocalStorage._gridColumnsOrderIndexesCache[key] = indexesMap;
        localStorage.setItem(key, JSON.stringify(indexesMap));
    }
}