import ReplyIcon from '@mui/icons-material/Reply';
import { Box, Button, Checkbox, Dialog, DialogContent, FormControlLabel, TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, { MutableRefObject, useRef, useState } from 'react';
import coneHeaderIcon from 'src/assets/icons/icon-cone-header.svg';
import Translate, { Localization } from '../../../localization/Localization';
import ToastService from '../../../ToastService';
import BusinessMessages from '../../../utils/BusinessMessages';
import { SendRoadWorksAreaMessageRequestArgs } from '../../RoadsConditionAndScenarios/services/MaintenanceScenarios/dataContracts/controller/SendRoadWorksAreaMessageRequestArgs';
import { MaintenanceScenariosApiClient } from '../../RoadsConditionAndScenarios/services/MaintenanceScenarios/MaintenanceScenariosApiClient';
import { AreaShapeProperties } from '../models/AreaShapeProperties';
import './SendRoadWorksAreaMessageDialogStyles.scss';

interface SendRoadWorksAreaMailDialogProps {
    isSendRoadWorksAreaMessageDialogOpened: boolean,
    area: AreaShapeProperties,
    handleSendRoadWorksAreaMessageDialogClosed: () => void
}

export const SendRoadWorksAreaMessageDialog = (props: SendRoadWorksAreaMailDialogProps): JSX.Element => {
    const area = props.area;

    const [shouldSendCopyToSender, setShouldSendCopyToSender] = useState<boolean>(false);

    let inputTextMessageRef: MutableRefObject<HTMLInputElement> = useRef(null);

    const handleSendRoadWorksAreaMessageClick = debounce(() => {
        let requestArgs: SendRoadWorksAreaMessageRequestArgs = {
            recipientUserId: area.ownerUserId,
            message: inputTextMessageRef.current.value,
            areaLabel: area.label,
            scenarioLabel: area.scenarioLabel,
            shouldSendCopyToSender: shouldSendCopyToSender,
            language: Localization.locale
        };

        MaintenanceScenariosApiClient.SendRoadWorksAreaMessage(requestArgs)
            .then((res) => {
                let data = res?.data;

                let warnings = BusinessMessages.GetWarnings(data);
                if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                }

                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                ToastService.showSuccessToast("", Translate.Resources.UI_SendRoadWorksAreaMessageDialog_SendingSuccessMessage);
                props.handleSendRoadWorksAreaMessageDialogClosed();
            });
    }, 500);

    return (
        <Dialog id="send-road-works-area-message-dialog" open={props.isSendRoadWorksAreaMessageDialogOpened} onClose={props.handleSendRoadWorksAreaMessageDialogClosed}>
            <DialogContent>
                <Box display="flex" flexDirection="column" className="content">
                    <Box display="flex" flexDirection="row" alignItems="center" className="dialog-title">
                        <img src={coneHeaderIcon} className="cone-header-icon" alt="cone header icon" />
                        <div className="label">{area.label}</div>
                    </Box>
                    <Box display="flex" flexDirection="column" className="receiver">
                        <div className="title">{Translate.Resources.UI_SendRoadWorksAreaMessageDialog_Receiver}</div>
                        <div className="value">{area.ownerUserFullName}</div>
                    </Box>
                    <Box>
                        <FormControlLabel
                            className="checkbox-label"
                            control={
                                <Checkbox
                                    checked={shouldSendCopyToSender}
                                    onChange={() => setShouldSendCopyToSender(!shouldSendCopyToSender)}
                                    color="primary"
                                />
                            }
                            label={Translate.Resources.UI_SendRoadWorksAreaMessageDialog_Checkbox_ReceiveCopy}
                        />
                    </Box>
                    <Box>
                        <TextField
                            label={Translate.Resources.UI_SendRoadWorksAreaMessageDialog_Message}
                            margin="normal"
                            multiline
                            variant="outlined"
                            rows="7"
                            className="input-text-multiline"
                            inputRef={inputTextMessageRef}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button className="btn-close" onClick={props.handleSendRoadWorksAreaMessageDialogClosed}>{Translate.Resources.UI_SendRoadWorksAreaMessageDialog_Btn_Close}</Button>
                        <Button className="btn-send" onClick={() => handleSendRoadWorksAreaMessageClick()}>
                            <ReplyIcon />
                            {Translate.Resources.UI_SendRoadWorksAreaMessageDialog_Btn_Send}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
