import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import { RoadLabelScoreExtended } from '../models/RoadLabelScoreExtended';

interface CustomScoreColorIndicatorCellProperties extends GridCellProps {
    poorScoreThreshold: number;
    goodScoreThreshold: number;
}

export const CustomScoreColorIndicatorCell = (props: CustomScoreColorIndicatorCellProperties) => {

    let dataItem = props.dataItem as RoadLabelScoreExtended;
    let averageScore = dataItem?.averageScore;

    let colorIndicator = averageScore === null || averageScore === undefined ? "cell-color-undetermined" : (averageScore < props.goodScoreThreshold ? "cell-color-good" :
        (averageScore > props.poorScoreThreshold ? "cell-color-bad" :
            (averageScore <= props.poorScoreThreshold && averageScore >= props.goodScoreThreshold ? "cell-color-to-monitor" : "")));

    return (
        <td className={colorIndicator}></td>
    );
}