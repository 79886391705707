import { faRoute, faSearch, faThumbsUp, faTimes, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Checkbox, FormControlLabel, IconButton, Input, InputAdornment, MenuItem, Select, Slider } from '@mui/material';
import { orderBy as sortOrderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridHeaderCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { orderBy } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import iconArrow from 'src/assets/icons/icon-arrow.svg';
import iconDrawerClosed from 'src/assets/icons/icon-drawer-closed.svg';
import iconDrawerOpened from 'src/assets/icons/icon-drawer-opened.svg';
import excelIcon from 'src/assets/icons/icon-excel.svg';
import jsonIcon from 'src/assets/icons/icon-json.svg';
import kmlIcon from 'src/assets/icons/icon-kml.svg';
import iconRoadsHeaderDrawerClosed from 'src/assets/icons/icon-roads-header-drawer-closed.svg';
import iconRoadsHeader from 'src/assets/icons/icon-roads-header.svg';
import shapefileIcon from 'src/assets/icons/icon-shapefile.svg';
import iconStatementDrawerClosed from 'src/assets/icons/icon-statement-drawer-closed.svg';
import { makeStyles } from "tss-react/mui";
import Translate, { Localization } from '../../../localization/Localization';
import { downloadFileFromBlob, getFileName } from '../../../utils/DownloadFile';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import '../../../utils/Number';
import styles from '../../../_variables.scss';
import { ProjectThumbnailModel } from '../../Home/models/ProjectThumbnailModel';
import { Auscultation } from '../../Home/services/dataContracts/queryStack/Auscultation';
import { Export } from '../models/Export';
import { ProjectVersionExtended } from '../models/ProjectVersionExtended';
import { RoadLabelScoreExtended } from '../models/RoadLabelScoreExtended';
import { RoadsConditionSummaryModel } from '../models/RoadsConditionSummaryModel';
import { RoadScoreRange } from '../models/RoadScoreRange';
import { RoadsConditionApiClient } from '../services/RoadsCondition/RoadsConditionApiClient';
import { SizingUtilities } from '../SizingUtilities';
import './AuscultationsSelectorWithRoadsDrawerStyles.scss';
import { CustomCheckboxCell } from './CustomCheckboxCell';
import { CustomCheckboxHeaderCell } from './CustomCheckboxHeaderCell';
import { CustomConeCell } from './CustomConeCell';
import { CustomConeHeaderCell } from './CustomConeHeaderCell';
import { CustomScoreColorIndicatorCell } from './CustomScoreColorIndicatorCell';

interface AuscultationsSelectorWithRoadsDrawerComponentProps {
    isScenarioView: boolean,
    inputSearchRoadsRef: React.RefObject<HTMLInputElement>,
    selectedSections: Map<number, number>,
    selectedProject: ProjectThumbnailModel,
    roadLabelsScores: Map<string, RoadLabelScoreExtended>,
    goodScoreThreshold: number,
    poorScoreThreshold: number,
    selectedRoadsSummary: RoadsConditionSummaryModel,
    projectVersion: ProjectVersionExtended,
    roadScoreRange: RoadScoreRange,
    averageScoreSliderValue: number[],
    auscultationsIdsString: string,
    openedDrawer: boolean,
    role: string,
    isAlgorithmsModelVersionInfoDialogOpen: boolean,
    handleDrawerClosed: () => void,
    handleDrawerOpened: () => void,
    handleSelectAuscultation: (auscultationId: number, isCompleteHeritageChecked: boolean) => void,
    handleRowClick: (item: RoadLabelScoreExtended, checked: boolean) => void,
    handleChangeRoadsSearchText: (value: string) => void,
    handleClearRoadsSearchText: () => void,
    onAverageScoreChanged: (event: Event, value: number | number[], activeThumb: number) => void,
    onAverageScoreChangeCommited: (event: React.SyntheticEvent | Event, value: number | number[]) => void,
    handleHeaderCheckboxChanged: (checked: boolean) => void
    handleToggleAlgorithmsModelVersionInfoDialog: () => void
}

//TODO HGA CMA voir si c'est encore nécessaire après mise à jour de package
function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

export const AuscultationsSelectorWithRoadsDrawerComponent = (props: AuscultationsSelectorWithRoadsDrawerComponentProps): JSX.Element => {
    const {
        averageScoreSliderValue,
        auscultationsIdsString,
        goodScoreThreshold,
        inputSearchRoadsRef,
        isAlgorithmsModelVersionInfoDialogOpen,
        isScenarioView,
        openedDrawer,
        poorScoreThreshold,
        roadLabelsScores,
        roadScoreRange,
        selectedSections,
        selectedProject,
        selectedRoadsSummary,
        role,
        handleDrawerClosed,
        handleDrawerOpened,
        handleClearRoadsSearchText,
        handleChangeRoadsSearchText,
        handleSelectAuscultation,
        handleRowClick,
        onAverageScoreChanged,
        onAverageScoreChangeCommited,
        handleHeaderCheckboxChanged,
        handleToggleAlgorithmsModelVersionInfoDialog
    } = props;

    const selectedSectionsIds = Array.from(selectedSections.keys());
    const forceUpdate = useForceUpdate();

    const measurementSystemType = MeasurementSystem.getCurrentType();

    let initialSort: SortDescriptor[] = [{ field: 'hiddenFixedAverageScoreForSort', dir: 'desc' }, { field: 'fixedAverageScore', dir: 'desc' }];

    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [skip, setSkip] = useState<number>(0);
    const [exportType, setExportType] = useState<{ type: Export | string, icon: string, }>({ type: "", icon: null });
    const [exportOnlySelection, setExportOnlySelection] = useState<boolean>(false);
    const [auscultations, setAuscultations] = useState<{
        fromDate: number | string,
        isFromDateDisabled: boolean,
        toDate: number | string,
        isToDateDisabled: boolean,
        list: { label: string, value: number }[],
        hasOnlyAuscultation: boolean,
        isCompleteHeritageSelected: boolean
    }>({
        fromDate: "",
        isFromDateDisabled: false,
        toDate: "",
        isToDateDisabled: false,
        list: [],
        hasOnlyAuscultation: false,
        isCompleteHeritageSelected: false
    });

    const handleSortColumnChange = (sortItems: SortDescriptor[]): void => {
        setSort(sortItems);
    }

    useEffect(() => {
        let projectVersion = props.projectVersion;
        if (projectVersion) {
            let statements = {
                fromDate: 0,
                isFromDateDisabled: false,
                toDate: 0,
                isToDateDisabled: false,
                list: [],
                hasOnlyAuscultation: false,
                isCompleteHeritageSelected: false
            };

            let statementsDates: { label: string, value: number }[] = [];
            if (projectVersion.auscultations.length > 1) {
                let projectAuscultationsArray = orderBy(projectVersion.auscultations, ['videoDateTime', 'processingDateTime', 'auscultationNumber'], ['asc', 'asc', 'asc']);
                projectAuscultationsArray.forEach((auscultation: Auscultation, index: number) => {
                    let value = auscultation.auscultationId;
                    let label = auscultation.videoDateTime.toLocaleDateString();
                    let item = { label: label, value: value };
                    statementsDates.push(item);

                    if (index === 0) {
                        statements.fromDate = item.value;
                    }

                    if (index === projectAuscultationsArray.length - 1) {
                        statements.toDate = item.value;
                    }

                    statements.isCompleteHeritageSelected = true;
                    statements.isFromDateDisabled = true;
                });
            }
            else {
                let auscultation = projectVersion.auscultations[0];
                let item = { label: auscultation.videoDateTime.toLocaleDateString(), value: auscultation.auscultationId };
                statementsDates.push(item);
                statements.hasOnlyAuscultation = true;
                statements.fromDate = item.value;
            }

            statements.list = statementsDates;
            setAuscultations(statements);
        }
    }, [props.projectVersion])

    useEffect(() => {
        if (selectedSectionsIds.length === 0) {
            setExportOnlySelection(false);
        }
    }, [selectedSectionsIds])

    const handleIsCompleteHeritageSelected = (e: ChangeEvent<HTMLInputElement>): void => {
        var checked = e.target.checked;
        setAuscultations({
            ...auscultations,
            isCompleteHeritageSelected: checked,
            isFromDateDisabled: checked ? true : false,
            isToDateDisabled: checked ? false : true,
            fromDate: !checked ? auscultations.toDate : auscultations.list[0].value
        });
        handleSelectAuscultation(auscultations.toDate as number, checked);
    }

    const handleFromDateChanged = (value: number): void => {
        setAuscultations({ ...auscultations, fromDate: value, toDate: value });
        handleSelectAuscultation(value, false);
    }

    const handleToDateChanged = (value: number): void => {
        setAuscultations({ ...auscultations, toDate: value });
        handleSelectAuscultation(value, true);
    }

    const handleExportOnlySelectionChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setExportOnlySelection(e.target.checked);
    }

    const handleExportTypeChanged = (e: ChangeEvent<{ name: string, value: Export }>): void => {
        let value = e.target.value;
        switch (value) {
            case Export.xls:
            case Export.excel:
                setExportType({ type: value, icon: excelIcon });
                break;
            case Export.geojson:
                setExportType({ type: value, icon: jsonIcon });
                break;
            case Export.kml:
                setExportType({ type: value, icon: kmlIcon });
                break;
            case Export.shapefile:
                setExportType({ type: value, icon: shapefileIcon });
                break;
            default:
                setExportType({ type: "", icon: null });
                break;
        }
    }

    const handleExport = (): void => {
        switch (exportType.type) {
            case Export.xls:
                handleExportExcelSynthesis();
                break;
            case Export.excel:
                handleExportExcel();
                break;
            case Export.geojson:
                handleExportGeoJson();
                break;
            case Export.kml:
                handleExportKml();
                break;
            case Export.shapefile:
                handleExportShapefile();
                break;
            default:
                return;
        }
    }

    const handleExportExcelSynthesis = (): void => {
        if (exportOnlySelection)
            exportExcelSynthesisSections();
        else
            exportExcelSynthesis();
    }

    const exportExcelSynthesis = (): void => {
        RoadsConditionApiClient.ExportSynthesisExcel(selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, goodScoreThreshold, poorScoreThreshold, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const exportExcelSynthesisSections = (): void => {
        RoadsConditionApiClient.ExportSynthesisExcelOfSections(selectedSectionsIds, selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, goodScoreThreshold, poorScoreThreshold, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const handleExportExcel = (): void => {
        if (exportOnlySelection)
            exportExcelSections();
        else
            exportExcel();
    }

    const exportExcel = (): void => {
        RoadsConditionApiClient.ExportExcel(selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, goodScoreThreshold, poorScoreThreshold, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const exportExcelSections = (): void => {
        RoadsConditionApiClient.ExportExcelOfSections(selectedSectionsIds, selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, goodScoreThreshold, poorScoreThreshold, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const handleExportShapefile = (): void => {
        if (exportOnlySelection)
            exportShapefileSections();
        else
            exportShapefile();
    }

    const exportShapefile = (): void => {
        RoadsConditionApiClient.ExportShapefile(selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, goodScoreThreshold, poorScoreThreshold, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const exportShapefileSections = (): void => {
        RoadsConditionApiClient.ExportShapefileOfSections(selectedSectionsIds, selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, goodScoreThreshold, poorScoreThreshold, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const handleExportGeoJson = (): void => {
        if (exportOnlySelection)
            exportGeoJsonSections();
        else
            exportGeoJson();
    }

    const exportGeoJson = (): void => {
        RoadsConditionApiClient.ExportGeoJson(selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, goodScoreThreshold, poorScoreThreshold, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const exportGeoJsonSections = (): void => {
        RoadsConditionApiClient.ExportGeoJsonOfSections(selectedSectionsIds, selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, goodScoreThreshold, poorScoreThreshold, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const handleExportKml = (): void => {
        if (exportOnlySelection)
            exportKmlSections();
        else
            exportKml();
    }

    const exportKml = (): void => {
        RoadsConditionApiClient.ExportKml(selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, goodScoreThreshold, poorScoreThreshold, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const exportKmlSections = (): void => {
        RoadsConditionApiClient.ExportKmlOfSections(selectedSectionsIds, selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, goodScoreThreshold, poorScoreThreshold, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        let sort = e.sort[0];
        if (sort && sort.field === "label") {
            setSort([{ field: 'hiddenLabelForSort', dir: sort.dir }, sort]);
            return;
        }

        if (sort && sort.field === "fixedAverageScore") {
            setSort([{ field: 'hiddenFixedAverageScoreForSort', dir: sort.dir }, sort]);
            return;
        }

        if (sort && sort.field === "isSelected") {
            setSort([{ field: 'hiddenIsSelectedForSort', dir: sort.dir }, sort]);
        }

        setSort(e.sort);
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const getValueLabelFormat = (value: number): string => {
        return (value / 10).toFixedLocalized(2);
    }

    var gridHeightGapFromWindow: number = SizingUtilities.labelScoreGridHeightGapFromWindow(isScenarioView, props.role);
    var gridHeight: number = SizingUtilities.computeGridHeight(gridHeightGapFromWindow);
    var rowHeight: number = SizingUtilities.rowHeight;
    var gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    var gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridHeightGapFromWindow;
        gridPageSize = Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
        forceUpdate();
    }
    window.onresize = resize;

    let filteredLabelsScores: RoadLabelScoreExtended[] = [];
    roadLabelsScores.forEach((element) => {
        if (element.isVisible === true) {
            filteredLabelsScores.push(element);
        }
    });

    let dataGrid = sortOrderBy(filteredLabelsScores, sort).slice(skip, skip + gridPageSize);

    const useStyles = makeStyles()(() => ({
        rail: {
            background: `linear-gradient(to right, ${styles.goodColor} 0% ${goodScoreThreshold * 100 / roadScoreRange.max - roadScoreRange.min}%, ${styles.toMonitorColor} ${goodScoreThreshold * 100 / roadScoreRange.max - roadScoreRange.min}% ${poorScoreThreshold * 100 / roadScoreRange.max - roadScoreRange.min}%, ${styles.badColor} ${poorScoreThreshold * 100 / roadScoreRange.max - roadScoreRange.min}% 100%)`
        }
    }));
    const { classes } = useStyles();

    const symbolOfKilometersOrMiles = MeasurementSystem.getSymbolOfKilometersOrMiles();
    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();
    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();

    let totalSectionsLength = selectedRoadsSummary ? `${MeasurementSystem.getLenghtInKilometersOrMiles(selectedRoadsSummary.totalSectionsLengthInMeters).toFixedLocalized(3)} ${symbolOfKilometersOrMiles}` : '';

    let poorConditionSectionsLength = selectedRoadsSummary ? `${MeasurementSystem.getLenghtInKilometersOrMiles(selectedRoadsSummary.poorConditionSectionsLengthInMeters).toFixedLocalized(3)} ${symbolOfKilometersOrMiles}` : '';
    let toMonitorConditionSectionsLength = selectedRoadsSummary ? `${MeasurementSystem.getLenghtInKilometersOrMiles(selectedRoadsSummary.toMonitorConditionSectionsLengthInMeters).toFixedLocalized(3)} ${symbolOfKilometersOrMiles}` : '';
    let goodConditionSectionsLength = selectedRoadsSummary ? `${MeasurementSystem.getLenghtInKilometersOrMiles(selectedRoadsSummary.goodConditionSectionsLengthInMeters).toFixedLocalized(3)} ${symbolOfKilometersOrMiles}` : '';

    let poorConditionSectionsSurface = selectedRoadsSummary ? `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(selectedRoadsSummary.poorConditionSectionsSurface).toFixedLocalized(0)} ${symbolOfSurfaceUnit}` : '';
    let toMonitorConditionSectionsSurface = selectedRoadsSummary ? `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(selectedRoadsSummary.toMonitorConditionSectionsSurface).toFixedLocalized(0)} ${symbolOfSurfaceUnit}` : '';
    let goodConditionSectionsSurface = selectedRoadsSummary ? `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(selectedRoadsSummary.goodConditionSectionsSurface).toFixedLocalized(0)} ${symbolOfSurfaceUnit}` : '';

    return (
        <Box className={`auscultations-selector-with-roads-drawer ${openedDrawer ? 'opened' : ''}`}>
            {!openedDrawer &&
                <Box className="closed-drawer-content" display="flex" flexDirection="column" alignItems="center" onClick={() => handleDrawerOpened()}>
                    <img className="open-drawer-icon" src={iconDrawerClosed} alt="icon drawer closed" />
                    <Box className="closed-drawer-label">
                        <img src={isScenarioView ? iconRoadsHeaderDrawerClosed : iconStatementDrawerClosed} alt="icon closed" />
                        {isScenarioView ? Translate.Resources.UI_MaintenanceSenarios_RoadsDrawer_Title_Roads : ` ${selectedProject?.label} - ${Translate.Resources.UI_NavMenu_Statements}`}
                    </Box>
                </Box>
            }
            {openedDrawer &&
                <Box display="flex" flexDirection="column" className="opened-auscultations-selector-with-roads-drawer-content">
                    <Box className="header" display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" width="85%" alignItems="center">
                            <img src={isScenarioView ? iconRoadsHeader : iconArrow} alt="icon header" />
                            <div className="project-label">
                                {isScenarioView ? Translate.Resources.UI_MaintenanceSenarios_RoadsDrawer_Title_Roads : selectedProject?.label}
                            </div>
                            <IconButton
                                className={role === "ADM" ? "" : "project-infobutton--hidden"}
                                onClick={handleToggleAlgorithmsModelVersionInfoDialog}
                            >
                                <InfoOutlinedIcon
                                    className={isAlgorithmsModelVersionInfoDialogOpen ? "project-infobutton--active" : "project-infobutton--inactive"}
                                />
                            </IconButton>
                        </Box>
                        <IconButton onClick={() => handleDrawerClosed()}>
                            <img src={iconDrawerOpened} alt="icon drawer opened" />
                        </IconButton>
                    </Box>
                    <Box className="statement-dates" display="flex" flexDirection="row" alignItems="center">
                        <Box display="flex" flexDirection="row" alignItems="center" className="from-date">
                            <Box className="select-title">{Translate.Resources.UI_Home_AuscultationSummaryDrawer_From}</Box>
                            <Box>
                                <Select
                                    className={isScenarioView || auscultations.isFromDateDisabled ? "select disabled" : "select"}
                                    value={auscultations.fromDate}
                                    disabled={isScenarioView || auscultations.isFromDateDisabled}
                                    onChange={(e) => handleFromDateChanged(e.target.value as number)}
                                >
                                    {auscultations.list.map((item, index: number) => {
                                        return (<MenuItem key={`fromDate-${index}`} value={item.value}>{item.label}</MenuItem>)
                                    })}
                                </Select>
                            </Box>
                        </Box>
                        {!auscultations.hasOnlyAuscultation &&
                            <>
                                <Box display="flex" flexDirection="row" alignItems="center" className="to-date">
                                    <Box className="select-title">{Translate.Resources.UI_Home_AuscultationSummaryDrawer_To}</Box>
                                    <Box>
                                        <Select
                                            className={isScenarioView || auscultations.isToDateDisabled ? "select disabled" : "select"}
                                            value={auscultations.toDate}
                                            disabled={isScenarioView || auscultations.isToDateDisabled}
                                            onChange={(e) => handleToDateChanged(e.target.value as number)}
                                        >
                                            {auscultations.list.map((item, index: number) => {
                                                return (<MenuItem key={`toDate-${index}`} value={item.value}>{item.label}</MenuItem>)
                                            })}
                                        </Select>
                                    </Box>
                                </Box>
                                <Checkbox className={`checkbox ${isScenarioView ? 'disabled' : ''}`}
                                    checked={auscultations.isCompleteHeritageSelected}
                                    onChange={(e) => handleIsCompleteHeritageSelected(e)}
                                    title={Translate.Resources.UI_RoadCondition_Checkbox_Tooltip_CumulativeDisplayOfSurvey}
                                    disabled={isScenarioView}
                                />
                            </>
                        }
                    </Box>
                    <hr />
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="roads-search">
                        <Box className="search-input">
                            <Input disableUnderline className="input-search-roads" inputRef={inputSearchRoadsRef}
                                endAdornment={<>
                                    <InputAdornment position="end" classes={{ root: 'input-icon-close-root' }}>
                                        <FontAwesomeIcon icon={faTimes} onClick={() => handleClearRoadsSearchText()} />
                                    </InputAdornment>
                                    <InputAdornment position="end" classes={{ root: 'input-icon-search-root' }}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputAdornment>
                                </>}
                                id="input-search-roads-text"
                                placeholder={Translate.Resources.UI_Home_AuscultationSummaryDrawer_Search_Placeholder}
                                onChange={(event) => handleChangeRoadsSearchText(event.target.value)}
                            />
                        </Box>
                        <Box className="score-slider">
                            <Slider
                                className="average-score-slider"
                                classes={{
                                    rail: classes.rail,
                                }}
                                valueLabelDisplay="on"
                                valueLabelFormat={getValueLabelFormat}
                                value={averageScoreSliderValue}
                                min={roadScoreRange.min * 10}
                                max={roadScoreRange.max * 10}
                                step={1}
                                onChange={onAverageScoreChanged}
                                onChangeCommitted={onAverageScoreChangeCommited}
                            />
                        </Box>
                    </Box>
                    <Box className="road-grid">
                        <Box display="flex" flexDirection="row" justifyContent="center" className="auscultation-summary-grid-content">
                            <LocalizationProvider language={Localization.locale}>
                                <IntlProvider locale={Localization.locale}>
                                    <Grid
                                        className={`auscultation-summary-grid ${isScenarioView ? ' scenario-view' : ''}`}
                                        data={dataGrid}
                                        selectedField="isSelected"
                                        sortable
                                        sort={sort}
                                        scrollable="virtual"
                                        skip={skip}
                                        total={filteredLabelsScores.length}
                                        rowHeight={rowHeight}
                                        pageSize={gridPageSize}
                                        style={gridStyle}
                                        onPageChange={pageChange}
                                        onSortChange={handleSortChange}
                                        onRowClick={(e) => handleRowClick(e.dataItem, e.dataItem.isSelected === true ? false : true)}
                                    >
                                        <GridColumn sortable={false}
                                            cell={(gridProps: GridCellProps) => <CustomScoreColorIndicatorCell {...gridProps} poorScoreThreshold={props.poorScoreThreshold} goodScoreThreshold={props.goodScoreThreshold} />}
                                        />
                                        <GridColumn field="isSelected"
                                            cell={(props: GridCellProps) => <CustomCheckboxCell {...props} handleRowClick={handleRowClick} />}
                                            headerCell={(props: GridHeaderCellProps) => <CustomCheckboxHeaderCell {...props} sortingField={"hiddenIsSelectedForSort"} roadLabelsScores={filteredLabelsScores} handleHeaderCheckboxChanged={handleHeaderCheckboxChanged} handleSortColumnChange={handleSortColumnChange} sort={sort} />}
                                        />
                                        {isScenarioView && <GridColumn field="relatedAreas"
                                            cell={(props: GridCellProps) => <CustomConeCell {...props} />}
                                            headerCell={(props: GridHeaderCellProps) => <CustomConeHeaderCell {...props} sortingField={"hiddenIsIncludedInAreaForSort"} handleSortColumnChange={handleSortColumnChange} sort={sort} />}
                                        />}
                                        <GridColumn field="label" title={Translate.Resources.UI_Home_AuscultationSummaryDrawer_RedRoadsScoreGrid_Columns_RoadLabel} />
                                        <GridColumn field="fixedAverageScore" title={Translate.Resources.UI_Home_AuscultationSummaryDrawer_RedRoadsScoreGrid_Columns_AverageRoadScore} className="column-number" />
                                        <GridColumn field="lengthInMeters" title={`${Translate.Resources.UI_Home_AuscultationSummaryDrawer_RedRoadsScoreGrid_Columns_Linear} (${symbolOfLengthUnit})`}
                                            cell={(props) => {
                                                let value = MeasurementSystem.getLengthInMetersOrYards(props.dataItem.lengthInMeters);
                                                return (
                                                    <td className="column-number">{Math.round(value)}</td>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </IntlProvider>
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <hr />
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="total-covered">
                        <FontAwesomeIcon icon={faRoute} />
                        <Box ml="10px">{totalSectionsLength} {Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_CoveredRoad}</Box>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" className="selection-condition">
                        <Box display="flex" flexDirection="column" alignItems="center" className="item">
                            <Box className="status-label bad">{Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_BadStatus}</Box>
                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="condition bad">
                                <FontAwesomeIcon className="icon" icon={faWrench} />
                                <Box>{selectedRoadsSummary ? `${selectedRoadsSummary.poorConditionSectionsPercent.toFixedLocalized(2)} %` : ''}</Box>
                            </Box>
                            <Box className="length">{`${poorConditionSectionsLength} ≈ ${poorConditionSectionsSurface}`}</Box>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" className="item">
                            <Box className="status-label to-monitor">{Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_ToMonitor}</Box>
                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="condition to-monitor">
                                <FontAwesomeIcon className="icon" icon={faSearch} />
                                <Box>{selectedRoadsSummary ? `${selectedRoadsSummary.toMonitorConditionSectionsPercent.toFixedLocalized(2)} %` : ''}</Box>
                            </Box>
                            <Box className="length">{`${toMonitorConditionSectionsLength} ≈ ${toMonitorConditionSectionsSurface}`}</Box>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="center" className="item">
                            <Box className="status-label good">{Translate.Resources.UI_Home_DetailsDrawer_RoadCondition_GoodStatus}</Box>
                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="condition good">
                                <FontAwesomeIcon className="icon" icon={faThumbsUp} />
                                <Box>{selectedRoadsSummary ? `${selectedRoadsSummary.goodConditionSectionsPercent.toFixedLocalized(2)} %` : ''}</Box>
                            </Box>
                            <Box className="length">{`${goodConditionSectionsLength} ≈ ${goodConditionSectionsSurface}`}</Box>
                        </Box>
                    </Box>
                    {!isScenarioView && props.role !== 'CLI' &&
                        <>
                            <hr />
                            <Box display="flex" flexDirection="row" alignItems="center" className="export">
                                <FormControlLabel
                                    className={selectedSectionsIds.length > 0 ? "checkbox" : "checkbox disabled"}
                                    control={<Checkbox checked={exportOnlySelection} disabled={selectedSectionsIds.length === 0} onChange={(e) => handleExportOnlySelectionChange(e)} />}
                                    label={Translate.Resources.UI_Home_AuscultationSummaryDrawer_Checkbox_ExportOnlySelection}
                                />
                                <Select
                                    className="select"
                                    value={exportType.type}
                                    onChange={(e) => handleExportTypeChanged(e as ChangeEvent<{ name: string, value: Export }>)}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={Export.xls}>{Translate.Resources.UI_RoadsCondition_AuscultationsSelectorWithRoadsDrawerComponent_Export_XLS_Overview}</MenuItem>
                                    <MenuItem value={Export.excel}>EXCEL</MenuItem>
                                    <MenuItem value={Export.geojson}>GEOJSON</MenuItem>
                                    <MenuItem value={Export.shapefile}>SHAPEFILE</MenuItem>
                                    <MenuItem value={Export.kml}>KML</MenuItem>
                                </Select>
                                <Button className={exportType.type !== "" ? "btn-export" : "btn-export disabled"} disabled={exportType.type === ""} onClick={handleExport}>
                                    {exportType.icon && <img src={exportType.icon} alt="export type icon" />}
                                    {Translate.Resources.UI_Home_AuscultationSummaryDrawer_Checkbox_Export}
                                </Button>
                            </Box>
                        </>
                    }
                </Box>
            }
        </Box>
    );
}
