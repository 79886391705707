import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, Input, InputAdornment, MenuItem, Select, Tooltip } from '@mui/material';
import { orderBy as sortOrderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridRowClickEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { ChangeEvent, useEffect, useState } from 'react';
import iconAdd from 'src/assets/icons/icon-add.svg';
import iconDrawerClosed from 'src/assets/icons/icon-drawer-closed.svg';
import iconDrawerOpened from 'src/assets/icons/icon-drawer-opened.svg';
import jsonIcon from 'src/assets/icons/icon-json.svg';
import kmlIcon from 'src/assets/icons/icon-kml.svg';
import iconSenariosHeaderDrawerClosed from 'src/assets/icons/icon-scenarios-header-drawer-closed.svg';
import iconSenariosHeader from 'src/assets/icons/icon-scenarios-header.svg';
import shapefileIcon from 'src/assets/icons/icon-shapefile.svg';
import Translate, { Localization } from '../../../localization/Localization';
import { downloadFileFromBlob, getFileName } from '../../../utils/DownloadFile';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { Export } from '../models/Export';
import { MaintenanceScenarioExtended } from '../models/MaintenanceScenarioExtended';
import { MaintenanceAreaStatus } from '../services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceAreaStatus';
import { RecipientForScenarioSending } from '../services/MaintenanceScenarios/dataContracts/queryStack/RecipientForScenarioSending';
import { MaintenanceScenariosApiClient } from '../services/MaintenanceScenarios/MaintenanceScenariosApiClient';
import { SizingUtilities } from '../SizingUtilities';
import { AddOrUpdateScenarioDialog, Mode } from './AddOrUpdateScenarioDialog';
import { CustomScenarioActionsCell } from './CustomScenarioActionsCell';
import './ScenariosSelectorStyles.scss';

interface ScenariosSelectorComponentProps {
    openedDrawer: boolean,
    inputSearchScenariosRef: React.RefObject<HTMLInputElement>,
    isAddScenarioDialogOpened: boolean,
    projectScenarios: MaintenanceScenarioExtended[],
    projectId: string,
    userFullName: string,
    selectedScenario: MaintenanceScenarioExtended,
    projectLabel: string,
    handleDrawerOpened: () => void,
    handleDrawerClosed: () => void,
    handleChangeScenariosSearchText: (value: string) => void,
    handleClearScenariosSearchText: () => void,
    handleAddScenario: (scenarioLabel: string) => Promise<void>,
    handleAddScenarioDialogOpened: () => void,
    handleAddScenarioDialogClosed: () => void,
    handleProjectScenarioClicked: (event: GridRowClickEvent) => void,
    handleUpdateScenarioLabel: (maintenanceScenarioId: number, scenarioLabel: string) => Promise<boolean>,
    handleDeleteScenario: (maintenanceScenarioId: number) => void,
    handleDuplicateScenario: (maintenanceScenarioId: number, duplicateScenarioLabel: string) => Promise<boolean>
}

//TODO HGA CMA voir si c'est encore nécessaire après mise à jour de package
function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

export const ScenariosSelectorComponent = (props: ScenariosSelectorComponentProps): JSX.Element => {
    const { openedDrawer, inputSearchScenariosRef, isAddScenarioDialogOpened, projectScenarios, userFullName, selectedScenario,
        handleDrawerOpened, handleDrawerClosed, handleChangeScenariosSearchText, handleClearScenariosSearchText, handleAddScenarioDialogOpened, handleAddScenarioDialogClosed, handleProjectScenarioClicked } = props;

    const forceUpdate = useForceUpdate();

    let initialSort: SortDescriptor[] = [{ field: 'creationDate', dir: 'desc' }];

    const measurementSystemType = MeasurementSystem.getCurrentType();

    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [skip, setSkip] = useState<number>(0);
    const [recipientsForScenarioSending, setRecipientsForScenarioSending] = useState<RecipientForScenarioSending[]>([]);

    const [exportType, setExportType] = useState<{ type: Export | string, icon: string, }>({ type: "", icon: null });

    useEffect(() => {
        if (props.projectId) {
            MaintenanceScenariosApiClient.GetRecipientsForScenarioSending(props.projectId)
                .then((res) => {
                    var data = res.data;
                    if (data) {
                        setRecipientsForScenarioSending(data);
                    }
                });
        }
    }, [props.projectId]);

    const handleExportScenario = (maintenanceScenarioId): void => {
        MaintenanceScenariosApiClient.ExportExcel(maintenanceScenarioId, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const handleExportTypeChanged = (e: ChangeEvent<{ name: string, value: Export }>): void => {
        let value = e.target.value;
        switch (value) {
            case Export.geojson:
                setExportType({ type: value, icon: jsonIcon });
                break;
            case Export.kml:
                setExportType({ type: value, icon: kmlIcon });
                break;
            case Export.shapefile:
                setExportType({ type: value, icon: shapefileIcon });
                break;
            default:
                setExportType({ type: "", icon: null });
                break;
        }
    }

    const handleExport = (): void => {
        switch (exportType.type) {
            case Export.geojson:
                handleExportGeoJson();
                break;

            case Export.shapefile:
                handleExportShapefile();
                break;

            case Export.kml:
                handleExportKml();
                break;

            default:
                return;
        }
    }

    const handleExportGeoJson = (): void => {
        MaintenanceScenariosApiClient.ExportGeoJson(selectedScenario.maintenanceScenarioId, selectedScenario.label, props.projectLabel, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const handleExportShapefile = (): void => {
        MaintenanceScenariosApiClient.ExportShapefile(selectedScenario.maintenanceScenarioId, selectedScenario.label, props.projectLabel, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const handleExportKml = (): void => {
        MaintenanceScenariosApiClient.ExportKml(selectedScenario.maintenanceScenarioId, selectedScenario.label, props.projectLabel, measurementSystemType)
            .then(res => {
                let fileName: string = getFileName(res);
                let blob: Blob = new Blob([res.data]);
                downloadFileFromBlob(blob, fileName);
            });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    var gridOffsetFromWindowTop: number = SizingUtilities.scenariosGridOffsetFromWindowTop();
    var gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    var rowHeight: number = SizingUtilities.rowHeight;
    var gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    var gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        gridPageSize = Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
        forceUpdate();
    }
    window.onresize = resize;

    let filteredProjectScenarios = projectScenarios.filter(x => x.isVisible === true || x.isSelected === true);
    let dataGrid = sortOrderBy(filteredProjectScenarios, sort).slice(skip, skip + gridPageSize);

    const selectedScenarioHasOnlyDraftAreas = selectedScenario?.areas?.every(x => x?.status === MaintenanceAreaStatus.draft);
    const isExportButtonDisabled = selectedScenarioHasOnlyDraftAreas || exportType.type === "";

    return (
        <>
            {!openedDrawer &&
                <Box className="closed-drawer-content" display="flex" flexDirection="column" alignItems="center" onClick={() => handleDrawerOpened()}>
                    <img className="open-drawer-icon" src={iconDrawerClosed} alt="icon drawer closed" />
                    <Box className="closed-drawer-label">
                        <img src={iconSenariosHeaderDrawerClosed} alt="icon scenarios header drawer closed" />
                        {Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Title_MyScenarios}
                    </Box>
                </Box>
            }
            {openedDrawer &&
                <Box display="flex" flexDirection="column" className="opened-scenarios-selector-drawer-content">
                    <Box className="header" display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" width="85%" alignItems="center">
                            <img src={iconSenariosHeader} alt="icon scenarios" />
                            <div className="title">{Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Title_MyScenarios}</div>
                        </Box>
                        <IconButton onClick={() => handleDrawerClosed()}>
                            <img src={iconDrawerOpened} alt="icon drawer opened" />
                        </IconButton>
                    </Box>
                    <Box className="search-input">
                        <Input disableUnderline className="input-search-scenarios" inputRef={inputSearchScenariosRef}
                            endAdornment={<>
                                <InputAdornment position="end" classes={{ root: 'input-icon-close-root' }}>
                                    <FontAwesomeIcon icon={faTimes} onClick={() => handleClearScenariosSearchText()} />
                                </InputAdornment>
                                <InputAdornment position="end" classes={{ root: 'input-icon-search-root' }}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputAdornment>
                            </>}
                            id="input-search-scenarios-text"
                            placeholder={Translate.Resources.UI_Home_AuscultationSummaryDrawer_Search_Placeholder}
                            onChange={(event) => handleChangeScenariosSearchText(event.target.value)}
                        />
                    </Box>
                    <Box className="scenarios-list">
                        <Box display="flex" flexDirection="row" justifyContent="center" className="scenarios-grid-content">
                            <LocalizationProvider language={Localization.locale}>
                                <IntlProvider locale={Localization.locale}>
                                    <Grid
                                        className="scenarios-grid"
                                        data={dataGrid}
                                        selectedField="isSelected"
                                        sortable
                                        sort={sort}
                                        scrollable="virtual"
                                        skip={skip}
                                        total={filteredProjectScenarios.length}
                                        rowHeight={rowHeight}
                                        pageSize={gridPageSize}
                                        style={gridStyle}
                                        onPageChange={pageChange}
                                        onSortChange={handleSortChange}
                                        onRowClick={handleProjectScenarioClicked}
                                    >
                                        <GridColumn field="label" width="95px" title={Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Scenarios_grid_Scenario} />
                                        <GridColumn field="budgetAmount" width="100px" title={Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Scenarios_grid_Budget}
                                            cell={(properties: GridCellProps) => <td className="column-number">{`${properties.dataItem.budgetAmount?.toLocaleString() ?? ''}`}</td>} />
                                        <GridColumn field="creationDate" width="100" title={Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Scenarios_grid_Date}
                                            cell={(properties: GridCellProps) => <td><span title={properties.dataItem.creationDate.toLocaleTimeString()}>{properties.dataItem.creationDate.toLocaleDateString()}</span></td>} />
                                        <GridColumn field="actions" width="50px" title="" sortable={false}
                                            headerCell={() => <></>}
                                            cell={(properties: GridCellProps) => <CustomScenarioActionsCell {...properties}
                                                recipientsForScenarioSending={recipientsForScenarioSending}
                                                userFullName={userFullName}
                                                handleUpdateScenarioLabel={props.handleUpdateScenarioLabel}
                                                handleDeleteScenario={props.handleDeleteScenario}
                                                handleDuplicateScenario={props.handleDuplicateScenario}
                                                handleExportScenario={handleExportScenario}
                                            />} />
                                    </Grid>
                                </IntlProvider>
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Button className="btn-add-scenario" onClick={() => handleAddScenarioDialogOpened()}>
                            <img src={iconAdd} alt="icon add" />
                            {Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Button_AddAScenario}
                        </Button>
                        <Select
                            className="select"
                            value={exportType.type}
                            onChange={(e) => handleExportTypeChanged(e as ChangeEvent<{ name: string, value: Export }>)}
                            disabled={selectedScenario === null}
                        >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value={Export.geojson}>GEOJSON</MenuItem>
                            <MenuItem value={Export.shapefile}>SHAPEFILE</MenuItem>
                            <MenuItem value={Export.kml}>KML</MenuItem>
                        </Select>
                        <Tooltip title={Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_Button_Export_Tooltip} placement="top">
                            <span>
                                <Button className={`btn-export ${isExportButtonDisabled ? ' disabled' : ''}`} disabled={isExportButtonDisabled} onClick={handleExport}>
                                    {exportType.icon && <img src={exportType.icon} alt="export icon" />}
                                    {Translate.Resources.UI_Home_AuscultationSummaryDrawer_Checkbox_Export}
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            }
            {isAddScenarioDialogOpened &&
                <AddOrUpdateScenarioDialog
                    mode={Mode.Creation}
                    isOpen={isAddScenarioDialogOpened}
                    onCancel={() => handleAddScenarioDialogClosed()}
                    handleValidate={props.handleAddScenario}
                />
            }
        </>
    );
}