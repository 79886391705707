import { RoadLabelScore } from "../services/RoadsCondition/dataContracts/queryStack/RoadLabelScore";
import { RelatedAreaModel } from "./RelatedAreaModel";

export interface RoadLabelScoreExtended extends RoadLabelScore {
    index: number,
    fixedAverageScore: string,
    lengthInMeters: number,
    hiddenFixedAverageScoreForSort: number,
    isSelected: boolean,
    hiddenIsSelectedForSort: number,
    isVisible: boolean,
    labelLowerWithoutDiacritics: string,
    hiddenLabelForSort: string,
    sectionIds: number[],
    relatedAreas: RelatedAreaModel[],
    hiddenIsIncludedInAreaForSort: number
}

export const getHiddenIsSelectedForSort = (isSelected: boolean): number => isSelected === false ? 2 : (isSelected === null ? 1 : 0);

export const getHiddenIsIncludedInAreaForSort = (relatedAreas: RelatedAreaModel[]): number => {
    var isIncludedInArea = relatedAreas.length > 0;
    var isDisplayedOnMap = relatedAreas.some(x => x.isDisplayedOnMap);

    if (isIncludedInArea === false) {
        return 2;
    }
    else if (isIncludedInArea === true && isDisplayedOnMap === false) {
        return 1;
    }
    else {
        return 0;
    }
};