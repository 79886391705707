import { faArrowDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShareIcon from '@mui/icons-material/Share';
import { Box, Button, Dialog, DialogContent, FormControlLabel, IconButton, Switch } from '@mui/material';
import { orderBy as sortOrderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridColumnResizeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { SetStateAction, useState } from 'react';
import iconCombinedImageViewBlack from 'src/assets/icons/icon-combined-image-view-black.svg';
import iconCombinedImageViewWhite from 'src/assets/icons/icon-combined-image-view-white.svg';
import iconImageViewBlack from 'src/assets/icons/icon-image-view-black.svg';
import iconImageViewWhite from 'src/assets/icons/icon-image-view-white.svg';
import iconLabeledImageViewBlack from 'src/assets/icons/icon-labeled-image-view-black.svg';
import iconLabeledImageViewWhite from 'src/assets/icons/icon-labeled-image-view-white.svg';
import iconNextImageBlack from 'src/assets/icons/icon-next-image-black.svg';
import iconNextSection from 'src/assets/icons/icon-next-section.svg';
import iconPreviousImageBlack from 'src/assets/icons/icon-previous-image-black.svg';
import iconPreviousSection from 'src/assets/icons/icon-previous-section.svg';
import buildinfo from '../../../config/buildinfo.json';
import Translate, { Localization } from '../../../localization/Localization';
import { AppModule, LocalStorage } from '../../../utils/Storage';
import { ImageAnomalyGridModel } from '../models/ImageAnomalyGridModel';
import { ImageExtended } from '../models/ImageExtended';
import { RoadSectionViewData } from '../models/RoadSectionViewData';
import { ScoreColors } from '../models/ScoreColors';
import './LabeledImagesDialogStyles.scss';
import { SendImageDialog } from './SendImageDialog';

const versionMajorMinor: string = buildinfo.versionMajorMinor;
const buildNumber: string = buildinfo.build.buildNumber;

interface LabeledImagesDialogProps {
    selectedRoadSectionLabel: string,
    selectedRoadSection: RoadSectionViewData,
    isImageDetailsDialogOpened: boolean,
    selectedImage: ImageExtended,
    hasScoreAnalysisAccess: boolean,
    role: string,
    scoringValue: number[],
    filteredImageAnomalies: ImageAnomalyGridModel[],
    showOnlyPathologiesEnteringIntoScoreCalculation: boolean,
    projectLabel: string,
    setIsImageDetailsDialogOpened: React.Dispatch<SetStateAction<boolean>>,
    handleSectionChanged: (selectedImage: ImageExtended, searchByNext: boolean) => void,
    handleChangeLabeledImage: (imageId: number) => void,
    handleChangeShowOnlyPathologiesEnteringIntoScoreCalculation: () => void
}

enum ImageDisplayedMode {
    image = "image",
    labeled = "labeled",
    combined = "combined"
}

const ImageAnomaliesGridName = 'imageAnomalies';
const ModuleKey = AppModule.Home;

export const LabeledImagesDialog = (props: LabeledImagesDialogProps): JSX.Element => {

    const {
        selectedRoadSectionLabel,
        selectedRoadSection,
        isImageDetailsDialogOpened,
        selectedImage,
        hasScoreAnalysisAccess,
        scoringValue,
        filteredImageAnomalies,
        showOnlyPathologiesEnteringIntoScoreCalculation,
        handleSectionChanged,
        handleChangeLabeledImage,
        handleChangeShowOnlyPathologiesEnteringIntoScoreCalculation,
        setIsImageDetailsDialogOpened,
    } = props;

    const [imageDisplayedMode, setImageDisplayedMode] = useState<ImageDisplayedMode>(hasScoreAnalysisAccess && props.role !== "CLIPLUS" ? ImageDisplayedMode.combined : ImageDisplayedMode.image);
    const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'anomalyType', dir: 'asc' }]);
    const [isSendImageDialogOpened, setIsSendImageDialogOpened] = useState<boolean>(false);

    const handleOpenImageInNewTab = (url: string): void => {
        var newWindow = window.open(url, '_blank');
        newWindow.addEventListener('load', function () {
            newWindow.document.title = "infraCare v" + versionMajorMinor + "." + buildNumber;
        }, false);
    }

    const onColumnResizeHandler = (event: GridColumnResizeEvent): void => {
        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            ImageAnomaliesGridName,
            event.columns.find(c => c.orderIndex === event.index).field,
            event.newWidth);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        setSort(e.sort);
    }

    const getAnomaliesGridWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, ImageAnomaliesGridName, fieldName, columnWidth);
    }

    return (
        <Dialog id="labeled-image-dialog" open={isImageDetailsDialogOpened}>
            <DialogContent>
                <Box display="flex" flexDirection="column" className="dialog-content">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" className="road">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box className={`road-score ${selectedRoadSection.scoreColor === ScoreColors.poor ? 'bad' : (selectedRoadSection.scoreColor === ScoreColors.toMonitor ? 'to-monitor' : (selectedRoadSection.scoreColor === ScoreColors.good ? 'good' : 'undetermined'))}`}>{selectedRoadSection.score !== null ? selectedRoadSection.score.toFixedLocalized(2) : "n/a"}</Box>
                            <Box className="road-label">{selectedRoadSectionLabel ?? ''}</Box>
                        </Box>
                        <IconButton size="small" onClick={() => setIsImageDetailsDialogOpened(false)}>
                            <FontAwesomeIcon size="1x" icon={faTimes} color="#1DB3DD" />
                        </IconButton>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" className="btns-navigation">
                        <Box display="flex" flexDirection="row">
                            <Button className="btn-navigation mr" onClick={() => handleSectionChanged(selectedImage, false)}>
                                <img src={iconPreviousSection} alt="icon previous section" className="img mr" />
                                {Translate.Resources.UI_Home_LabeledImages_PreviousSection}
                            </Button>
                            <Button className="btn-navigation" onClick={() => handleChangeLabeledImage(selectedImage.previousImageId)}>
                                <img src={iconPreviousImageBlack} alt="previous icon" className="img mr" />
                                {Translate.Resources.UI_Home_LabeledImages_PreviousImage}
                            </Button>
                        </Box>
                        <Box>
                            {selectedRoadSection.images.map((image: ImageExtended, index: number) => {
                                return (
                                    <Button key={`$btn-image-${index}`} className={`btn-image ${index === 0 ? 'radius-left' : ''} ${index === selectedRoadSection.images.length - 1 ? 'radius-right' : ''} ${image.referenceImageId === selectedImage.referenceImageId ? 'selected' : ''}`} onClick={() => handleChangeLabeledImage(image.imageId)}>
                                        {image.referenceImageId}
                                    </Button>
                                )
                            })}
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Button className="btn-navigation mr" onClick={() => handleChangeLabeledImage(selectedImage.nextImageId)}>
                                {Translate.Resources.UI_Home_LabeledImages_NextImage}
                                <img src={iconNextImageBlack} alt="black next icon" className="img ml" />
                            </Button>
                            <Button className="btn-navigation" onClick={() => props.handleSectionChanged(selectedImage, true)}>
                                {Translate.Resources.UI_Home_LabeledImages_NextSection}
                                <img src={iconNextSection} alt="icon next section" className="img ml" />
                            </Button>
                        </Box>
                    </Box>
                    <Box className={`images-anomaly-content ${hasScoreAnalysisAccess && props.role !== "CLIPLUS" ? 'has-access' : ''}`} display="flex" flexDirection="row">
                        <Box className={`score-indicator ${selectedImage.scoreColor === ScoreColors.poor ? 'bad' : (selectedImage.scoreColor === ScoreColors.toMonitor ? 'to-monitor' : (selectedImage.scoreColor === ScoreColors.good ? 'good' : 'undetermined'))}`}></Box>
                        <Box className="content" display="flex" flexDirection="column">
                            <Box display="flex" flexDirection="row" justifyContent="space-between" className="score">
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Box className={`image-score ${selectedImage.scoreColor === ScoreColors.poor ? 'bad' : (selectedImage.scoreColor === ScoreColors.toMonitor ? 'to-monitor' : (selectedImage.scoreColor === ScoreColors.good ? 'good' : 'undetermined'))}`}>
                                        {selectedImage.score !== null ? selectedImage.score.toFixedLocalized(2) : "n/a"}
                                    </Box>
                                    <Box className="image-reference">
                                        {selectedImage.referenceImageId}
                                    </Box>
                                    <Button className="btn-share" onClick={() => setIsSendImageDialogOpened(true)}>
                                        <ShareIcon />
                                        {Translate.Resources.UI_RoadsCondition_LabeledImages_Button_Share}
                                    </Button>
                                    {isSendImageDialogOpened &&
                                        <SendImageDialog
                                            isSendImageDialogOpened={isSendImageDialogOpened}
                                            selectedImage={selectedImage}
                                            roadLabel={selectedRoadSectionLabel}
                                            projectLabel={props.projectLabel}
                                            handleCloseDialog={() => setIsSendImageDialogOpened(false)}
                                        />
                                    }
                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="center" className="image-score-item-content">
                                    <Box display="flex" flexDirection="row" alignItems="center" className="image-score-indication" ml={`calc(${((selectedImage?.score > 10 ? 10 : selectedImage?.score) * 10)}% - 5px)`}>
                                        {selectedImage.score !== null &&
                                            <>
                                                <FontAwesomeIcon className="arrow-icon" icon={faArrowDown} size="1x" />
                                                <span>{selectedImage.score.toFixedLocalized(2)}</span>
                                            </>
                                        }
                                    </Box>
                                    <Box display="flex" flexDirection="row" className="score-line">
                                        <Box width={`${(scoringValue[0] * 100) / 10}%`} className="good"></Box>
                                        <Box width={`${(((scoringValue[1] * 100) / 10) - ((scoringValue[0] * 100) / 10))}%`} className="to-monitor"></Box>
                                        <Box width={`${((10 - scoringValue[1]) * 100) / 10}%`} className="bad"></Box>
                                    </Box>
                                    <Box display="flex" flexDirection="row" width="100%">
                                        <Box className="score-indication" ml={`calc(${(scoringValue[0] * 10)}% - 10px)`}>{scoringValue[0]}</Box>
                                        <Box className="score-indication" ml={`calc(${(scoringValue[1] * 10)}% - 10px - ${(scoringValue[0] * 10)}% - 10px)`}>{scoringValue[1]}</Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Button className={`displayed-image-mode-btn radius-left ${imageDisplayedMode === ImageDisplayedMode.image ? 'selected' : ''}`} onClick={() => setImageDisplayedMode(ImageDisplayedMode.image)}>
                                        <img src={imageDisplayedMode === ImageDisplayedMode.image ? iconImageViewWhite : iconImageViewBlack} alt="black icon" />
                                    </Button>
                                    {hasScoreAnalysisAccess && props.role !== "CLIPLUS" &&
                                        <>
                                            <Button className={`displayed-image-mode-btn ${imageDisplayedMode === ImageDisplayedMode.labeled ? 'selected' : ''}`} onClick={() => setImageDisplayedMode(ImageDisplayedMode.labeled)}>
                                                <img src={imageDisplayedMode === ImageDisplayedMode.labeled ? iconLabeledImageViewWhite : iconLabeledImageViewBlack} alt="icon labeled" />
                                            </Button>
                                            <Button className={`displayed-image-mode-btn radius-right ${imageDisplayedMode === ImageDisplayedMode.combined ? 'selected' : ''}`} onClick={() => setImageDisplayedMode(ImageDisplayedMode.combined)}>
                                                <img src={imageDisplayedMode === ImageDisplayedMode.combined ? iconCombinedImageViewWhite : iconCombinedImageViewBlack} alt="icon combined" />
                                            </Button>
                                        </>
                                    }
                                </Box>
                            </Box>
                            {imageDisplayedMode === ImageDisplayedMode.image &&
                                <>
                                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="images">
                                        <figure className="image-figure">
                                            <img
                                                className="img" alt="selected content"
                                                src={`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`}
                                                onClick={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`)} />
                                            <figcaption className="image-date">
                                                {selectedImage.auscultation.videoDateTime.toLocaleDateString()}
                                            </figcaption>
                                        </figure>

                                    </Box>
                                    {hasScoreAnalysisAccess && props.role === "CLIPLUS" &&
                                        <Box display="flex" flexDirection="row" justifyContent="center" marginBottom="15px">
                                            {selectedImage.trustedAnomaliesCounters && Array.from(selectedImage.trustedAnomaliesCounters).map(([key, anomaly]) => {
                                                if (anomaly.counter > 0 && anomaly.scoringWeight > 0)
                                                    return (
                                                        <Box className="anomaly-item entering-into-score" key={`anomalie-${key}`} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                                            <Box>{Translate.GetAnomalyTypeLabel(key)}</Box>
                                                            <Box>{anomaly.counter}</Box>
                                                        </Box>
                                                    );
                                                return (<React.Fragment key={`anomalie-${key}`} />);
                                            })}
                                            {selectedImage.trustedAnomaliesCounters && Array.from(selectedImage.trustedAnomaliesCounters).map(([key, anomaly]) => {
                                                if (anomaly.counter > 0 && (anomaly.scoringWeight === null || anomaly.scoringWeight === 0))
                                                    return (
                                                        <Box className="anomaly-item" key={`anomalie-${key}`} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                                            <Box>{Translate.GetAnomalyTypeLabel(key)}</Box>
                                                            <Box>{anomaly.counter}</Box>
                                                        </Box>
                                                    );
                                                return (<React.Fragment key={`anomalie-${key}`} />);
                                            })}
                                        </Box>
                                    }
                                </>
                            }
                            {hasScoreAnalysisAccess && props.role !== "CLIPLUS" && imageDisplayedMode === ImageDisplayedMode.labeled &&
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" className="images labeled">
                                    <figure className="image-figure">
                                        <img src={`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`} className="img" alt="labeled content"
                                            onClick={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`)} />
                                        <figcaption className="image-date">
                                            {selectedImage.auscultation.videoDateTime.toLocaleDateString()}
                                        </figcaption>
                                    </figure>
                                </Box>
                            }
                            {hasScoreAnalysisAccess && props.role !== "CLIPLUS" && imageDisplayedMode === ImageDisplayedMode.combined &&
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" className="images combined">
                                    <figure className="image-figure">
                                        <img src={`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`} className="img" alt="content"
                                            onClick={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`)} />
                                        <figcaption className="image-date">
                                            {selectedImage.auscultation.videoDateTime.toLocaleDateString()}
                                        </figcaption>
                                    </figure>
                                    <figure className="image-figure">
                                        <img src={`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`} className="img" alt="labeled content"
                                            onClick={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`)} />
                                        <figcaption className="image-date">
                                            {selectedImage.auscultation.videoDateTime.toLocaleDateString()}
                                        </figcaption>
                                    </figure>
                                </Box>
                            }
                            {hasScoreAnalysisAccess && props.role !== "CLIPLUS" && imageDisplayedMode !== ImageDisplayedMode.image &&
                                <Box display="flex" flexDirection="column" alignItems="center" className="anomalies">
                                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                        <FormControlLabel className="switch-filter" control={<Switch checked={showOnlyPathologiesEnteringIntoScoreCalculation} onChange={() => handleChangeShowOnlyPathologiesEnteringIntoScoreCalculation()} />}
                                            label={Translate.Resources.UI_Home_LabeledImages_Checkbox_Label_ShowOnlyPathologiesEnteringIntoScoreCalculation} />
                                    </Box>
                                    <Box display="flex" flexDirection="row" justifyContent="center" className="image-anomalies-list">
                                        <LocalizationProvider language={Localization.locale}>
                                            <IntlProvider locale={Localization.locale}>
                                                <Grid
                                                    className="image-anomalies-grid"
                                                    data={sortOrderBy(filteredImageAnomalies, sort)}
                                                    resizable={true}
                                                    onColumnResize={onColumnResizeHandler}
                                                    sortable={true}
                                                    onSortChange={handleSortChange}
                                                    sort={sort}
                                                    rowHeight={40}
                                                >
                                                    <GridColumn className="column-text" field="anomalyType" orderIndex={0} width={getAnomaliesGridWidth("anomalyType", 340)} title={Translate.Resources.UI_Home_LabeledImages_ImageAnomaliesGrid_Columns_Pathology} />
                                                    <GridColumn className="column-text" field="confidencePercentage" orderIndex={1} width={getAnomaliesGridWidth("confidencePercentage", 300)} title={Translate.Resources.UI_Home_LabeledImages_ImageAnomaliesGrid_Columns_Confidence} />
                                                    <GridColumn className="column-text" field="confidenceThresholdPercentage" orderIndex={2} width={getAnomaliesGridWidth("confidenceThresholdPercentage", 300)} title={Translate.Resources.UI_Home_LabeledImages_ImageAnomaliesGrid_Columns_ConfidenceThreshold} />
                                                    <GridColumn className="column-text" field="coefficient" orderIndex={3} width={getAnomaliesGridWidth("coefficient", 300)} title={Translate.Resources.UI_Home_LabeledImages_ImageAnomaliesGrid_Columns_Coefficient} />
                                                    <GridColumn className="column-text" field="area" orderIndex={4} width={getAnomaliesGridWidth("area", 300)} title={Translate.Resources.UI_Home_LabeledImages_ImageAnomaliesGrid_Columns_Area} />
                                                </Grid>
                                            </IntlProvider>
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}


