import TuneIcon from '@mui/icons-material/Tune';
import { Box, IconButton } from '@mui/material';
import { AnimationOptions, CameraBoundsOptions, data, layer, Map as AzureMap, MapMouseEvent, math, Shape, source } from 'azure-maps-control';
import { debounce, isEqual, orderBy } from 'lodash';
import React, { Component } from 'react';
import { InputActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ScaleLoader } from 'react-spinners';
import iconArrow from 'src/assets/icons/icon-arrow.svg';
import pinIcon from 'src/assets/icons/icon-pin.svg';
import securityHelmetIcon from 'src/assets/icons/icon-security-helmet.png';
import selectedWorkDoneAndToDoMultiAreasConeIcon from 'src/assets/icons/icon-selected-work-done-and-to-do-multi-areas-cone.svg';
import selectedWorkDoneAreaConeIcon from 'src/assets/icons/icon-selected-work-done-area-cone.svg';
import selectedWorkDoneMultiAreasConeIcon from 'src/assets/icons/icon-selected-work-done-multi-areas-cone.svg';
import selectedWorkToDoAreaConeIcon from 'src/assets/icons/icon-selected-work-to-do-area-cone.svg';
import selectedWorkToDoMultiAreasConeIcon from 'src/assets/icons/icon-selected-work-to-do-multi-areas-cone.svg';
import workDoneAndToDoMultiAreasConeIcon from 'src/assets/icons/icon-work-done-and-to-do-multi-areas-cone.svg';
import workDoneAreaConeIcon from 'src/assets/icons/icon-work-done-area-cone.svg';
import workDoneMultiAreasConeIcon from 'src/assets/icons/icon-work-done-multi-areas-cone.svg';
import workToDoAreaConeIcon from 'src/assets/icons/icon-work-to-do-area-cone.svg';
import workToDoMultiAreasConeIcon from 'src/assets/icons/icon-work-to-do-multi-areas-cone.svg';
import Translate from '../../localization/Localization';
import { MapFiltersComponent } from '../../shared/components/MapFilters/MapFiltersComponent';
import { MapStyleSelector } from '../../shared/components/MapStyleSelector/MapStyleSelector';
import { Address, MapsService } from '../../shared/MapsService/MapsService';
import { CustomMapChoice } from '../../shared/models/CustomMapChoice';
import { MapCursorMode } from '../../shared/models/MapCursorMode';
import { MeasurementSystemType } from '../../shared/models/MeasurementSystemType';
import { ScoreTypes } from '../../shared/models/ScoreTypes';
import '../../utils/Map';
import MathExtensions from '../../utils/MathExtensions';
import { MeasurementSystem } from '../../utils/MeasurementSystem';
import Utilities from '../../utils/Utilities';
import { RouteComponentProps, withRouter } from '../../withRouter';
import styles from '../../_variables.scss';
import { ProjectVersion } from '../Home/services/dataContracts/queryStack/ProjectVersion';
import { MergedProjectVersion } from '../RoadsConditionAndScenarios/models/MergedProjectVersion';
import { RoadSectionViewData } from '../RoadsConditionAndScenarios/models/RoadSectionViewData';
import { RouteLocationStateModel } from '../RoadsConditionAndScenarios/models/RouteLocationStateModel';
import { ScoreColors } from '../RoadsConditionAndScenarios/models/ScoreColors';
import { ScoreTypesColors } from '../RoadsConditionAndScenarios/models/ScoreTypesColors';
import { ShapeEntityType } from '../RoadsConditionAndScenarios/models/ShapeEntityType';
import { areaWidth, cameraAnimationDuration, cameraAnimationType, mainDatasourceId, roadLayerId, RoadsConditionAndScenariosShared, sectionWidth, transparentColor } from '../RoadsConditionAndScenarios/RoadsConditionAndScenariosShared';
import { MaintenanceArea } from '../RoadsConditionAndScenarios/services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceArea';
import { MaintenanceAreaSection } from '../RoadsConditionAndScenarios/services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceAreaSection';
import { MaintenanceAreaStatus } from '../RoadsConditionAndScenarios/services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceAreaStatus';
import { MaintenanceScenario } from '../RoadsConditionAndScenarios/services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceScenario';
import { MaintenanceScenariosApiClient } from '../RoadsConditionAndScenarios/services/MaintenanceScenarios/MaintenanceScenariosApiClient';
import { RoadWorksInformationDrawer } from './components/RoadWorksInformationDrawer';
import { WorkZonesFilterSectionComponent } from './components/WorkZonesFilterSectionComponent';
import { AddressSelectItem } from './models/AddressSelectItem';
import { AreaShapeProperties } from './models/AreaShapeProperties';
import { MaintenanceAreaExtended } from './models/MaintenanceAreaExtended';
import { SelectOptionModel } from './models/SelectOptionModel';
import { WorkZonesFiltersModel } from './models/WorkZonesFiltersModel';
import './RoadWorksStyles.scss';

const coneDatasourceId = 'coneDatasourceId';
const areaDatasourceId = 'areaDatasourceId';

const helmetDatasourceId = "helmetDatasourceId";
const helmetIconSymbolLayerId = "helmetIconSymbolLayerId";
const helmetId = "helmetId";
const helmetEntityType = "helmetEntityType";

const areaRoadLayerId = "areaRoadLayerId";
const coneIconSymbolLayerId = "coneIconSymbolLayerId";
const selectedConeIconSymbolLayerId = "selectedConeIconSymbolLayerId";
const areaConeEntityTypes = {
    workToDoArea: "workToDoArea",
    workDoneArea: "workDoneArea",
    workToDoMultiArea: "workToDoMultiArea",
    workDoneMultiArea: "workDoneMultiArea",
    workToDoAndWorkDoneMultiArea: "workToDoAndWorkDoneMultiArea"
};

const addressDatasourceId = "addressDatasourceId";
const addressIconSymbolLayerId = "addressIconSymbolLayerId";
const addressId = "addressId";
const addressEntityType = "addressEntityType";

interface RoadWorksViewState {
    loading: boolean,
    isMapFiltersDialogDisplayed: boolean,
    activeRoadsConditions: Map<string, boolean>,
    activeAnomalies: Set<string>,
    isGroupView: boolean,
    mergedProject: MergedProjectVersion,
    projectScenarios: MaintenanceScenario[],
    activeStatusOfWorkZones: Set<string>,
    yearValues: SelectOptionModel[],
    scenarioNameValues: SelectOptionModel[],
    workZonesFilters: WorkZonesFiltersModel,
    isRoadWorksInformationDrawerOpened: boolean,
    selectedMaintenanceAreas: Map<number, AreaShapeProperties>,
    isInputAddressLoading: boolean,
    inputAddressValue: string,
    inputAddress: string,
    currentMeasurementSystemType: MeasurementSystemType
}

export class RoadWorksView extends Component<RouteComponentProps, RoadWorksViewState> {
    _isMounted: boolean;
    map: AzureMap;
    thresholdOfGoodScore: number;
    thresholdOfPoorScore: number;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    projectVersionsCache: Map<number, ProjectVersion>;
    areaEnlightenedShapeIds: Set<string>;
    conePinIds: Set<string>;
    maintenanceAreas: Map<number, MaintenanceAreaExtended>;
    masquedConeShape: Shape;
    selectedConeShape: Shape;
    projectLabel: string;
    inputSearchAddressRef: React.RefObject<HTMLInputElement>;

    constructor(props) {
        super(props);

        this.map = null;
        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.projectVersionsCache = new Map<number, ProjectVersion>();
        this.areaEnlightenedShapeIds = new Set<string>();
        this.conePinIds = new Set<string>();
        this.maintenanceAreas = new Map<number, MaintenanceAreaExtended>();
        this.masquedConeShape = null;
        this.selectedConeShape = null;
        this.projectLabel = null;
        this.inputSearchAddressRef = React.createRef();

        this.state = {
            loading: false,
            isMapFiltersDialogDisplayed: false,
            activeRoadsConditions: new Map<string, boolean>([[ScoreTypes.poor, false], [ScoreTypes.toMonitor, false], [ScoreTypes.good, false]]),
            activeAnomalies: new Set<string>(),
            isGroupView: false,
            mergedProject: null,
            projectScenarios: [],
            activeStatusOfWorkZones: new Set<string>([MaintenanceAreaStatus.workToDo, MaintenanceAreaStatus.workDone]),
            yearValues: [],
            scenarioNameValues: [],
            workZonesFilters: {
                activeStatusOfWorkZones: new Set<string>([MaintenanceAreaStatus.workToDo, MaintenanceAreaStatus.workDone]),
                scenarioNames: new Set<string>(),
                years: new Set<number>()
            },
            isRoadWorksInformationDrawerOpened: false,
            selectedMaintenanceAreas: new Map<number, AreaShapeProperties>(),
            isInputAddressLoading: false,
            inputAddressValue: "",
            inputAddress: "",
            currentMeasurementSystemType: MeasurementSystem.getCurrentType()
        };
    }

    async componentDidMount() {
        this._isMounted = true;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.projectLabel = locationState.label;

        await this.initViewData(locationState, this.state.activeRoadsConditions);
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            currentMeasurementSystemType: measurementSystemType
        });
    }

    initViewData = async (locationState: RouteLocationStateModel, activeRoadsConditions: Map<string, boolean>): Promise<void> => {

        let mapChoice = RoadsConditionAndScenariosShared.getMapChoiceValue();
        this.map = RoadsConditionAndScenariosShared.createMap('roadWorks-map', 4, locationState.locationGeometry, mapChoice);

        this.thresholdOfGoodScore = locationState.scoringValue[0];
        this.thresholdOfPoorScore = locationState.scoringValue[1];

        if (locationState.projectVersionId) {
            this.setState({ loading: true });

            let shouldShowUnscoredSections = false;
            let mergedProject = await RoadsConditionAndScenariosShared.getMergedProject(
                locationState.projectVersionId,
                shouldShowUnscoredSections,
                this.thresholdOfGoodScore,
                this.thresholdOfPoorScore,
                this.mergedProjectAuscultationsCache,
                this.projectVersionsCache);

            let project = mergedProject.projectVersion;

            let projectScenarios = await this.getRoadWorks(project.projectId);
            this.maintenanceAreas = this.createExtendedAreas(projectScenarios);

            this.initMap(this.map, () => {
                let datasource = this.createMainDatasource();
                this.createMapSectionsShapes(mergedProject, datasource, activeRoadsConditions);

                let areaDatasource = this.createAreaDatasource();
                let coneDatasource = this.createConeDatasource();
                this.createMapAreasShapes(this.maintenanceAreas, areaDatasource, coneDatasource);

                this.createAddressDatasource();
                this.createHelmetDatasource();

                this.setMapZoom(this.map, mergedProject);
            });

            if (this._isMounted) {
                this.setState({
                    loading: false,
                    mergedProject: mergedProject,
                    projectScenarios: projectScenarios
                });
            }
        }
    }

    initMap = (map: AzureMap, callback: () => void): void => {
        RoadsConditionAndScenariosShared.setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {

            if (!this._isMounted)
                return;

            map.events.add('mousedown', this.handleMapMousedown);
            map.events.add('zoomend', () => this.handleZoomend());

            if (callback) {
                callback();
            }
        });
    }

    getRoadWorks = async (projectId: string): Promise<MaintenanceScenario[]> => {
        return await MaintenanceScenariosApiClient.GetRoadWorks(projectId)
            .then((res) => {
                if (this._isMounted && res) {
                    return res.data;
                }

                return null;
            });
    }

    createMapSectionsShapes = (mergedProject: MergedProjectVersion, datasource: source.DataSource, activeRoadsConditions: Map<string, boolean>): void => {
        mergedProject.roadsSections.forEach((section: RoadSectionViewData) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            let unselectedSectionShapeId = RoadsConditionAndScenariosShared.getSectionShapeId(roadSectionId);
            let sectionScoreType = section.scoreColor === ScoreColors.poor ? ScoreTypes.poor :
                (section.scoreColor === ScoreColors.toMonitor ? ScoreTypes.toMonitor :
                    (section.scoreColor === ScoreColors.good ? ScoreTypes.good : null));

            let strokeColor = styles.unfilteredSectionColor;
            if (activeRoadsConditions.get(sectionScoreType) === true) {
                strokeColor = ScoreTypesColors.get(sectionScoreType);
            }

            let unselectedSectionShape = RoadsConditionAndScenariosShared.createShape(coordinates, unselectedSectionShapeId, strokeColor, sectionWidth, ShapeEntityType.section, roadSectionId, sectionScoreType);
            datasource.add(unselectedSectionShape);
        });
    }

    createExtendedAreas = (projectScenarios: MaintenanceScenario[]): Map<number, MaintenanceAreaExtended> => {
        if (!projectScenarios)
            return null;

        let areas: Map<number, MaintenanceAreaExtended> = new Map<number, MaintenanceAreaExtended>();
        projectScenarios.forEach((scenario: MaintenanceScenario) => {
            scenario.areas.forEach((area: MaintenanceArea) => {
                let extendedArea = {
                    ...area,
                    scenarioLabel: scenario.label,
                    year: area.startDate ? area.startDate.getFullYear() : null,
                    ownerUserId: scenario.ownerUserId,
                    ownerUserFullName: scenario.ownerUserFullName,
                    isEnlightened: false,
                    coordinates: null,
                    sortedCoordinates: null,
                    isFiltered: true
                } as MaintenanceAreaExtended;

                let areaCoordinates: data.Position[] = [];
                area.sections.forEach((section: MaintenanceAreaSection) => {
                    let coordinates = section.pathGeometry.coordinates;
                    areaCoordinates = areaCoordinates.concat(coordinates);
                });
                extendedArea.coordinates = areaCoordinates;
                extendedArea.sortedCoordinates = areaCoordinates.sort();

                areas.set(extendedArea.maintenanceAreaId, extendedArea);
            });
        });

        return areas;
    }

    createMapAreasShapes = (areas: Map<number, MaintenanceAreaExtended>, areaDatasource: source.DataSource, coneDatasource: source.DataSource): void => {
        //NOTE HGA on les tri par id car on n'a pas la date de création, mais on souhaite que les plus récentes se dessinent par dessus
        const mapEntries = [...areas].map(([key, value]) => (value));

        orderBy(mapEntries, [(a) => { return a.maintenanceAreaId }], ['asc'])
            .forEach((entry: MaintenanceAreaExtended) => {
                let area: AreaShapeProperties = {
                    maintenanceAreaId: entry.maintenanceAreaId,
                    startDate: entry.startDate,
                    status: entry.status,
                    hexColor: entry.hexColor,
                    label: entry.label,
                    typeOfWork: entry.typeOfWork,
                    lengthInLinearMeters: entry.lengthInLinearMeters,
                    areaInSquareMeters: entry.areaInSquareMeters,
                    budgetAmount: entry.budgetAmount,
                    ownerUserId: entry.ownerUserId,
                    ownerUserFullName: entry.ownerUserFullName,
                    isFiltered: entry.isFiltered,
                    isEnlightened: false,
                    year: entry.year,
                    scenarioLabel: entry.scenarioLabel
                };

                entry.sections.forEach((section: MaintenanceAreaSection) => {
                    let coordinates = section.pathGeometry.coordinates;
                    let roadSectionId = section.roadSectionId;
                    let enlightenedAreaShapeId = this.getEnlightenedAreaShapeId(roadSectionId, area.maintenanceAreaId);
                    let selectedAreaShape = RoadsConditionAndScenariosShared.createShape(coordinates, enlightenedAreaShapeId, transparentColor, 0, ShapeEntityType.areaEnlightened, roadSectionId);
                    areaDatasource.add(selectedAreaShape);

                    let unselectedAreaShapeId = this.getAreaShapeId(roadSectionId, area.maintenanceAreaId);
                    let unselectedAreaShape = RoadsConditionAndScenariosShared.createShape(coordinates, unselectedAreaShapeId, area.hexColor, areaWidth, ShapeEntityType.area, roadSectionId, null, area);
                    areaDatasource.add(unselectedAreaShape);
                });
            });

        let areaGroupings = this.createMaintenanceAreasGroupings(areas);

        this.createConePins(areaGroupings, coneDatasource);
    }

    createMaintenanceAreasGroupings = (areas: Map<number, MaintenanceAreaExtended>): Map<number, { areas: MaintenanceAreaExtended[], order: number }> => {
        let groupings = new Map<number, { areas: MaintenanceAreaExtended[], order: number }>();

        areas.forEach((area: MaintenanceAreaExtended) => {
            let isMatchOnExistingGroup: boolean = false;
            let groupNumber = 0;
            for (let x of groupings) {
                let groupKey = x[0];
                let groupValue = x[1];
                let firstExistingArea = groupValue.areas[0];
                isMatchOnExistingGroup = isEqual(firstExistingArea.sortedCoordinates, area.sortedCoordinates);
                if (isMatchOnExistingGroup) {
                    groupValue.order = Math.max(groupValue.order, area.maintenanceAreaId);
                    groupValue.areas.push(area);
                    groupings.set(groupKey, groupValue);
                    isMatchOnExistingGroup = true;
                    break;
                }
                groupNumber++;
            }

            if (!isMatchOnExistingGroup) {
                groupings.set(groupNumber, { areas: [area], order: area.maintenanceAreaId });
            }
        });

        return groupings;
    }

    createConePins = (areaGroupings: Map<number, { areas: MaintenanceAreaExtended[], order: number }>, coneDatasource: source.DataSource): void => {
        const mapEntries = [...areaGroupings].map(([key, value]) => (value));

        orderBy(mapEntries, [(a) => { return a.order }], ['asc'])
            .forEach((entry: { areas: MaintenanceAreaExtended[], order: number }) => {
                let firstArea = entry.areas[0];
                let coordinates = firstArea.coordinates;
                if (coordinates.length > 0) {
                    let areas: AreaShapeProperties[] = [];
                    entry.areas.forEach((area: MaintenanceAreaExtended) => {
                        areas.push({
                            maintenanceAreaId: area.maintenanceAreaId,
                            startDate: area.startDate,
                            status: area.status,
                            hexColor: area.hexColor,
                            label: area.label,
                            typeOfWork: area.typeOfWork,
                            lengthInLinearMeters: area.lengthInLinearMeters,
                            areaInSquareMeters: area.areaInSquareMeters,
                            budgetAmount: area.budgetAmount,
                            ownerUserId: area.ownerUserId,
                            ownerUserFullName: area.ownerUserFullName,
                            isFiltered: area.isFiltered,
                            isEnlightened: false,
                            year: area.year,
                            scenarioLabel: area.scenarioLabel
                        });
                    });

                    let props = { isConeIcon: true, EntityType: null, areas: areas, index: entry.order, visible: true };
                    let selectedProps = { isSelectedConeIcon: true, EntityType: null, areas: areas, index: entry.order, visible: false };

                    if (entry.areas.length === 1) {
                        if (firstArea.status === MaintenanceAreaStatus.workDone) {
                            props.EntityType = areaConeEntityTypes.workDoneArea;
                            selectedProps.EntityType = areaConeEntityTypes.workDoneArea;
                        }
                        else if (firstArea.status === MaintenanceAreaStatus.workToDo) {
                            props.EntityType = areaConeEntityTypes.workToDoArea;
                            selectedProps.EntityType = areaConeEntityTypes.workToDoArea;
                        }
                    }
                    else if (entry.areas.length > 1) {
                        let isAllWorkToDo = true;
                        let isAllWorkDone = true;
                        entry.areas.forEach(area => {
                            if (area.status === MaintenanceAreaStatus.workDone) {
                                isAllWorkToDo = false;
                            }
                            if (area.status === MaintenanceAreaStatus.workToDo) {
                                isAllWorkDone = false;
                            }
                        });

                        if (isAllWorkToDo && !isAllWorkDone) {
                            props.EntityType = areaConeEntityTypes.workToDoMultiArea;
                            selectedProps.EntityType = areaConeEntityTypes.workToDoMultiArea;
                        }
                        else if (!isAllWorkToDo && isAllWorkDone) {
                            props.EntityType = areaConeEntityTypes.workDoneMultiArea;
                            selectedProps.EntityType = areaConeEntityTypes.workDoneMultiArea;
                        }
                        else {
                            props.EntityType = areaConeEntityTypes.workToDoAndWorkDoneMultiArea;
                            selectedProps.EntityType = areaConeEntityTypes.workToDoAndWorkDoneMultiArea;
                        }
                    }

                    let boundingBox = data.BoundingBox.fromPositions(coordinates);
                    let centralPoint = data.BoundingBox.getCenter(boundingBox);
                    let distances = new Map<number, data.Position>();
                    coordinates.forEach((position) => {
                        let distance = math.getDistanceTo(centralPoint, position);
                        distances.set(distance, position);
                    });

                    let minDistance = Math.min(...Array.from(distances).map(x => x[0]));
                    let midPoint = distances.get(minDistance);

                    let coneShapeId = this.getConeShapeId(entry.order);
                    let conePin = new data.Feature(new data.Point(midPoint), props, coneShapeId);
                    coneDatasource.add(conePin);
                    this.conePinIds.add(coneShapeId);

                    let selectedConeShapeId = this.getSelectedConeShapeId(entry.order);
                    let selectedConePin = new data.Feature(new data.Point(midPoint), selectedProps, selectedConeShapeId);
                    coneDatasource.add(selectedConePin);
                    this.conePinIds.add(selectedConeShapeId);
                }
            });
    }

    getConeShapeId = (index: number): string => {
        return `cone-${index}`;
    }

    getSelectedConeShapeId = (index: number): string => {
        return `cone-${index}-selected`;
    }

    getEnlightenedAreaShapeId = (roadSectionId: number, maintenanceAreaId: number): string => {
        return `${roadSectionId}/area/enlightened/${maintenanceAreaId}`;
    }

    getAreaShapeId = (roadSectionId: number, maintenanceAreaId: number): string => {
        return `${roadSectionId}/area/${maintenanceAreaId}`;
    }

    showShape = (shape: Shape, strokeColor: string, shapeProperties: any): void => {
        shapeProperties.strokeColor = strokeColor;
        shapeProperties.strokeWidth = areaWidth;
        shape.setProperties(shapeProperties);
    }

    hideShape = (shape: Shape, shapeProperties: any): void => {
        shapeProperties.strokeColor = transparentColor;
        shapeProperties.strokeWidth = 0;
        shape.setProperties(shapeProperties);
    }

    isShapeVisible = (shapeProperties: any): boolean => {
        return shapeProperties.strokeColor !== transparentColor && shapeProperties.strokeWidth !== 0;
    }

    createMainDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(mainDatasourceId);
        this.map.sources.add(datasource);

        let roadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, roadLayerId);
        this.map.layers.add([roadLayer]);

        return datasource;
    }

    createAreaDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(areaDatasourceId);
        this.map.sources.add(datasource);

        let areaRoadLayer = this.createAreaRoadLayer(datasource, areaRoadLayerId);

        this.map.layers.add([areaRoadLayer]);

        this.map.events.add('mouseover', areaRoadLayer, this.handleLayerMouseover);
        this.map.events.add('mouseout', areaRoadLayer, this.handleLayerMouseout);

        return datasource;
    }

    createHelmetDatasource = (): void => {
        let datasource = new source.DataSource(helmetDatasourceId);
        this.map.sources.add(datasource);

        let helmetSymbolLayer = this.createHelmetIconSymbolLayer(datasource, helmetIconSymbolLayerId);
        this.map.layers.add([helmetSymbolLayer]);
    }

    createAddressDatasource = (): void => {
        let datasource = new source.DataSource(addressDatasourceId);
        this.map.sources.add(datasource);

        let addressSymbolLayer = this.createAddressIconSymbolLayer(datasource, addressIconSymbolLayerId);
        this.map.layers.add([addressSymbolLayer]);
    }

    createConeDatasource = (): source.DataSource => {
        let coneDatasource = new source.DataSource(coneDatasourceId);
        this.map.sources.add(coneDatasource);

        let coneSymbolLayer = this.createConeIconSymbolLayer(coneDatasource, coneIconSymbolLayerId);
        let selectedConeSymbolLayer = this.createSelectedConeIconSymbolLayer(coneDatasource, selectedConeIconSymbolLayerId);

        this.map.layers.add([coneSymbolLayer, selectedConeSymbolLayer]);

        this.map.events.add('mouseover', coneSymbolLayer, this.handleLayerMouseover);
        this.map.events.add('mouseout', coneSymbolLayer, this.handleLayerMouseout);

        return coneDatasource;
    }

    createAreaRoadLayer = (datasource: source.DataSource, layerId: string): layer.LineLayer => {
        return new layer.LineLayer(datasource, layerId, {
            strokeColor: ['case', ['has', 'strokeColor'], ['get', 'strokeColor'], transparentColor],
            strokeWidth: ['case', ['has', 'strokeWidth'], ['get', 'strokeWidth'], 0],
            lineJoin: 'round', lineCap: 'round'
        });
    }

    createHelmetIconSymbolLayer = (datasource: source.DataSource, id: string): layer.SymbolLayer => {
        let symbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, id, {
            iconOptions: {
                image: 'helmetIcon',
                anchor: 'center',
                ignorePlacement: true,
                allowOverlap: true,
                rotationAlignment: 'map'
            }
        });

        this.map.imageSprite.add('helmetIcon', securityHelmetIcon);

        return symbolLayer;
    }

    createConeIconSymbolLayer = (datasource: source.DataSource, id: string): layer.SymbolLayer => {
        let symbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, id, {
            iconOptions: {
                image: ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workDoneArea], 'workDoneAreaConeIcon',
                    ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workDoneMultiArea], 'workDoneMultiAreasConeIcon',
                        ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoArea], 'workToDoAreaConeIcon',
                            ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoMultiArea], 'workToDoMultiAreasConeIcon',
                                ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoAndWorkDoneMultiArea], 'workDoneAndToDoMultiAreasConeIcon', '']
                            ]
                        ]
                    ]
                ],
                anchor: 'center',
                ignorePlacement: true,
                allowOverlap: true,
                rotationAlignment: 'map'
            },
            filter: ['all', ['has', 'isConeIcon'], ['==', ['get', 'visible'], true]],
            zOrder: 'source'
        });

        this.map.imageSprite.add('workDoneAreaConeIcon', workDoneAreaConeIcon);
        this.map.imageSprite.add('workDoneMultiAreasConeIcon', workDoneMultiAreasConeIcon);
        this.map.imageSprite.add('workToDoAreaConeIcon', workToDoAreaConeIcon);
        this.map.imageSprite.add('workToDoMultiAreasConeIcon', workToDoMultiAreasConeIcon);
        this.map.imageSprite.add('workDoneAndToDoMultiAreasConeIcon', workDoneAndToDoMultiAreasConeIcon);

        return symbolLayer;
    }

    createSelectedConeIconSymbolLayer = (datasource: source.DataSource, id: string): layer.SymbolLayer => {
        let symbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, id, {
            iconOptions: {
                image: ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workDoneArea], 'selectedWorkDoneAreaConeIcon',
                    ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workDoneMultiArea], 'selectedWorkDoneMultiAreasConeIcon',
                        ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoArea], 'selectedWorkToDoAreaConeIcon',
                            ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoMultiArea], 'selectedWorkToDoMultiAreasConeIcon',
                                ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoAndWorkDoneMultiArea], 'selectedWorkDoneAndToDoMultiAreasConeIcon', '']
                            ]
                        ]
                    ]
                ],
                anchor: 'center',
                ignorePlacement: true,
                allowOverlap: true,
                rotationAlignment: 'map'
            },
            filter: ['all', ['has', 'isSelectedConeIcon'], ['==', ['get', 'visible'], true]],
            zOrder: 'source'
        });

        this.map.imageSprite.add('selectedWorkDoneAreaConeIcon', selectedWorkDoneAreaConeIcon);
        this.map.imageSprite.add('selectedWorkDoneMultiAreasConeIcon', selectedWorkDoneMultiAreasConeIcon);
        this.map.imageSprite.add('selectedWorkToDoAreaConeIcon', selectedWorkToDoAreaConeIcon);
        this.map.imageSprite.add('selectedWorkToDoMultiAreasConeIcon', selectedWorkToDoMultiAreasConeIcon);
        this.map.imageSprite.add('selectedWorkDoneAndToDoMultiAreasConeIcon', selectedWorkDoneAndToDoMultiAreasConeIcon);

        return symbolLayer;
    }

    createAddressIconSymbolLayer = (datasource: source.DataSource, id: string): layer.SymbolLayer => {
        let symbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, id, {
            iconOptions: {
                image: 'addressIcon',
                anchor: 'bottom',
                ignorePlacement: true,
                allowOverlap: true,
                rotationAlignment: 'map'
            }
        });

        this.map.imageSprite.add('addressIcon', pinIcon);

        return symbolLayer;
    }

    handleLayerMouseover = (): void => {
        RoadsConditionAndScenariosShared.setMapCursor(this.map, MapCursorMode.Pointer);
    }

    handleLayerMouseout = (): void => {
        RoadsConditionAndScenariosShared.setMapCursor(this.map, MapCursorMode.Auto);
    }

    handleMapMousedown = (e: MapMouseEvent): void => {
        let areaDatasource = this.map.sources.getById(areaDatasourceId) as source.DataSource;
        this.removeHelmetIconPinAndHideAreaEnlightenedShapes(areaDatasource);
        this.removeSelectedCone();

        let newState: RoadWorksViewState = { ...this.state };
        newState.isRoadWorksInformationDrawerOpened = false;

        let shapes = e.shapes.filter(x => 'getProperties' in x);
        let selectedMaintenanceAreas: Map<number, AreaShapeProperties> = new Map<number, AreaShapeProperties>();

        if (this.hasConeShape(shapes)) {
            let shapeIndex = 0;
            let clickedShape: Shape = null;
            let clickedShapeProps = null;

            for (let i = 0; i < shapes.length; i++) {
                let shape = shapes[i] as Shape;
                let props = shape.getProperties();
                if (props.isConeIcon) {
                    if (shapeIndex <= props.index) {
                        shapeIndex = props.index;
                        clickedShape = shape;
                        clickedShapeProps = props;
                    }
                }
            }

            clickedShapeProps.areas.forEach((area: AreaShapeProperties) => {
                let maintenanceAreaId = area.maintenanceAreaId;
                if (!selectedMaintenanceAreas.has(maintenanceAreaId)) {
                    area.isEnlightened = true;
                    selectedMaintenanceAreas.set(maintenanceAreaId, area);
                }
            });

            let coneDatasource = this.map.sources.getById(coneDatasourceId) as source.DataSource;

            if (clickedShape && clickedShapeProps) {
                clickedShapeProps.visible = false;
                clickedShape.setProperties(clickedShapeProps);
                this.masquedConeShape = clickedShape;
            }

            let selectedShapeId = this.getSelectedConeShapeId(shapeIndex);
            let selectedShape = coneDatasource.getShapeById(selectedShapeId);
            if (selectedShape) {
                let selectedShapeProps = selectedShape.getProperties();
                selectedShapeProps.visible = true;
                selectedShape.setProperties(selectedShapeProps);
                this.selectedConeShape = selectedShape;
            }
        }
        else if (this.hasAreaShapes(shapes)) {
            let areaShapeIndex = 0;
            for (let i = 0; i < shapes.length; i++) {
                let shape = shapes[i] as Shape;
                let props = shape.getProperties();
                let areaProps = props.areaProps as AreaShapeProperties;
                if (props.EntityType === ShapeEntityType.area && areaProps.isFiltered === true) {
                    let coordinates = shape.getCoordinates() as data.Position[];
                    if (areaShapeIndex === 0) {
                        let midPoint = MathExtensions.interpolateMidPoint(coordinates);
                        this.createHelmetIconPin(midPoint);
                    }

                    let maintenanceAreaId = areaProps.maintenanceAreaId;
                    if (!selectedMaintenanceAreas.has(maintenanceAreaId)) {
                        areaProps.isEnlightened = true;
                        selectedMaintenanceAreas.set(maintenanceAreaId, areaProps);
                    }

                    areaShapeIndex += 1;
                }
            }
        }

        if (selectedMaintenanceAreas.size > 0) {
            selectedMaintenanceAreas.forEach((shapeProperties: AreaShapeProperties) => {
                let maintenanceArea = this.maintenanceAreas.get(shapeProperties.maintenanceAreaId);
                maintenanceArea.sections.forEach((section: MaintenanceAreaSection) => {
                    let shapeId = this.getEnlightenedAreaShapeId(section.roadSectionId, maintenanceArea.maintenanceAreaId);
                    RoadsConditionAndScenariosShared.showAreaEnlightenedShape(shapeId, areaDatasource);
                    this.areaEnlightenedShapeIds.add(shapeId);
                });
            });

            newState.selectedMaintenanceAreas = selectedMaintenanceAreas;
            newState.isRoadWorksInformationDrawerOpened = true;
        }

        this.setState(newState);
    }

    hasAreaShapes = (shapes: Array<data.Feature<data.Geometry, any> | Shape>): boolean => {
        let isAreaEntityShape = false;
        for (let s of shapes) {
            let shape = s as Shape;
            let props = shape.getProperties();
            if (props && (props.EntityType === ShapeEntityType.area || props.EntityType === ShapeEntityType.areaEnlightened)) {
                isAreaEntityShape = true;
                break;
            }
        }

        return isAreaEntityShape;
    }

    hasConeShape = (shapes: Array<data.Feature<data.Geometry, any> | Shape>): boolean => {
        let isAreaConeEntityShape = false;
        for (let s of shapes) {
            let shape = s as Shape;
            let props = shape.getProperties();
            if (props && props.isConeIcon) {
                isAreaConeEntityShape = true;
                break;
            }
        }

        return isAreaConeEntityShape;
    }

    createHelmetIconPin = (coordinates: data.Position): void => {
        let datasource = this.map.sources.getById(helmetDatasourceId) as source.DataSource;
        let pin = new data.Feature(new data.Point(coordinates), { EntityType: helmetEntityType }, helmetId);
        datasource.add(pin);
    }

    removeHelmetIconPin = (): void => {
        let datasource = this.map.sources.getById(helmetDatasourceId) as source.DataSource;
        let shape = datasource.getShapeById(helmetId);
        if (shape) {
            datasource.remove(shape);
        }
    }

    createAddressIconPin = (coordinates: data.Position, datasource: source.DataSource): void => {
        let pin = new data.Feature(new data.Point(coordinates), { EntityType: addressEntityType }, addressId);
        datasource.add(pin);
    }

    removeAddressIconPin = (datasource: source.DataSource): void => {
        let shape = datasource.getShapeById(addressId);
        if (shape) {
            datasource.remove(shape);
        }
    }

    handleZoomend = (): void => {
        let zoom = this.map.getCamera().zoom;

        if (zoom >= 16) {
            this.displayAreaRoadLayer(this.map, true);
            this.displayConeIconSymbolLayer(this.map, false);
        }
        else {
            this.displayAreaRoadLayer(this.map, false);
            this.displayConeIconSymbolLayer(this.map, true);
        }
    }

    displayAreaRoadLayer = (map: AzureMap, isVisible: boolean): void => {
        let areaRoadLayer = map.layers.getLayerById(areaRoadLayerId) as layer.LineLayer;
        if (areaRoadLayer) {
            let options = areaRoadLayer.getOptions();

            if (options.visible !== isVisible) {
                options.visible = isVisible;
                areaRoadLayer.setOptions(options);
            }
        }
    }

    displayConeIconSymbolLayer = (map: AzureMap, isVisible: boolean): void => {
        let conIconLayer = map.layers.getLayerById(coneIconSymbolLayerId) as layer.SymbolLayer;
        if (conIconLayer) {
            let options = conIconLayer.getOptions();

            if (options.visible !== isVisible) {
                options.visible = isVisible;
                conIconLayer.setOptions(options);
            }
        }
    }

    setMapZoom = (map: AzureMap, mergedProject: MergedProjectVersion): void => {
        let options: CameraBoundsOptions & AnimationOptions = {
            bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
            padding: 20,
            type: cameraAnimationType,
            duration: cameraAnimationDuration
        };

        map.setCamera(options);
    }

    handleMapStyleChange = (map) => (mapChoice: CustomMapChoice) => Utilities.ensureCustomMapChoice(map, mapChoice);

    handleMapFilterButtonClicked = (): void => {
        this.setState({
            isMapFiltersDialogDisplayed: true
        });
    }

    handleCloseMapFiltersDialog = (state: RoadWorksViewState): void => {
        let yearsValue: SelectOptionModel[] = [];
        state.workZonesFilters.years.forEach((x) => {
            yearsValue.push({ label: x ? `${x}` : 'n/a', value: x });
        });

        let scenarioNameValues: SelectOptionModel[] = [];
        state.workZonesFilters.scenarioNames.forEach((x) => {
            scenarioNameValues.push({ label: x, value: x });
        });

        this.setState({
            isMapFiltersDialogDisplayed: false,
            activeStatusOfWorkZones: state.workZonesFilters.activeStatusOfWorkZones,
            yearValues: yearsValue,
            scenarioNameValues: scenarioNameValues
        });
    }

    handleDisplaySectionsFromFilters = (activeRoadsConditions: Map<string, boolean>, activeAnomalies: Set<string>, isGroupView: boolean, state: RoadWorksViewState): void => {

        let datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        RoadsConditionAndScenariosShared.updateSectionShapesColor(datasource, activeRoadsConditions);

        let years = new Set<number>();
        state.yearValues.forEach((y) => {
            let value = y.value as number;
            years.add(value);
        });

        let scenarioNames = new Set<string>();
        state.scenarioNameValues.forEach((s) => {
            let value = s.value as string;
            scenarioNames.add(value);
        });

        let areasDico: Map<number, MaintenanceAreaExtended> = new Map<number, MaintenanceAreaExtended>();

        let areaDatasource = this.map.sources.getById(areaDatasourceId) as source.DataSource;
        areaDatasource.getShapes().forEach((shape: Shape) => {
            let properties = shape.getProperties();
            let entityType = properties.EntityType;

            if (entityType === ShapeEntityType.area) {
                let areaProps: AreaShapeProperties = properties.areaProps;
                let areaStatus = areaProps.status;

                let condition = true;
                if (condition && years.size > 0) {
                    condition = condition && years.has(areaProps.year);
                }

                if (condition && scenarioNames.size > 0) {
                    condition = condition && scenarioNames.has(areaProps.scenarioLabel);
                }

                if (condition && state.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workToDo) && !state.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workDone)) {
                    condition = condition && areaStatus === MaintenanceAreaStatus.workToDo;
                }

                if (condition && state.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workDone) && !state.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workToDo)) {
                    condition = condition && areaStatus === MaintenanceAreaStatus.workDone;
                }

                if (condition && state.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workDone) && state.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workToDo)) {
                    condition = condition && (areaStatus === MaintenanceAreaStatus.workDone || areaStatus === MaintenanceAreaStatus.workToDo);
                }

                if (condition && !state.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workDone) && !state.activeStatusOfWorkZones.has(MaintenanceAreaStatus.workToDo)) {
                    condition = condition && areaStatus === null;
                }

                if (condition) {
                    if (!this.isShapeVisible(properties)) {
                        areaProps.isFiltered = true;
                        this.showShape(shape, areaProps.hexColor, properties);
                    }

                    let area = this.maintenanceAreas.get(areaProps.maintenanceAreaId);
                    if (!areasDico.has(area.maintenanceAreaId)) {
                        areasDico.set(area.maintenanceAreaId, area);
                    }
                }
                else {
                    if (this.isShapeVisible(properties)) {
                        areaProps.isFiltered = false;
                        this.hideShape(shape, properties);
                    }
                }
            }
        });

        let coneDatasource = this.map.sources.getById(coneDatasourceId) as source.DataSource;

        if (!isEqual(state.activeAnomalies, activeAnomalies) || isGroupView !== state.isGroupView) {
            let hasAnomaliesLayerMapEvent = false;
            let anomaliesDatasource = RoadsConditionAndScenariosShared.recreateAnomaliesDatasource(isGroupView, this.map, state.mergedProject, hasAnomaliesLayerMapEvent);

            if (activeAnomalies.size > 0) {
                state.mergedProject.roadsSections.forEach((section) => {
                    let scoringParameters = section.roadExtended?.scoringParameters;
                    let anomalies = RoadsConditionAndScenariosShared.getSectionVisibleAnomalies(section, scoringParameters, activeAnomalies);

                    if (anomalies.size >= 1) {
                        let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(section, anomalies);
                        anomaliesDatasource.add(anomalyPoint);
                    }
                });

                let existingConeIconSymbolLayer = this.map.layers.getLayerById(coneIconSymbolLayerId) as layer.SymbolLayer;
                if (existingConeIconSymbolLayer) {
                    this.map.layers.remove(existingConeIconSymbolLayer);
                    let coneSymbolLayer = this.createConeIconSymbolLayer(coneDatasource, coneIconSymbolLayerId);
                    this.map.layers.add(coneSymbolLayer);
                }

                let existingSelectedConeIconSymbolLayer = this.map.layers.getLayerById(selectedConeIconSymbolLayerId) as layer.SymbolLayer;
                if (existingSelectedConeIconSymbolLayer) {
                    this.map.layers.remove(existingSelectedConeIconSymbolLayer);
                    let selectedConeSymbolLayer = this.createSelectedConeIconSymbolLayer(coneDatasource, selectedConeIconSymbolLayerId);
                    this.map.layers.add(selectedConeSymbolLayer);
                }

                let existingAddressIconSymbolLayer = this.map.layers.getLayerById(addressIconSymbolLayerId) as layer.SymbolLayer;
                if (existingAddressIconSymbolLayer) {
                    this.map.layers.remove(existingAddressIconSymbolLayer);
                    let addressDatasource = this.map.sources.getById(addressDatasourceId) as source.DataSource;
                    let addressIconSymbolLayer = this.createAddressIconSymbolLayer(addressDatasource, addressIconSymbolLayerId);
                    this.map.layers.add(addressIconSymbolLayer);
                }

                //si l'icone casque est affichée, on la supprime et on la recrée pour qu'elle soit toujours positionnée au dessus
                let existingHelmetIconLayer = this.map.layers.getLayerById(helmetIconSymbolLayerId) as layer.SymbolLayer;
                if (existingHelmetIconLayer) {
                    this.map.layers.remove(existingHelmetIconLayer);
                    let helmetDatasource = this.map.sources.getById(helmetDatasourceId) as source.DataSource;
                    let helmetSymbolLayer = this.createHelmetIconSymbolLayer(helmetDatasource, helmetIconSymbolLayerId);
                    this.map.layers.add(helmetSymbolLayer);
                }

                this.handleZoomend();
            }
        }

        let newState: RoadWorksViewState = { ...state };
        if (!isEqual(state.workZonesFilters.activeStatusOfWorkZones, state.activeStatusOfWorkZones) ||
            !isEqual(state.workZonesFilters.scenarioNames, scenarioNames) ||
            !isEqual(state.workZonesFilters.years, years)) {
            newState.selectedMaintenanceAreas = new Map<number, AreaShapeProperties>();
            newState.isRoadWorksInformationDrawerOpened = false;

            this.removeHelmetIconPinAndHideAreaEnlightenedShapes(areaDatasource);
            this.removeConePins(coneDatasource);

            let areaGroupings = this.createMaintenanceAreasGroupings(areasDico);

            this.createConePins(areaGroupings, coneDatasource);
        }

        newState.activeRoadsConditions = activeRoadsConditions;
        newState.activeAnomalies = activeAnomalies;
        newState.isGroupView = isGroupView;
        newState.workZonesFilters = {
            activeStatusOfWorkZones: state.activeStatusOfWorkZones,
            years: years,
            scenarioNames: scenarioNames
        };
        newState.isMapFiltersDialogDisplayed = false;

        this.setState(newState);
    }

    handleBtnStatusOfWorkClicked = (StatusOfWork: string, isActive: boolean, state: RoadWorksViewState): void => {
        let activeStatusOfWorkZonesSet = new Set(state.activeStatusOfWorkZones);
        if (isActive) {
            activeStatusOfWorkZonesSet.delete(StatusOfWork);
        }
        else {
            activeStatusOfWorkZonesSet.add(StatusOfWork);
        }
        this.setState({
            activeStatusOfWorkZones: activeStatusOfWorkZonesSet
        });
    }

    handleYearSelected = (e: SelectOptionModel[]): void => {
        this.setState({
            yearValues: e
        });
    }

    handleScenarioNameSelected = (e: SelectOptionModel[]): void => {
        this.setState({
            scenarioNameValues: e
        });
    }

    //TODO voir option avec conf par defaut dans le composant
    isDefaultMapFiltersActive = (activeRoadsConditions: Map<string, boolean>, activeAnomalies: Set<string>, workZonesFilters: WorkZonesFiltersModel): boolean => {
        let allRoadsConditionsAreNotActive = true;
        for (let isActive of activeRoadsConditions.values()) {
            if (isActive) {
                allRoadsConditionsAreNotActive = false;
                break;
            }
        }

        let isDefaultWorkZonesFiltersActive = true;
        if (workZonesFilters) {
            isDefaultWorkZonesFiltersActive = workZonesFilters.activeStatusOfWorkZones.size === 2 &&
                workZonesFilters.years.size === 0 &&
                workZonesFilters.scenarioNames.size === 0;
        }

        return allRoadsConditionsAreNotActive && activeAnomalies.size === 0 && isDefaultWorkZonesFiltersActive;
    }

    handleRoadWorksInformationDrawerClosed = (): void => {
        let areaDatasource = this.map.sources.getById(areaDatasourceId) as source.DataSource;
        this.removeHelmetIconPinAndHideAreaEnlightenedShapes(areaDatasource);
        this.removeSelectedCone();

        this.setState({
            isRoadWorksInformationDrawerOpened: false,
            selectedMaintenanceAreas: new Map<number, AreaShapeProperties>()
        });
    }

    handleAreaEnlightenmentChange = (maintenanceAreaId: number, state: RoadWorksViewState): void => {
        let selectedMaintenanceAreas = state.selectedMaintenanceAreas;
        let areaShapeProperties = selectedMaintenanceAreas.get(maintenanceAreaId);

        let maintenanceArea = this.maintenanceAreas.get(maintenanceAreaId);
        areaShapeProperties.isEnlightened = !areaShapeProperties.isEnlightened;

        selectedMaintenanceAreas.set(maintenanceAreaId, areaShapeProperties);
        this.setState({
            selectedMaintenanceAreas: selectedMaintenanceAreas
        });

        let areaDatasource = this.map.sources.getById(areaDatasourceId) as source.DataSource;
        maintenanceArea.sections.forEach((section: MaintenanceAreaSection) => {
            let shapeId = this.getEnlightenedAreaShapeId(section.roadSectionId, maintenanceAreaId);
            if (areaShapeProperties.isEnlightened) {
                RoadsConditionAndScenariosShared.showAreaEnlightenedShape(shapeId, areaDatasource);
                this.areaEnlightenedShapeIds.add(shapeId);
            }
            else {
                RoadsConditionAndScenariosShared.hideAreaEnlightenedShape(shapeId, areaDatasource);
                this.areaEnlightenedShapeIds.delete(shapeId);
            }
        });
    }

    removeHelmetIconPinAndHideAreaEnlightenedShapes = (datasource: source.DataSource): void => {
        this.removeHelmetIconPin();
        this.handleHideAreaEnlightenedShapes(datasource);
        this.areaEnlightenedShapeIds = new Set<string>();
    }

    removeSelectedCone = (): void => {
        if (this.masquedConeShape) {
            let masquedConeShape = this.masquedConeShape;
            let masquedProps = masquedConeShape.getProperties();
            masquedProps.visible = true;
            masquedConeShape.setProperties(masquedProps);
        }

        if (this.selectedConeShape) {
            let shapeSelected = this.selectedConeShape;
            let selectedShapeProps = shapeSelected.getProperties();
            selectedShapeProps.visible = false;
            shapeSelected.setProperties(selectedShapeProps);
        }
    }

    removeConePins = (datasource: source.DataSource): void => {
        this.conePinIds.forEach((coneId: string) => {
            let shape = datasource.getShapeById(coneId);
            if (shape) {
                datasource.remove(shape);
            }
        });
    }

    handleHideAreaEnlightenedShapes = (datasource: source.DataSource): void => {
        this.areaEnlightenedShapeIds.forEach((shapeId) => {
            RoadsConditionAndScenariosShared.hideAreaEnlightenedShape(shapeId, datasource);
        });
    }

    loadInputAddressesOptions = debounce((inputValue: string, callback): void => {
        this.loadOptions(inputValue, callback);
    }, 500);

    loadOptions = (inputValue: string, callback): void => {
        if (inputValue.trim().length > 2) {
            this.setState({ isInputAddressLoading: true });

            MapsService.SearchAddress(inputValue)
                .then((res: Address[]) => {
                    let listAddress: AddressSelectItem[] = [{ label: "", value: "", latitude: null, longitude: null }];
                    res.forEach(x => {
                        const address: AddressSelectItem = {
                            value: x.id,
                            label: x.freeformAddress,
                            latitude: x.position.latitude,
                            longitude: x.position.longitude
                        };

                        listAddress.push(address);
                    });

                    callback(listAddress);
                    this.setState({ isInputAddressLoading: false });
                });
        }
    }

    onFreeFormAddressFocus = (inputAddress): void => {
        this.setState({ inputAddressValue: inputAddress ?? '' });
    }

    handleChangeAddress = (e: AddressSelectItem): void => {
        let datasource = this.map.sources.getById(addressDatasourceId) as source.DataSource;
        this.removeAddressIconPin(datasource);

        if (e) {
            this.setState({ inputAddressValue: e.label, inputAddress: e.label });

            if (e.value) {
                let position = new data.Position(e.longitude, e.latitude);
                this.createAddressIconPin(position, datasource);

                this.map.setCamera({
                    zoom: 16,
                    center: position,
                    type: cameraAnimationType,
                    duration: cameraAnimationDuration
                });
            }
        }
        else {
            this.setState({ inputAddressValue: "", inputAddress: "" });
        }
    }

    handleInputAddressChange = (value: string, inputActionMeta: InputActionMeta): void => {
        if (inputActionMeta.action === "input-change") {
            this.setState({ inputAddressValue: value, inputAddress: value });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.map?.dispose();
    }

    render() {
        const state = this.state;

        const mapFilterButtonClassName = `map-filter-button ${state.isMapFiltersDialogDisplayed || !this.isDefaultMapFiltersActive(state.activeRoadsConditions, state.activeAnomalies, state.workZonesFilters) ? 'selected' : ''}`;

        const scaleLoader: JSX.Element = (
            <Box display="flex" flexDirection="column" alignItems="center" className="loader">
                <ScaleLoader width={5} height={20} radius={50} color="#000000" loading={state.loading} />
            </Box>
        );

        return (
            <Box className="road-works">
                {state.loading ? scaleLoader : ''}
                <Box display="flex" flexDirection="column" className="content">
                    <Box display="flex" flexDirection="row" alignItems="center" className="header">
                        <img src={iconArrow} alt="icon arrow" />
                        <div className="project-label">{this.projectLabel}</div>
                        <Box className="search-input">
                            <AsyncSelect
                                value={state.inputAddress ? { label: state.inputAddress, value: state.inputAddress } : null}
                                inputValue={state.inputAddressValue}
                                className="search-address"
                                loadOptions={this.loadInputAddressesOptions}
                                onFocus={() => this.onFreeFormAddressFocus(state.inputAddress)}
                                onChange={(e) => this.handleChangeAddress(e as AddressSelectItem)}
                                onInputChange={(value, inputActionMeta) => this.handleInputAddressChange(value, inputActionMeta)}
                                blurInputOnSelect={true}
                                openMenuOnClick={false}
                                isClearable={true}
                                placeholder={Translate.Resources.UI_RoadWorksView_InputSearchAddress_PlaceHolder}
                                isLoading={state.isInputAddressLoading}
                            />
                        </Box>
                    </Box>
                    <div id="roadWorks-map"></div>
                </Box>
                <MapStyleSelector
                    handleMapStyleChange={this.handleMapStyleChange(this.map)}
                />
                {!state.loading &&
                    <>
                        <IconButton
                            className={mapFilterButtonClassName}
                            onClick={this.handleMapFilterButtonClicked}
                        >
                            <TuneIcon />
                        </IconButton>
                        {state.isMapFiltersDialogDisplayed && (
                            <MapFiltersComponent
                                activeRoadsConditions={state.activeRoadsConditions}
                                activeAnomalies={state.activeAnomalies}
                                isGroupView={state.isGroupView}
                                trustedAnomaliesEnteringScore={state?.mergedProject?.trustedAnomaliesEnteringScore}
                                trustedAnomaliesOther={state?.mergedProject?.trustedAnomaliesOther}
                                additionalSection={{
                                    title: Translate.Resources.UI_MapFilters_Accordion_Title_StatusOfWorkZones,
                                    component: <WorkZonesFilterSectionComponent
                                        projectScenarios={state.projectScenarios}
                                        yearValues={state.yearValues}
                                        scenarioNameValues={state.scenarioNameValues}
                                        activeStatusOfWorkZones={state.activeStatusOfWorkZones}
                                        handleBtnStatusOfWorkClicked={(statusOfWork: string, isActive: boolean) => this.handleBtnStatusOfWorkClicked(statusOfWork, isActive, state)}
                                        handleYearSelected={this.handleYearSelected}
                                        handleScenarioNameSelected={this.handleScenarioNameSelected}
                                    />
                                }}
                                handleCloseMapFiltersDialog={() => this.handleCloseMapFiltersDialog(this.state)}
                                handleDisplaySectionsFromFilters={(activeRoadsConditions: Map<string, boolean>, activeAnomalies: Set<string>, isGroupView: boolean) => this.handleDisplaySectionsFromFilters(activeRoadsConditions, activeAnomalies, isGroupView, state)}
                            />
                        )}
                    </>
                }
                {state.isRoadWorksInformationDrawerOpened &&
                    <RoadWorksInformationDrawer
                        selectedMaintenanceAreas={state.selectedMaintenanceAreas}
                        handleClose={this.handleRoadWorksInformationDrawerClosed}
                        handleAreaEnlightenmentChange={(maintenanceAreaId: number) => this.handleAreaEnlightenmentChange(maintenanceAreaId, state)}
                    />
                }
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(RoadWorksView));
