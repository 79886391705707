import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from 'react';
import Translate from "../../../localization/Localization";
import { MaintenanceAreaExtended } from "../models/MaintenanceAreaExtended";
import { MaintenanceAreaStatus } from "../services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceAreaStatus";

interface CustomAreaStatusCellProps {
    handleUpdateAreaStatus: (dataItem: MaintenanceAreaExtended, status: MaintenanceAreaStatus) => void
}

export const CustomAreaStatusCell = (props: GridCellProps & CustomAreaStatusCellProps): JSX.Element => {

    const areaStatusList: { text: string, value: MaintenanceAreaStatus }[] = [
        { text: Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Status_Draft, value: MaintenanceAreaStatus.draft },
        { text: Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Status_WorkToDo, value: MaintenanceAreaStatus.workToDo },
        { text: Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_Status_WorkDone, value: MaintenanceAreaStatus.workDone },
    ];

    const handleChange = (e: DropDownListChangeEvent): void => {
        let selectedElement: { text: string, value: MaintenanceAreaStatus } = e.value;
        props.handleUpdateAreaStatus(props.dataItem, selectedElement.value);
    }

    const defaultRendering = (
        <td>
            <DropDownList
                className="status-select"
                onChange={handleChange}
                value={areaStatusList.find((c) => c.value === props.dataItem.status)}
                data={areaStatusList}
                textField="text"
            />
        </td >
    );

    return defaultRendering;
}