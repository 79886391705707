import styles from '../../_variables.scss';

var _labelScoreGridHeightGapFromWindow: number = null;
var _labelScoreGridHeightGapFromWindowWhenClientView: number = null;
var _labelScoreGridHeightGapFromWindowWhenScenarioView: number = null;
var _scenariosGridOffsetFromWindowTop: number = null;
var _scenarioAreasGridOffsetFromWindowTop: number = null;

export class SizingUtilities {

    //40 is the row height defined in prop of the kendo grid
    public static rowHeight: number = 56;

    // labelScoreGridOffsetFromWindowTop is the height of elements above grid.
    public static labelScoreGridHeightGapFromWindow = (isScenarioView: boolean, role: string) => {
        if (isScenarioView) {
            if (!_labelScoreGridHeightGapFromWindowWhenScenarioView) {
                _labelScoreGridHeightGapFromWindowWhenScenarioView = parseInt(styles.scenarioLabelScoreGridHeightGapFromWindow);
            }

            return _labelScoreGridHeightGapFromWindowWhenScenarioView;
        }

        if (role === 'CLI') {
            if (!_labelScoreGridHeightGapFromWindowWhenClientView) {
                _labelScoreGridHeightGapFromWindowWhenClientView = parseInt(styles.clientLabelScoreGridHeightGapFromWindow);
            }

            return _labelScoreGridHeightGapFromWindowWhenClientView;
        }

        if (!_labelScoreGridHeightGapFromWindow) {
            _labelScoreGridHeightGapFromWindow = parseInt(styles.labelScoreGridHeightGapFromWindow);
        }

        return _labelScoreGridHeightGapFromWindow;
    }

    // scenariosGridOffsetFromWindowTop is the height of elements above grid.
    public static scenariosGridOffsetFromWindowTop = () => {
        if (!_scenariosGridOffsetFromWindowTop) {
            _scenariosGridOffsetFromWindowTop = parseInt(styles.scenariosGridOffset);
        }

        return _scenariosGridOffsetFromWindowTop;
    }

    public static scenarioAreasGridOffsetFromWindowTop = () => {
        if (!_scenarioAreasGridOffsetFromWindowTop) {
            _scenarioAreasGridOffsetFromWindowTop = parseInt(styles.scenarioAreasGridOffset);
        }

        return _scenarioAreasGridOffsetFromWindowTop;
    }

    // window.innerHeight get the hight of page from navigator size
    public static computeGridHeight = (gridHeightGapFromWindow: number) => {
        return window.innerHeight - gridHeightGapFromWindow;
    }

    // rowHeight is the row height defined in prop of the kendo grid
    public static computeGridPageSize = (gridHeight: number, rowHeight: number) => {
        return Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
    }

}