import { Box, Button, Dialog, DialogContent } from '@mui/material';
import React from 'react';
import Translate from '../../../localization/Localization';
import './ConfirmUpdateAreaSectionsDialogStyles.scss';

interface ConfirmUpdateAreaSectionsDialogProps {
    isOpen: boolean,
    handleCanceled: () => void,
    handleConfirmed: () => void
}

export const ConfirmUpdateAreaSectionsDialog = (props: ConfirmUpdateAreaSectionsDialogProps): JSX.Element => {
    return (
        <Dialog
            disableEscapeKeyDown
            aria-modal="false"
            open={props.isOpen}
            onClose={props.handleCanceled}
            className="confirm-update-area-sections-dialog"
        >
            <DialogContent id="confirm-update-area-sections-dialog-content">
                <Box display="flex" flexDirection="column" justifyContent="space-around" height="100%">
                    <Box className="text">{Translate.Resources.UI_MaintenanceSenarios_ConfirmUpdateSectionsAreaDialog_Message}</Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Button className="btn-no" onClick={props.handleCanceled}>{Translate.Resources.UI_MaintenanceSenarios_ConfirmUpdateSectionsAreaDialog_No}</Button>
                        <Button className="btn-yes" onClick={props.handleConfirmed}>{Translate.Resources.UI_MaintenanceSenarios_ConfirmUpdateSectionsAreaDialog_Yes}</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
