import { useMatomo } from '@datapunt/matomo-tracker-react';
import { faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Paper, PaperProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import allProjectsVersionsIcon from 'src/assets/icons/icon-all-projects-versions.svg';
import helpIcon from 'src/assets/icons/icon-help.svg';
import userIcon from 'src/assets/icons/icon-user.svg';
import Translate from '../localization/Localization';
import AllProjectsVersionsDialog from '../shared/components/AllProjectsVersionsDialog/AllProjectsVersionsDialog';
import { HelpDialogContentComponent } from '../shared/components/HelpDialogContentComponent/HelpDialogContentComponent';
import NavMenu from '../shared/components/NavMenu/NavMenu';
import { UserDialog } from '../shared/components/UserDialog/UserDialog';
import { MeasurementSystemType } from '../shared/models/MeasurementSystemType';
import { UserModel } from '../shared/User/services/dataContracts/queryStack/UserModel';
import { ProjectVersion } from './Home/services/dataContracts/queryStack/ProjectVersion';
import './Layout.scss';

const PaperComponent = (props: PaperProps): JSX.Element => {
    return (
        <Draggable handle="#help-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="parent">
            <Paper {...props} />
        </Draggable>
    );
}

interface LayoutProps {
    user: UserModel,
    projects: ProjectVersion[],
    children: JSX.Element | JSX.Element[],
    handleMeasurementSystemTypeChanged: (measurementSystemType: MeasurementSystemType) => void
}

interface HelpDialogModel {
    isDialogOpened: boolean,
    helpDialogContentComponent: JSX.Element
}

export const Layout = (props: LayoutProps): JSX.Element => {

    const location = useLocation();
    const { trackPageView } = useMatomo();

    const [helpDialog, setHelpDialog] = useState<HelpDialogModel>({ isDialogOpened: false, helpDialogContentComponent: <></> });
    const [isAllProjectsVersionsDialogOpen, setIsProjectsVersionsDialogOpen] = useState<boolean>(false);
    const [isUserDialogOpen, setIsUserDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        trackPageView({ href: location.pathname });
    }, [location.pathname]);

    const handleClickCloseHelpDialog = (): void => {
        setHelpDialog({
            isDialogOpened: false,
            helpDialogContentComponent: <></>
        });
    }

    const handleClickOpenHelpDialog = (): void => {
        setHelpDialog({
            isDialogOpened: true,
            helpDialogContentComponent: <HelpDialogContentComponent />
        });
    }

    const handleClickOpenProjectsVersionsDialog = (): void => setIsProjectsVersionsDialogOpen(true);
    const handleValidateProjectsVersionsDialog = (): void => setIsProjectsVersionsDialogOpen(false);
    const handleClickCancelProjectsVersionsDialog = (): void => setIsProjectsVersionsDialogOpen(false);

    const handleClickOpenUserDialog = (): void => setIsUserDialogOpen(true);
    const handleValidateUserDialog = (measurementSystemType: MeasurementSystemType): void => {
        setIsUserDialogOpen(false);
        props.handleMeasurementSystemTypeChanged(measurementSystemType);
    }
    const handleClickCancelUserDialog = (): void => setIsUserDialogOpen(false);

    return (
        <Container>
            <Box display="flex" flexDirection="row" width="100vw" height="100vh">
                <NavMenu role={props.user?.role} />
                {props.children}
            </Box>
            <div className="commmon-component">
                {props.user?.role === "ADM" &&
                    <IconButton className="header-icon" onClick={handleClickOpenProjectsVersionsDialog}>
                        <img src={allProjectsVersionsIcon} alt="all projects versions icon"/>
                    </IconButton>
                }
                <IconButton className="header-icon" onClick={handleClickOpenHelpDialog}>
                    <img src={helpIcon} alt="help icon" />
                </IconButton>
                <IconButton className="header-icon" onClick={handleClickOpenUserDialog}>
                    <img src={userIcon} alt="user icon" />
                </IconButton>
            </div>
            <Dialog
                disableEscapeKeyDown
                aria-modal="false"
                open={helpDialog.isDialogOpened}
                onClose={handleClickCloseHelpDialog}
                scroll="paper"
                className="help-dialog"
                PaperComponent={PaperComponent}
                aria-labelledby="help-dialog-title"
            >
                <DialogTitle id="help-dialog-title">
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box className="dialog-title">
                            <FontAwesomeIcon className="icon" icon={faQuestionCircle} />
                            {Translate.Resources.UI_HelpDialog_Title_Help}
                        </Box>
                        <Box>
                            <IconButton onClick={handleClickCloseHelpDialog}>
                                <FontAwesomeIcon icon={faTimes} size="1x" />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {helpDialog.helpDialogContentComponent}
                </DialogContent>
            </Dialog>
            {isAllProjectsVersionsDialogOpen &&
                <AllProjectsVersionsDialog {...props} projects={props.projects} onValidate={handleValidateProjectsVersionsDialog} isOpen={isAllProjectsVersionsDialogOpen} onCancel={handleClickCancelProjectsVersionsDialog} />
            }
            {isUserDialogOpen &&
                <UserDialog isOpen={isUserDialogOpen} user={props.user} onValidate={handleValidateUserDialog} onCancel={handleClickCancelUserDialog} />
            }
        </Container >
    );
}