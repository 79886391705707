import { GridCellProps } from "@progress/kendo-react-grid";
import React from 'react';
import iconConeBlue from 'src/assets/icons/icon-cone-blue.svg';
import iconConeGray from 'src/assets/icons/icon-cone-gray.svg';
import { RoadLabelScoreExtended } from "../models/RoadLabelScoreExtended";

export const CustomConeCell = (props: GridCellProps) => {
    var dataItem = props.dataItem as RoadLabelScoreExtended;
    var relatedAreas = dataItem.relatedAreas;
    var isIncludedInArea = relatedAreas.length > 0;
    var isDisplayedOnMap = relatedAreas.some(x => x.isDisplayedOnMap);

    var tooltip = "";
    if (relatedAreas.length === 1) {
        tooltip = `${relatedAreas[0].maintenanceAreaLabel} ${relatedAreas[0].typeOfWork ? ' - ' + relatedAreas[0].typeOfWork : ''}`;
    }
    else {
        relatedAreas.map((area) => {
            return (
                tooltip += `
${area.maintenanceAreaLabel} ${area.typeOfWork ? ' - ' + area.typeOfWork : ''}`
            );
        });
    }

    return (
        <td>
            {(isIncludedInArea ? (isDisplayedOnMap ? <img title={tooltip} src={iconConeBlue} alt="icon cone blue" /> : <img title={tooltip} src={iconConeGray} alt="icon cone gray" />) : <></>)}
        </td>
    );
}
