import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReplyIcon from '@mui/icons-material/Reply';
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Input, InputAdornment, Paper, PaperProps, TextField } from '@mui/material';
import React, { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import iconConeHeader from 'src/assets/icons/icon-cone-header.svg';
import Translate, { Localization } from '../../../localization/Localization';
import ToastService from '../../../ToastService';
import BusinessMessages from '../../../utils/BusinessMessages';
import { RecipientForScenarioSendingExtended } from '../models/RecipientForScenarioSendingExtended';
import { SendScenarioRequestArgs } from '../services/MaintenanceScenarios/dataContracts/controller/SendScenarioRequestArgs';
import { RecipientForScenarioSending } from '../services/MaintenanceScenarios/dataContracts/queryStack/RecipientForScenarioSending';
import { MaintenanceScenariosApiClient } from '../services/MaintenanceScenarios/MaintenanceScenariosApiClient';
import './RecipientsForScenarioSendingDialogStyles.scss';

interface RecipientsForScenarioSendingDialogProps {
    isOpen: boolean,
    scenarioLabel: string,
    userFullName: string,
    recipientsForScenarioSending: RecipientForScenarioSending[],
    maintenanceScenarioId: number,
    onClose: () => void
}

export const RecipientsForScenarioSendingDialog = (props: RecipientsForScenarioSendingDialogProps): JSX.Element => {

    const PaperComponent = (props: PaperProps): JSX.Element => {
        return (
            <Draggable handle="#recipients-for-scenario-sending-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="parent">
                <Paper {...props} />
            </Draggable>
        );
    }

    const Content = (): JSX.Element => {
        const [remarks, setRemarks] = useState<string>('');
        const [recipients, setRecipients] = useState<RecipientForScenarioSendingExtended[]>([]);
        const [isSendButtonEnabled, setIsSendButtonEnabled] = useState<boolean>(false);

        let inputSearchRef: MutableRefObject<HTMLInputElement> = useRef(null);

        useEffect(() => {
            var recipients: RecipientForScenarioSendingExtended[] = [];
            props.recipientsForScenarioSending.forEach((item) => {
                let recipient: RecipientForScenarioSendingExtended = {
                    ...item,
                    isSelected: false,
                    isVisible: true,
                    fullNameLowerWithoutDiacritics: item.fullName.toLowerCase().removeDiacritics()
                }
                recipients.push(recipient);
            });
            setRecipients(recipients);
        }, [props.recipientsForScenarioSending]);

        const handlelRecipientSelectionChanged = (e: ChangeEvent<HTMLInputElement>, email: string) => {
            var recipientsList = [...recipients];
            var item = recipientsList.find(x => x.email === email);
            item.isSelected = e.target.checked;
            setRecipients(recipientsList);
            setIsSendButtonEnabled(recipientsList.some(x => x.isSelected));
        }

        const handleSearchRecipientsChanged = (value: string): void => {
            var recipientsList = [...recipients];
            var searchText = value.trim().toLowerCase().removeDiacritics();
            if (searchText) {
                recipientsList.forEach((recipient) => {
                    if (recipient.fullNameLowerWithoutDiacritics.includes(searchText)) {
                        recipient.isVisible = true;
                    }
                    else {
                        recipient.isVisible = false;
                    }
                });
            }
            else {
                recipientsList.forEach((recipient) => {
                    recipient.isVisible = true;
                });
            }

            setRecipients(recipientsList);
            setIsSendButtonEnabled(recipientsList.some(x => x.isSelected));
        }

        const handleClearInputSearchText = (): void => {
            inputSearchRef.current.value = "";
            handleSearchRecipientsChanged('');
        }

        const handleMessageChanged = (value: string): void => {
            setRemarks(value);
        }

        const handleCloseRecipientsDialog = (): void => {
            recipients.forEach((item) => {
                item.isSelected = false;
            });
            props.onClose();
        }

        const handleSendScenarioClicked = (): void => {
            setIsSendButtonEnabled(false);

            var recipientUsersIds = recipients.filter(x => x.isSelected).map(x => x.userId);
            var requestArgs: SendScenarioRequestArgs = {
                maintenanceScenarioId: props.maintenanceScenarioId,
                recipientScenarioLabel: `${props.scenarioLabel} (${props.userFullName} - ${new Date().toLocaleString()})`,
                recipientUsersIds: recipientUsersIds,
                remarks: remarks,
                language: Localization.locale,
                ianaTimeZoneId: Localization.ianaTimeZoneId
            }

            MaintenanceScenariosApiClient.SendScenario(requestArgs)
                .then((res) => {
                    let data = res?.data;

                    let warnings = BusinessMessages.GetWarnings(data);
                    if (warnings.length > 0) {
                        ToastService.showWarningToast("", warnings);
                    }

                    let errors = BusinessMessages.GetErrors(data);
                    if (errors.length > 0) {
                        ToastService.showErrorToast("", errors);
                    }
                    else {
                        ToastService.showSuccessToast(Translate.Resources.UI_RecipientsForScenarioSendingDialog_ScenarioSending_SuccesMessage);
                        handleCloseRecipientsDialog();
                    }

                    setIsSendButtonEnabled(true);
                });
        }

        return (
            <Box display="flex" flexDirection="column">
                <Box className="search-input">
                    <Input disableUnderline className="input-search-recipients"
                        inputRef={inputSearchRef}
                        endAdornment={<>
                            <InputAdornment position="end" classes={{ root: 'input-icon-close-root' }}>
                                <FontAwesomeIcon icon={faTimes} onClick={() => handleClearInputSearchText()} />
                            </InputAdornment>
                            <InputAdornment position="end" classes={{ root: 'input-icon-search-root' }}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputAdornment>
                        </>}
                        id="input-search-recipients-text"
                        placeholder={Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_RecipientsDialog_FindAContact}
                        onChange={(event) => handleSearchRecipientsChanged(event.target.value)}
                    />
                </Box>
                <Box className="recipients-content">
                    {recipients.filter(x => x.isVisible).map((recipient, index) => {
                        return (
                            <Box key={`recipient-${index}`} display="flex" flexDirection="row" alignItems="center">
                                <Checkbox checked={recipient.isSelected} color="primary" onChange={(e) => handlelRecipientSelectionChanged(e, recipient.email)} />
                                <div className="name" key={`recipient-${index}`}>{recipient.fullName}</div>
                            </Box>
                        );
                    })}
                </Box>
                <Box display="flex" flexDirection="column">
                    <TextField
                        label={Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_RecipientsDialog_Message}
                        value={remarks}
                        margin="normal"
                        multiline
                        variant="outlined"
                        rows="4"
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => handleMessageChanged(e.target.value)}
                    />
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button className="btn_close" onClick={handleCloseRecipientsDialog}>
                        {Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_RecipientsDialog_Close}
                    </Button>
                    <Button className={`btn_send ${!isSendButtonEnabled ? 'disabled' : ''}`} onClick={handleSendScenarioClicked} disabled={!isSendButtonEnabled}>
                        <ReplyIcon className="reply-icon" />
                        {Translate.Resources.UI_MaintenanceSenarios_MyScenariosDrawer_RecipientsDialog_SendTheScenario}
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Dialog
            disableEscapeKeyDown
            aria-modal="false"
            open={props.isOpen}
            onClose={props.onClose}
            scroll="paper"
            className="recipients-for-scenario-sending-dialog"
            PaperComponent={PaperComponent}
            aria-labelledby="recipients-for-scenario-sending-dialog-title"
        >
            <DialogTitle id="recipients-for-scenario-sending-dialog-title">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="row" className="title">
                        <img src={iconConeHeader} alt="icon cone header" />
                        {props.scenarioLabel}
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent id="recipients-for-scenario-sending-dialog-content">
                <Content />
            </DialogContent>
        </Dialog>
    );
}