import { Button } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from 'react';
import { MaintenanceAreaExtended } from "../models/MaintenanceAreaExtended";

interface CustomDisplayAreaSectionsCellProperties {
    handleHideAreaSections: (dataItem: MaintenanceAreaExtended) => void,
    handleDisplayAreaSections: (dataItem: MaintenanceAreaExtended) => void
}

export const CustomDisplayAreaSections = (props: GridCellProps & CustomDisplayAreaSectionsCellProperties): JSX.Element => {
    if (props.dataItem.isEnlightened) {
        return (
            <td className="display-area-sections"><Button className="btn-hide-area-sections" onClick={() => props.handleHideAreaSections(props.dataItem)} /></td>
        );
    }
    else {
        return (
            <td className="display-area-sections"><Button className="btn-display-area-sections" onClick={() => props.handleDisplayAreaSections(props.dataItem)} /></td>
        );
    }
}