import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Paper, PaperProps, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import Translate from '../../../localization/Localization';
import './AddOrUpdateScenarioStyles.scss';

export enum Mode {
    Creation = 0,
    Editing = 1,
    duplicating = 2
}

interface AddOrUpdateScenarioDialogProps {
    mode: Mode,
    isOpen: boolean,
    currentScenarioLabel?: string,
    onCancel: () => void,
    handleValidate: (scenarioLabel: string) => Promise<void>
}

export const AddOrUpdateScenarioDialog = (props: AddOrUpdateScenarioDialogProps): JSX.Element => {

    const PaperComponent = (props: PaperProps): JSX.Element => {
        return (
            <Draggable handle="#add-Scenario-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="parent">
                <Paper {...props} />
            </Draggable>
        );
    }

    const Content = (): JSX.Element => {

        const [scenarioLabel, setScenarioLabel] = useState<string>("");
        const [disabledValidateButton, setDisabledValidateButton] = useState<boolean>(true);

        useEffect(() => {
            if (props.currentScenarioLabel) {
                setScenarioLabel(props.currentScenarioLabel);
                var disabledValidateButton = getValidateButtonStatus(props.currentScenarioLabel);
                setDisabledValidateButton(disabledValidateButton);
            }
        }, [props.currentScenarioLabel]);

        const getValidateButtonStatus = (label): boolean => {
            return !label.trim() || ((props.mode === Mode.Editing || props.mode === Mode.duplicating) && label.trim() === props.currentScenarioLabel.trim());
        }

        const handleScenarioLabelChanged = (value: string): void => {
            setScenarioLabel(value);
            var disabledValidateButton = getValidateButtonStatus(value);
            setDisabledValidateButton(disabledValidateButton);
        }

        const handleValidateClicked = async () => {
            setDisabledValidateButton(true);
            await props.handleValidate(scenarioLabel.trim());
        }

        return (
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column">
                    <div className="title">{Translate.Resources.UI_AddScenarioDialog_TextBox_Title_Name}</div>
                    <TextField
                        className="input-text"
                        hiddenLabel
                        size="small"
                        placeholder={props.mode === Mode.Creation ? Translate.Resources.UI_AddScenarioDialog_TextBox_Placeholder_FillInTheNameOfTheScenario :
                            (props.mode === Mode.Editing ? Translate.Resources.UI_AddScenarioDialog_TextBox_Placeholder_EnterTheNewNameOfTheScenario :
                                Translate.Resources.UI_AddScenarioDialog_TextBox_Placeholder_FillInTheNewNameOfTheScenario)}
                        value={scenarioLabel}
                        onChange={(e) => handleScenarioLabelChanged(e.target.value)}
                    />
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between" mt="25px">
                    <Button className="btn-cancel" onClick={props.onCancel}>
                        <ArrowBackIcon className="btn-icon" />
                        {Translate.Resources.UI_AddScenarioDialog_Button_Cancel}
                    </Button>
                    <Button className={`btn-validate ${disabledValidateButton ? 'disabled' : ''}`} onClick={handleValidateClicked} disabled={disabledValidateButton}>
                        <CheckIcon className="btn-icon" />
                        {Translate.Resources.UI_AddScenarioDialog_Button_Validate}
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Dialog
            disableEscapeKeyDown
            aria-modal="false"
            open={props.isOpen}
            onClose={props.onCancel}
            scroll="paper"
            className="add-Scenario-dialog"
            PaperComponent={PaperComponent}
            aria-labelledby="add-Scenario-dialog-title"
        >
            <DialogTitle id="add-Scenario-dialog-title">
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <div className="title">{props.mode === Mode.Creation ? Translate.Resources.UI_AddScenarioDialog_Title_NewScenario :
                        (props.mode === Mode.Editing ? Translate.Resources.UI_AddScenarioDialog_Title_RenameScenario :
                            Translate.Resources.UI_AddScenarioDialog_Title_DuplicateScenario)}
                    </div>
                    <IconButton onClick={props.onCancel}>
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent id="add-Scenario-dialog-content">
                <Content />
            </DialogContent>
        </Dialog>
    );
}