import { Box } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import iconConeGray from 'src/assets/icons/icon-cone-gray.svg';

interface CustomConeHeaderCellProperties extends GridHeaderCellProps {
    sort?: SortDescriptor[],
    sortingField: string,
    handleSortColumnChange?: (sortItems: SortDescriptor[]) => void,
}

export const CustomConeHeaderCell = (props: CustomConeHeaderCellProperties) => {
    let sortIconClass = "k-icon k-i-sort-not-sorted-sm";
    let sortIndex = -1;
    if (props.sort?.length > 0) {
        let currentSortIndex: number = props.sort.findIndex(x => x.field === props.sortingField);
        if (currentSortIndex !== -1) {
            sortIconClass = props.sort[currentSortIndex].dir === "asc" ? "k-icon k-i-sort-asc-sm" : "k-icon k-i-sort-desc-sm";
            sortIndex = currentSortIndex;
        }
    }

    const handleSortChange = () => {
        let sortItems: SortDescriptor[] = null;
        if (props.sort.length > 0 && props.sort[0].dir === 'asc' && props.sort[0].field === props.sortingField) {
            sortItems = [{ field: props.sortingField, dir: 'desc' }];
        } else if (props.sort.length > 0 && props.sort[0].dir === 'desc' && props.sort[0].field === props.sortingField) {
            sortItems = [];
        } else {
            sortItems = [{
                field: props.sortingField, dir: 'asc'
            }];
        }
        props.handleSortColumnChange(sortItems);
    }


    return (
        <Box display="flex" flexDirection="row">
            <img src={iconConeGray} alt="icon cone gray" />
            <Box className="column-mouse-hover" minWidth="20px" onClick={handleSortChange}>
                <span className={sortIconClass}></span>
                {props.sort.length > 1 && sortIndex !== -1 &&
                    <span className="k-sort-order">{(sortIndex + 1)}</span>
                }
            </Box>
        </Box>
    );
}
