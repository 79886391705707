import { GridCellProps } from "@progress/kendo-react-grid";
import { ColorPicker, ColorPickerChangeEvent, ColorPickerPaletteSettings } from "@progress/kendo-react-inputs";
import React from 'react';
import { MaintenanceAreaExtended } from "../models/MaintenanceAreaExtended";
import './CustomColorPickerCellStyles.scss';

interface CustomColorPickerCellProps {
    paletteColors: string[]
    handleColorChanged: (dataItem: MaintenanceAreaExtended) => void
}

export const CustomColorPickerCell = (props: GridCellProps & CustomColorPickerCellProps): JSX.Element => {
    let dataItem = props.dataItem;
    let paletteColors = props.paletteColors;
    if (dataItem && dataItem.hexColor && paletteColors && !paletteColors.includes(dataItem.hexColor)) {
        paletteColors = Array.from(paletteColors);
        paletteColors.unshift(dataItem.hexColor);
    }

    const paletteSettings: ColorPickerPaletteSettings = {
        palette: paletteColors,
        columns: 4,
        tileSize: 30
    };

    const handleOnChangeColor = (event: ColorPickerChangeEvent): void => {
        var hexCode = rgbaToHex(event.value);
        dataItem.hexColor = hexCode === "#ffffff" ? "transparent" : hexCode;
        props.handleColorChanged(dataItem);
    }

    const rgbaToHex = (rgb: string): string => {
        var inParts = rgb.substring(rgb.indexOf("(")).split(","),
            r = parseInt(trim(inParts[0].substring(1)), 10),
            g = parseInt(trim(inParts[1]), 10),
            b = parseInt(trim(inParts[2]), 10);

        var outParts = [
            r.toString(16),
            g.toString(16),
            b.toString(16)
        ];

        outParts.forEach(function (part, i) {
            if (part.length === 1) {
                outParts[i] = '0' + part;
            }
        });

        return ('#' + outParts.join(''));
    }

    const trim = (str: string): string => {
        return str.replace(/^\s+|\s+$/gm, '');
    }

    const defaultRendering = (
        <td className={`color-picker ${dataItem.hexColor === "transparent" ? ' transparent' : ''}`}>
            <ColorPicker
                value={dataItem.hexColor}
                paletteSettings={paletteSettings}
                onChange={handleOnChangeColor}
            />
        </td>
    );

    return defaultRendering;
}