import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton } from '@mui/material';
import { GridRowClickEvent } from '@progress/kendo-react-grid';
import * as booleanContains from '@turf/boolean-contains';
import { LineString as Line, Polygon } from "@turf/helpers";
import { AnimationOptions, CameraBoundsOptions, data, layer, Map as AzureMap, MapMouseEvent, math, Popup, Shape, source } from 'azure-maps-control';
import { control, drawing } from 'azure-maps-drawing-tools';
import { debounce, isEqual, orderBy } from 'lodash';
import React, { Component } from 'react';
import iconArrow from 'src/assets/icons/icon-arrow.svg';
import azureMapPinIcon from 'src/assets/icons/icon-azure-map-pin.png';
import Translate, { Localization } from '../../localization/Localization';
import { MaintenanceScenariosMessages } from '../../shared/BusinessMessages/MaintenanceScenarios/MaintenanceScenariosMessages';
import { AlgorithmsModelVersionInfoDialog } from '../../shared/components/AlgorithmsModelVersionInfoDialog/AlgorithmsModelVersionInfoDialog';
import { CustomMapChoice } from '../../shared/models/CustomMapChoice';
import { MapCursorMode } from '../../shared/models/MapCursorMode';
import { MeasurementSystemType } from '../../shared/models/MeasurementSystemType';
import { ScoreTypes } from '../../shared/models/ScoreTypes';
import ToastService from '../../ToastService';
import BusinessMessages from '../../utils/BusinessMessages';
import '../../utils/Map';
import { MeasurementSystem } from '../../utils/MeasurementSystem';
import { AppModule, LocalStorage, StorageKeyBuilder } from '../../utils/Storage';
import '../../utils/String';
import Utilities from '../../utils/Utilities';
import { RouteComponentProps, withRouter } from '../../withRouter';
import styles from '../../_variables.scss';
import { ProjectVersion } from '../Home/services/dataContracts/queryStack/ProjectVersion';
import { ScoringParameter } from '../Home/services/dataContracts/queryStack/ScoringParameter';
import { AuscultationsSelectorWithRoadsDrawerComponent } from './components/AuscultationsSelectorWithRoadsDrawerComponent';
import { MapWithImagesComponent } from './components/MapWithImagesComponent';
import { ScenarioManagementComponent } from './components/ScenarioManagementComponent';
import { ScenariosSelectorComponent } from './components/ScenariosSelectorComponent';
import { AuscultationExtended } from './models/AuscultationExtended';
import { ImageExtended } from './models/ImageExtended';
import { MaintenanceAreaExtended } from './models/MaintenanceAreaExtended';
import { MaintenanceScenarioExtended } from './models/MaintenanceScenarioExtended';
import { MergedProjectVersion } from './models/MergedProjectVersion';
import { ProjectVersionExtended } from './models/ProjectVersionExtended';
import { RelatedAreaModel } from './models/RelatedAreaModel';
import { getHiddenIsIncludedInAreaForSort, getHiddenIsSelectedForSort, RoadLabelScoreExtended } from './models/RoadLabelScoreExtended';
import { RoadLabelsScoreRankingExtended } from './models/RoadLabelsScoreRankingExtended';
import { RoadsConditionSummaryModel } from './models/RoadsConditionSummaryModel';
import { RoadScoreRange } from './models/RoadScoreRange';
import { RoadSectionViewData } from './models/RoadSectionViewData';
import { RoadViewData } from './models/RoadViewData';
import { RouteLocationStateModel } from './models/RouteLocationStateModel';
import { ScoreColors } from './models/ScoreColors';
import { ScoreTypesColors } from './models/ScoreTypesColors';
import { SelectedSectionModel } from './models/SelectedSectionModel';
import { ShapeEntityType } from './models/ShapeEntityType';
import { areaWidth, cameraAnimationDuration, cameraAnimationType, mainDatasourceId, roadLayerId, RoadsConditionAndScenariosShared, sectionWidth, transparentColor } from './RoadsConditionAndScenariosShared';
import './RoadsConditionAndScenariosStyles.scss';
import { ScenarioAreasPaletteColors } from './ScenarioAreasPaletteColors';
import { AddScenarioRequestArgs } from './services/MaintenanceScenarios/dataContracts/controller/AddScenarioRequestArgs';
import { DeleteScenarioAreaRequestArgs } from './services/MaintenanceScenarios/dataContracts/controller/DeleteScenarioAreaRequestArgs';
import { DeleteScenarioRequestArgs } from './services/MaintenanceScenarios/dataContracts/controller/DeleteScenarioRequestArgs';
import { DuplicateScenarioRequestArgs } from './services/MaintenanceScenarios/dataContracts/controller/DuplicateScenarioRequestArgs';
import { UpdateScenarioLabelRequestArgs } from './services/MaintenanceScenarios/dataContracts/controller/UpdateScenarioLabelRequestArgs';
import { MaintenanceArea } from './services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceArea';
import { MaintenanceAreaSection } from './services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceAreaSection';
import { MaintenanceAreaStatus } from './services/MaintenanceScenarios/dataContracts/queryStack/MaintenanceAreaStatus';
import { MaintenanceScenariosApiClient } from './services/MaintenanceScenarios/MaintenanceScenariosApiClient';
import { ImageAnomaly } from './services/RoadsCondition/dataContracts/queryStack/ImageAnomaly';
import { MultiAuscultationMergedScores } from './services/RoadsCondition/dataContracts/queryStack/MultiAuscultationMergedScores';
import { RoadLabelScore } from './services/RoadsCondition/dataContracts/queryStack/RoadLabelScore';

const carDatasourceId = "carDatasourceId";
const carId = "carId";
const carIconSymbolLayerId = "carIconSymbolLayerId";

const projectPinId = "projectPinId";
const projectSymbolLayerId = "projectSymbolLayerId";

const selectionDrawElementId = "selection-draw";
const deselectionDrawElementId = "deselection-draw";

const selectedSectionWidth = 12;

const openedScenariosSelectorDrawerKey = 'openedScenariosSelectorDrawer';
const openedScenarioManagementDrawerKey = 'openedScenarioManagementDrawer';
const openedAuscultationsSelectorWithRoadsDrawerKey = 'openedAuscultationsSelectorWithRoadsDrawer';
const openedMapDrawerKey = 'openedMapDrawer';
const selectedScenarioForProjectKey = 'selectedScenarioForProject';

const moduleKey = AppModule.MaintenanceScenarios;

interface RoadsConditionAndScenariosViewState {
    loading: boolean,
    openedScenariosSelectorDrawer: boolean,
    openedScenarioManagementDrawer: boolean,
    openedAuscultationsSelectorWithRoadsDrawer: boolean,
    openedMapDrawer: boolean,
    projectVersion: ProjectVersionExtended,
    mergedProject: MergedProjectVersion,
    selectedRoadsSummary: RoadsConditionSummaryModel,
    roadLabelsScores: Map<string, RoadLabelScoreExtended>,
    roadScoreRange: RoadScoreRange,
    averageScoreSliderValue: number[],
    selectedSectionsMap: Map<number, number>,
    lastClickedSelection: SelectedSectionModel,
    selectedImage: ImageExtended,
    selectedRoadSection: RoadSectionViewData,
    selectedRoadSectionLabel: string,
    isLabeledImagesContentOpened: boolean,
    isAddScenarioDialogOpened: boolean,
    projectScenarios: MaintenanceScenarioExtended[],
    selectedScenario: MaintenanceScenarioExtended,
    ownerUserIdOfLastSelectedScenario: string,
    typesOfWorks: string[],
    displayedAreasIds: Map<number, number>,
    highlightedAreaId: number,
    activeRoadsConditions: Map<string, boolean>,
    activeAnomalies: Set<string>,
    isGroupView: boolean,
    isAlgorithmsModelVersionInfoDialogOpen: boolean,
    measurementSystemType: MeasurementSystemType
}

interface RoadsConditionAndScenariosViewProps {
    role: string,
    userFullName: string,
    isScenarioView: boolean
}

const initialState: RoadsConditionAndScenariosViewState = {
    loading: false,
    openedScenariosSelectorDrawer: false,
    openedScenarioManagementDrawer: false,
    openedAuscultationsSelectorWithRoadsDrawer: false,
    openedMapDrawer: true,
    projectVersion: null,
    mergedProject: null,
    selectedRoadsSummary: null,
    roadLabelsScores: new Map<string, RoadLabelScoreExtended>(),
    roadScoreRange: { min: 0, max: 0 },
    averageScoreSliderValue: [0, 0],
    selectedSectionsMap: new Map<number, number>(),
    lastClickedSelection: null,
    selectedImage: null,
    selectedRoadSection: null,
    selectedRoadSectionLabel: null,
    isLabeledImagesContentOpened: false,
    isAddScenarioDialogOpened: false,
    isAlgorithmsModelVersionInfoDialogOpen: false,
    projectScenarios: [],
    selectedScenario: null,
    ownerUserIdOfLastSelectedScenario: null,
    typesOfWorks: [],
    displayedAreasIds: new Map<number, number>(),
    highlightedAreaId: null,
    activeRoadsConditions: new Map<string, boolean>([[ScoreTypes.poor, true], [ScoreTypes.toMonitor, true], [ScoreTypes.good, true]]),
    activeAnomalies: new Set<string>(),
    isGroupView: false,
    measurementSystemType: null
}

export class RoadsConditionAndScenariosView extends Component<RouteComponentProps & RoadsConditionAndScenariosViewProps, RoadsConditionAndScenariosViewState> {
    _isMounted: boolean;
    thresholdOfGoodScore: number;
    thresholdOfPoorScore: number;
    shouldShowUnscoredSections: boolean;
    hasScoreAnalysisAccess: boolean;
    inputSearchRoadsRef: React.RefObject<HTMLInputElement>;
    inputSearchScenariosRef: React.RefObject<HTMLInputElement>;
    selectedProject: ProjectVersionExtended;
    selectionDrawingManager: drawing.DrawingManager;
    deselectionDrawingManager: drawing.DrawingManager;
    projectPopup: Popup;
    areaTooltipPopup: Popup;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    projectVersionsCache: Map<number, ProjectVersion>;
    map: AzureMap;
    //NOTE CMA HGA afin de pouvoir désenregistrer les évenements asssociés, on a besoin de garder les pointeurs vers ces handlers car ce sont des fonctions lambda
    projectPinClickHandler: () => void;
    anomalyPointClickHandler: (e: void | MapMouseEvent | layer.Layer) => void;

    constructor(props: RouteComponentProps & RoadsConditionAndScenariosViewProps) {
        super(props);

        this.hasScoreAnalysisAccess = this.props.role === "ADM" || this.props.role === "ATXPLUS" || this.props.role === "CLIPLUS";
        this.inputSearchRoadsRef = React.createRef();
        this.inputSearchScenariosRef = React.createRef();
        this.map = null;
        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.projectVersionsCache = new Map<number, ProjectVersion>();

        initialState.measurementSystemType = MeasurementSystem.getCurrentType();
        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        await this.initViewData(locationState, initialState);
    }

    async componentDidUpdate(prevProps: RouteComponentProps & RoadsConditionAndScenariosViewProps): Promise<void> {

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        if (this.selectedProject && (this.selectedProject.projectVersionId !== locationState.projectVersionId
            || this.selectedProject.projectId !== locationState.projectId
            || this.thresholdOfGoodScore !== locationState.scoringValue[0]
            || this.thresholdOfPoorScore !== locationState.scoringValue[1]
            || this.shouldShowUnscoredSections !== locationState.shouldShowUnscoredSections
        )) {
            await this.initViewData(locationState, initialState);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.disposeMap();
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            measurementSystemType: measurementSystemType
        });
    }

    disposeMap = (): void => {
        this.projectPinClickHandler = null;
        this.anomalyPointClickHandler = null;

        this.selectionDrawingManager?.dispose();
        this.deselectionDrawingManager?.dispose();

        this.map?.dispose();
    }

    initViewData = async (locationState: RouteLocationStateModel, state: RoadsConditionAndScenariosViewState): Promise<void> => {
        this.disposeMap();

        let mapChoice = RoadsConditionAndScenariosShared.getMapChoiceValue();
        this.map = RoadsConditionAndScenariosShared.createMap('map', 4, locationState.locationGeometry, mapChoice);

        if (!this.props.isScenarioView) {
            this.map.resize("100%", styles.roadConditionMapContentWidthWithoutDrawer);
        }

        this.thresholdOfGoodScore = locationState.scoringValue[0];
        this.thresholdOfPoorScore = locationState.scoringValue[1];
        this.shouldShowUnscoredSections = locationState.shouldShowUnscoredSections;

        if (locationState.projectVersionId && this.props.role) {
            var projectVersionId = locationState.projectVersionId;

            this.selectedProject = null;
            this.setState({ loading: true });

            //NOTE AME CMA : 
            // On doit s'assurer que le "mergedProject" de la version courante est toujours chargée et en cache. 
            // Dans certaines parties du code (ex: ajout d'un scenario) on s'attends a ce que ce soit bien le cas.
            let mergedProject = await RoadsConditionAndScenariosShared.getMergedProject(
                projectVersionId,
                this.shouldShowUnscoredSections,
                this.thresholdOfGoodScore,
                this.thresholdOfPoorScore,
                this.mergedProjectAuscultationsCache,
                this.projectVersionsCache);

            this.selectedProject = mergedProject.projectVersion;
            this.projectVersionsCache.set(projectVersionId, this.selectedProject);

            let openedMapDrawer = this.getMapDrawerStoredValue();
            let openedScenariosSelectorDrawer = this.getScenariosSelectorDrawerStoredValue();
            let openedScenarioManagementDrawer = this.getScenarioManagementDrawerStoredValue();
            let openedAuscultationsSelectorWithRoadsDrawer = this.getAuscultationsSelectorWithRoadsDrawerStoredValue();

            let {
                displayedAreasIds,
                isLabeledImagesContentOpened,
                ownerUserIdOfLastSelectedScenario,
                projectScenarios,
                selectedScenario,
                typesOfWorks
            } = state;

            //TODO HGA CMA avoir une seule methode qui calcul les dimensions en fonction des visibilités des drawers
            if (this.props.isScenarioView) {
                const maintenanceScenarioId = this.getMaintenanceScenarioIdStoredValue(locationState.projectId);
                projectScenarios = await this.getScenarios(locationState.projectId, maintenanceScenarioId);

                selectedScenario =
                    (maintenanceScenarioId && projectScenarios.find((scenario) => scenario.maintenanceScenarioId === maintenanceScenarioId))
                    || this.getMostRecentScenario(projectScenarios);

                if (!selectedScenario) {
                    this.setMaintenanceScenarioIdStoredValue(locationState.projectId, null)
                }

                if (selectedScenario) {
                    selectedScenario.isSelected = true;
                    openedScenarioManagementDrawer = true;
                    ownerUserIdOfLastSelectedScenario = selectedScenario.ownerUserId;
                    if (selectedScenario.projectVersionId !== projectVersionId) {
                        mergedProject = await RoadsConditionAndScenariosShared.getMergedProject(
                            selectedScenario.projectVersionId,
                            this.shouldShowUnscoredSections,
                            this.thresholdOfGoodScore,
                            this.thresholdOfPoorScore,
                            this.mergedProjectAuscultationsCache,
                            this.projectVersionsCache);
                        typesOfWorks = await this.getTypesOfWorks(selectedScenario.ownerUserId);
                    }
                }
            }

            const selectedRoadsSummary = this.computeSummary(mergedProject.roadsSections);

            const roadLabelsRanking = this.buildExtendedRoadLabelsScoreRanking(mergedProject, mergedProject.extendedRoads);

            if (selectedScenario) {
                this.updateRoadLabelsScoreRelatedArea(roadLabelsRanking.roadLabelsScores, selectedScenario);
            }

            this.initMap(this.map, () => {
                this.createMapSectionsShapes(mergedProject, state.activeRoadsConditions);
                if (selectedScenario) {
                    this.hideAreasRelatedShapes();
                    this.showAreasRelatedShapes(selectedScenario, displayedAreasIds);
                }

                this.setMapZoom(this.map, mergedProject, true, openedMapDrawer);
            });

            if (this.props.isScenarioView) {
                this.resizeMapWhenScenarioView(this.map, openedMapDrawer, openedScenariosSelectorDrawer, openedScenarioManagementDrawer, openedAuscultationsSelectorWithRoadsDrawer, isLabeledImagesContentOpened);
            }
            else {
                this.resizeMapWhenRoadsConditionView(this.map, openedAuscultationsSelectorWithRoadsDrawer, isLabeledImagesContentOpened);
            }

            if (this._isMounted) {
                this.setState({
                    openedMapDrawer: openedMapDrawer,
                    openedScenariosSelectorDrawer: openedScenariosSelectorDrawer,
                    openedScenarioManagementDrawer: openedScenarioManagementDrawer,
                    openedAuscultationsSelectorWithRoadsDrawer: openedAuscultationsSelectorWithRoadsDrawer,
                    projectVersion: mergedProject.projectVersion,
                    mergedProject: mergedProject,
                    selectedRoadsSummary: selectedRoadsSummary,
                    roadLabelsScores: roadLabelsRanking.roadLabelsScores,
                    roadScoreRange: roadLabelsRanking.roadScoreRange,
                    averageScoreSliderValue: roadLabelsRanking.averageScoreSliderValue,
                    projectScenarios: projectScenarios,
                    selectedScenario,
                    ownerUserIdOfLastSelectedScenario,
                    typesOfWorks,
                    loading: false
                });
            }
        }
    }

    getMapDrawerStoredValue = (): boolean =>
        this.props.isScenarioView ? LocalStorage.GetBool(moduleKey, openedMapDrawerKey, true) : false;

    getScenariosSelectorDrawerStoredValue = (): boolean =>
        this.props.isScenarioView ? LocalStorage.GetBool(moduleKey, openedScenariosSelectorDrawerKey, true) : false;

    getScenarioManagementDrawerStoredValue = (): boolean =>
        LocalStorage.GetBool(moduleKey, openedScenarioManagementDrawerKey, false);

    getAuscultationsSelectorWithRoadsDrawerStoredValue = (): boolean =>
        this.props.isScenarioView ? LocalStorage.GetBool(moduleKey, openedAuscultationsSelectorWithRoadsDrawerKey, false) : true;

    getMaintenanceScenarioIdStoredValue = (projectId: string): number => {
        const localKey = StorageKeyBuilder.BuildLocalKey(selectedScenarioForProjectKey, projectId)
        return LocalStorage.GetNumber(moduleKey, localKey);
    }

    setMaintenanceScenarioIdStoredValue = (projectId: string, maintenanceScenarioId: number): void => {
        const localKey = StorageKeyBuilder.BuildLocalKey(selectedScenarioForProjectKey, projectId)
        LocalStorage.SetNumber(moduleKey, localKey, maintenanceScenarioId);
    }

    resizeMapWhenRoadsConditionView = (map: AzureMap, openedDrawer: boolean, isLabeledImagesContentOpened: boolean): void => {
        let height = isLabeledImagesContentOpened ? styles.roadConditionMapContentHeightWithLabeledImagesContentOpened : "100%";
        let width = openedDrawer ? styles.roadConditionMapContentWidthWithOpenedDrawer : styles.roadConditionMapContentWidthWithClosedDrawer;
        map.resize(height, width);
    }

    resizeMapWhenScenarioView = (map: AzureMap, openedMapDrawer: boolean, openedScenariosSelectorDrawer: boolean, openedScenarioManagementDrawer: boolean, openedAuscultationsSelectorWithRoadsDrawer: boolean, isLabeledImagesContentOpened: boolean): void => {
        let width = "";
        let height = "";

        if (openedMapDrawer) {
            height = isLabeledImagesContentOpened ? styles.scenarioMapHeightWithLabeledImagesDrawerOpened : styles.scenarioMapHeight;

            if (!openedScenariosSelectorDrawer && !openedScenarioManagementDrawer && !openedAuscultationsSelectorWithRoadsDrawer) {
                width = styles.scenarioMapWidthWithClosedDrawers;
            }
            else if (openedScenariosSelectorDrawer && !openedScenarioManagementDrawer && !openedAuscultationsSelectorWithRoadsDrawer) {
                width = styles.scenarioMapWidthWithOnlyOpenedScenariosSelectorDrawer;
            }
            else if (!openedScenariosSelectorDrawer && openedScenarioManagementDrawer && !openedAuscultationsSelectorWithRoadsDrawer) {
                width = styles.scenarioMapWidthWithOnlyOpenedScenarioManagementDrawer;
            }
            else if (!openedScenariosSelectorDrawer && !openedScenarioManagementDrawer && openedAuscultationsSelectorWithRoadsDrawer) {
                width = styles.scenarioMapWidthWithOnlyOpenedAuscultationsSelectorWithRoadsDrawer;
            }
            else if (!openedScenariosSelectorDrawer && openedScenarioManagementDrawer && openedAuscultationsSelectorWithRoadsDrawer) {
                width = styles.scenarioMapWidthWithOpenedScenarioManagementAndOpenedAuscultationSelectorWithRoadsDrawers;
            }
            else if (openedScenariosSelectorDrawer && !openedScenarioManagementDrawer && openedAuscultationsSelectorWithRoadsDrawer) {
                width = styles.scenarioMapWidthWithOpenedScenariosSelectorAndOpenedAuscultationsSelectorWithRoadsDrawers;
            }
            else if (openedScenariosSelectorDrawer && openedScenarioManagementDrawer && !openedAuscultationsSelectorWithRoadsDrawer) {
                width = styles.scenarioMapWidthWithOpenedScenariosSelectorAndOpenedScenarioManagementDawers;
            }
            else if (openedScenariosSelectorDrawer && openedScenarioManagementDrawer && openedAuscultationsSelectorWithRoadsDrawer) {
                width = styles.scenarioMapWidthWithOpenedDrawers;
            }
        }

        map.resize(height, width);
    }

    setMapZoom = (map: AzureMap, mergedProject: MergedProjectVersion, animation: boolean, openedMapDrawer: boolean): void => {
        if (!this.props.isScenarioView || (this.props.isScenarioView && openedMapDrawer)) {
            var options: CameraBoundsOptions & AnimationOptions = {
                bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
                padding: 20
            };

            if (animation) {
                options.type = cameraAnimationType;
                options.duration = cameraAnimationDuration;
            }

            map.setCamera(options);
        }
    }

    createMainDatasource = (mergedProject: MergedProjectVersion): source.DataSource => {
        var datasource = new source.DataSource(mainDatasourceId);
        this.map.sources.add(datasource);

        var roadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, roadLayerId);
        this.map.layers.add(roadLayer);
        this.map.events.add('mouseover', roadLayer, (e) => this.handleRoadLayerMouseover(e));
        this.map.events.add('mouseout', roadLayer, this.handleRoadLayerMouseout);

        this.projectPinClickHandler = () => this.handleProjectPinClick(mergedProject);

        var projectPinSymbolLayer = this.createProjectPinLayer(datasource);
        this.map.events.add('mouseover', projectPinSymbolLayer, this.handleProjectPinMouseover);
        this.map.events.add('mouseout', projectPinSymbolLayer, this.handleProjectPinMouseout);
        this.map.events.add('mousedown', projectPinSymbolLayer, this.projectPinClickHandler);

        if (mergedProject.projectLocationGeometry) {
            var projectPin = new data.Feature(new data.Point(mergedProject.projectLocationGeometry.coordinates), { EntityType: ShapeEntityType.project, projectLabel: mergedProject.projectLabel, projectPin: true }, projectPinId);
            datasource.add(projectPin);
        }

        return datasource;
    }

    removeMainDatasource = (): void => {
        var existingRoadLayer = this.map.layers.getLayerById(roadLayerId);
        if (existingRoadLayer) {
            this.map.events.remove('mouseover', existingRoadLayer, (e) => this.handleRoadLayerMouseover(e as MapMouseEvent));
            this.map.events.remove('mouseout', existingRoadLayer, this.handleRoadLayerMouseout);

            this.map.layers.remove(roadLayerId);
        }

        var existingProjectSymbolLayer = this.map.layers.getLayerById(projectSymbolLayerId);
        if (existingProjectSymbolLayer) {
            this.map.events.remove('mouseover', existingProjectSymbolLayer, this.handleProjectPinMouseover);
            this.map.events.remove('mouseout', existingProjectSymbolLayer, this.handleProjectPinMouseout);
            this.map.events.remove('mousedown', existingProjectSymbolLayer, this.projectPinClickHandler);

            this.map.layers.remove(projectSymbolLayerId);
        }

        var existingCarIconLayer = this.map.layers.getLayerById(carIconSymbolLayerId);
        if (existingCarIconLayer) {
            this.map.layers.remove(carIconSymbolLayerId);
        }

        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        datasource.clear();
        this.map.sources.remove(datasource);
    }

    recreateMainDatasource = (mergedProject: MergedProjectVersion): source.DataSource => {
        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        if (datasource) {
            this.removeMainDatasource();
        }

        datasource = this.createMainDatasource(mergedProject);
        return datasource;
    }

    anomalyLayerMouseover = (): void => {
        this.handleLayerMouseover();
    }

    anomalyLayerMouseout = (): void => {
        this.handleLayerMouseout();
    }

    handleAnomalyPointClicked = (e: void | MapMouseEvent | layer.Layer, mergedProject: MergedProjectVersion): void => {
        e = e as MapMouseEvent;
        let shape = e.shapes[0] as Shape;
        let shapeProps = shape.getProperties();
        let section: RoadSectionViewData = mergedProject.roadsSections.get(shapeProps.sectionId);
        if (section) {
            let clickedPosition = e.position;
            this.handleSectionClicked(section, clickedPosition);
        }
    }

    displayCarIconLayer = (map: AzureMap, visible: boolean): void => {
        let carIconLayer = map.layers.getLayerById(carIconSymbolLayerId) as layer.SymbolLayer;
        if (carIconLayer) {
            let options = carIconLayer.getOptions();
            options.visible = visible;
            carIconLayer.setOptions(options);
        }
    }

    displayProjectPinLayer = (map: AzureMap, visible: boolean): void => {
        var projectPinLayer = map.layers.getLayerById(projectSymbolLayerId) as layer.SymbolLayer;
        var options = projectPinLayer?.getOptions();
        if (projectPinLayer && (!options || (options && options.visible !== visible))) {
            projectPinLayer.setOptions({
                iconOptions: {
                    image: 'azure-map-pin-icon'
                },
                visible: visible,
                //Only render Point with property projectPin.
                filter: ['has', "projectPin"]
            });
        }
    }

    createCarIconPin = (coordinates: data.Position, datasource: source.DataSource, id: string, heading: number): void => {
        let pin = new data.Feature(new data.Point(coordinates), { EntityType: 'car' }, id);
        datasource.add(pin);

        //Add car icon symbol
        let existingcarIconSymbolLayer = this.map.layers.getLayerById(carIconSymbolLayerId);
        if (existingcarIconSymbolLayer) {
            this.map.layers.remove(existingcarIconSymbolLayer);
        }

        this.createCarIconSymbolLayer(datasource, carIconSymbolLayerId, heading);
    }

    createCarIconSymbolLayer = (datasource: source.DataSource, id: string, heading: number): void => {
        var symbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, id, {
            iconOptions: {
                anchor: 'center',
                //For smoother animation, ignore the placement of the icon. This skips the label collision calculations and allows the icon to overlap map labels.
                ignorePlacement: true,
                //For smoother animation, allow symbol to overlap all other symbols on the map.
                allowOverlap: true,
                rotation: heading,
                //Have the rotation align with the map.
                rotationAlignment: 'map',
                image: 'carIcon'
            }
        });

        this.map.imageSprite.createFromTemplate('carIcon', 'car', 'gold', 'black').then(() => {
            //Add a symbol layer that uses the custom created icon.
            this.map.layers.add(symbolLayer);
        });
    }

    createProjectPinLayer = (datasource: source.DataSource): layer.SymbolLayer => {
        var projectPinSymbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, projectSymbolLayerId,
            {
                iconOptions: {
                    image: 'azure-map-pin-icon'
                },
                //Only render Point with property projectPin.
                filter: ['has', "projectPin"]
            });

        this.map.imageSprite.add('azure-map-pin-icon', azureMapPinIcon);
        this.map.layers.add(projectPinSymbolLayer);

        return projectPinSymbolLayer;
    }

    getSelectedSectionShapeId = (roadSectionId: number): string => {
        return `${roadSectionId}/selected`;
    }

    getEnlightenedAreaShapeId = (roadSectionId: number): string => {
        return `${roadSectionId}/area/enlightened`;
    }

    getAreaShapeId = (roadSectionId: number): string => {
        return `${roadSectionId}/area`;
    }

    showSectionSelectedShape = (datasource: source.DataSource, sectionId: number): void => {
        var shapeId = this.getSelectedSectionShapeId(sectionId);
        var shape: Shape = datasource.getShapeById(shapeId);
        if (shape) {
            this.showSectionSelected(shape);
        }
    }

    showSectionSelected = (shape: Shape): void => {
        let props = shape.getProperties();
        props.strokeColor = styles.selectedSectionColor;
        props.strokeWidth = selectedSectionWidth;
        shape.setProperties(props);
    }

    hideSectionSelectedShape = (datasource: source.DataSource, sectionId: number): void => {
        var shapeId = this.getSelectedSectionShapeId(sectionId);
        var shape: Shape = datasource.getShapeById(shapeId);
        if (shape) {
            RoadsConditionAndScenariosShared.hideShape(shape);
        }
    }

    hideAreaShape = (datasource: source.DataSource, sectionId: number): void => {
        var shapeId = this.getAreaShapeId(sectionId);
        var shape: Shape = datasource.getShapeById(shapeId);
        if (shape) {
            RoadsConditionAndScenariosShared.hideShape(shape);
        }
    }

    hideAllSectionSelectedShapes() {
        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        datasource.getShapes().forEach((section: Shape) => {
            var entityType = section.getProperties().EntityType;
            if (entityType === ShapeEntityType.sectionSelected) {
                RoadsConditionAndScenariosShared.hideShape(section);
            }
        });
    }

    hideAreasRelatedShapes() {
        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        datasource.getShapes().forEach((section: Shape) => {
            var entityType = section.getProperties().EntityType;
            if (entityType === ShapeEntityType.areaEnlightened || entityType === ShapeEntityType.area) {
                RoadsConditionAndScenariosShared.hideShape(section);
            }
        });
    }

    showAreasRelatedShapes(selectedScenario: MaintenanceScenarioExtended, displayedAreasIds: Map<number, number>) {
        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        selectedScenario.areas.forEach((area) => {
            area.sections.forEach((section) => {
                this.showAreaShape(datasource, section.roadSectionId, area.hexColor);
                if (displayedAreasIds.has(area.maintenanceAreaId)) {
                    let shapeId = this.getEnlightenedAreaShapeId(section.roadSectionId);
                    RoadsConditionAndScenariosShared.showAreaEnlightenedShape(shapeId, datasource);
                }
            });
        });
    }

    showAreaShape = (datasource: source.DataSource, sectionId: number, strokeColor: string): void => {
        let shapeId = this.getAreaShapeId(sectionId);
        let shape: Shape = datasource.getShapeById(shapeId);
        if (shape) {
            let props = shape.getProperties();
            props.strokeColor = strokeColor;
            props.strokeWidth = areaWidth;
            shape.setProperties(props);
        }
    }

    handleZoomend = (selectionDrawingManager: drawing.DrawingManager, deselectionDrawingManager: drawing.DrawingManager, projectPopup: Popup, areaTooltipPopup: Popup): void => {
        var zoom = this.map.getCamera().zoom;

        if (zoom <= 11) {
            this.displayRoadLayer(this.map, false);
            this.resetCursorAndDrawingMode(this.map, selectionDrawingManager, deselectionDrawingManager);
            this.hideDrawingManagers();
            this.displayCarIconLayer(this.map, false);

            this.displayProjectPinLayer(this.map, true);

            // Pin visible donc attacher la popup 
            projectPopup.attach(this.map);
            areaTooltipPopup.attach(this.map);
        }
        else if (zoom > 11) {
            this.displayRoadLayer(this.map, true);
            this.showDrawingManagers();
            this.displayCarIconLayer(this.map, true);

            this.displayProjectPinLayer(this.map, false);

            // Pin invisible donc remove la popup 
            projectPopup.remove();
            areaTooltipPopup.remove();
        }
    }

    hideDrawingManagers = (): void => {
        var containerElement = document.getElementById(selectionDrawElementId);
        if (containerElement?.style.display !== "none") {
            containerElement.style.display = "none";
        }

        containerElement = document.getElementById(deselectionDrawElementId);
        if (containerElement?.style.display !== "none") {
            containerElement.style.display = "none";
        }
    }

    showDrawingManagers = (): void => {
        var containerElement = document.getElementById(selectionDrawElementId);
        if (containerElement?.style.display === "none") {
            containerElement.style.display = "block";
        }

        containerElement = document.getElementById(deselectionDrawElementId);
        if (containerElement?.style.display === "none") {
            containerElement.style.display = "block";
        }
    }

    resetCursorAndDrawingMode = (map: AzureMap, selectionDrawingManager: drawing.DrawingManager, deselectionDrawingManager: drawing.DrawingManager): void => {
        var selectionDrawingOptions = selectionDrawingManager.getOptions();
        var deselectionDrawingOptions = deselectionDrawingManager.getOptions();

        if (selectionDrawingOptions.mode !== drawing.DrawingMode.idle) {
            // désactiver le mode de selection multiple des sections
            RoadsConditionAndScenariosShared.setMapCursor(map, MapCursorMode.Auto);
            selectionDrawingManager.setOptions({ mode: drawing.DrawingMode.idle });
        }

        if (deselectionDrawingOptions.mode !== drawing.DrawingMode.idle) {
            // désactiver le mode de selection multiple des sections
            RoadsConditionAndScenariosShared.setMapCursor(map, MapCursorMode.Auto);
            deselectionDrawingManager.setOptions({ mode: drawing.DrawingMode.idle });
        }
    }

    displayRoadLayer = (map: AzureMap, isVisible: boolean): void => {
        var roadLayer = map.layers.getLayerById(roadLayerId) as layer.LineLayer;
        var options = roadLayer?.getOptions();
        if (roadLayer && isVisible !== options.visible) {
            // rendre les sections invisibles
            roadLayer.setOptions({ strokeColor: ['get', 'strokeColor'], strokeWidth: ['get', 'strokeWidth'], lineJoin: 'round', lineCap: 'round', visible: isVisible });
        }
    }

    createMapSectionsShapes = (mergedProject: MergedProjectVersion, activeRoadsConditions: Map<string, boolean>): void => {
        let datasource = this.recreateMainDatasource(mergedProject);

        mergedProject.roadsSections.forEach((section: RoadSectionViewData) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            //NOTE HGA CMA l'ordre de creation des shapes est importante. En cas de changement, il faut tenir compte/maj aussi des largeurs
            let enlightenedAreaShapeId = this.getEnlightenedAreaShapeId(roadSectionId);
            let selectedAreaShape = RoadsConditionAndScenariosShared.createShape(coordinates, enlightenedAreaShapeId, transparentColor, 0, ShapeEntityType.areaEnlightened, roadSectionId);
            datasource.add(selectedAreaShape);

            let selectedSectionShapeId = this.getSelectedSectionShapeId(roadSectionId);
            let selectedSectionShape = RoadsConditionAndScenariosShared.createShape(coordinates, selectedSectionShapeId, transparentColor, 0, ShapeEntityType.sectionSelected, roadSectionId);
            datasource.add(selectedSectionShape);

            let unselectedSectionShapeId = RoadsConditionAndScenariosShared.getSectionShapeId(roadSectionId);
            let sectionScoreType = section.scoreType;
            let strokeColor = styles.unfilteredSectionColor;
            if (activeRoadsConditions.get(sectionScoreType) === true) {
                strokeColor = ScoreTypesColors.get(sectionScoreType);
            }

            let unselectedSectionShape = RoadsConditionAndScenariosShared.createShape(coordinates, unselectedSectionShapeId, strokeColor, sectionWidth, ShapeEntityType.section, roadSectionId, sectionScoreType);
            datasource.add(unselectedSectionShape);

            let unselectedAreaShapeId = this.getAreaShapeId(roadSectionId);
            let unselectedAreaShape = RoadsConditionAndScenariosShared.createShape(coordinates, unselectedAreaShapeId, transparentColor, 0, ShapeEntityType.area, roadSectionId);
            datasource.add(unselectedAreaShape);
        });
    }

    initMap = (map: AzureMap, callback: () => void): void => {

        RoadsConditionAndScenariosShared.setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {

            if (!this._isMounted)
                return;



            //click sur map
            map.events.add('mousedown', (e: MapMouseEvent) => this.handleMapClick(e, this.state));

            this.projectPopup = new Popup({ closeButton: false });
            this.areaTooltipPopup = new Popup({ closeButton: false });

            this.selectionDrawingManager = this.createDrawingManager(map, selectionDrawElementId);

            this.setSelectionOrDeselectionElementTitle(selectionDrawElementId, this.selectionDrawingManager);

            //Clear the map and drawing canvas when the user enters into a drawing mode.
            map.events.add('drawingmodechanged', this.selectionDrawingManager, (mode: drawing.DrawingMode) => this.handleDrawingModeChanged(mode, this.selectionDrawingManager, map));

            //Monitor for when a polygon drawing has been completed.
            map.events.add('drawingcomplete', this.selectionDrawingManager, (drawingShape: Shape) => this.handleSelectionDrawingComplete(drawingShape, map, this.selectionDrawingManager));

            this.deselectionDrawingManager = this.createDrawingManager(map, deselectionDrawElementId);

            this.setSelectionOrDeselectionElementTitle(deselectionDrawElementId, this.deselectionDrawingManager);

            //Clear the map and drawing canvas when the user enters into a drawing mode.
            map.events.add('drawingmodechanged', this.deselectionDrawingManager, (mode: drawing.DrawingMode) => this.handleDrawingModeChanged(mode, this.deselectionDrawingManager, map));

            //Monitor for when a polygon drawing has been completed.
            map.events.add('drawingcomplete', this.deselectionDrawingManager, (drawingShape: Shape) => this.handleSelectionDrawingComplete(drawingShape, map, this.deselectionDrawingManager));

            map.events.add('zoomend', () => this.handleZoomend(this.selectionDrawingManager, this.deselectionDrawingManager, this.projectPopup, this.areaTooltipPopup));

            if (callback) {
                callback();
            }
        });
    }

    createDrawingManager = (map: AzureMap, containerId: string): drawing.DrawingManager => {
        return new drawing.DrawingManager(map, {
            toolbar: new control.DrawingToolbar({
                buttons: ['draw-polygon', 'draw-rectangle'],
                position: 'top-left',
                style: 'light',
                containerId: containerId,
                visible: true
            })
        });
    }

    setSelectionOrDeselectionElementTitle = (containerElementId: string, drawingManager: drawing.DrawingManager): void => {
        let drawElement = document.getElementById(containerElementId)?.children[0];
        if (drawElement) {
            let polygonElement = drawElement.children[0];
            let rectangleElement = drawElement.children[1];
            polygonElement.setAttribute("title", drawingManager === this.selectionDrawingManager ? Translate.Resources.UI_Home_Auscultation_Button_PolygonalSelection : Translate.Resources.UI_Home_Auscultation_Button_PolygonalDeselection);
            rectangleElement.setAttribute("title", drawingManager === this.selectionDrawingManager ? Translate.Resources.UI_Home_Auscultation_Button_RectangleSelection : Translate.Resources.UI_Home_Auscultation_Button_RectangleDeselection);
        }
    }

    handleMapClick = async (e: MapMouseEvent, state: RoadsConditionAndScenariosViewState): Promise<void> => {
        const { mergedProject } = state;

        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        var isAltKeyPressed = (e.originalEvent as any).altKey;
        var isCtrlKeyPressed = (e.originalEvent as any).ctrlKey;
        var isShiftKeyPressed = (e.originalEvent as any).shiftKey;

        var clickedSectionId: number = null;
        var shape = (e.shapes[0] as any);
        if (shape) {
            clickedSectionId = shape.id ?? shape?.data?.properties?.RoadSectionId;
        }

        if (isAltKeyPressed && !isCtrlKeyPressed && !isShiftKeyPressed) {
            //désélectionner tous les sections déjà sélectionnés
            let shapes: Shape[] = datasource.getShapes();
            let selectedSections: Shape[] = shapes.filter(x => x.getProperties().EntityType === ShapeEntityType.sectionSelected);
            selectedSections.forEach((section: Shape) => { RoadsConditionAndScenariosShared.hideShape(section) });

            let roadLabelsScores = state.roadLabelsScores;
            roadLabelsScores.forEach((element) => {
                element.isSelected = false;
                element.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(false);
            });

            let selectedRoadsSummary: RoadsConditionSummaryModel = this.computeSummary(mergedProject.roadsSections);

            this.setState({
                selectedSectionsMap: new Map<number, number>(),
                lastClickedSelection: null,
                selectedRoadsSummary: selectedRoadsSummary,
                roadLabelsScores: roadLabelsScores
            });

            return;
        }

        var section: RoadSectionViewData = mergedProject.roadsSections.get(clickedSectionId);
        if (!section || section.roadSectionScoreId === null)
            return;

        console.log("Section : ");
        console.log(section);

        var clickedPosition: data.Position = e.position;
        if (clickedPosition) {

            if (!isAltKeyPressed && !isCtrlKeyPressed && !isShiftKeyPressed) {
                //Click simple: dans ce cas il faut positionner l'icone voiture dans la position de l'image la plus proche de la sélection
                this.handleSectionClicked(section, clickedPosition);
                return;
            }

            var selectedSectionsMap = new Map(state.selectedSectionsMap);
            var nearestImage = this.getRoadSectionNearestImage(clickedSectionId, clickedPosition);

            if (!isAltKeyPressed && isCtrlKeyPressed && !isShiftKeyPressed && nearestImage.imageId) {
                //Click avec cntrKey: dans ce cas il faut gérer la sélection/désélection de la section
                this.invertSectionSelection(datasource, clickedSectionId, selectedSectionsMap, nearestImage.imageId);
                return;
            }

            if (!isAltKeyPressed && !isCtrlKeyPressed && isShiftKeyPressed) {
                //Click avec shiftKey: dans ce cas il faut gérer la sélection multiple des sections
                if (state.lastClickedSelection) {

                    if (state.lastClickedSelection.roadSectionId === clickedSectionId) {
                        this.invertSectionSelection(datasource, clickedSectionId, selectedSectionsMap, nearestImage.imageId);
                        return;
                    }

                    let lastSelectedImage = state.mergedProject.imagesDico.get(state.lastClickedSelection.nearestImageId);
                    let currentSelectedImage = state.mergedProject.imagesDico.get(nearestImage.imageId);

                    if (lastSelectedImage && currentSelectedImage) {

                        this.setState({
                            selectedSectionsMap: selectedSectionsMap,
                            lastClickedSelection: { roadSectionId: clickedSectionId, nearestImageId: nearestImage.imageId }
                        });

                        let shapeId = this.getSelectedSectionShapeId(clickedSectionId);
                        let selectedSectionShape: Shape = datasource.getShapeById(shapeId);
                        if (selectedSectionShape) {
                            if (RoadsConditionAndScenariosShared.isShapeVisible(selectedSectionShape)) {
                                //Gérer une déselection multiple
                                if (lastSelectedImage.timestamp > currentSelectedImage.timestamp) {
                                    //Parcours with prev
                                    this.deselectContinuousSections(lastSelectedImage, currentSelectedImage, selectedSectionsMap, datasource, false, true);
                                }
                                else if (lastSelectedImage.timestamp < currentSelectedImage.timestamp) {
                                    //Parcours with next
                                    this.deselectContinuousSections(lastSelectedImage, currentSelectedImage, selectedSectionsMap, datasource, true, false);
                                }
                            }
                            else {
                                //Gérer une multi-selection puisque une section est déjà sélectionnée
                                if (lastSelectedImage.timestamp > currentSelectedImage.timestamp) {
                                    //Parcours with prev
                                    this.selectContinuousSections(lastSelectedImage, currentSelectedImage, selectedSectionsMap, datasource, false, true);
                                }
                                else if (lastSelectedImage.timestamp < currentSelectedImage.timestamp) {
                                    //Parcours with next
                                    this.selectContinuousSections(lastSelectedImage, currentSelectedImage, selectedSectionsMap, datasource, true, false);
                                }
                            }

                            this.updateRoadLabelsScoresSelections(state.roadLabelsScores, selectedSectionsMap);

                            let filteredSections = this.getSelectedSections(selectedSectionsMap, state.mergedProject);
                            let selectedRoadsSummary = this.computeSummary(filteredSections);

                            this.setState({
                                selectedSectionsMap: selectedSectionsMap,
                                selectedRoadsSummary: selectedRoadsSummary
                            });
                        }
                    }

                }
                else {
                    //Click avec shift sans aucune selection précédente: dans ce cas il faut gérer la sélection/désélection de la section
                    this.invertSectionSelection(datasource, clickedSectionId, selectedSectionsMap, nearestImage.imageId);
                }

                return;
            }
        }
    }

    handleSectionClicked = (section: RoadSectionViewData, clickedPosition: data.Position): void => {
        //Chercher les images de la section
        var images: ImageExtended[] = section?.images;
        //Chercher l'image la plus proche
        var image: ImageExtended = this.getNearestImageFromClickedPosition(clickedPosition, images);
        this.setImagePosition(image);

        //Récuppérer le contenu de l'image + chercher les anomalies de la section
        if (this.hasScoreAnalysisAccess) {
            this.initTrustedAnomaliesCounters(section);
        }

        this.showImageAndSectionDetails(image);
    }

    handleProjectPinClick = (mergedProject: MergedProjectVersion): void => {
        this.map.setCamera({
            bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
            padding: 20
        });
    }

    getAreaTooltip = (label: string, typeOfWork: string): string => {
        if (label?.trim() && typeOfWork?.trim())
            return `${label} (${typeOfWork})`;

        if (label?.trim() && !typeOfWork?.trim())
            return `${label}`;

        if (!label?.trim() && typeOfWork?.trim())
            return `(${typeOfWork})`;

        if (!label?.trim() && !typeOfWork?.trim())
            return null;

        return null;
    }

    handleRoadLayerMouseover = (e: MapMouseEvent): void => {
        this.handleLayerMouseover();

        let tooltips: string[] = [];
        let areasAlreadyChecked = new Map<number, number>();
        e.shapes.forEach((s) => {
            let shape = s as Shape;
            let props = shape.getProperties();
            if (props.EntityType === ShapeEntityType.area) {
                let sectionId = props.RoadSectionId;
                if (this.state.selectedScenario.areasBySectionId.has(sectionId)) {
                    let areas = this.state.selectedScenario.areasBySectionId.get(sectionId);
                    areas.forEach((area) => {
                        if (!areasAlreadyChecked.has(area.maintenanceAreaId)) {
                            areasAlreadyChecked.set(area.maintenanceAreaId, area.maintenanceAreaId);
                            let tooltip = this.getAreaTooltip(area.label, area.typeOfWork);
                            if (tooltip) {
                                tooltips.push(tooltip);
                            }
                        }
                    });
                }
            }
        });

        if (tooltips.length > 0) {
            let content = `<div style="padding:10px;">`;
            tooltips.forEach(tooltip => {
                content += `<p style="margin:0;">${tooltip}</p>`;
            });
            content += `</div>`;

            this.areaTooltipPopup.setOptions({
                content: content,
                position: e.position,
                pixelOffset: [0, -18]
            });
            this.areaTooltipPopup.open(this.map);
        }
    }

    handleRoadLayerMouseout = (): void => {
        this.handleLayerMouseout();

        if (this.areaTooltipPopup.isOpen()) {
            this.areaTooltipPopup.close();
        }
    }

    handleLayerMouseover = (): void => {
        if (this.selectionDrawingManager.getOptions().mode === drawing.DrawingMode.idle && this.deselectionDrawingManager.getOptions().mode === drawing.DrawingMode.idle) {
            RoadsConditionAndScenariosShared.setMapCursor(this.map, MapCursorMode.Pointer);
        }
    }

    handleLayerMouseout = (): void => {
        if (this.selectionDrawingManager.getOptions().mode === drawing.DrawingMode.idle && this.deselectionDrawingManager.getOptions().mode === drawing.DrawingMode.idle) {
            RoadsConditionAndScenariosShared.setMapCursor(this.map, MapCursorMode.Auto);
        }
    }

    handleProjectPinMouseover = (e): void => {
        e = e as MapMouseEvent;
        this.handleRoadLayerMouseover(e);

        let locationState = this.props.location.state as RouteLocationStateModel;
        let projectLabel = locationState.label;

        this.projectPopup.setOptions({
            content: `<div style="padding:10px;">${projectLabel}</div>`,
            position: e.position,
            pixelOffset: [0, -18]
        });
        this.projectPopup.open(this.map);
    }

    handleProjectPinMouseout = (): void => {
        this.handleRoadLayerMouseout();
        this.projectPopup.close();
    }

    handleDrawingModeChanged = (mode: drawing.DrawingMode, drawingManager: drawing.DrawingManager, map: AzureMap): void => {
        let elementId = drawingManager === this.selectionDrawingManager ? selectionDrawElementId : deselectionDrawElementId;
        let drawElement = document.getElementById(elementId)?.children[0];

        let polygonElement = drawElement?.children[0];
        let drawPolygonClassName = polygonElement?.className;

        let rectangleElement = drawElement?.children[1];
        let drawRectangleClassName = rectangleElement?.className;

        switch (mode) {
            case drawing.DrawingMode.drawRectangle:
                if (rectangleElement) {
                    rectangleElement.className += " selected";
                }

                if (polygonElement && drawPolygonClassName && drawPolygonClassName.includes(" selected")) {
                    polygonElement.className = drawPolygonClassName.replace(" selected", "");
                }
                break;

            case drawing.DrawingMode.drawPolygon:
                if (polygonElement) {
                    polygonElement.className += " selected";
                }

                if (rectangleElement && drawRectangleClassName && drawRectangleClassName.includes(" selected")) {
                    rectangleElement.className = drawRectangleClassName.replace(" selected", "");
                }
                break;

            default:
                this.deselectDrawingButtons(drawingManager);
                break;
        }

        drawingManager.getSource().clear();

        if (mode !== drawing.DrawingMode.idle) {
            if (drawingManager === this.selectionDrawingManager) {
                this.deselectionDrawingManager.setOptions({ mode: drawing.DrawingMode.idle });
            }
            else {
                this.selectionDrawingManager.setOptions({ mode: drawing.DrawingMode.idle });
            }
        }

        var otherDrawingManager = drawingManager === this.selectionDrawingManager ? this.deselectionDrawingManager : this.selectionDrawingManager;
        if (mode !== drawing.DrawingMode.idle || otherDrawingManager.getOptions()?.mode !== drawing.DrawingMode.idle) {
            RoadsConditionAndScenariosShared.setMapCursor(map, MapCursorMode.Crosshair);
        }
        else {
            RoadsConditionAndScenariosShared.setMapCursor(map, MapCursorMode.Auto);
        }
    }

    handleSelectionDrawingComplete = (drawingShape: Shape, map: AzureMap, drawingManager: drawing.DrawingManager): void => {
        let selectedSectionsMap = new Map(this.state.selectedSectionsMap);
        let shapeId: string = null;
        let clickedSectionId: number = null;
        let nearestImageId: number = null;

        var drawingGeometry = drawingShape.toJson().geometry as Polygon;
        var datasource = map.sources.getById(mainDatasourceId) as source.DataSource;

        datasource.getShapes().forEach((shape: Shape) => {
            shapeId = shape.getId() as string;
            if (shape.getProperties().EntityType === ShapeEntityType.section) {
                clickedSectionId = parseInt(shapeId);

                //shape.toJson().geometry as Line
                var shapeGeometry = this.state.projectVersion.roadsSections.get(clickedSectionId).pathGeometry;
                var isLineInPolygon: boolean = booleanContains.isLineInPoly(drawingGeometry, shapeGeometry as Line);
                if (isLineInPolygon) {
                    var nearestImage = this.getRoadSectionNearestImage(clickedSectionId);
                    if (nearestImage) {
                        nearestImageId = nearestImage.imageId;
                        var selectedSectionShapeId = this.getSelectedSectionShapeId(clickedSectionId);
                        var selectedSectionShape = datasource.getShapeById(selectedSectionShapeId);

                        if (drawingManager === this.selectionDrawingManager && !RoadsConditionAndScenariosShared.isShapeVisible(selectedSectionShape)) {
                            this.showSectionSelected(selectedSectionShape);
                            selectedSectionsMap.set(clickedSectionId, nearestImageId);
                        } else if (drawingManager === this.deselectionDrawingManager && RoadsConditionAndScenariosShared.isShapeVisible(selectedSectionShape)) {
                            RoadsConditionAndScenariosShared.hideShape(selectedSectionShape);
                            selectedSectionsMap.delete(clickedSectionId);
                        }
                    }
                }
            }
        });

        this.updateRoadLabelsScoresSelections(this.state.roadLabelsScores, selectedSectionsMap);

        var filteredSections = this.getSelectedSections(selectedSectionsMap, this.state.mergedProject);
        var selectedRoadsSummary = this.computeSummary(filteredSections);

        this.setState({
            selectedSectionsMap: selectedSectionsMap,
            lastClickedSelection: selectedSectionsMap.size > 0 ? { roadSectionId: clickedSectionId, nearestImageId: nearestImageId } : null,
            selectedRoadsSummary: selectedRoadsSummary
        });

        drawingManager.setOptions({ mode: drawing.DrawingMode.idle });
        //TODO HGA mutualiser avec handleDrawingModeChanged
        this.deselectDrawingButtons(drawingManager);
    }

    deselectDrawingButtons = (drawingManager: drawing.DrawingManager): void => {
        let elementId = drawingManager === this.selectionDrawingManager ? selectionDrawElementId : deselectionDrawElementId;
        let drawElement = document.getElementById(elementId)?.children[0];
        if (drawElement) {
            for (var i = 0; i < drawElement.children.length; i++) {
                var toolButton = drawElement.children[i];
                var className = toolButton.className;
                if (toolButton && className && className.includes(" selected")) {
                    toolButton.className = className.replace(" selected", "");
                }
            }
        }
    }

    buildExtendedRoadLabelsScoreRanking = (data: MultiAuscultationMergedScores, roads: Map<number, RoadViewData>): RoadLabelsScoreRankingExtended => {
        var roadLabelsScoreRanking = data.roadLabelsScoreRanking;
        var roadLabelsScores = new Map<string, RoadLabelScoreExtended>();
        if (roadLabelsScoreRanking) {
            var minFixedAverageScore = 0;
            var maxFixedAverageScore = 0;

            roadLabelsScoreRanking.roadLabelScores.forEach((element: RoadLabelScore, index: number) => {
                var fixedAverageScore = element.averageScore;

                if (fixedAverageScore < minFixedAverageScore) {
                    minFixedAverageScore = fixedAverageScore;
                }

                if (fixedAverageScore > maxFixedAverageScore) {
                    maxFixedAverageScore = fixedAverageScore;
                }

                let sectionIds: number[] = [];
                element.roadIds.forEach((roadId: number) => {
                    let road = roads.get(roadId);
                    sectionIds = sectionIds.concat(Array.from(road.extendedSections.keys()));
                });

                var item: RoadLabelScoreExtended = {
                    ...element,
                    index: index + 1,
                    fixedAverageScore: fixedAverageScore?.toFixedLocalized(2, 2),
                    lengthInMeters: Math.round(element.lengthInMetersScored),
                    hiddenFixedAverageScoreForSort: fixedAverageScore,
                    isSelected: false,
                    hiddenIsSelectedForSort: getHiddenIsSelectedForSort(false),
                    isVisible: true,
                    labelLowerWithoutDiacritics: element.label.toLowerCase().removeDiacritics(),
                    hiddenLabelForSort: element.labelIsReferenceRoadId ? `zzz${element.label}` : element.label,
                    sectionIds: sectionIds,
                    relatedAreas: [],
                    hiddenIsIncludedInAreaForSort: getHiddenIsIncludedInAreaForSort([])
                };
                roadLabelsScores.set(item.label, item);
            });
        }

        maxFixedAverageScore = Math.ceil(maxFixedAverageScore);

        return {
            roadLabelsScores: roadLabelsScores,
            roadScoreRange: { min: minFixedAverageScore, max: maxFixedAverageScore },
            averageScoreSliderValue: [minFixedAverageScore * 10, maxFixedAverageScore * 10]
        };
    }

    computeSummary = (roadsSections: Map<number, RoadSectionViewData>): RoadsConditionSummaryModel => {
        if (roadsSections) {
            var totalSectionsLengthInMeters = 0;
            var totalPoorConditionSectionsLengthInMeters = 0;
            var totalToMonitorSectionsLengthInMeters = 0;
            var totalGoodConditionSectionLengthInMeters = 0;
            var totalPoorConditionSectionsSurface = 0;
            var totalToMonitorSectionsSurface = 0;
            var totalGoodConditionSectionSurface = 0;

            roadsSections.forEach((section: RoadSectionViewData) => {
                switch (section.scoreColor) {
                    case ScoreColors.poor:
                        totalPoorConditionSectionsLengthInMeters += section.lengthInMeters;
                        totalPoorConditionSectionsSurface += (section.lengthInMeters * section.roadExtended.widthInMeters);
                        break;

                    case ScoreColors.toMonitor:
                        totalToMonitorSectionsLengthInMeters += section.lengthInMeters;
                        totalToMonitorSectionsSurface += (section.lengthInMeters * section.roadExtended.widthInMeters);
                        break;

                    case ScoreColors.good:
                        totalGoodConditionSectionLengthInMeters += section.lengthInMeters;
                        totalGoodConditionSectionSurface += (section.lengthInMeters * section.roadExtended.widthInMeters);
                        break;

                    default:
                        break;
                }

                totalSectionsLengthInMeters = (section.roadSectionScoreId !== null) ? totalSectionsLengthInMeters + section.lengthInMeters : totalSectionsLengthInMeters;
            });

            return {
                totalSectionsLengthInMeters: totalSectionsLengthInMeters,
                poorConditionSectionsLengthInMeters: totalPoorConditionSectionsLengthInMeters,
                toMonitorConditionSectionsLengthInMeters: totalToMonitorSectionsLengthInMeters,
                goodConditionSectionsLengthInMeters: totalGoodConditionSectionLengthInMeters,
                poorConditionSectionsPercent: (totalPoorConditionSectionsLengthInMeters * 100) / totalSectionsLengthInMeters,
                toMonitorConditionSectionsPercent: (totalToMonitorSectionsLengthInMeters * 100) / totalSectionsLengthInMeters,
                goodConditionSectionsPercent: (totalGoodConditionSectionLengthInMeters * 100) / totalSectionsLengthInMeters,
                poorConditionSectionsSurface: totalPoorConditionSectionsSurface,
                toMonitorConditionSectionsSurface: totalToMonitorSectionsSurface,
                goodConditionSectionsSurface: totalGoodConditionSectionSurface
            } as RoadsConditionSummaryModel;
        }

        return null;
    }

    //TODO HGA refactorer la methode pour quel retourne uniquement les ids des sections calculés pour sortir le changement de state d'ici
    selectContinuousSections = (lastSelectedImage: ImageExtended, currentSelectedImage: ImageExtended, selectedSectionsMap: Map<number, number>, datasource: source.DataSource, searchByNext: boolean, searchByPrevious: boolean): void => {
        var imageId: number = null;
        if (searchByNext)
            imageId = lastSelectedImage.nextImageId;

        if (searchByPrevious)
            imageId = lastSelectedImage.previousImageId;

        while (imageId && imageId !== currentSelectedImage.imageId) {
            var image = this.state.mergedProject.imagesDico.get(imageId);

            if (!image)
                break;

            let newSectionId = image.roadSectionId;

            if (!selectedSectionsMap.has(newSectionId)) {
                this.showSectionSelectedShape(datasource, newSectionId);
                selectedSectionsMap.set(newSectionId, imageId);
            }

            if (searchByNext)
                imageId = image.nextImageId;

            if (searchByPrevious)
                imageId = image.previousImageId;

        }

        if (!selectedSectionsMap.has(currentSelectedImage.roadSectionId)) {
            this.showSectionSelectedShape(datasource, currentSelectedImage.roadSectionId);
            selectedSectionsMap.set(currentSelectedImage.roadSectionId, currentSelectedImage.imageId);
        }
    }

    //TODO HGA refactorer la methode pour quel retourne uniquement les ids des sections calculés pour sortir le changement de state d'ici
    deselectContinuousSections = (lastDeSelectedImage: ImageExtended, currentDeSelectedImage: ImageExtended, selectedSectionsMap: Map<number, number>, datasource: source.DataSource, searchByNext: boolean, searchByPrevious: boolean): void => {
        var imageId: number = null;
        if (searchByNext)
            imageId = lastDeSelectedImage.nextImageId;

        if (searchByPrevious)
            imageId = lastDeSelectedImage.previousImageId;

        while (imageId && imageId !== currentDeSelectedImage.imageId) {
            var image = this.state.mergedProject.imagesDico.get(imageId);

            if (!image)
                break;

            let newSectionId = image.roadSectionId;

            this.hideSectionSelectedShape(datasource, newSectionId);
            selectedSectionsMap.delete(newSectionId);

            if (searchByNext)
                imageId = image.nextImageId;

            if (searchByPrevious)
                imageId = image.previousImageId;
        }

        if (selectedSectionsMap.has(currentDeSelectedImage.roadSectionId)) {
            this.hideSectionSelectedShape(datasource, currentDeSelectedImage.roadSectionId);
            selectedSectionsMap.delete(currentDeSelectedImage.roadSectionId);
        }
    }

    initTrustedAnomaliesCounters = (section: RoadSectionViewData): void => {
        if (section.trustedAnomaliesCounters)
            return;

        let scoringParameters = section.roadExtended?.scoringParameters;
        let sectionTrustedAnomaliesCounters = new Map<string, { counter: number, scoringWeight: number }>();

        section.images.forEach((image) => {
            let imageTrustedAnomaliesCounters = this.getTrustedAnomaliesCounters(scoringParameters, new Map<number, ImageAnomaly[]>([[image.imageId, image.imageAnomalies]]));
            image.trustedAnomaliesCounters = imageTrustedAnomaliesCounters;
            imageTrustedAnomaliesCounters.forEach((value, key) => {
                let anomaly = sectionTrustedAnomaliesCounters.get(key);
                if (!anomaly) {
                    anomaly = { counter: value.counter, scoringWeight: value.scoringWeight };
                    sectionTrustedAnomaliesCounters.set(key, anomaly);
                }
                else {
                    anomaly.counter += value.counter;
                }
            });
        });

        section.trustedAnomaliesCounters = sectionTrustedAnomaliesCounters;
    }

    getTrustedAnomaliesCounters = (scoringParameters: Map<string, ScoringParameter>, imageAnomaliesMap: Map<number, ImageAnomaly[]>): Map<string, { counter: number, scoringWeight: number }> => {
        var scoringParameter: ScoringParameter = null;
        var anomalies = new Map<string, { counter: number, scoringWeight: number }>();

        imageAnomaliesMap.forEach((x) => {
            x.forEach(y => {
                scoringParameter = scoringParameters.get(y.anomalyType);
                if (scoringParameter && y.confidence >= scoringParameter.confidenceThreshold) {
                    let anomaly = anomalies.get(y.anomalyType);
                    let counter = anomaly?.counter ?? 0;
                    anomalies.set(y.anomalyType, { counter: counter + 1, scoringWeight: scoringParameter.weight });
                }
            });
        });

        return anomalies;
    }

    showImageContent = (image: ImageExtended, sectionId: number, roadSectionLabel: string): void => {
        this.setState((prevState, props) => {
            var isLabeledImagesContentOpened = true;
            if (!prevState.isLabeledImagesContentOpened) {
                if (this.props.isScenarioView) {
                    this.resizeMapWhenScenarioView(this.map, prevState.openedMapDrawer, prevState.openedScenariosSelectorDrawer, prevState.openedScenarioManagementDrawer, prevState.openedAuscultationsSelectorWithRoadsDrawer, isLabeledImagesContentOpened);
                }
                else {
                    this.resizeMapWhenRoadsConditionView(this.map, prevState.openedAuscultationsSelectorWithRoadsDrawer, isLabeledImagesContentOpened);
                }
            }

            return {
                selectedImage: image,
                selectedRoadSection: prevState.mergedProject.roadsSections.get(sectionId),
                selectedRoadSectionLabel: roadSectionLabel,
                isLabeledImagesContentOpened: isLabeledImagesContentOpened
            }
        });
    }

    handleCloseLabeledImageComponent = (state: RoadsConditionAndScenariosViewState): void => {
        var isLabeledImagesContentOpened = false;
        if (this.props.isScenarioView) {
            this.resizeMapWhenScenarioView(this.map, state.openedMapDrawer, state.openedScenariosSelectorDrawer, state.openedScenarioManagementDrawer, state.openedAuscultationsSelectorWithRoadsDrawer, isLabeledImagesContentOpened);
        }
        else {
            this.resizeMapWhenRoadsConditionView(this.map, state.openedAuscultationsSelectorWithRoadsDrawer, isLabeledImagesContentOpened);
        }

        var datasource = this.map.sources.getById(carDatasourceId) as source.DataSource;
        var existingPin: Shape = datasource.getShapeById(carId);
        if (existingPin) {
            datasource.remove(existingPin);
        }

        this.setState({
            selectedImage: null,
            selectedRoadSection: null,
            selectedRoadSectionLabel: null,
            isLabeledImagesContentOpened: isLabeledImagesContentOpened
        });
    }

    invertSectionSelection = (datasource: source.DataSource, clickedSectionId: number, selectedSectionsMap: Map<number, number>, nearestImageId: number): void => {
        var shapeId = this.getSelectedSectionShapeId(clickedSectionId);
        var shape = datasource.getShapeById(shapeId);
        if (RoadsConditionAndScenariosShared.isShapeVisible(shape)) {
            RoadsConditionAndScenariosShared.hideShape(shape);
            selectedSectionsMap.delete(clickedSectionId);
        }
        else {
            this.showSectionSelected(shape);
            selectedSectionsMap.set(clickedSectionId, nearestImageId);
        }

        this.updateRoadLabelsScoresSelections(this.state.roadLabelsScores, selectedSectionsMap);

        var filteredSections = this.getSelectedSections(selectedSectionsMap, this.state.mergedProject);
        var selectedRoadsSummary = this.computeSummary(filteredSections);

        this.setState({
            selectedSectionsMap: selectedSectionsMap,
            lastClickedSelection: selectedSectionsMap.size > 0 ? { roadSectionId: clickedSectionId, nearestImageId: nearestImageId } : null,
            selectedRoadsSummary: selectedRoadsSummary
        });
    }

    getNearestImageFromClickedPosition = (clickedPosition: data.Position, images: ImageExtended[]): ImageExtended => {
        var finished: boolean = false;
        var indexStart: number = 0;

        //Création d'aun array qui contient l'ensmble des images de la section et leurs distance par rapport au position cliqué 
        var imagesDistanceFromSelectedPoint = [];
        while (!finished) {
            if (indexStart >= images.length) {
                finished = true;
            } else {
                var image: ImageExtended = images[indexStart];
                imagesDistanceFromSelectedPoint.push({
                    imgId: image.imageId,
                    distance: math.getDistanceTo(clickedPosition, image.locationGeometry.coordinates)
                });
                indexStart = indexStart + 1;
            }
        }

        //Chercher l'image la plus proche (la distance la plus petite de l'ensemble des images de la section)
        var nearByImageDistance: number = Math.min(...imagesDistanceFromSelectedPoint.map(elem => elem.distance));
        var nearByImageId: number = imagesDistanceFromSelectedPoint.find(x => x.distance === nearByImageDistance)?.imgId;

        return images.find(i => i.imageId === nearByImageId);
    }

    getRoadSectionNearestImage = (sectionId: number, clickedPosition?: data.Position): ImageExtended => {
        var roadSection = this.state.mergedProject.roadsSections.get(sectionId);
        if (roadSection.roadSectionScoreId === null)
            return null;

        if (clickedPosition)
            return this.getNearestImageFromClickedPosition(clickedPosition, roadSection?.images);

        if (roadSection?.images && roadSection.images.length > 0)
            return roadSection.images[0];

        return null;
    }

    setImagePosition = (image: ImageExtended): void => {
        //TODO HGA à faire uniquement dans un mode debug admin
        console.log("Image : ");
        console.log(image);

        var datasource = this.map.sources.getById(carDatasourceId) as source.DataSource;
        if (datasource) {
            var existingPin: Shape = datasource.getShapeById(carId);
            if (existingPin) {
                datasource.remove(existingPin);
            }
        }
        else {
            datasource = new source.DataSource(carDatasourceId);
            this.map.sources.add(datasource);
        }

        var heading: number = 0;
        if (image.nextImageId != null) {
            var nextImage = this.state.mergedProject.imagesDico.get(image.nextImageId);
            if (nextImage != null) {
                heading = math.getHeading(image.locationGeometry, nextImage.locationGeometry);
            }
        }

        this.createCarIconPin(image.locationGeometry.coordinates, datasource, carId, heading);
    }

    updateRoadLabelsScoresSelections = (roadLabelsScores: Map<string, RoadLabelScoreExtended>, selectedSections: Map<number, number>): void => {
        roadLabelsScores.forEach((x: RoadLabelScoreExtended) => {
            let count: number = 0;
            x.sectionIds.forEach((sectionId: number) => {
                if (selectedSections.has(sectionId)) {
                    count++;
                }
            });

            let roadLabelSectionsLength = x.sectionIds.length;
            if (count === roadLabelSectionsLength) {
                x.isSelected = true;
                x.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(true);
                x.isVisible = true;
            }
            else if (count === 0) {
                x.isSelected = false;
                x.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(false);
            }
            else if (count < roadLabelSectionsLength) {
                x.isSelected = null;
                x.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(null);
                x.isVisible = true;
            }
        });
    }

    getSelectedSections = (selectedSectionsMap: Map<number, number>, mergedProject: MergedProjectVersion): Map<number, RoadSectionViewData> => {
        var selectedSectionsArray = Array.from(selectedSectionsMap);
        var roadsSectionsArray = selectedSectionsMap.size > 0
            ? Array.from(mergedProject.roadsSections).filter(x => selectedSectionsArray.map(y => y[0]).includes(x[0]))
            : Array.from(mergedProject.roadsSections);
        var roadsSectionsDico = new Map<number, RoadSectionViewData>();
        roadsSectionsArray.forEach((element) => {
            roadsSectionsDico.set(element[0], element[1])
        });

        return roadsSectionsDico;
    }

    handleAuscultationsSelectorWithRoadsDrawerOpened = (state: RoadsConditionAndScenariosViewState): void => {
        let openedAuscultationsSelectorWithRoadsDrawer = true;
        LocalStorage.SetItem(moduleKey, openedAuscultationsSelectorWithRoadsDrawerKey, '' + openedAuscultationsSelectorWithRoadsDrawer);

        if (this.props.isScenarioView) {
            this.resizeMapWhenScenarioView(this.map, state.openedMapDrawer, state.openedScenariosSelectorDrawer, state.openedScenarioManagementDrawer, openedAuscultationsSelectorWithRoadsDrawer, state.isLabeledImagesContentOpened);
        }
        else {
            this.resizeMapWhenRoadsConditionView(this.map, openedAuscultationsSelectorWithRoadsDrawer, state.isLabeledImagesContentOpened);
        }

        this.setState({ openedAuscultationsSelectorWithRoadsDrawer: openedAuscultationsSelectorWithRoadsDrawer });
    }

    handleAuscultationsSelectorWithRoadsDrawerClosed = (state: RoadsConditionAndScenariosViewState): void => {
        let openedAuscultationsSelectorWithRoadsDrawer = false;
        LocalStorage.SetItem(moduleKey, openedAuscultationsSelectorWithRoadsDrawerKey, '' + openedAuscultationsSelectorWithRoadsDrawer);

        if (this.props.isScenarioView) {
            this.resizeMapWhenScenarioView(this.map, state.openedMapDrawer, state.openedScenariosSelectorDrawer, state.openedScenarioManagementDrawer, openedAuscultationsSelectorWithRoadsDrawer, state.isLabeledImagesContentOpened);
        }
        else {
            this.resizeMapWhenRoadsConditionView(this.map, openedAuscultationsSelectorWithRoadsDrawer, state.isLabeledImagesContentOpened);
        }

        this.setState({ openedAuscultationsSelectorWithRoadsDrawer: openedAuscultationsSelectorWithRoadsDrawer });
    }

    handleScenariosSelectorDrawerOpened = (state: RoadsConditionAndScenariosViewState): void => {
        let openedScenariosSelectorDrawer = true;
        LocalStorage.SetItem(moduleKey, openedScenariosSelectorDrawerKey, '' + openedScenariosSelectorDrawer);

        this.resizeMapWhenScenarioView(this.map, state.openedMapDrawer, openedScenariosSelectorDrawer, state.openedScenarioManagementDrawer, state.openedAuscultationsSelectorWithRoadsDrawer, state.isLabeledImagesContentOpened);
        this.setState({ openedScenariosSelectorDrawer: openedScenariosSelectorDrawer });
    }

    handleScenariosSelectorDrawerClosed = (state: RoadsConditionAndScenariosViewState): void => {
        let openedScenariosSelectorDrawer = false;
        LocalStorage.SetItem(moduleKey, openedScenariosSelectorDrawerKey, '' + openedScenariosSelectorDrawer);

        this.resizeMapWhenScenarioView(this.map, state.openedMapDrawer, openedScenariosSelectorDrawer, state.openedScenarioManagementDrawer, state.openedAuscultationsSelectorWithRoadsDrawer, state.isLabeledImagesContentOpened);
        this.setState({ openedScenariosSelectorDrawer: openedScenariosSelectorDrawer });
    }

    handleScenarioManagementDrawerOpened = (state: RoadsConditionAndScenariosViewState): void => {
        let openedScenarioManagementDrawer = true;
        LocalStorage.SetItem(moduleKey, openedScenarioManagementDrawerKey, '' + openedScenarioManagementDrawer);

        this.resizeMapWhenScenarioView(this.map, state.openedMapDrawer, state.openedScenariosSelectorDrawer, openedScenarioManagementDrawer, state.openedAuscultationsSelectorWithRoadsDrawer, state.isLabeledImagesContentOpened);
        this.setState({ openedScenarioManagementDrawer: openedScenarioManagementDrawer });
    }

    handleScenarioManagementDrawerClosed = (state: RoadsConditionAndScenariosViewState): void => {
        let openedScenarioManagementDrawer = false;
        LocalStorage.SetItem(moduleKey, openedScenarioManagementDrawerKey, '' + openedScenarioManagementDrawer);

        this.resizeMapWhenScenarioView(this.map, state.openedMapDrawer, state.openedScenariosSelectorDrawer, openedScenarioManagementDrawer, state.openedAuscultationsSelectorWithRoadsDrawer, state.isLabeledImagesContentOpened);
        this.setState({ openedScenarioManagementDrawer: openedScenarioManagementDrawer });
    }

    handleMapWithImagesDrawerOpened = (state: RoadsConditionAndScenariosViewState): void => {
        var openedMapDrawer = true;
        LocalStorage.SetItem(moduleKey, openedMapDrawerKey, '' + openedMapDrawer);

        this.setState({
            openedMapDrawer: openedMapDrawer
        },
            () => {
                this.resizeMapWhenScenarioView(this.map, openedMapDrawer, state.openedScenariosSelectorDrawer, state.openedScenarioManagementDrawer, state.openedAuscultationsSelectorWithRoadsDrawer, state.isLabeledImagesContentOpened);
                this.setMapZoom(this.map, state.mergedProject, true, openedMapDrawer);
            }
        );
    }

    handleMapWithImagesDrawerClosed = (state: RoadsConditionAndScenariosViewState): void => {
        var openedMapDrawer = false;
        LocalStorage.SetItem(moduleKey, openedMapDrawerKey, '' + openedMapDrawer);

        this.resizeMapWhenScenarioView(this.map, openedMapDrawer, state.openedScenariosSelectorDrawer, state.openedScenarioManagementDrawer, state.openedAuscultationsSelectorWithRoadsDrawer, state.isLabeledImagesContentOpened);
        this.setState({ openedMapDrawer: openedMapDrawer });
    }

    handleSelectAuscultation = async (auscultationId: number, isCompleteHeritageChecked: boolean, state: RoadsConditionAndScenariosViewState): Promise<void> => {
        let selectedAuscultation = state.projectVersion.extendedAuscultations.get(auscultationId);
        let auscultationsIdsArray = this.getAuscultationIds(isCompleteHeritageChecked, selectedAuscultation);
        let auscultationsIdsString: string = Utilities.GetCommaSeparatedString(auscultationsIdsArray);
        let multiAuscultationMergedScores = await RoadsConditionAndScenariosShared.getMergedScoresRawData(this.selectedProject.projectVersionId, auscultationsIdsString, this.thresholdOfPoorScore);

        let mergedProject = RoadsConditionAndScenariosShared.buildMergedProject(state.projectVersion, multiAuscultationMergedScores, auscultationsIdsString, this.shouldShowUnscoredSections);
        let selectedRoadsSummary = this.computeSummary(mergedProject.roadsSections);
        let roadLabelsRanking = this.buildExtendedRoadLabelsScoreRanking(multiAuscultationMergedScores, mergedProject.extendedRoads);

        this.createMapSectionsShapes(mergedProject, state.activeRoadsConditions);

        this.inputSearchRoadsRef.current.value = "";

        let hasAnomaliesLayerMapEvent = true;
        RoadsConditionAndScenariosShared.removeAnomaliesDatasource(this.map, hasAnomaliesLayerMapEvent, this.anomalyLayerMouseover, this.anomalyLayerMouseout, this.anomalyPointClickHandler);

        if (this._isMounted) {
            this.setState((prevState, props) => {
                let isLabeledImagesContentOpened = prevState.isLabeledImagesContentOpened;
                if (prevState.isLabeledImagesContentOpened) {
                    isLabeledImagesContentOpened = false;
                    this.resizeMapWhenRoadsConditionView(this.map, prevState.openedAuscultationsSelectorWithRoadsDrawer, isLabeledImagesContentOpened);
                }

                this.setMapZoom(this.map, mergedProject, true, state.openedMapDrawer);

                return {
                    mergedProject: mergedProject,
                    selectedRoadsSummary: selectedRoadsSummary,
                    selectedImage: null,
                    selectedRoadSection: null,
                    selectedRoadSectionLabel: null,
                    isLabeledImagesContentOpened: isLabeledImagesContentOpened,
                    selectedSectionsMap: new Map<number, number>(),
                    roadLabelsScores: roadLabelsRanking.roadLabelsScores,
                    roadScoreRange: roadLabelsRanking.roadScoreRange,
                    averageScoreSliderValue: roadLabelsRanking.averageScoreSliderValue,
                    activeAnomalies: new Set<string>(),
                    isGroupView: false
                }
            });
        }
    }

    getAuscultationIds = (isCompleteHeritageValue: boolean, selectedAuscultation: AuscultationExtended): number[] => {
        const { projectVersion } = this.state;
        var auscultationIds: number[] = [];
        if (isCompleteHeritageValue) {
            var extendedAuscultations: AuscultationExtended[] = [];
            for (let item of Array.from(projectVersion.extendedAuscultations)) {
                extendedAuscultations.push(item[1]);
            }

            var orderedAuscultations = orderBy(extendedAuscultations, ['videoDateTime', 'processingDateTime', 'auscultationNumber'], ['asc', 'asc', 'asc']);
            for (let item of orderedAuscultations) {
                auscultationIds.push(item.auscultationId);
                if (item.auscultationId === selectedAuscultation.auscultationId)
                    break;
            }
        }
        else
            auscultationIds.push(selectedAuscultation.auscultationId);

        return auscultationIds;
    }

    handleChangeImage = async (imageId: number, state: RoadsConditionAndScenariosViewState): Promise<void> => {
        var image: ImageExtended = state.mergedProject.imagesDico.get(imageId);
        if (image) {
            this.setImagePosition(image);
            var roadSection = state.mergedProject.roadsSections.get(image.roadSectionId);

            if (this.hasScoreAnalysisAccess) {
                this.initTrustedAnomaliesCounters(roadSection);
            }

            this.showImageAndSectionDetails(image);
        }
    }

    handleSectionChanged = (selectedImage: ImageExtended, searchByNext: boolean, state: RoadsConditionAndScenariosViewState): void => {
        var image: ImageExtended = state.mergedProject.imagesDico.get(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId);
        while (image?.roadSectionId === selectedImage.roadSectionId) {
            image = state.mergedProject.imagesDico.get(searchByNext ? image.nextImageId : image.previousImageId);
        }

        if (image) {
            this.handleChangeImage(image.imageId, state);
        }
    }

    handleRoadLabelRowClick = (roadLabelRow: RoadLabelScoreExtended, checked: boolean, state: RoadsConditionAndScenariosViewState): void => {
        if (!state.loading) {

            var selectedSectionsMap = new Map(state.selectedSectionsMap);
            var label = roadLabelRow.label;
            var roadIds = roadLabelRow.roadIds;

            var lastSectionNearestImage = this.selectOrUnselectRelatedRoadsSections(roadIds, state, checked, selectedSectionsMap);

            var roadLabelsScores = state.roadLabelsScores;
            var element = roadLabelsScores.get(label);
            element.isSelected = checked;
            element.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(checked);

            var filteredSections = this.getSelectedSections(selectedSectionsMap, state.mergedProject);
            var selectedRoadsSummary = this.computeSummary(filteredSections);

            if (selectedSectionsMap.size > 0) {
                var selectedSectionsPositions: data.Position[] = [];
                Array.from(selectedSectionsMap).forEach((section) => {
                    var sectionId: number = section[0];
                    var roadSection = state.mergedProject.roadsSections.get(sectionId);
                    var pathGeometry = roadSection.pathGeometry;
                    pathGeometry.coordinates.forEach((position: data.Position) => {
                        selectedSectionsPositions.push(position);
                    });
                });
                if (!this.props.isScenarioView || (this.props.isScenarioView && state.openedMapDrawer)) {
                    this.map.setCamera({
                        bounds: data.BoundingBox.fromPositions(selectedSectionsPositions),
                        padding: 30,
                        type: cameraAnimationType,
                        duration: cameraAnimationDuration
                    });
                }
            }
            else {
                this.setMapZoom(this.map, state.mergedProject, true, state.openedMapDrawer);
            }

            this.setState({
                selectedSectionsMap: selectedSectionsMap,
                lastClickedSelection: selectedSectionsMap.size > 0 ? { roadSectionId: lastSectionNearestImage.roadSectionId, nearestImageId: lastSectionNearestImage.imageId } : null,
                selectedRoadsSummary: selectedRoadsSummary,
                roadLabelsScores: roadLabelsScores
            });
        }
    }

    handleRoadLabelsHeaderCheckboxChanged = (checked: boolean, state: RoadsConditionAndScenariosViewState): void => {
        var selectedSectionsMap = new Map(state.selectedSectionsMap);
        var lastSectionNearestImage: ImageExtended = null;
        var roadLabelsScores = state.roadLabelsScores;
        roadLabelsScores.forEach((item) => {
            if (item.isVisible === true) {
                lastSectionNearestImage = this.selectOrUnselectRelatedRoadsSections(item.roadIds, state, checked, selectedSectionsMap);
                item.isSelected = checked;
                item.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(checked);
            }
        });

        var filteredSections = this.getSelectedSections(selectedSectionsMap, state.mergedProject);
        var selectedRoadsSummary = this.computeSummary(filteredSections);
        this.setMapZoom(this.map, state.mergedProject, true, state.openedMapDrawer);

        this.setState({
            selectedSectionsMap: selectedSectionsMap,
            lastClickedSelection: selectedSectionsMap.size > 0 ? { roadSectionId: lastSectionNearestImage.roadSectionId, nearestImageId: lastSectionNearestImage.imageId } : null,
            selectedRoadsSummary: selectedRoadsSummary
        });
    }

    handleClearRoadsSearchText = (state: RoadsConditionAndScenariosViewState): void => {
        this.inputSearchRoadsRef.current.value = "";

        var roadLabelsScores = state.roadLabelsScores;
        roadLabelsScores.forEach((element) => {
            element.isVisible = true;
        });

        this.setState({
            roadLabelsScores: roadLabelsScores
        });
    }

    handleChangeRoadsSearchText = debounce((value: string, state: RoadsConditionAndScenariosViewState): void => {
        let inputValue = value;
        let roadLabelsScores = state.roadLabelsScores;
        if (inputValue.length > 2) {
            this.updateRoadLabelsScoresVisibility(roadLabelsScores, state.averageScoreSliderValue[0] / 10, state.averageScoreSliderValue[1] / 10, inputValue);
        }
        else if (inputValue.length === 0) {
            this.updateRoadLabelsScoresVisibility(roadLabelsScores, state.averageScoreSliderValue[0] / 10, state.averageScoreSliderValue[1] / 10, "");
        }

        this.setState({
            roadLabelsScores: roadLabelsScores
        });
    }, 500);

    updateRoadLabelsScoresVisibility = (roadLabelsScores: Map<string, RoadLabelScoreExtended>, minAverageScore: number, maxAverageScore: number, inputSearchText: string): void => {
        let searchText = inputSearchText.trim().toLowerCase().removeDiacritics();

        roadLabelsScores.forEach((road) => {
            if (((road.averageScore >= minAverageScore && road.averageScore <= maxAverageScore) && road.labelLowerWithoutDiacritics.includes(searchText)) || road.isSelected !== false || road.averageScore === null) {
                road.isVisible = true;
            }
            else {
                road.isVisible = false;
            }
        });
    }

    handleOnAverageScoreChanged = (event: Event, value: number | number[], activeThumb: number): void => {
        const [min, max] = value as number[];
        if (max > min && max !== min)
            this.setState({ averageScoreSliderValue: value as number[] });
    };

    handleOnAverageScoreChangeCommited = (event: React.SyntheticEvent | Event, value: number | number[], state: RoadsConditionAndScenariosViewState): void => {
        const [min, max] = value as number[];
        let roadLabelsScores = state.roadLabelsScores;
        this.updateRoadLabelsScoresVisibility(roadLabelsScores, min / 10, max / 10, this.inputSearchRoadsRef.current.value);
        this.setState({
            roadLabelsScores: roadLabelsScores
        });
    }

    getScenarios = (projectId: string, maintenanceScenarioId?: number): Promise<MaintenanceScenarioExtended[]> => {
        return MaintenanceScenariosApiClient.GetScenarios(projectId)
            .then((res) => {
                if (res) {
                    var data = res.data as MaintenanceScenarioExtended[];
                    data.forEach((item) => {
                        item.isSelected = (maintenanceScenarioId && item.maintenanceScenarioId === maintenanceScenarioId) ? true : false;
                        item.isVisible = true;
                        item.labelLowerWithoutDiacritics = item.label.toLowerCase().removeDiacritics();

                        let areasBySectionId = this.buildAreasBySectionIdsMap(item);
                        item.areasBySectionId = areasBySectionId;
                    });

                    return data;
                }

                return null;
            });
    }

    buildAreasBySectionIdsMap = (item: MaintenanceScenarioExtended) => {
        let areasBySectionId = new Map<number, MaintenanceArea[]>();
        item.areas.forEach((area => {
            area.sections.forEach((section) => {
                if (areasBySectionId.has(section.roadSectionId)) {
                    let includedArea = areasBySectionId.get(section.roadSectionId);
                    includedArea.push(area);
                }
                else {
                    areasBySectionId.set(section.roadSectionId, [area]);
                }
            });
        }));
        return areasBySectionId;
    }

    getMostRecentScenario = (scenarios: MaintenanceScenarioExtended[]): MaintenanceScenarioExtended => {
        return scenarios.reduce((mostRecentScenario, scenario) => {
            if (!mostRecentScenario) {
                return scenario;
            }
            return mostRecentScenario.creationDate.getTime() > scenario.creationDate.getTime()
                ? mostRecentScenario
                : scenario;
        }, null);
    }

    handleChangeScenariosSearchText = (value: string, state: RoadsConditionAndScenariosViewState): void => {
        let inputValue = value;
        let projectScenarios = state.projectScenarios;
        if (inputValue.length > 2) {
            this.updateProjectScenariosVisibility(projectScenarios, inputValue);
        }
        else if (inputValue.length === 0) {
            this.updateProjectScenariosVisibility(projectScenarios, "");
        }

        this.setState({
            projectScenarios: projectScenarios
        });
    }

    handleClearScenariosSearchText = (state: RoadsConditionAndScenariosViewState): void => {
        this.inputSearchScenariosRef.current.value = "";

        var projectScenarios = state.projectScenarios;
        projectScenarios.forEach((element) => {
            element.isVisible = true;
        });

        this.setState({
            projectScenarios: projectScenarios
        });
    }

    updateProjectScenariosVisibility = (projectScenarios: MaintenanceScenarioExtended[], inputSearchText: string): void => {
        let searchText = inputSearchText.trim().toLowerCase().removeDiacritics();

        projectScenarios.forEach((scenario) => {
            if ((scenario.labelLowerWithoutDiacritics.includes(searchText))) {
                scenario.isVisible = true;
            }
            else {
                scenario.isVisible = false;
            }
        });
    }

    handleAddScenarioClicked = (scenarioLabel: string, state: RoadsConditionAndScenariosViewState): Promise<void> => {
        this.setState({ loading: true });

        var projectId = state.projectVersion.projectId;
        var requestArgs: AddScenarioRequestArgs = {
            label: scenarioLabel,
            projectId: projectId,
            projectVersionId: this.selectedProject.projectVersionId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        return MaintenanceScenariosApiClient.AddScenario(requestArgs)
            .then(async (res) => {
                let data = res?.data;

                let warnings = BusinessMessages.GetWarnings(data);
                if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                }

                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    this.setState({ loading: false });
                }
                else {
                    this.inputSearchScenariosRef.current.value = '';
                    const { customData: newScenarioMaintenanceId } = res.data;
                    const projectScenarios = await this.getScenarios(projectId, newScenarioMaintenanceId);
                    const selectedScenario = projectScenarios.find(({ isSelected }) => isSelected)

                    this.setMaintenanceScenarioIdStoredValue(selectedScenario.projectId, newScenarioMaintenanceId);
                    LocalStorage.SetItem(moduleKey, openedScenarioManagementDrawerKey, '' + true);

                    let mergedProject = state.mergedProject;
                    let roadLabelsScores = state.roadLabelsScores;
                    this.hideAreasRelatedShapes();

                    let roadsConditionRelatedFragment: Partial<RoadsConditionAndScenariosViewState> | any = { roadLabelsScores };
                    if (selectedScenario.projectVersionId !== state.projectVersion.projectVersionId) {
                        mergedProject = this.mergedProjectAuscultationsCache.get(selectedScenario.projectVersionId);
                        const selectedRoadsSummary = this.computeSummary(mergedProject.roadsSections);
                        const roadLabelsRanking = this.buildExtendedRoadLabelsScoreRanking(mergedProject, mergedProject.extendedRoads);
                        roadLabelsScores = roadLabelsRanking.roadLabelsScores;

                        roadsConditionRelatedFragment = {
                            averageScoreSliderValue: roadLabelsRanking.averageScoreSliderValue,
                            mergedProject,
                            projectVersion: mergedProject.projectVersion,
                            roadLabelsScores,
                            roadScoreRange: roadLabelsRanking.roadScoreRange,
                            selectedRoadsSummary,
                            selectedSectionsMap: new Map<number, number>(),
                        }

                        this.createMapSectionsShapes(mergedProject, state.activeRoadsConditions);
                    }

                    const displayedAreasIds = new Map<number, number>();
                    this.showAreasRelatedShapes(selectedScenario, displayedAreasIds);
                    this.updateRoadLabelsScoreRelatedArea(roadLabelsScores, selectedScenario);

                    this.resizeMapWhenScenarioView(
                        this.map,
                        state.openedMapDrawer,
                        state.openedScenariosSelectorDrawer,
                        state.openedScenarioManagementDrawer,
                        state.openedAuscultationsSelectorWithRoadsDrawer,
                        state.isLabeledImagesContentOpened
                    );

                    if (state.openedMapDrawer) {
                        this.setMapZoom(this.map, mergedProject, true, state.openedMapDrawer);
                    };

                    if (this._isMounted) {
                        this.setState({
                            ...roadsConditionRelatedFragment,
                            displayedAreasIds,
                            highlightedAreaId: null,
                            isAddScenarioDialogOpened: false,
                            loading: false,
                            openedScenarioManagementDrawer: true,
                            ownerUserIdOfLastSelectedScenario: selectedScenario.ownerUserId,
                            projectScenarios,
                            selectedScenario,
                        });

                        if (state.ownerUserIdOfLastSelectedScenario !== selectedScenario.ownerUserId) {
                            const typesOfWorks = await this.getTypesOfWorks(selectedScenario.ownerUserId);
                            this.setState((prevState) => {
                                if (prevState.selectedScenario.ownerUserId !== selectedScenario.ownerUserId) {
                                    return null;
                                }
                                return { typesOfWorks };
                            });
                        }
                    }
                }
            });
    }

    handleAddScenarioDialogOpened = (): void => {
        this.setState({
            isAddScenarioDialogOpened: true
        });
    }

    handleAddScenarioDialogClosed = (state: RoadsConditionAndScenariosViewState): void => {
        if (this.inputSearchScenariosRef.current.value) {
            this.inputSearchScenariosRef.current.value = "";
            var projectScenarios = this.state.projectScenarios;
            projectScenarios.forEach((element) => {
                element.isVisible = true;
            });

            state.projectScenarios = projectScenarios;
        }

        state.isAddScenarioDialogOpened = false;

        this.setState(state);
    }

    handleUpdateScenarioLabel = (maintenanceScenarioId: number, scenarioLabel: string, state: RoadsConditionAndScenariosViewState): Promise<boolean> => {
        var requestArgs: UpdateScenarioLabelRequestArgs = {
            maintenanceScenarioId: maintenanceScenarioId,
            label: scenarioLabel,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        return MaintenanceScenariosApiClient.UpdateScenarioLabel(requestArgs)
            .then(async (res) => {
                let data = res?.data;

                let warnings = BusinessMessages.GetWarnings(data);
                if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                }

                let errors = BusinessMessages.GetErrors(data);
                let hasError: boolean = false;
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    hasError = true;
                    if (!data.businessErrors.some(x => x.name === MaintenanceScenariosMessages.MaintenanceScenarioNotFound)) {
                        return hasError;
                    }
                }

                this.inputSearchScenariosRef.current.value = "";
                var projectId = state.projectVersion.projectId;
                var projectScenarios = await this.getScenarios(projectId, state.selectedScenario?.maintenanceScenarioId ?? null);

                if (this._isMounted) {
                    this.setState({
                        projectScenarios: projectScenarios
                    });
                }

                return hasError;
            });
    }

    handleDeleteScenario = (maintenanceScenarioId: number, state: RoadsConditionAndScenariosViewState): Promise<void> => {
        var requestArgs: DeleteScenarioRequestArgs = {
            maintenanceScenarioId: maintenanceScenarioId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        return MaintenanceScenariosApiClient.DeleteScenario(requestArgs)
            .then(async (res) => {
                let data = res?.data;

                let warnings = BusinessMessages.GetWarnings(data);
                if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                }

                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                }

                const deletedScenario = state.projectScenarios.find(scenario => scenario.maintenanceScenarioId === maintenanceScenarioId);
                if (this.getMaintenanceScenarioIdStoredValue(deletedScenario.projectId) === maintenanceScenarioId) {
                    this.setMaintenanceScenarioIdStoredValue(deletedScenario.projectId, null);
                };

                this.inputSearchScenariosRef.current.value = "";
                var projectId = state.projectVersion.projectId;
                var projectScenarios = await this.getScenarios(projectId);

                if (this._isMounted) {
                    this.setState({
                        projectScenarios: projectScenarios,
                        selectedScenario: null,
                        ownerUserIdOfLastSelectedScenario: null
                    });
                }

                this.hideAreasRelatedShapes();
            });
    }

    handleDuplicateScenario = (maintenanceScenarioId: number, duplicateScenarioLabel: string, state: RoadsConditionAndScenariosViewState): Promise<boolean> => {
        var requestArgs: DuplicateScenarioRequestArgs = {
            maintenanceScenarioId: maintenanceScenarioId,
            duplicateScenarioLabel: duplicateScenarioLabel,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        return MaintenanceScenariosApiClient.DuplicateScenario(requestArgs)
            .then(async (res) => {
                let data = res?.data;

                let warnings = BusinessMessages.GetWarnings(data);
                if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                }

                let errors = BusinessMessages.GetErrors(data);
                let hasError: boolean = false;
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    hasError = true;
                    if (!data.businessErrors.some(x => x.name === MaintenanceScenariosMessages.MaintenanceScenarioNotFound)) {
                        return hasError;
                    }
                }

                this.inputSearchScenariosRef.current.value = "";
                var projectId = state.projectVersion.projectId;
                var projectScenarios = await this.getScenarios(projectId, state.selectedScenario?.maintenanceScenarioId ?? null);

                if (this._isMounted) {
                    this.setState({
                        projectScenarios: projectScenarios
                    });
                }

                return hasError;
            });
    }

    handleProjectScenarioClicked = async (event: GridRowClickEvent, state: RoadsConditionAndScenariosViewState): Promise<void> => {
        this.setState({ loading: true });

        let projectScenarios = state.projectScenarios;
        let selectedScenario = event.dataItem as MaintenanceScenarioExtended;
        let selected: boolean = !selectedScenario.isSelected;
        selectedScenario.isSelected = selected;
        projectScenarios.forEach((scenario) => {
            if (scenario.maintenanceScenarioId === selectedScenario.maintenanceScenarioId) {
                scenario.isSelected = selected;
            }
            else {
                scenario.isSelected = false;
            }
        });

        this.hideAreasRelatedShapes();

        let newState = { ...state };
        newState.displayedAreasIds = new Map<number, number>();
        newState.highlightedAreaId = null;

        if (selected) {
            if (newState.ownerUserIdOfLastSelectedScenario !== selectedScenario.ownerUserId) {
                let typesOfWorks = await this.getTypesOfWorks(selectedScenario.ownerUserId);
                newState.typesOfWorks = typesOfWorks;
            }

            if (selectedScenario.projectVersionId !== newState.projectVersion.projectVersionId) {
                let mergedProject = await RoadsConditionAndScenariosShared.getMergedProject(
                    selectedScenario.projectVersionId,
                    this.shouldShowUnscoredSections,
                    this.thresholdOfGoodScore,
                    this.thresholdOfPoorScore,
                    this.mergedProjectAuscultationsCache,
                    this.projectVersionsCache);
                let selectedRoadsSummary = this.computeSummary(mergedProject.roadsSections);
                let roadLabelsRanking = this.buildExtendedRoadLabelsScoreRanking(mergedProject, mergedProject.extendedRoads);

                this.createMapSectionsShapes(mergedProject, state.activeRoadsConditions);

                newState.projectVersion = mergedProject.projectVersion;
                newState.mergedProject = mergedProject;
                newState.selectedRoadsSummary = selectedRoadsSummary;
                newState.roadLabelsScores = roadLabelsRanking.roadLabelsScores;
                newState.roadScoreRange = roadLabelsRanking.roadScoreRange;
                newState.averageScoreSliderValue = roadLabelsRanking.averageScoreSliderValue;
                newState.selectedSectionsMap = new Map<number, number>();
            }

            newState.projectScenarios = projectScenarios;
            newState.selectedScenario = selectedScenario;
            this.setMaintenanceScenarioIdStoredValue(selectedScenario.projectId, selectedScenario.maintenanceScenarioId);
            newState.ownerUserIdOfLastSelectedScenario = selectedScenario.ownerUserId;
            newState.openedScenarioManagementDrawer = true;
            LocalStorage.SetItem(moduleKey, openedScenarioManagementDrawerKey, '' + true);

            this.showAreasRelatedShapes(selectedScenario, newState.displayedAreasIds);

            let roadLabelsScores = newState.roadLabelsScores;
            this.updateRoadLabelsScoreRelatedArea(roadLabelsScores, selectedScenario);
            newState.roadLabelsScores = roadLabelsScores;
        }
        else {
            let locationState = this.props.location.state as RouteLocationStateModel;
            newState.typesOfWorks = [];
            if (newState.projectVersion.projectVersionId !== locationState.projectVersionId) {
                let mergedProject = await RoadsConditionAndScenariosShared.getMergedProject(
                    locationState.projectVersionId,
                    this.shouldShowUnscoredSections,
                    this.thresholdOfGoodScore,
                    this.thresholdOfPoorScore,
                    this.mergedProjectAuscultationsCache,
                    this.projectVersionsCache);

                let selectedRoadsSummary = this.computeSummary(mergedProject.roadsSections);
                let roadLabelsRanking = this.buildExtendedRoadLabelsScoreRanking(mergedProject, mergedProject.extendedRoads);

                this.createMapSectionsShapes(mergedProject, state.activeRoadsConditions);

                newState.projectVersion = mergedProject.projectVersion;
                newState.mergedProject = mergedProject;
                newState.selectedRoadsSummary = selectedRoadsSummary;
                newState.roadLabelsScores = roadLabelsRanking.roadLabelsScores;
                newState.roadScoreRange = roadLabelsRanking.roadScoreRange;
                newState.averageScoreSliderValue = roadLabelsRanking.averageScoreSliderValue;
            }

            newState.projectScenarios = projectScenarios;
            newState.selectedScenario = null;
            this.setMaintenanceScenarioIdStoredValue(selectedScenario.projectId, null);
            newState.ownerUserIdOfLastSelectedScenario = null;

            let roadLabelsScores = newState.roadLabelsScores;
            roadLabelsScores.forEach((element) => {
                element.relatedAreas = [];
                element.hiddenIsIncludedInAreaForSort = getHiddenIsIncludedInAreaForSort([]);
            });
            newState.roadLabelsScores = roadLabelsScores;
        }

        this.resizeMapWhenScenarioView(this.map, newState.openedMapDrawer, newState.openedScenariosSelectorDrawer, newState.openedScenarioManagementDrawer, newState.openedAuscultationsSelectorWithRoadsDrawer, newState.isLabeledImagesContentOpened);
        if (newState.openedMapDrawer) {
            this.setMapZoom(this.map, newState.mergedProject, true, newState.openedMapDrawer);
        }
        newState.loading = false;

        if (this._isMounted) {
            this.setState(newState);
        }
    }

    getTypesOfWorks = (ownerUserId: string): Promise<string[]> => {
        return MaintenanceScenariosApiClient.GetTypesOfWorks(ownerUserId)
            .then((res) => {
                if (res) {
                    return res.data;
                }

                return null;
            });
    }

    handleAddOrUpdateArea = async (maintenanceArea: MaintenanceAreaExtended, maintenanceScenarioId: number, isAdd: boolean, isStatusChanged: boolean, state: RoadsConditionAndScenariosViewState): Promise<void> => {
        this.setState({ loading: true });

        let requestArg = {
            maintenanceAreaId: !isAdd ? maintenanceArea.maintenanceAreaId : null,
            maintenanceScenarioId: maintenanceScenarioId,
            label: maintenanceArea.label,
            hexColor: maintenanceArea.hexColor,
            typeOfWork: maintenanceArea.typeOfWork,
            averageScore: maintenanceArea.averageScore,
            lengthInLinearMeters: maintenanceArea.lengthInLinearMeters,
            lengthInLinearMetersIsOverridden: maintenanceArea.lengthInLinearMetersIsOverridden,
            areaInSquareMeters: maintenanceArea.areaInSquareMeters,
            areaInSquareMetersIsOverridden: maintenanceArea.areaInSquareMetersIsOverridden,
            unitPrice: maintenanceArea.unitPrice,
            budgetAmount: maintenanceArea.budgetAmount,
            startDate: maintenanceArea.startDate,
            status: maintenanceArea.status,
            remarks: maintenanceArea.remarks,
            sections: maintenanceArea.sections,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        }

        return await MaintenanceScenariosApiClient.AddOrUpdateScenarioArea(requestArg)
            .then(async (res) => {
                let data = res?.data;

                let warnings = BusinessMessages.GetWarnings(data);
                if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                }

                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                }

                if (!data.hasBusinessErrors && isStatusChanged) {
                    let confirmMessage = "";

                    switch (maintenanceArea.status) {
                        case MaintenanceAreaStatus.draft:
                            confirmMessage = Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_confirmMessage_StatusChangedToDraft;
                            break;

                        case MaintenanceAreaStatus.workToDo:
                            confirmMessage = Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_confirmMessage_StatusChangedToToBeCarriedOut;
                            break;

                        case MaintenanceAreaStatus.workDone:
                            confirmMessage = Translate.Resources.UI_MaintenanceSenarios_ScenarioManagementDrawer_confirmMessage_StatusChangedToFinished;
                            break;

                        default:
                            break;
                    }

                    ToastService.showSuccessToast(confirmMessage);
                }

                let activeScenarioAreaId: number = data.customData;

                await this.updateProjectScenariosAfterAddUpdateOrDeleteAction(maintenanceScenarioId, state, activeScenarioAreaId);
            });
    }

    handleDeleteArea = async (maintenanceScenarioId: number, maintenanceAreaId: number, state: RoadsConditionAndScenariosViewState): Promise<void> => {
        this.setState({ loading: true });

        let requestArgs: DeleteScenarioAreaRequestArgs = {
            maintenanceScenarioId: maintenanceScenarioId,
            maintenanceAreaId: maintenanceAreaId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        await MaintenanceScenariosApiClient.DeleteScenarioArea(requestArgs)
            .then(async (res) => {
                let data = res?.data;

                let warnings = BusinessMessages.GetWarnings(data);
                if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                }

                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    this.setState({ loading: false });
                    ToastService.showErrorToast("", errors);
                }
                else {
                    await this.updateProjectScenariosAfterAddUpdateOrDeleteAction(requestArgs.maintenanceScenarioId, state);
                }
            });
    }

    updateProjectScenariosAfterAddUpdateOrDeleteAction = async (maintenanceScenarioId: number, state: RoadsConditionAndScenariosViewState, activeScenarioAreaId?: number): Promise<void> => {
        await Promise.all([
            MaintenanceScenariosApiClient.GetScenario(state.projectVersion.projectId, maintenanceScenarioId),
            MaintenanceScenariosApiClient.GetTypesOfWorks(state.selectedScenario.ownerUserId)
        ])
            .then((res) => {
                var selectedScenario = res[0].data as MaintenanceScenarioExtended;
                selectedScenario.isSelected = true;
                selectedScenario.isVisible = true;
                selectedScenario.labelLowerWithoutDiacritics = selectedScenario.label.toLowerCase().removeDiacritics();

                let areasBySectionId = this.buildAreasBySectionIdsMap(selectedScenario);
                selectedScenario.areasBySectionId = areasBySectionId;

                var projectScenarios = state.projectScenarios;
                var index = projectScenarios.findIndex(x => x.maintenanceScenarioId === maintenanceScenarioId);
                projectScenarios[index] = selectedScenario;

                var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
                datasource.getShapes().forEach((section: Shape) => {
                    var entityType = section.getProperties().EntityType;
                    if (entityType === ShapeEntityType.areaEnlightened || entityType === ShapeEntityType.area || entityType === ShapeEntityType.sectionSelected) {
                        RoadsConditionAndScenariosShared.hideShape(section);
                    }
                });

                this.showAreasRelatedShapes(selectedScenario, state.displayedAreasIds);

                this.updateRoadLabelsScoreRelatedArea(state.roadLabelsScores, selectedScenario);
                var selectedSectionsMap = new Map<number, number>();
                this.updateRoadLabelsScoresSelections(state.roadLabelsScores, selectedSectionsMap);
                var selectedRoadsSummary: RoadsConditionSummaryModel = this.computeSummary(state.mergedProject.roadsSections);

                var typesOfWorks = res[1].data;

                if (this._isMounted) {
                    this.setState({
                        selectedScenario: selectedScenario,
                        projectScenarios: projectScenarios,
                        selectedSectionsMap: selectedSectionsMap,
                        selectedRoadsSummary: selectedRoadsSummary,
                        roadLabelsScores: state.roadLabelsScores,
                        typesOfWorks: typesOfWorks,
                        highlightedAreaId: activeScenarioAreaId,
                        loading: false
                    });
                }
            });
    }

    updateRoadLabelsScoreRelatedArea = (roadLabelsScores: Map<string, RoadLabelScoreExtended>, selectedScenario: MaintenanceScenarioExtended): void => {
        roadLabelsScores.forEach((roadLabelsScore) => {
            var relatedArea: RelatedAreaModel[] = [];
            roadLabelsScore.sectionIds.forEach((sectionId: number) => {
                selectedScenario.areas.forEach((area) => {
                    if (area.sections.some(x => x.roadSectionId === sectionId) && !relatedArea.some(x => x.maintenanceAreaId === area.maintenanceAreaId)) {
                        relatedArea.push({
                            maintenanceAreaId: area.maintenanceAreaId,
                            maintenanceAreaLabel: area.label,
                            typeOfWork: area.typeOfWork,
                            isDisplayedOnMap: false
                        });
                    }
                });
            });

            roadLabelsScore.relatedAreas = relatedArea;
            roadLabelsScore.hiddenIsIncludedInAreaForSort = getHiddenIsIncludedInAreaForSort(relatedArea);
        });
    }

    handleDisplayOrHideSelectedAreaSection = (maintenanceAreaId: number, sections: MaintenanceAreaSection[], isDisplayAreaSection: boolean, state: RoadsConditionAndScenariosViewState): void => {
        this.setState({ loading: true });

        var displayedAreasIds = state.displayedAreasIds;
        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        sections.forEach((section: MaintenanceAreaSection) => {
            let shapeId = this.getEnlightenedAreaShapeId(section.roadSectionId);
            if (isDisplayAreaSection) {
                RoadsConditionAndScenariosShared.showAreaEnlightenedShape(shapeId, datasource);
                displayedAreasIds.set(maintenanceAreaId, maintenanceAreaId)
            }
            else {
                RoadsConditionAndScenariosShared.hideAreaEnlightenedShape(shapeId, datasource);
                displayedAreasIds.delete(maintenanceAreaId);
            }
        });

        var roadLabelsScores = state.roadLabelsScores;
        roadLabelsScores.forEach((roadLabelsScore) => {
            roadLabelsScore.relatedAreas.forEach((relatedArea) => {
                if (relatedArea.maintenanceAreaId === maintenanceAreaId) {
                    relatedArea.isDisplayedOnMap = isDisplayAreaSection;
                }
            });
            roadLabelsScore.hiddenIsIncludedInAreaForSort = getHiddenIsIncludedInAreaForSort(roadLabelsScore.relatedAreas);
        });

        this.setState({
            roadLabelsScores: roadLabelsScores,
            displayedAreasIds: displayedAreasIds,
            loading: false
        });
    }

    handleAreaSectionsEditStarted = (maintenanceArea: MaintenanceAreaExtended, state: RoadsConditionAndScenariosViewState): void => {
        this.setState({ loading: true });

        //deselect current selected sections
        this.hideAllSectionSelectedShapes();

        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;

        var selectedSectionsMap = new Map<number, number>();
        //select area sections
        let sections = maintenanceArea.sections;
        sections.forEach((section) => {
            this.hideAreaShape(datasource, section.roadSectionId);
            this.showSectionSelectedShape(datasource, section.roadSectionId);

            //NOTE HGA CMA nécessaire à l'algo de selection deselection avec la touche Shift
            var nearestImage = this.getRoadSectionNearestImage(section.roadSectionId);
            selectedSectionsMap.set(section.roadSectionId, nearestImage.imageId);
        });

        var roadLabelsScores = state.roadLabelsScores;

        this.updateRoadLabelsScoreRelatedArea(roadLabelsScores, state.selectedScenario);
        this.updateRoadLabelsScoresSelections(roadLabelsScores, selectedSectionsMap);

        var filteredSections = this.getSelectedSections(selectedSectionsMap, state.mergedProject);
        var selectedRoadsSummary = this.computeSummary(filteredSections);

        this.setState({
            selectedSectionsMap: selectedSectionsMap,
            selectedRoadsSummary: selectedRoadsSummary,
            roadLabelsScores: roadLabelsScores,
            loading: false
        });
    }

    handleAreaSectionsEditCanceled = (maintenanceArea: MaintenanceAreaExtended, state: RoadsConditionAndScenariosViewState): void => {
        this.setState({ loading: true });
        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        let sections = maintenanceArea.sections;
        sections.forEach((section) => {
            this.showAreaShape(datasource, section.roadSectionId, maintenanceArea.hexColor);
            this.hideSectionSelectedShape(datasource, section.roadSectionId);
        });

        var selectedSectionsMap = new Map<number, number>();
        var selectedRoadsSummary: RoadsConditionSummaryModel = this.computeSummary(state.mergedProject.roadsSections);

        this.setState({
            selectedSectionsMap: selectedSectionsMap,
            selectedRoadsSummary: selectedRoadsSummary,
            loading: false
        });
    }

    handleAddAreaCanceled = (maintenanceArea: MaintenanceAreaExtended): void => {
        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        maintenanceArea.sections.forEach((section) => {
            let shapeId = this.getEnlightenedAreaShapeId(section.roadSectionId);
            let shape: Shape = datasource.getShapeById(shapeId);
            RoadsConditionAndScenariosShared.hideShape(shape);
        });
    }

    showImageAndSectionDetails = (image: ImageExtended): void => {
        this.setState((prevState, props) => {
            var isLabeledImagesContentOpened = true;
            if (!prevState.isLabeledImagesContentOpened) {
                if (this.props.isScenarioView) {
                    this.resizeMapWhenScenarioView(this.map, prevState.openedMapDrawer, prevState.openedScenariosSelectorDrawer, prevState.openedScenarioManagementDrawer, prevState.openedAuscultationsSelectorWithRoadsDrawer, isLabeledImagesContentOpened);
                }
                else {
                    this.resizeMapWhenRoadsConditionView(this.map, prevState.openedAuscultationsSelectorWithRoadsDrawer, isLabeledImagesContentOpened);
                }
            }

            var roadSection = prevState.mergedProject.roadsSections.get(image.roadSectionId);
            var roadSectionLabel = roadSection.label ?? roadSection.roadLabel;

            return {
                selectedImage: image,
                selectedRoadSection: roadSection,
                selectedRoadSectionLabel: roadSectionLabel,
                isLabeledImagesContentOpened: isLabeledImagesContentOpened,
                mergedProject: prevState.mergedProject
            };
        });
    }

    selectOrUnselectRelatedRoadsSections(roadIds: number[], state: RoadsConditionAndScenariosViewState, checked: boolean, selectedSectionsMap: Map<number, number>) {
        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        var nearestImage: ImageExtended = null;

        roadIds.forEach((matchedRoadId: number) => {
            var road: RoadViewData = state.mergedProject.extendedRoads.get(matchedRoadId);
            road.extendedSections.forEach((extendedSection: RoadSectionViewData) => {
                let sectionId = extendedSection.roadSectionId;
                let selectedSectionShapeId = this.getSelectedSectionShapeId(sectionId);
                let selectedSectionShape: Shape = datasource.getShapeById(selectedSectionShapeId);
                if (extendedSection.roadSectionScoreId !== null && selectedSectionShape) {
                    nearestImage = this.getRoadSectionNearestImage(sectionId);

                    if (!checked) {
                        //Si la section est déjà sélectionné, on supprime la sélection
                        RoadsConditionAndScenariosShared.hideShape(selectedSectionShape);
                        selectedSectionsMap.delete(sectionId);
                    }
                    else if (checked) {
                        this.showSectionSelected(selectedSectionShape);
                        selectedSectionsMap.set(sectionId, nearestImage.imageId);
                    }
                }
            });
        });
        return nearestImage;
    }

    handleDisplaySectionsFromFilters = (activeRoadsConditions: Map<string, boolean>, activeAnomalies: Set<string>, isGroupView: boolean, state: RoadsConditionAndScenariosViewState): void => {

        let datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        RoadsConditionAndScenariosShared.updateSectionShapesColor(datasource, activeRoadsConditions);

        if (!isEqual(state.activeAnomalies, activeAnomalies) || isGroupView !== state.isGroupView) {
            let hasAnomaliesLayerMapEvent = true;
            let anomaliesDatasource = RoadsConditionAndScenariosShared.recreateAnomaliesDatasource(isGroupView, this.map, state.mergedProject, hasAnomaliesLayerMapEvent, this.anomalyLayerMouseover, this.anomalyLayerMouseout, this.anomalyPointClickHandler, this.handleAnomalyPointClicked);

            if (activeAnomalies.size > 0) {
                state.mergedProject.roadsSections.forEach((section: RoadSectionViewData) => {
                    let scoringParameters = section.roadExtended?.scoringParameters;
                    let anomalies = RoadsConditionAndScenariosShared.getSectionVisibleAnomalies(section, scoringParameters, activeAnomalies);

                    if (anomalies.size >= 1) {
                        let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(section, anomalies);
                        anomaliesDatasource.add(anomalyPoint);
                    }
                });

                //si l'icone voiture est affichée, on la supprime et on la recrée pour qu'elle soit toujours positionnée au dessus
                let existingCarIconLayer = this.map.layers.getLayerById(carIconSymbolLayerId) as layer.SymbolLayer;
                let heading = 0;
                if (existingCarIconLayer) {
                    heading = existingCarIconLayer.getOptions().iconOptions.rotation as number;
                    this.map.layers.remove(existingCarIconLayer);
                    let carDatasource = this.map.sources.getById(carDatasourceId) as source.DataSource;
                    this.createCarIconSymbolLayer(carDatasource, carIconSymbolLayerId, heading);
                }
            }
        }

        this.setState({
            activeRoadsConditions: activeRoadsConditions,
            activeAnomalies: activeAnomalies,
            isGroupView: isGroupView
        });
    }

    handleMapStyleChange = (map) => (mapChoice: CustomMapChoice) => Utilities.ensureCustomMapChoice(map, mapChoice);

    handleToggleAlgorithmsModelVersionInfoDialog = () => this.setState((prevState) => ({
        isAlgorithmsModelVersionInfoDialogOpen: !prevState.isAlgorithmsModelVersionInfoDialogOpen
    }));

    render() {
        const state = this.state;

        const auscultationsSelectorWithRoadsDrawerComponent = <AuscultationsSelectorWithRoadsDrawerComponent
            isScenarioView={this.props.isScenarioView}
            inputSearchRoadsRef={this.inputSearchRoadsRef}
            selectedSections={state.selectedSectionsMap}
            selectedProject={this.selectedProject}
            roadLabelsScores={state.roadLabelsScores}
            goodScoreThreshold={this.thresholdOfGoodScore}
            poorScoreThreshold={this.thresholdOfPoorScore}
            selectedRoadsSummary={state.selectedRoadsSummary}
            projectVersion={state.projectVersion}
            roadScoreRange={state.roadScoreRange}
            averageScoreSliderValue={state.averageScoreSliderValue}
            auscultationsIdsString={state.mergedProject?.auscultationsIdsString}
            openedDrawer={state.openedAuscultationsSelectorWithRoadsDrawer}
            role={this.props.role}
            isAlgorithmsModelVersionInfoDialogOpen={state.isAlgorithmsModelVersionInfoDialogOpen}
            handleDrawerOpened={() => this.handleAuscultationsSelectorWithRoadsDrawerOpened(state)}
            handleDrawerClosed={() => this.handleAuscultationsSelectorWithRoadsDrawerClosed(state)}
            handleSelectAuscultation={(auscultationId, isCompleteHeritageChecked) => this.handleSelectAuscultation(auscultationId, isCompleteHeritageChecked, state)}
            handleRowClick={(item, checked) => this.handleRoadLabelRowClick(item, checked, state)}
            handleChangeRoadsSearchText={(value) => this.handleChangeRoadsSearchText(value, state)}
            handleClearRoadsSearchText={() => this.handleClearRoadsSearchText(state)}
            onAverageScoreChanged={this.handleOnAverageScoreChanged}
            onAverageScoreChangeCommited={(event, value) => this.handleOnAverageScoreChangeCommited(event, value, state)}
            handleHeaderCheckboxChanged={(checked) => this.handleRoadLabelsHeaderCheckboxChanged(checked, state)}
            handleToggleAlgorithmsModelVersionInfoDialog={this.handleToggleAlgorithmsModelVersionInfoDialog}
        />

        let roadsConditionRender: JSX.Element = (!state.loading ? auscultationsSelectorWithRoadsDrawerComponent : <></>);

        let scenarioManagementComponentClassName = `scenario-management-drawer ${state.openedScenarioManagementDrawer ? 'opened' : ''}`;
        scenarioManagementComponentClassName += state.openedScenarioManagementDrawer && state.openedScenariosSelectorDrawer ? ' opened-scenario-selector' : '';
        scenarioManagementComponentClassName += state.openedScenarioManagementDrawer && state.openedAuscultationsSelectorWithRoadsDrawer ? ' opened-auscultations-selector-with-roads' : '';
        scenarioManagementComponentClassName += state.openedScenarioManagementDrawer && state.openedMapDrawer ? ' opened-map' : '';

        let maintenanceScenariosRender: JSX.Element = (
            <Box display="flex" flexDirection="column" className="maintenance-scenarios">
                <Box display="flex" flexDirection="row" className="drawers">
                    <Box className={`scenarios-selector-drawer ${state.openedScenariosSelectorDrawer ? 'opened' : ''}`}>
                        <ScenariosSelectorComponent
                            openedDrawer={state.openedScenariosSelectorDrawer}
                            inputSearchScenariosRef={this.inputSearchScenariosRef}
                            isAddScenarioDialogOpened={state.isAddScenarioDialogOpened}
                            projectScenarios={state.projectScenarios}
                            projectId={state.projectVersion?.projectId}
                            userFullName={this.props.userFullName}
                            selectedScenario={state.selectedScenario}
                            projectLabel={state.mergedProject?.projectLabel}
                            handleDrawerOpened={() => this.handleScenariosSelectorDrawerOpened(state)}
                            handleDrawerClosed={() => this.handleScenariosSelectorDrawerClosed(state)}
                            handleChangeScenariosSearchText={(value) => this.handleChangeScenariosSearchText(value, state)}
                            handleClearScenariosSearchText={() => this.handleClearScenariosSearchText(state)}
                            handleAddScenario={(scenarioLabel) => this.handleAddScenarioClicked(scenarioLabel, state)}
                            handleAddScenarioDialogOpened={this.handleAddScenarioDialogOpened}
                            handleAddScenarioDialogClosed={() => this.handleAddScenarioDialogClosed(state)}
                            handleProjectScenarioClicked={(e) => this.handleProjectScenarioClicked(e, state)}
                            handleUpdateScenarioLabel={(maintenanceScenarioId, scenarioLabel) => this.handleUpdateScenarioLabel(maintenanceScenarioId, scenarioLabel, state)}
                            handleDeleteScenario={(maintenanceScenarioId) => this.handleDeleteScenario(maintenanceScenarioId, state)}
                            handleDuplicateScenario={(maintenanceScenarioId, duplicateScenarioLabel) => this.handleDuplicateScenario(maintenanceScenarioId, duplicateScenarioLabel, state)}
                        />
                    </Box>
                    <Box className={scenarioManagementComponentClassName}>
                        <ScenarioManagementComponent
                            isOpen={state.openedScenarioManagementDrawer}
                            selectedScenario={state.selectedScenario}
                            goodScoreThreshold={this.thresholdOfGoodScore}
                            poorScoreThreshold={this.thresholdOfPoorScore}
                            roadsConditionViewData={state.mergedProject}
                            selectedSectionsMap={state.selectedSectionsMap}
                            typesOfWorks={state.typesOfWorks}
                            displayedAreasIds={state.displayedAreasIds}
                            areasPaletteColors={ScenarioAreasPaletteColors}
                            highlightedAreaId={state.highlightedAreaId}
                            handleDrawerOpened={() => this.handleScenarioManagementDrawerOpened(state)}
                            handleDrawerClosed={() => this.handleScenarioManagementDrawerClosed(state)}
                            handleAddOrUpdateArea={(maintenanceArea, maintenanceScenarioId, isAdd, isStatusChanged) => this.handleAddOrUpdateArea(maintenanceArea, maintenanceScenarioId, isAdd, isStatusChanged, state)}
                            handleDeleteArea={(maintenanceScenarioId, maintenanceAreaId) => this.handleDeleteArea(maintenanceScenarioId, maintenanceAreaId, state)}
                            handleDisplayOrHideSelectedAreaSection={(maintenanceAreaId, sections, isDisplayAreaSection) => this.handleDisplayOrHideSelectedAreaSection(maintenanceAreaId, sections, isDisplayAreaSection, state)}
                            handleAreaSectionsEditStarted={(maintenanceArea) => this.handleAreaSectionsEditStarted(maintenanceArea, state)}
                            handleAreaSectionsEditCanceled={(maintenanceArea) => this.handleAreaSectionsEditCanceled(maintenanceArea, state)}
                            handleAddAreaCanceled={this.handleAddAreaCanceled}
                        />
                    </Box>
                    {auscultationsSelectorWithRoadsDrawerComponent}
                </Box>
            </Box>
        );

        return (
            <>
                <Box display="flex" flexDirection="column">
                    {this.props.isScenarioView &&
                        <Box display="flex" flexDirection="row" alignItems="center" className="scenario-view-header">
                            <img src={iconArrow} alt="arrow" />
                            <div className="project-label">{this.selectedProject?.label}</div>
                            <IconButton className={this.props.role === "ADM" ? "" : "project-infobutton--hidden"} onClick={this.handleToggleAlgorithmsModelVersionInfoDialog} >
                                <InfoOutlinedIcon className={state.isAlgorithmsModelVersionInfoDialogOpen ? "project-infobutton--active" : "project-infobutton--inactive"} />
                            </IconButton>
                        </Box>
                    }
                    <Box className={`roads-condition ${this.props.isScenarioView ? 'scenario-view' : ''}`} display="flex" flexDirection="row">
                        {this.props.isScenarioView ?
                            maintenanceScenariosRender :
                            roadsConditionRender
                        }
                        <MapWithImagesComponent
                            openedMapDrawer={state.openedMapDrawer}
                            openedAuscultationsSelectorWithRoadsDrawer={state.openedAuscultationsSelectorWithRoadsDrawer}
                            openedScenarioManagementDrawer={state.openedScenarioManagementDrawer}
                            openedScenariosSelectorDrawer={state.openedScenariosSelectorDrawer}
                            isScenarioView={this.props.isScenarioView}
                            loading={state.loading}
                            isLabeledImagesContentOpened={state.isLabeledImagesContentOpened}
                            selectedRoadSectionLabel={state.selectedRoadSectionLabel}
                            selectedRoadSection={state.selectedRoadSection}
                            selectedImage={state.selectedImage}
                            thresholdOfGoodScore={this.thresholdOfGoodScore}
                            thresholdOfPoorScore={this.thresholdOfPoorScore}
                            hasScoreAnalysisAccess={this.hasScoreAnalysisAccess}
                            scoringParameters={state.projectVersion?.scoringParameters}
                            role={this.props.role}
                            mergedProject={state.mergedProject}
                            activeRoadsConditions={state.activeRoadsConditions}
                            activeAnomalies={state.activeAnomalies}
                            isGroupView={state.isGroupView}
                            handleDrawerOpened={() => this.handleMapWithImagesDrawerOpened(state)}
                            handleDrawerClosed={() => this.handleMapWithImagesDrawerClosed(state)}
                            handleChangeImage={(imageId) => this.handleChangeImage(imageId, state)}
                            handleSectionChanged={(selectedImage, searchByNext) => this.handleSectionChanged(selectedImage, searchByNext, state)}
                            handleCloseLabeledImageComponent={() => this.handleCloseLabeledImageComponent(state)}
                            handleDisplaySectionsFromFilters={(activeRoadsConditions: Map<string, boolean>, activeAnomalies: Set<string>, isGroupView: boolean) => this.handleDisplaySectionsFromFilters(activeRoadsConditions, activeAnomalies, isGroupView, state)}
                            handleMapStyleChange={this.handleMapStyleChange(this.map)}
                        />
                    </Box>
                </Box>
                {state.isAlgorithmsModelVersionInfoDialogOpen &&
                    <AlgorithmsModelVersionInfoDialog
                        auscultations={state.projectVersion?.auscultations}
                        projectLabel={state.mergedProject?.projectLabel}
                        versionNumber={state.projectVersion?.versionNumber}
                        isOpen={state.isAlgorithmsModelVersionInfoDialogOpen}
                        toggleIsDialogOpen={this.handleToggleAlgorithmsModelVersionInfoDialog}
                    />
                }
            </>
        );
    }
}

export default React.forwardRef(withRouter(RoadsConditionAndScenariosView));
