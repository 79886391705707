export const ScenarioAreasPaletteColors = [
    "#858889",
    "#61b1b9",
    "#ffcccc",
    "#004568",
    "#634b72",
    "#c0ce2e",
    "#a4186d",
    "#78d4ed",
    "#ff6666",
    "#ffc766",
    "#89dca0",
    "transparent"
];