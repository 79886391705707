import axios, { AxiosResponse } from 'axios';
import { Localization } from '../../../../localization/Localization';
import { CommandResult } from '../../../../shared/models/CommandResult';
import { MeasurementSystemType } from '../../../../shared/models/MeasurementSystemType';
import { AddOrUpdateScenarioAreaRequestArgs } from './dataContracts/controller/AddOrUpdateScenarioAreaRequestArgs';
import { AddScenarioRequestArgs } from './dataContracts/controller/AddScenarioRequestArgs';
import { DeleteScenarioAreaRequestArgs } from './dataContracts/controller/DeleteScenarioAreaRequestArgs';
import { DeleteScenarioRequestArgs } from './dataContracts/controller/DeleteScenarioRequestArgs';
import { DuplicateScenarioRequestArgs } from './dataContracts/controller/DuplicateScenarioRequestArgs';
import { SendRoadWorksAreaMessageRequestArgs } from './dataContracts/controller/SendRoadWorksAreaMessageRequestArgs';
import { SendScenarioRequestArgs } from './dataContracts/controller/SendScenarioRequestArgs';
import { UpdateScenarioLabelRequestArgs } from './dataContracts/controller/UpdateScenarioLabelRequestArgs';
import { MaintenanceScenario } from './dataContracts/queryStack/MaintenanceScenario';
import { RecipientForScenarioSending } from './dataContracts/queryStack/RecipientForScenarioSending';

const controllerUrl = 'api/MaintenanceScenarios/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export class MaintenanceScenariosApiClient {

    public static GetScenarios = (projectId: string)
        : Promise<AxiosResponse<MaintenanceScenario[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetScenarios?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetRoadWorks = (projectId: string)
        : Promise<AxiosResponse<MaintenanceScenario[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetRoadWorks?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static SendRoadWorksAreaMessage = (requestArgs: SendRoadWorksAreaMessageRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SendRoadWorksAreaMessage`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetScenario = (projectId: string, maintenanceScenarioId: number)
        : Promise<AxiosResponse<MaintenanceScenario>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetScenario?projectId=${encodeURIComponent(projectId)}&maintenanceScenarioId=${encodeURIComponent(maintenanceScenarioId)}`,
            headers: headers
        });
    }

    public static AddScenario = (requestArgs: AddScenarioRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddScenario`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static DeleteScenario = (requestArgs: DeleteScenarioRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DeleteScenario`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateScenarioLabel = (requestArgs: UpdateScenarioLabelRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateScenarioLabel`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static DuplicateScenario = (requestArgs: DuplicateScenarioRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DuplicateScenario`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetTypesOfWorks = (ownerUserId: string)
        : Promise<AxiosResponse<string[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetTypesOfWorks?ownerUserId=${encodeURIComponent(ownerUserId)}`,
            headers: headers
        });
    }

    public static AddOrUpdateScenarioArea = (requestArgs: AddOrUpdateScenarioAreaRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddOrUpdateScenarioArea`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static DeleteScenarioArea = (requestArgs: DeleteScenarioAreaRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DeleteScenarioArea`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetRecipientsForScenarioSending = (projectId: string)
        : Promise<AxiosResponse<RecipientForScenarioSending[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetRecipientsForScenarioSending?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static SendScenario = (requestArgs: SendScenarioRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}SendScenario`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static ExportExcel = (maintenanceScenarioId: number, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportExcel?maintenanceScenarioId=${encodeURIComponent(maintenanceScenarioId)}&language=${encodeURIComponent(Localization.locale)}&ianaTimeZoneId=${encodeURIComponent(Localization.ianaTimeZoneId)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}`,
            headers: {
                'Accept': 'application/vnd.ms-excel',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportGeoJson = (maintenanceScenarioId: number, scenarioLabel: string, projectLabel: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportGeoJson?maintenanceScenarioId=${encodeURIComponent(maintenanceScenarioId)}&projectLabel=${encodeURIComponent(projectLabel)}&scenarioLabel=${encodeURIComponent(scenarioLabel)}&ianaTimeZoneId=${encodeURIComponent(Localization.ianaTimeZoneId)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportShapefile = (maintenanceScenarioId: number, scenarioLabel: string, projectLabel: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportShapefile?maintenanceScenarioId=${encodeURIComponent(maintenanceScenarioId)}&projectLabel=${encodeURIComponent(projectLabel)}&scenarioLabel=${encodeURIComponent(scenarioLabel)}&ianaTimeZoneId=${encodeURIComponent(Localization.ianaTimeZoneId)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}`,
            headers: {
                'Accept': 'application/zip',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportKml = (maintenanceScenarioId: number, scenarioLabel: string, projectLabel: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportKml?maintenanceScenarioId=${encodeURIComponent(maintenanceScenarioId)}&projectLabel=${encodeURIComponent(projectLabel)}&scenarioLabel=${encodeURIComponent(scenarioLabel)}&ianaTimeZoneId=${encodeURIComponent(Localization.ianaTimeZoneId)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}`,
            headers: {
                'Accept': 'application/zip',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }
}
